import { useTranslation } from 'react-i18next';

import { AnimationShell, Icon, Link, Typography } from '@/components';

export function OpportunityCardPremium() {
  const { t } = useTranslation(['opportunities']);
  const makeOppPremiumUrl = 'https://buy.stripe.com/8wM7wkcmM4V5gms007';
  const classNameCard = 'grid w-full grid-cols-1 place-items-stretch gap-6 bg-neutral-25';
  const classNameCardInside =
    'my-4 w-full items-center justify-between rounded-md border border-neutral-50 bg-yellow-50 p-8';

  return (
    <AnimationShell type="opacity" className={classNameCard}>
      <div className={classNameCardInside}>
        <Typography as="h3" size="heading-2" color="primary" weight="semibold">
          {t('Want to get better results?')}
        </Typography>
        <Typography as="p" size="body-2" color="primary" weight="normal" className="mt-4">
          {t(
            "Featured projects reach x10 more investors and receive x3 NBOs. Enhance your project's visibility and receive more offers by upgrading to our premium service for just €2.000 + VAT"
          )}
          {/* <Link to="mailto:projects@nteaser.com">projects@nteaser.com</Link> */}
        </Typography>
        <div className="mt-4 flex justify-center">
          <Link
            to={makeOppPremiumUrl}
            target="_blank"
            className="flex items-center justify-center gap-x-2 rounded-md border border-solid border-secondary-accent bg-secondary-accent px-2 py-1.5 text-center text-sm font-semibold text-white transition hover:bg-white hover:text-secondary-accent hover:no-underline active:bg-secondary-accent active:text-white"
          >
            <span className="flex items-center justify-center gap-x-1">
              <Icon name="lightning" className="h-5 w-5 fill-inherit text-inherit" />

              {t('Upgrade now')}
            </span>
          </Link>
        </div>

        <Typography as="p" size="body-2" color="primary" weight="normal" className="mt-4">
          {t('With the upgrade, you get:')}
        </Typography>
        <ul className=" mt-2 list-disc space-y-2 pl-8">
          <li>
            <Typography as="p" size="body-2" color="primary" weight="normal">
              <b>{t('Prime Visibility')}: </b>
              <span>{t('Featured publication on the first page of nTeaser.')}</span>
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="primary" weight="normal">
              <b>{t('Extensive Reach')}: </b>
              <span>{t('Over 1,000 investors see your listing and can send NBOs.')}</span>
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="primary" weight="normal">
              <b>{t('Targeted Outreach')}: </b>
              <span>{t('10-12 emails sent to potential buyers.')}</span>
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="primary" weight="normal">
              <b>{t('Enhanced LinkedIn Promotion')}: </b>
              <span>{t('Personalised post')}</span>
            </Typography>
          </li>
        </ul>
      </div>
    </AnimationShell>
  );
}
