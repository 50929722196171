import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/query';

import { API_BASE_URL } from '@/config';

import { logout } from '../store';

export const baseQuery = fetchBaseQuery({
  baseUrl: `${API_BASE_URL}`,
  credentials: 'include',
});

export const baseQueryHandler: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions = {}
) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401 || result?.error?.status === 403) {
    api.dispatch(logout());
  } 

  return result;
};
