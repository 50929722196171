import { ClassValue } from 'clsx';

import { Icon } from '@/components';
import { cn } from '@/utils/styles';

import { IconName } from './types';

export type IconState = {
  icon: IconName;
  status: 'active' | 'inactive';
};

export type IconsProps = {
  readonly icons: IconState[];
  readonly size?: 'md' | 'lg' | 'xl';
  readonly disabled?: boolean;
  readonly className?: ClassValue;
};

export function Icons({ icons, size = 'md', disabled = false, className }: IconsProps) {
  return (
    <div className={cn('inline-flex items-center justify-center gap-2', className)}>
      {icons.map(({ icon, status }, index) => (
        <Icon
          key={`${icon}-${index.toString()}`}
          name={icon}
          className={cn(
            size === 'md' ? 'h-6 w-6' : size === 'lg' ? 'h-8 w-8' : 'h-10 w-10',
            disabled || status === 'inactive' ? 'text-neutral-200' : 'text-success-700'
          )}
        />
      ))}
    </div>
  );
}
