import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AVATAR_FOLDER_ID } from '@/config';
import { FileSchema, useUploadFileMutation } from '@/features/files';
import { useNotifications } from '@/features/notifications';

import { useUpdateUserMutation } from '../api';

export function useUploadAvatar() {
  const [updateUser] = useUpdateUserMutation();
  const [uploadFile] = useUploadFileMutation();
  const { t } = useTranslation(['users']);
  const { add } = useNotifications();

  return useCallback(
    (file: File | null, onSuccess?: (file: FileSchema) => void) => {
      if (!file) return;

      uploadFile({ file, folder: AVATAR_FOLDER_ID })
        .unwrap()
        .then((data) => {
          onSuccess?.(data);
          updateUser({ avatar: data.id });
          add({
            id: new Date().toString(),
            title: t('Avatar uploaded'),
            description: t('Avatar uploaded successfully'),
          });
        })
        .catch(() => {
          add({
            id: new Date().toString(),
            title: t('ERROR'),
            description: t('There was an error uploading the avatar. Please try again later.'),
          });
        });
    },
    [uploadFile, updateUser, add, t]
  );
}
