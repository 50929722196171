import { isNullish } from '@/types';
import { Suscription } from '../types';

export function useSuscriptions() {
  const isActive = (suscription: Suscription) => {
    const now = new Date();
    const start = new Date(suscription.start_at);
    const end = new Date(suscription.end_at);
    return now >= start && now <= end;
  };

  const isTrial = (suscription: Suscription) => {
    return (
      suscription.start_at &&
      suscription.end_at &&
      isNullish(suscription.renew_at) &&
      isNullish(suscription.renewal_cycle)
    );
  };

  const hasTrial = (suscriptions: Suscription[]) => {
    return suscriptions.find((suscription) => isTrial(suscription));
  };

  const hasTrialActive = (suscriptions: Suscription[]) => {
    return suscriptions.find((suscription) => isTrial(suscription) && isActive(suscription));
  };

  const hasPremiumActive = (suscriptions: Suscription[]) => {
    return suscriptions.find((suscription) => !isTrial(suscription) && isActive(suscription));
  };

  const getExpiredDays = (suscription: Suscription) => {
    const now = new Date();
    const end = new Date(suscription.end_at);

    return Math.ceil((end.getTime() - now.getTime()) / (1000 * 3600 * 24));
  };

  const getActiveSuscriptions = (suscriptions: Suscription[]) => {
    return suscriptions.some((suscription) => isActive(suscription));
  };

  return { hasTrial, hasTrialActive, hasPremiumActive, getExpiredDays, getActiveSuscriptions };
}
