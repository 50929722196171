export const colors = {
  primary:
    'border-neutral-50 hover:border-base-primary focus:shadow-primary focus:border-primary-700 focus-visible:border-primary-700 active:border-primary-700 focus:ring-primary-50',
  secondary:
    'border-neutral-50 hover:border-base-primary focus:shadow-secondary focus:border-secondary-accent focus-visible:border-secondary-accent active:border-secondary-accent focus:ring-secondary-50',
  neutral:
    'border-neutral-50 hover:border-base-primary focus:shadow-error focus:border-base-primary focus-visible:border-base-primary active:border-base-primary focus:ring-neutral-200',
  // Feedback
  warning:
    'border-warning-400 hover:border-warning-400 focus:shadow-warning focus:border-warning-400 focus-visible:border-warning-400 active:border-warning-400 focus:ring-warning-200',
  error:
    'border-secondary-accent hover:border-secondary-accent focus:shadow-secondary focus:border-secondary-accent focus-visible:border-secondary-accent active:border-secondary-accent focus:ring-secondary-50',
};
