import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TechnologyCollection } from '@/features/projects/types';

export function useCollections() {
  const { t } = useTranslation(['collections']);

  const options = useMemo(
    () => [
      { label: t(`Solar`), value: 'projects_solar_technologies' as TechnologyCollection },
      { label: t(`Wind`), value: 'projects_wind_technologies' as TechnologyCollection },
      { label: t(`BESS`), value: 'projects_bess_technologies' as TechnologyCollection },
    ],
    [t]
  );

  const kv = useMemo(
    () =>
      options.reduce((map, option) => ({ ...map, [option.value]: option.label }), {}) as Record<
        TechnologyCollection,
        string
      >,
    [options]
  );

  const map = useCallback((value: TechnologyCollection) => kv[value], [kv]);

  return { options, kv, map };
}
