import { ClassValue } from 'clsx';
import { ReactNode } from 'react';

import { AnimationShell, Typography } from '@/components';
import { cn } from '@/utils/styles';

export type TableProps<T> = {
  readonly rows: T[];
  readonly columns: {
    key: string;
    title: string;
    render: (row: T, index: number) => ReactNode;
    className?: ClassValue;
  }[];
};

export function Table<T extends { id: string | number }>({ rows, columns }: TableProps<T>) {
  return (
    <table className="min-w-full whitespace-nowrap text-left">
      <thead className="bg-neutral-50">
        <tr>
          {columns.map((column, index, { length }) => (
            <AnimationShell
              key={column.key}
              element="th"
              type="opacity"
              delay={0.1 * index}
              className={cn('py-2.5 pl-4 pr-2.5', {
                'rounded-tl-lg': index === 0,
                'rounded-tr-lg': index === length - 1,
              })}
            >
              <Typography as="span" size="caption" color="primary" weight="semibold">
                {column.title}
              </Typography>
            </AnimationShell>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map((row, index) => (
          <AnimationShell
            key={row.id}
            element="tr"
            type="slide-up"
            delay={0.05 * index}
            className="group cursor-pointer border-b border-neutral-50 bg-base-white transition hover:bg-primary-50"
          >
            {columns.map((column, index) => (
              <td key={column.key} className={cn('py-3 pl-4 pr-2.5', column.className)}>
                {column.render(row, index)}
              </td>
            ))}
          </AnimationShell>
        ))}
      </tbody>
    </table>
  );
}
