import { ClassValue } from 'clsx';
import { ReactNode } from 'react';

import { cn } from '@/utils/styles';

import { alignments, colors, families, sizes, weights } from './constants';
import type {
  TypographyAlign,
  TypographyColor,
  TypographyElements,
  TypographyFamily,
  TypographySize,
  TypographyWeight,
} from './types';

const levels: Record<TypographySize, TypographyElements> = {
  'caption': 'span',
  'body-3': 'p',
  'body-2': 'p',
  'body-1': 'p',
  'title': 'h3',
  'heading-2': 'h2',
  'heading-1': 'h1',
};

export type TypographyProps = {
  readonly children: ReactNode;
  readonly family?: TypographyFamily;
  readonly color?: TypographyColor;
  readonly size?: TypographySize;
  readonly weight?: TypographyWeight;
  readonly align?: TypographyAlign;
  readonly as?: TypographyElements;
  readonly className?: ClassValue;
};

export function Typography({
  children,
  family = 'sans',
  size = 'body-1',
  color = 'primary',
  weight = 'semibold',
  align = 'left',
  as,
  className,
}: TypographyProps) {
  const Component = as ?? levels[size];

  return (
    <Component
      className={cn(families[family], weights[weight], sizes[size], colors[color], alignments[align], className)}
    >
      {children}
    </Component>
  );
}
