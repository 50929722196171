import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initial-state';
import { error, loading, login, logout, reset } from './reducers';

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState(),
  reducers: { error, loading, login, logout, reset },
});
