import { skipToken } from '@reduxjs/toolkit/query';

import { useCurrentUser } from '@/features/users/hooks';

import { useGetOpportunityOffersQuery } from '../api';
import { OpportunityDetails, OpportunityItemList } from '../types';

export function useIsOpportunityEnabled(opportunity: OpportunityItemList | OpportunityDetails | null) {
  const user = useCurrentUser();
  const { data: offers } = useGetOpportunityOffersQuery(opportunity ? { id: opportunity.id } : skipToken);
  if (!opportunity) return null;
  if (!user) return null;
  if (!offers) return null;

  return (
    opportunity.deadline.category !== 'overdue' ||
    opportunity.company === user.company.id ||
    offers.offers.map((offer) => offer.company.id === user.company.id).includes(true)
  );
}
