import { useTranslation } from 'react-i18next';

import { Field, Input } from '@/components';

export type SearchFilterProps = {
  readonly defaultValue?: string;
  readonly onChange: (value: string) => void;
};

export function SearchFilter({ defaultValue = '', onChange }: SearchFilterProps) {
  const { t } = useTranslation(['opportunities']);

  return (
    <Field id="search">
      <Input
        type="search"
        color={'primary'}
        placeholder={t(`Search...`)}
        rightIcon="search"
        defaultValue={defaultValue}
        registration={{
          name: 'search',
          onChange: (event) => {
            onChange(event.target.value);
            return Promise.resolve();
          },
        }}
      />
    </Field>
  );
}
