import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Form, Input } from '@/components';

export type UserFormFields = {
  first_name: string;
  last_name: string;
  email: string;
};

export type TeamFormProps = {
  readonly onSubmit: (data: UserFormFields) => void;
};

export function TeamForm({ onSubmit }: TeamFormProps) {
  const { t } = useTranslation(['team']);

  const defaultValues = useMemo(
    () => ({
      first_name: '',
      last_name: '',
      email: '',
    }),
    []
  );

  return (
    <Form<UserFormFields> id="invite-member-form" onSubmit={onSubmit} options={{ defaultValues }}>
      {({ register, formState }) => (
        <div className="space-y-6">
          <Field
            id="first_name"
            label={t('First name')}
            error={formState.errors['first_name']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="first_name"
              type="text"
              placeholder={t('John')}
              color={formState.errors['first_name'] ? 'error' : 'primary'}
              registration={register('first_name', { required: t('This field is required') })}
            />
          </Field>

          <Field
            id="last_name"
            label={t('Last name')}
            error={formState.errors['last_name']}
            delay={0.15}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="last_name"
              type="text"
              placeholder={t('Doe')}
              color={formState.errors['last_name'] ? 'error' : 'primary'}
              registration={register('last_name')}
            />
          </Field>

          <Field
            id="email"
            label={t('Email address')}
            error={formState.errors['email']}
            delay={0.25}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="email"
              type="email"
              placeholder={t('john.doe@example.com')}
              color={formState.errors['email'] ? 'error' : 'primary'}
              registration={register('email', { required: t('This field is required') })}
            />
          </Field>
        </div>
      )}
    </Form>
  );
}
