import { Button, Typography } from '@/components';
import { useTranslation } from 'react-i18next';

export type OpportunitiesContentHeaderProps = {
  readonly onCreateOpportunity: () => void;
};

export function OpportunitiesContentHeader({ onCreateOpportunity }: OpportunitiesContentHeaderProps) {
  const { t } = useTranslation(['opportunities']);

  return (
    <>
      <div className="flex justify-end">
        <Button size="md" color="primary" icon="plus" onClick={() => onCreateOpportunity()}>
          {t('Create project')}
        </Button>
      </div>
      <div className="space-y-3">
        <Typography as="h1" size="heading-1" color="primary" weight="semibold">
          {t('Projects')}
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal">
          {t(
            'Explore specific renewable energy projects in Spain, including PV, wind, BESS, and hybrid projects, where you can submit Expressions of Interest.'
          )}
        </Typography>
      </div>
    </>
  );
}
