import { AnimationShell } from '@/components';
import { OfferItemList } from '@/features/offers/types';

import { MyOffersItem } from '../item';

export type MyOffersItemsProps = {
  readonly offers: OfferItemList[];
};

export function MyOffersItems({ offers }: MyOffersItemsProps) {
  return (
    <AnimationShell
      type="opacity"
      className="grid w-full grid-cols-1 place-items-stretch gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
    >
      {offers.map((offer, index) => (
        <MyOffersItem key={offer.id} index={index} offer={offer} />
      ))}
    </AnimationShell>
  );
}
