import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';

import { GA_MEASUREMENT_ID } from '@/config';
import { Notifications } from '@/features/notifications';
import { store } from '@/store';

import { Router } from './pages/router';

export function App() {
  // Google Analytics
  ReactGA.initialize(GA_MEASUREMENT_ID);

  return (
    <Provider store={store}>
      <Notifications />
      <Router />
    </Provider>
  );
}
