import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Upload({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="upload"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5303 11.3027C12.2374 11.0098 11.7626 11.0098 11.4697 11.3027L9.30268 13.4697C9.00978 13.7626 9.00978 14.2374 9.30268 14.5303C9.59557 14.8232 10.0704 14.8232 10.3633 14.5303L11.25 13.6437V19C11.25 19.4142 11.5858 19.75 12 19.75C12.4142 19.75 12.75 19.4142 12.75 19V13.6437L13.6367 14.5303C13.9296 14.8232 14.4044 14.8232 14.6973 14.5303C14.9902 14.2374 14.9902 13.7626 14.6973 13.4697L12.5303 11.3027Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.58995 9.33614C6.33184 6.41742 8.97322 4.25 12.1249 4.25C15.8492 4.25 18.8749 7.27579 18.8749 11V11.2616C21.0708 11.4235 22.8099 13.2637 22.8099 15.5C22.8099 17.8422 20.9022 19.75 18.5599 19.75H15.9999C15.5857 19.75 15.2499 19.4142 15.2499 19C15.2499 18.5858 15.5857 18.25 15.9999 18.25H18.5599C20.0737 18.25 21.3099 17.0138 21.3099 15.5C21.3099 13.9862 20.0737 12.75 18.5599 12.75H18.1249C17.7107 12.75 17.3749 12.4142 17.3749 12V11C17.3749 8.10421 15.0207 5.75 12.1249 5.75C9.52293 5.75 7.36166 7.65439 6.952 10.1447C6.89472 10.4929 6.60248 10.7541 6.25007 10.772C4.26523 10.8731 2.68994 12.4991 2.68994 14.5C2.68994 16.5708 4.36916 18.25 6.43994 18.25H7.99994C8.41416 18.25 8.74994 18.5858 8.74994 19C8.74994 19.4142 8.41416 19.75 7.99994 19.75H6.43994C3.54073 19.75 1.18994 17.3992 1.18994 14.5C1.18994 11.8877 3.09979 9.74201 5.58995 9.33614Z"
        fill="currentColor"
      />
    </svg>
  );
}
