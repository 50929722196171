import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useStructureType() {
  const [t] = useTranslation(['projects']);

  const options = useMemo(
    () => [
      { label: t('Fixed'), value: 'fixed' as const },
      { label: t('1 Axis Tracker'), value: '1-axis-tracker' as const },
      { label: t('2 Axis Tracker'), value: '2-axis-tracker' as const },
    ],
    [t]
  );

  const kv = useMemo(
    () => options.reduce((map, option) => ({ ...map, [option.value]: option.label }), {}) as Record<string, string>,
    [options]
  );

  const map = useCallback((value?: string | null) => (value ? kv[value] : null), [kv]);

  return { options, kv, map };
}
