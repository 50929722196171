import { OpportunityStats, OpportunityStatsDetails } from '../types';

export type opportunityStatsTransformerProps = {
  readonly opportunity: string;
  readonly opportunityStats?: OpportunityStats[];
};

export function opportunityStatsTransformer({
  opportunity,
  opportunityStats,
}: opportunityStatsTransformerProps): OpportunityStatsDetails | null {
  if (!opportunityStats && opportunityStats?.length === 0) return null;

  return {
    opportunity: opportunity,
    views: opportunityStats?.find((stat) => stat.key === 'view')?.count ?? 0,
    downloads: opportunityStats?.find((opportunity) => opportunity.key === 'download_teaser')?.count ?? 0,
  };
}
