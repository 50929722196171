import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AnimationShell, Link, Typography } from '@/components';
import { Route } from '@/constants';
import { UserForm, UserFormFields, useLogin, useRegisterMutation } from '@/features/auth';
import { useNotifications } from '@/features/notifications';
import { useRegisterGAPageView } from '@/hooks';

export function SignUpPage() {
  const { t } = useTranslation(['signup']);
  const { add } = useNotifications();
  useRegisterGAPageView('Sign up');

  const [register] = useRegisterMutation();

  const login = useLogin();

  const handleSubmit = useCallback(
    (data: UserFormFields) => {
      register(data)
        .unwrap()
        .then(() => {
          add({
            id: new Date().toString(),
            title: t('Account created'),
            description: t('Your account has been created'),
          });
          login({ email: data.email, password: data.password });
        })
        .catch(() => {
          add({
            id: new Date().toString(),
            title: t('ERROR'),
            description: t(`There was an error creating the account. Please try again later.`),
          });
        });
    },
    [t, add, login, register]
  );

  return (
    <div className="w-full space-y-8">
      <Typography as="h1" size="heading-1" color="primary" weight="semibold">
        {t(`Create your account`)}
      </Typography>

      <UserForm onSubmit={handleSubmit} />

      <AnimationShell type="opacity" delay={0.35} className="inline-flex w-full items-center justify-center gap-x-1">
        <Typography weight="normal" size="body-2">
          {t(`Already have an account?`)}
        </Typography>
        <Link to={Route.SignIn}>{t(`Sign in`)}</Link>
      </AnimationShell>
    </div>
  );
}
