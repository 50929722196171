import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AnimationShell, Icon, Typography } from '@/components';
import { Route } from '@/constants';
import { EmailAddressForm, EmailAddressFormFields, usePasswordRequestMutation } from '@/features/auth';
import { useRegisterGAPageView } from '@/hooks';

function useRecoveryPasswordPage() {
  const navigate = useNavigate();

  const [mutation, { isLoading, isSuccess, isError }] = usePasswordRequestMutation();
  useRegisterGAPageView('Recovery password');

  const onSubmitEmailAddressForm = useCallback(
    ({ email }: EmailAddressFormFields) => {
      mutation({ email })
        .unwrap()
        .then(() => {
          navigate(Route.RecoveryPasswordSuccess);
        });
    },
    [mutation, navigate]
  );

  return {
    navigate,
    isMutating: isLoading,
    isMutated: isSuccess || isError,
    onSubmitEmailAddressForm,
  };
}

export function RecoveryPasswordPage() {
  const { t } = useTranslation(['reset-password']);

  const { navigate, onSubmitEmailAddressForm } = useRecoveryPasswordPage();

  return (
    <div className="w-full space-y-8">
      <AnimationShell
        type="slide-right"
        className="inline-flex cursor-pointer items-center gap-x-1.5 text-primary-900 transition hover:text-base-brand"
        onClick={() => navigate(Route.SignIn)}
      >
        <Icon name="chevron-left" className="h-4 w-4 text-inherit" />
        <Typography size="body-3" color="inherit">
          {t('Go back')}
        </Typography>
      </AnimationShell>

      <Typography>{t('Recovery password')}</Typography>

      <EmailAddressForm onSubmit={onSubmitEmailAddressForm} />
    </div>
  );
}
