import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OfferMilestoneType } from '@/features/offers/types';

export function useMilestones() {
  const { t } = useTranslation(['milestones']);

  const options = useMemo(
    () => [
      { label: t('SPA Signing'), value: 'spa-signing' as OfferMilestoneType },
      { label: t('Ready to Build'), value: 'ready-to-build' as OfferMilestoneType },
      { label: t('COD'), value: 'cod' as OfferMilestoneType },
      // { label: t('Operation'), value: 'operation' as OfferMilestoneType },
      { label: t('Other'), value: 'other' as OfferMilestoneType },
    ],
    [t]
  );

  const kv = useMemo(
    () =>
      options.reduce((map, option) => ({ ...map, [option.value]: option.label }), {}) as Record<
        OfferMilestoneType,
        string
      >,
    [options]
  );

  const map = useCallback((value: OfferMilestoneType) => kv[value], [kv]);

  return { options, kv, map };
}
