import { SidebarState } from './types';

export function toogle(state: SidebarState) {
  state.isOpen = !state.isOpen;
}

export function open(state: SidebarState) {
  state.isOpen = true;
}

export function close(state: SidebarState) {
  state.isOpen = false;
}
