import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useProjectStatus(status?: string) {
  const { t } = useTranslation(['projects']);

  return useMemo(() => {
    switch (status) {
      case 'draft':
        return t('Draft');
      case 'pending':
        return t('Pending');
      case 'published':
        return t('Published');
      case 'archived':
        return t('Archived');
      default:
        return t('Draft');
    }
  }, [status, t]);
}
