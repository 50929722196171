import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Field, Form, Input, TextArea, Typography } from '@/components';
import { ASSETS_BASE_URL } from '@/config';
import { CompanySchema } from '@/features/company/types';
import { ImageUpload } from '@/features/files';
import { nullableStringSetter } from '@/helpers';
import { useDevelopmentStatus } from '@/hooks';

import { useUploadLogo } from '../../hooks';

export type CompanyFormFields = {
  name: string;
  logo: string | null;
  website: string | null;
  description: string | null;
  activity_interests: string[];
  technology_interests: string[];
  development_status_interests: string[];
  capacity_interests: string[];
  location_interests: string;
};

export type CompanyFormProps = {
  readonly company: CompanySchema;
  readonly onSubmit: (data: CompanyFormFields) => void;
};

export function CompanyForm({ company, onSubmit }: CompanyFormProps) {
  const { t } = useTranslation(['settings']);

  const uploadLogo = useUploadLogo({ id: company.id });
  const { options: developmentStatusOptions } = useDevelopmentStatus();

  const options = useMemo(
    () => ({
      defaultValues: {
        name: company?.name ?? '',
        logo: company?.logo ?? null,
        website: company?.website ?? null,
        description: company?.description ?? null,
        activity_interests: company?.activity_interests ?? [],
        technology_interests: company?.technology_interests ?? [],
        development_status_interests: company?.development_status_interests ?? [],
        capacity_interests: company?.capacity_interests ?? [],
        location_interests: company?.location_interests?.join(',') ?? '',
      },
    }),
    [company]
  );

  return (
    <div className="space-y-6">
      <Form<CompanyFormFields> id="company-form" onSubmit={onSubmit} options={options}>
        {({ formState, register, watch, setValue }) => (
          <>
            <div className="mb-8 flex flex-col space-y-6">
              <Typography as="h5" color="primary" size="title" weight="normal">
                {t('Company info')}
              </Typography>

              <Field
                id="logo"
                label={t('Logo')}
                error={formState.errors['logo']}
                delay={0}
                classes={{ field: 'space-y-1' }}
              >
                <ImageUpload
                  baseUrl={ASSETS_BASE_URL}
                  value={watch('logo')}
                  onSelectFile={(file) => {
                    uploadLogo(file, (data) => {
                      setValue('logo', data.id);
                    });
                  }}
                />
              </Field>

              <Field
                id="name"
                label={t('Name')}
                error={formState.errors['name']}
                delay={0.15}
                classes={{ field: 'space-y-1' }}
              >
                <Input
                  id="name"
                  color={formState.errors['name'] ? 'error' : 'primary'}
                  autoComplete="off"
                  registration={register('name', {
                    required: t('This field is required'),
                    setValueAs: nullableStringSetter,
                  })}
                />
              </Field>

              <Field
                id="website"
                label={t('Website')}
                error={formState.errors['website']}
                delay={0.3}
                classes={{ field: 'space-y-1' }}
              >
                <Input
                  id="website"
                  color={formState.errors['name'] ? 'error' : 'primary'}
                  placeholder="e.g. https://www.nteaser.com"
                  registration={register('website')}
                />
              </Field>

              <Field
                id="description"
                label={t('Company description')}
                error={formState.errors['description']}
                delay={0.45}
                classes={{ field: 'space-y-1' }}
              >
                <TextArea id="description" placeholder="Type here..." registration={register('description')} />
              </Field>
            </div>
            <hr></hr>
            <div className="mt-8 flex flex-col space-y-6">
              <Typography as="h5" color="primary" size="title" weight="normal">
                {t('Company interests')}
              </Typography>

              <Field label={t('Activity')} delay={0.15} classes={{ field: 'space-y-3', input: 'flex' }}>
                <Checkbox value="seller" registration={register('activity_interests')} classes={{ main: 'w-1/3' }}>
                  {t('Seller')}
                </Checkbox>
                <Checkbox value="buyer" registration={register('activity_interests')} classes={{ main: 'w-1/3' }}>
                  {t('Buyer')}
                </Checkbox>
                <Checkbox
                  value="service-provider"
                  registration={register('activity_interests')}
                  classes={{ main: 'w-1/3' }}
                >
                  {t('Service provider')}
                </Checkbox>
              </Field>

              <Field label={t('Technology')} delay={0.25} classes={{ field: 'space-y-3', input: 'flex' }}>
                <Checkbox value="solar" registration={register('technology_interests')} classes={{ main: 'w-1/3' }}>
                  {t('Solar')}
                </Checkbox>
                <Checkbox value="wind" registration={register('technology_interests')} classes={{ main: 'w-1/3' }}>
                  {t('Wind')}
                </Checkbox>
                <Checkbox value="bess" registration={register('technology_interests')} classes={{ main: 'w-1/3' }}>
                  {t('BESS')}
                </Checkbox>
              </Field>

              <Field label={t('Capacity')} delay={0.25} classes={{ field: 'space-y-3', input: 'flex' }}>
                <Checkbox value="1-5" registration={register('capacity_interests')} classes={{ main: 'w-1/3' }}>
                  {t('1 - 5 MW')}
                </Checkbox>
                <Checkbox value="5-50" registration={register('capacity_interests')} classes={{ main: 'w-1/3' }}>
                  {t('5 - 50 MW')}
                </Checkbox>
                <Checkbox value=">50" registration={register('capacity_interests')} classes={{ main: 'w-1/3' }}>
                  {t('> 50 MW')}
                </Checkbox>
              </Field>

              <Field
                label={t('Development status')}
                delay={0.35}
                classes={{ field: 'space-y-3', input: 'flex flex-wrap gap-y-3' }}
              >
                {developmentStatusOptions.map((option) => (
                  <Checkbox
                    key={option.value}
                    value={option.value}
                    classes={{ main: 'w-1/3' }}
                    registration={register('development_status_interests')}
                  >
                    {option.label}
                  </Checkbox>
                ))}
              </Field>

              <Field
                label={t('Location')}
                error={formState.errors['location_interests']}
                delay={0.45}
                classes={{ field: 'space-y-1' }}
              >
                <Input
                  color={formState.errors['location_interests'] ? 'error' : 'primary'}
                  autoComplete="off"
                  registration={register('location_interests')}
                />
              </Field>
            </div>
          </>
        )}
      </Form>

      <div className="flex w-full items-center justify-end gap-x-3 overflow-hidden border-t border-neutral-50 bg-white p-6">
        <Button type="submit" form="company-form" size="lg" color="primary">
          {t('Save changes')}
        </Button>
      </div>
    </div>
  );
}
