import { cn } from '@/utils/styles';
import { TransactionDetails } from '../../types';
import { Icon, Typography } from '@/components';
import { IconName } from '@/components/icons/types';
import { useCountries } from '@/hooks';

export function TransactionListItem({ transaction }: { transaction: TransactionDetails }) {
  const rowClassName = 'py-2 px-1 border-b border-neutral-50 border-b-2';
  const { options: countries } = useCountries();
  const country = countries.find((c) => c.value === transaction.country)?.label || transaction.country;
  return (
    <div className="flex w-full">
      <div className={cn('w-1/12', rowClassName)}>
        <Typography size="body-3" color="primary" weight="normal">
          {transaction.dateLabel}
        </Typography>
      </div>
      <div className={cn('w-[11%]', rowClassName, 'flex gap-1')}>
        {transaction.technologies.map((technology) => (
          <Icon
            name={technology as IconName}
            className="h-5 w-5 text-primary-700"
            key={`${technology}-${transaction.id}`}
          />
        ))}
      </div>
      <div className={cn('w-[19.5%]', rowClassName)}>
        <Typography size="body-3" color="primary" weight="semibold">
          {transaction.buyer}
        </Typography>
      </div>
      <div className={cn('w-[19.5%]', rowClassName)}>
        <Typography size="body-3" color="primary" weight="semibold">
          {transaction.seller}
        </Typography>
      </div>
      <div className={cn('w-1/12', rowClassName)}>
        <Typography size="body-3" color="primary" weight="normal">
          {transaction.capacityLabel}
        </Typography>
      </div>
      <div className={cn('w-3/12', rowClassName)}>
        <Typography size="body-3" color="primary" weight="normal">
          {transaction.city}
        </Typography>
      </div>
      <div className={cn('w-1/12', rowClassName)}>
        <Typography size="body-3" color="primary" weight="normal">
          {country}
        </Typography>
      </div>
    </div>
  );
}
