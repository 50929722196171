import { ClassValue } from 'clsx';
import { ReactNode } from 'react';
import { Link as RouterLink, To } from 'react-router-dom';

import { cn } from '@/utils/styles';

export type LinkProps = {
  readonly children: ReactNode;
  readonly to: To;
  readonly target?: string;
  readonly className?: ClassValue;
};

export function Link({ children, to, target = '_self', className }: LinkProps) {
  return (
    <RouterLink
      to={to}
      target={target}
      className={cn('cursor-pointer text-primary-700 transition hover:underline hover:underline-offset-2', className)}
    >
      {children}
    </RouterLink>
  );
}
