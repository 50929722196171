import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { close, open, toogle } from '../store';
import { SidebarState } from '../store/types';

export function useSidebar() {
  const { isOpen } = useSelector((state: { sidebar: SidebarState }) => state.sidebar);

  const dispatch = useDispatch();

  return {
    isOpen,
    toogle: useCallback(() => dispatch(toogle()), [dispatch]),
    open: useCallback(() => dispatch(open()), [dispatch]),
    close: useCallback(() => dispatch(close()), [dispatch]),
  } as const;
}
