import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Chip, Collapsable, CollapsableBody, CollapsableHeader, Icon, Link, Overlay, Typography } from '@/components';
import { Route } from '@/constants';
import { cn } from '@/utils/styles';

import { OfferDetails } from '../../types';
import { useCompanyRole } from '@/features/company/hooks';

export function OfferFeedback({
  offer,
  initialExpandedState = true,
}: {
  offer: OfferDetails;
  initialExpandedState?: boolean;
}) {
  const { t } = useTranslation(['offers']);
  const { isFree } = useCompanyRole();

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const header = (
    <CollapsableHeader
      title={t('Offer Feedback')}
      description={t(
        "This section provides valuable insights from the seller on your Non-Binding Offer (NBO). Use this feedback to refine your future offers and better align with the seller's requirements."
      )}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );

  const ratingValue = (rating: number | null) => {
    return rating ? (
      <div className="flex">
        {[1, 2, 3, 4, 5].map((index) => {
          return (
            <Icon
              key={index}
              name="start-rating"
              className={cn('mr-1 h-5 w-5', rating && index <= rating ? 'text-gold-chip' : 'text-neutral-300')}
            />
          );
        })}
      </div>
    ) : null;
  };

  const data = useMemo(() => {
    const status = <Chip color={offer.status.color}>{offer.status.name}</Chip>;

    const nextStepsText = (
      <>
        <Typography as="p" size="body-2" color="primary" weight="normal">
          {t(
            'A member of the nTeaser team will contact you shortly to explain the details and coordinate an introductory meeting with the seller. In this meeting, you will establish the next steps, address any project-related questions, and clarify any points regarding your offer.'
          )}
        </Typography>
        <br />
        <Typography as="p" size="body-2" color="primary" weight="normal">
          {t('If you have any questions in the meantime, feel free to contact us at ')}
          <Link to="mailto:rodrigo@nteaser.com">rodrigo@nteaser.com</Link>
        </Typography>
      </>
    );

    switch (offer.status.name) {
      case 'rejected':
        return [
          { label: t('Offer Status'), value: status },
          { label: t('Pricing'), value: ratingValue(offer?.rating.priceScore) },
          { label: t('Payment Milestone'), value: ratingValue(offer?.rating.milestonesScore) },
          {
            label: t('Proccess Speed'),
            value: ratingValue(offer?.rating.speedScore),
          },
          {
            label: t('Comments'),
            value: offer?.rating.comments,
          },
        ];
      case 'accepted':
        return [
          { label: t('Offer Status'), value: status },
          { label: t('Next steps'), value: nextStepsText },
        ];
      default:
        return [{ label: t('Offer Status'), value: status }];
    }
  }, [offer, t]);

  const overlay = {
    title: t('Want to see the Feedback'),
    description: t(
      'Upgrade to a Business Account for detailed feedback on your offer, better insights for future projects, and full project access.'
    ),
    callToAction: {
      label: t('Upgrade now'),
      path: Route.PlanSettings,
    },
  };

  return (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      {offer.status.name === 'rejected' && isFree ? (
        <Overlay title={overlay.title} description={overlay.description} callToAction={overlay.callToAction} />
      ) : (
        <CollapsableBody data={data} />
      )}
    </Collapsable>
  );
}
