import { api } from '@/features/auth';

import {
  CreateOpportunityRequest,
  GetOpportunitiesResponse,
  GetOpportunityRequest,
  GetOpportunityResponse,
  GetOpportunityOffersResponse,
} from './types';

export const opportunitiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMyOpportunities: builder.query<GetOpportunitiesResponse['data'], void>({
      query: () => ({
        url: `items/opportunities`,
        method: 'GET',
        params: {
          fields: '*, projects.name, projects.project.*,projects.project.technologies.*.*, offers.*.*',
          filter: `{"company": {"users": {"_eq": "$CURRENT_USER"}}, "status": {"_neq": "archived"}}`,
        },
      }),
      transformResponse: ({ data }: GetOpportunitiesResponse) => data,
      providesTags: [{ type: 'Opportunities', id: 'me' }],
    }),
    getOpportunity: builder.query<GetOpportunityResponse['data'], GetOpportunityRequest>({
      query: ({ id }: GetOpportunityRequest) => ({
        url: `items/opportunities/${id}`,
        method: 'GET',
        params: {
          fields:
            '*, projects.project.*,projects.*,projects.project.technologies.*.*,offers.id,offers.company.id,offers.company.name,files.*.*',
        },
      }),
      transformResponse: ({ data }: GetOpportunityResponse) => data,
      providesTags: (_result, _error, args) => [{ type: 'Opportunities', id: args.id }],
    }),
    createOpportunity: builder.mutation<GetOpportunityResponse['data'], CreateOpportunityRequest>({
      query: (data: CreateOpportunityRequest) => ({
        url: `items/opportunities`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Opportunities', id: 'me' }],
    }),
    getOpportunities: builder.query<GetOpportunitiesResponse['data'], void>({
      query: () => ({
        url: `items/opportunities`,
        method: 'GET',
        params: {
          fields: '*, projects.project.*,projects.project.technologies.*.*,offers.*.*',
          filter: `{"status": {"_eq": "published"}}`,
          limit: 500,
        },
      }),
      transformResponse: ({ data }: GetOpportunitiesResponse) => data,
      providesTags: [{ type: 'Opportunities', id: 'published' }],
    }),
    getOpportunityOffers: builder.query<GetOpportunityOffersResponse['data'], GetOpportunityRequest>({
      query: ({ id }: GetOpportunityRequest) => ({
        url: `items/opportunities/${id}`,
        method: 'GET',
        params: {
          fields: 'offers.*.*',
        },
      }),
      transformResponse: ({ data }: GetOpportunityOffersResponse) => data,
      providesTags: (_result, _error, args) => [{ type: 'Offers', id: args.id }],
    }),
  }),
});

export const {
  useGetOpportunityQuery,
  useGetMyOpportunitiesQuery,
  useCreateOpportunityMutation,
  useGetOpportunitiesQuery,
  useGetOpportunityOffersQuery,
} = opportunitiesApi;

export type {
  GetOpportunitiesResponse,
  GetOpportunityRequest,
  GetOpportunityResponse,
  GetOpportunityOffersResponse,
} from './types';
