import { ReactNode } from 'react';

import { AnimationShell } from '@/components';

export interface AsideProps {
  children: ReactNode;
}

export function Aside({ children }: AsideProps) {
  return (
    <AnimationShell type="opacity" className="h-full w-80 shrink-0 border-r border-neutral-50 bg-white p-8">
      {children}
    </AnimationShell>
  );
}
