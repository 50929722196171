import { useTranslation } from 'react-i18next';

import { TabProps, Tabs } from '@/components/tabs';

import { OpportunityDetails } from '../../types';
import { useBelongsToMyCompany } from '@/features/company/hooks';

export type OpportunityHeaderTabsProps = {
  opportunity: OpportunityDetails;
  activeTabIndex: number;
  onTabChange: (index: number) => void;
};

export function OpportunityHeaderTabs({ opportunity, activeTabIndex, onTabChange }: OpportunityHeaderTabsProps) {
  const { t } = useTranslation(['opportunities']);
  const opportunityBelongsToMyCompanyAndPublished =
    useBelongsToMyCompany(opportunity?.company) && opportunity?.isPublished;

  const tabs = {
    left: [] as TabProps[],
    right: [] as TabProps[],
  };

  if (opportunityBelongsToMyCompanyAndPublished) {
    ['Offers', 'Q&A', 'Opportunity'].forEach((tab, index) => {
      tabs.left.push({
        label: t(tab),
        index: index,
      });
    });
  } else {
    tabs.left.push({
      label: t('Transaction'),
      index: 0,
    });

    opportunity.projects.forEach((project) =>
      tabs.left.push({
        label: project.name,
        index: tabs.left.length + tabs.right.length,
      })
    );

    tabs.right.push({
      label: t('Q&A'),
      index: tabs.left.length + tabs.right.length,
    });
  }

  return <Tabs tabs={tabs} activeTabIndex={activeTabIndex} onTabChange={onTabChange} />;
}
