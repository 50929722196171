import Markdown, { Components } from 'react-markdown';

/**
 * Our version of 'react-markdown' with our custom styles.
 */

export type CustomMarkdownProps = {
  readonly text: string;
  readonly className?: string;
  readonly components?: Partial<Components>;
};

const ourComponents: Partial<Components> = {
  // HEADINGS
  h1: ({ ...props }) => <h1 className="mb-4 text-3xl font-bold" {...props} />,
  h2: ({ ...props }) => <h2 className="mb-4 text-xl font-semibold" {...props} />,
  h3: ({ ...props }) => <h3 className="mb-4 text-lg font-medium" {...props} />,
  h4: ({ ...props }) => <h4 className="text-md mb-4 font-medium" {...props} />,
  h5: ({ ...props }) => <h5 className="mb-4 text-sm font-medium" {...props} />,
  h6: ({ ...props }) => <h6 className="mb-4 text-xs font-medium" {...props} />,
  // LINKS
  a: ({ ...props }) => (
    <a
      className="text-base-link hover:text-feedback-success-dark cursor-pointer font-medium underline underline-offset-2"
      {...props}
      target="_blank"
      rel="noopener noreferrer"
    />
  ),
  // LISTS
  ul: ({ ...props }) => <ul className="mb-4 ml-4 list-disc pl-4" {...props} />,
  ol: ({ ...props }) => <ul className="mb-4 ml-4 list-decimal pl-4" {...props} />,
  li: ({ ...props }) => <li className="mb-1.5 text-neutral-500" {...props} />,
  // TABLES
  table: ({ ...props }) => (
    <table className="mb-4 w-full table-auto border-collapse transition hover:shadow-md" {...props} />
  ),
  thead: ({ ...props }) => <thead className="bg-chat-table-header text-white" {...props} />,
  tbody: ({ ...props }) => <tbody className="bg-white" {...props} />,
  tr: ({ ...props }) => <tr {...props} />,
  th: ({ ...props }) => (
    <th
      className="border-chat-table-border font-serif border px-4 py-2 text-left font-normal tracking-wide text-white"
      {...props}
    />
  ),
  td: ({ ...props }) => (
    <td className="border-base-secondary border px-4 py-2 font-sans font-normal text-base-primary" {...props} />
  ),
  // OTHERS
  p: ({ ...props }) => <p className="mb-4 text-neutral-500" {...props} />,
  em: ({ ...props }) => <em className="italic text-neutral-500" style={{ fontSize: '100%' }} {...props} />,
  strong: ({ ...props }) => <strong className="text-neutral-500" style={{ fontSize: '100%' }} {...props} />,
  blockquote: ({ className, ...props }) => (
    <blockquote
      className={`tifin-blockquote text-primary mb-4 rounded-sm border-l-4 border-base-tertiary bg-neutral-50 py-3 pl-4 pr-3 italic ${className}`}
      {...props}
    />
  ),
  mark: ({ ...props }) => <mark className="rounded-sm bg-base-tertiary px-1 py-0.5 text-white" {...props} />,
};

export function CustomMarkdown({ components = {}, text, className, ...restProps }: CustomMarkdownProps) {
  return (
    <Markdown components={{ ...ourComponents, ...components }} {...restProps} className={className}>
      {text}
    </Markdown>
  );
}
