import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AnimationShell, Icon, Typography } from '@/components';
import { ASSETS_BASE_URL } from '@/config';
import { FileSchema } from '@/features/files';
import { useSendServiceStats } from '@/features/stats';

import { useServiceDocuments } from '../../hooks/use-service-documents';
import { ServiceDetails } from '../../types';

export type ServiceDocumentsProps = {
  readonly service: ServiceDetails;
};

export function ServiceDocuments({ service }: ServiceDocumentsProps) {
  const { t } = useTranslation(['services']);

  const files = useServiceDocuments(service);

  const sendDownloadStats = useSendServiceStats({ type: 'download' });

  const handleOnClick = useCallback(
    (service: ServiceDetails) => {
      sendDownloadStats(service.id);
    },
    [sendDownloadStats]
  );

  const getTitleFromFile = (file?: FileSchema) => {
    if (!file) return '';
    return file.title ? file.title : file.filename_download;
  };

  return (
    <section className="">
      <AnimationShell type="slide-left" className=" space-y-4">
        <Typography as="h4" size="heading-2" color="primary" weight="semibold">
          {t('Documents')}
        </Typography>
        {files.map((file, index) => (
          <div key={`document-${index}`} className="mt-4 flex justify-between border">
            <Typography as="h5" size="body-1" color="primary" weight="normal" className="p-4">
              <a
                href={`${ASSETS_BASE_URL}/${file?.id}`}
                target="_blank"
                onClick={() => handleOnClick(service)}
                rel="noreferrer"
              >
                {getTitleFromFile(file)}
              </a>
            </Typography>
            <a
              download
              href={`${ASSETS_BASE_URL}/${file?.id}?download`}
              target="_blank"
              className="flex bg-neutral-50 p-2 align-middle"
              onClick={() => handleOnClick(service)}
              rel="noreferrer"
            >
              <Icon
                name="download"
                className="block h-10 w-10 align-middle text-neutral-300 transition hover:text-primary-700"
              ></Icon>
            </a>
          </div>
        ))}
      </AnimationShell>
    </section>
  );
}
