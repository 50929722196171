import { useTranslation } from 'react-i18next';

import { EmptyState, WorkOfficeDesk } from '@/components';

export function ServicesCommingSoon() {
  const { t } = useTranslation(['services']);

  return (
    <EmptyState
      artwork={<WorkOfficeDesk className="w-64" />}
      title={t('Coming soon!')}
      description={t('We are working hard to bring you the best services providers.')}
    ></EmptyState>
  );
}
