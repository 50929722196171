import { Icon, Typography } from '@/components';
import { cn } from '@/utils/styles';

import { CardType } from './types';

export function Card({ icon, title, value, className, asGroup = true }: CardType) {
  return (
    <div className={cn(asGroup ? 'static' : 'relative w-full', className)}>
      <Icon name={icon} className="my-3 h-10 w-10  rounded-full bg-neutral-50 p-2" />
      <Typography as="p" size="body-2" color="primary" weight="semibold">
        {title}
      </Typography>
      <Typography
        as="p"
        size={asGroup ? 'body-2' : 'heading-1'}
        color={asGroup ? 'secondary' : 'primary'}
        weight={asGroup ? 'normal' : 'semibold'}
        className={cn(asGroup ? '' : 'absolute right-0 top-0')}
      >
        {value}
      </Typography>
    </div>
  );
}
