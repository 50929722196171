import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button, Typography } from '@/components';
import { OfferEntryTable } from '@/features/offers/types';
import { cn } from '@/utils/styles';

import { OpportunityOffersItem } from './opportunity-offers-item';

export type PaginatedOffersListProps = {
  readonly items: OfferEntryTable[];
};

export function PaginatedOffersList({ items }: PaginatedOffersListProps) {
  const { t } = useTranslation(['offers']);

  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = searchParams.get('page') ? parseInt(searchParams.get('page') ?? '1') : 1;

  const [activePage, setActivePage] = useState(initialPage);
  const pages = Math.ceil(items.length / 10);
  const itemsPerPage = 10;
  const displayItems = items.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);
  const paginationList = [...Array(pages).keys()].splice(
    activePage < 3 ? 0 : activePage > pages - 3 ? pages - 5 : activePage - 3, // Only visible 5 links in pagination
    5
  );

  const offerHeaderTable = useMemo(() => {
    const headers = [
      t('Company'),
      t('Price'),
      t('Payment Milestone'),
      t('Due Diligence timings'),
      t('Status'),
      t('See Details'),
    ];
    return (
      <div className="flex w-full items-start justify-start border-t border-neutral-50 bg-neutral-50 px-5 py-1 ">
        {headers.map((headerTitle, index) => (
          <Typography as="p" size="body-3" color="primary" weight="semibold" className="w-1/6" key={`header-${index}`}>
            {headerTitle}
          </Typography>
        ))}
      </div>
    );
  }, [t]);

  const updatePage = (page: number) => {
    setActivePage(page);
    setSearchParams((searchParams) => {
      searchParams.set('page', page.toString());
      return searchParams;
    });
  };

  return (
    <>
      {offerHeaderTable}
      {displayItems.map((offer, index) => (
        <OpportunityOffersItem key={`offer-${offer.id}`} offer={offer} index={index} />
      ))}

      <div className="mt-8 flex justify-center">
        <Button
          onClick={() => updatePage(1)}
          color="neutral"
          className="mr-2 bg-neutral-25 enabled:hover:bg-neutral-50"
          disabled={activePage === 1}
        >
          <span>{'<<'}</span>
        </Button>
        {paginationList.map((index) => (
          <Button
            key={index}
            onClick={() => updatePage(index + 1)}
            color="neutral"
            className={cn(
              'mr-2 bg-neutral-25 enabled:hover:bg-neutral-50',
              index + 1 === activePage ? 'bg-neutral-50' : ''
            )}
            disabled={index + 1 === activePage}
          >
            {index + 1}
          </Button>
        ))}
        <Button
          onClick={() => updatePage(pages)}
          color="neutral"
          className="mr-2 bg-neutral-25  enabled:hover:bg-neutral-50"
          disabled={activePage === pages}
        >
          <span>{'>>'}</span>
        </Button>
      </div>
    </>
  );
}
