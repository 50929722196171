import { AnimationShell, Button, Typography } from '@/components';

import { useNotifications } from '../../hooks';

export function Notifications() {
  const { notifications, remove } = useNotifications();

  return (
    <div className="pointer-events-none fixed inset-0 z-50 flex flex-col items-end justify-end space-y-3 px-6 pb-6">
      {notifications.map((notification) => (
        <AnimationShell
          key={notification.id}
          type="slide-up"
          className="pointer-events-auto relative w-96 rounded border border-neutral-50 bg-base-white p-4"
        >
          <Button
            icon="trash"
            color="neutral"
            className=" absolute right-2 top-2 border-none bg-white p-0 text-right text-black hover:bg-white hover:text-neutral-500  enabled:hover:bg-white enabled:hover:text-neutral-500 enabled:active:bg-white enabled:active:text-neutral-500"
            onClick={() => remove(notification.id)}
          />
          {notification.title ? (
            <Typography as="h5" size="title" color="primary" weight="semibold">
              {notification.title}
            </Typography>
          ) : null}

          <Typography as="p" size="body-2" color="primary" weight="normal">
            {notification.description}
          </Typography>
        </AnimationShell>
      ))}
    </div>
  );
}
