import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function usePanelConfiguration() {
  const [t] = useTranslation(['projects']);

  const options = useMemo(
    () => [
      { label: t('1V'), value: '1V' as const },
      { label: t('2V'), value: '2V' as const },
      { label: t('3V'), value: '3V' as const },
      { label: t('1H'), value: '1H' as const },
      { label: t('2H'), value: '2H' as const },
      { label: t('3H'), value: '3H' as const },
    ],
    [t]
  );

  const kv = useMemo(
    () => options.reduce((map, option) => ({ ...map, [option.value]: option.label }), {}) as Record<string, string>,
    [options]
  );

  const map = useCallback((value?: string | null) => (value ? kv[value] : null), [kv]);

  return { options, kv, map };
}
