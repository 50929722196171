import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function ChevronLeft({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="chevron-left"
    >
      <path
        d="M9.05939 11.0655C9.10004 11.1061 9.13228 11.1544 9.15428 11.2075C9.17628 11.2606 9.1876 11.3175 9.1876 11.375C9.1876 11.4325 9.17628 11.4894 9.15428 11.5425C9.13228 11.5956 9.10004 11.6439 9.05939 11.6845C9.01874 11.7252 8.97048 11.7574 8.91737 11.7794C8.86426 11.8014 8.80734 11.8127 8.74986 11.8127C8.69237 11.8127 8.63545 11.8014 8.58234 11.7794C8.52923 11.7574 8.48097 11.7252 8.44032 11.6845L4.06532 7.30953C4.02465 7.2689 3.99238 7.22065 3.97036 7.16754C3.94834 7.11442 3.93701 7.05749 3.93701 7C3.93701 6.9425 3.94834 6.88557 3.97036 6.83246C3.99238 6.77935 4.02465 6.7311 4.06532 6.69047L8.44032 2.31547C8.52242 2.23338 8.63376 2.18726 8.74986 2.18726C8.86595 2.18726 8.97729 2.23338 9.05939 2.31547C9.14148 2.39756 9.1876 2.5089 9.1876 2.625C9.1876 2.7411 9.14148 2.85244 9.05939 2.93453L4.99337 7L9.05939 11.0655Z"
        fill="currentColor"
      />
    </svg>
  );
}
