import { useTranslation } from 'react-i18next';

import { Button, Drawer } from '@/components';
import { ProjectTechnology } from '@/features/projects/types';

import { CapexOpexForm } from './capex-opex-form';

export type CapexOpexDrawerProps = {
  readonly technology: ProjectTechnology;
  readonly isOpen: boolean;
  readonly onSubmit: (data: Partial<ProjectTechnology>, description?: string) => void;
  readonly onClose: () => void;
};

export function CapexOpexDrawer({ technology, isOpen, onSubmit, onClose }: CapexOpexDrawerProps) {
  const { t } = useTranslation(['projects']);

  const footer = (
    <>
      <Button size="lg" color="neutral" onClick={onClose}>
        {t('Cancel')}
      </Button>
      <Button type="submit" form="capex-opex-form" size="lg" color="primary">
        {t('Save')}
      </Button>
    </>
  );

  return (
    <Drawer title={t('Capex/Opex')} open={isOpen} onClose={onClose} footer={footer}>
      <CapexOpexForm
        id="capex-opex-form"
        technology={technology}
        onSubmit={(values: Partial<ProjectTechnology>) =>
          onSubmit(values, t('Capex And Opex section has been updated'))
        }
      />
    </Drawer>
  );
}
