import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LandType } from '@/features/projects/types';

export function useLandRight() {
  const { t } = useTranslation(['connection']);

  const options = useMemo(
    () => [
      { label: t(`Rental`), value: 'rental' as LandType },
      { label: t(`Purchase`), value: 'purchase' as LandType },
      { label: t(`Other`), value: 'other' as LandType },
    ],
    [t]
  );

  const kv = useMemo(
    () => options.reduce((map, option) => ({ ...map, [option.value]: option.label }), {}) as Record<LandType, string>,
    [options]
  );

  const map = useCallback((value: LandType) => kv[value], [kv]);

  return { options, kv, map };
}
