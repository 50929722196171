import { cn } from '@/utils/styles';

import type { ArtworkProps } from '../types';

export function AcceptOffer({ className }: ArtworkProps) {
  return (
    <svg className={cn(className)} viewBox="0 0 192 114" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M83.5 90.222L83.566 90.252C83.849 89.495 84.154 88.692 84.461 87.897C84.131 88.717 83.811 89.489 83.5 90.222Z"
        fill="#B7F6D1"
      />
      <path
        d="M134.931 75.459C134.875 69.942 128.623 62.071 114.978 50.78C109.014 49.7745 103.405 47.2616 98.6849 43.48C81.7139 56.259 71.6849 49.727 76.9299 44.749C82.5769 39.411 89.3439 33.098 95.1539 28.603C95.0439 28.438 90.2809 25.803 89.8389 25.569C85.5919 23.269 81.1229 28.269 72.0939 31.069C68.2499 32.246 66.0439 31.179 54.7159 26.986C58.0439 40.962 53.8159 62.57 43.6819 73.806C44.8159 74.174 48.5479 75.601 53.6569 77.643C55.6429 74.571 57.7449 71.619 59.0369 69.999C63.6109 64.251 70.6569 68.407 67.8369 74.763C67.6989 75.076 67.9369 74.835 67.9369 74.835C71.8369 68.206 79.9369 72.647 76.9609 79.663C80.3959 73.476 87.8009 76.977 86.1739 83.655C89.1739 77.028 96.8459 80.61 94.9139 87.984C94.2719 90.435 93.6569 92.612 92.9499 94.613L93.4979 94.88C97.3979 96.756 104.198 101.04 108.614 94.034C112.347 96.756 116.834 97.491 121.229 90.319C123.877 91.588 125.529 91.019 127.169 89.565C129.469 87.506 130.277 85.501 129.615 83.129C133.11 81.104 134.986 78.64 134.931 75.459Z"
        fill="#B7F6D1"
      />
      <path
        d="M53.6569 77.641C48.5479 75.599 44.8159 74.172 43.6819 73.804C53.8149 62.568 58.0439 40.96 54.7159 26.984C66.0439 31.177 68.2499 32.244 72.0939 31.067C81.1229 28.267 85.5939 23.267 89.8389 25.567C90.2809 25.806 95.0389 28.436 95.1539 28.601C89.3439 33.095 82.5769 39.409 76.9299 44.747C71.6849 49.725 81.7139 56.257 98.6849 43.478C103.405 47.2596 109.014 49.7725 114.978 50.778C128.623 62.069 134.878 69.94 134.931 75.457C134.986 78.638 133.11 81.102 129.616 83.125C130.278 85.497 129.469 87.502 127.17 89.561C125.534 91.014 123.878 91.584 121.23 90.315C116.83 97.487 112.348 96.752 108.615 94.03C104.202 101.036 97.3979 96.752 93.4989 94.876L92.9509 94.609"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.093 18.69C47.047 17.017 37.007 13.39 24.226 8.90699C33.66 22.166 23.656 62.607 6.16699 71.45C16.7992 73.8402 27.441 76.1879 38.092 78.493C55.028 68.306 60.821 31.822 51.093 18.69Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.2261 8.889C22.5161 8.282 20.7691 7.67499 18.9851 7.04899"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 70.255C2.655 70.641 4.384 71.027 6.186 71.431"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.212 90.322C118.545 89.035 114.886 85.891 109.312 79.859"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.166 69.501C124.466 75.514 128.623 79.67 129.616 83.127"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.646 87.099C103.301 88.791 105.728 91.991 108.597 94.051"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5129 67.092C28.1999 63.912 35.7669 65.977 33.4389 69.243C31.1109 72.509 22.2789 70.922 25.5129 67.092Z"
        fill="#279D59"
      />
      <path
        d="M25.5129 67.092C28.1999 63.912 35.7669 65.977 33.4389 69.243C31.1109 72.509 22.2789 70.922 25.5129 67.092Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.814 100.744C25.4466 100.745 26.065 100.932 26.591 101.284C27.1171 101.635 27.5273 102.135 27.77 102.719C28.033 103.353 28.085 104.056 27.9183 104.722C27.7516 105.388 27.3751 105.983 26.8442 106.419C26.3134 106.855 25.6565 107.108 24.9705 107.142C24.2845 107.176 23.6058 106.988 23.0348 106.607C22.4637 106.225 22.0305 105.67 21.7991 105.023C21.5678 104.377 21.5506 103.673 21.75 103.015C21.9495 102.358 22.355 101.783 22.9068 101.374C23.4585 100.965 24.1272 100.744 24.814 100.744Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M151.941 100.955V96.496" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M147.941 104.955H143.482" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M151.941 108.955V113.415" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M155.941 104.955H160.4" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M151.941 108.955C153.002 108.955 154.019 108.534 154.769 107.783C155.519 107.033 155.941 106.016 155.941 104.955C155.941 103.894 155.519 102.877 154.769 102.127C154.019 101.376 153.002 100.955 151.941 100.955C150.88 100.955 149.863 101.376 149.113 102.127C148.362 102.877 147.941 103.894 147.941 104.955C147.941 106.016 148.362 107.033 149.113 107.783C149.863 108.534 150.88 108.955 151.941 108.955Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.6999 84.672C88.4819 77.226 80.3889 73.051 76.8359 79.891C75.2647 82.9305 73.7564 86.0022 72.3119 89.104C69.1419 95.96 78.8729 99.731 81.7119 94.087C83.2102 91.0229 84.5415 87.88 85.6999 84.672Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.938 74.833C66.356 77.518 64.149 81.288 63.083 83.311C59.483 90.211 68.545 95.503 71.91 89.545C73.822 86.125 75.551 82.391 76.691 80.111C80.277 72.811 71.928 68.048 67.938 74.833Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.0369 69.997C57.7369 71.634 55.5979 74.631 53.5939 77.739C49.4559 84.12 58.1939 89.472 61.9239 84.378C63.3039 82.52 66.0239 77.996 67.3489 75.716C71.3579 68.839 63.8369 63.965 59.0369 69.997Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.915 87.982C96.861 80.552 89.061 76.972 86.106 83.808C84.898 86.608 82.755 92.339 82.245 93.922C80.575 99.103 89.453 102.859 91.899 97.287C93.1461 94.269 94.1546 91.1578 94.915 87.982Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.755 41.015C143.148 34.7762 138.987 28.8742 134.324 23.38C123.81 28.82 122.631 30.508 118.638 29.78C109.332 28.089 102.161 23.51 98.2248 26.305C91.2988 31.21 83.7138 38.273 76.9118 44.731C71.5528 49.789 81.9438 56.144 98.6658 43.48C103.382 47.2653 108.994 49.7734 114.96 50.763C114.96 51.263 134.684 65.558 134.912 75.463C140.077 72.145 150.586 66.31 156.538 62.963C154.213 55.2645 150.926 47.8902 146.755 41.015Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.78 18.635C131.4 14.847 144.346 8.411 158.966 1"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M191 56.977C178.44 63.542 164.685 70.549 156.17 74.594"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.283 50.743C119.575 51.546 114.741 51.168 110.215 49.643"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M98.666 43.479L102.344 39.765" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M123.86 19.187C121.745 21.027 122.481 24.887 124.393 28.694C126.617 27.546 129.784 25.714 134.287 23.38C132.246 21.34 126.95 16.522 123.86 19.187Z"
        fill="#279D59"
      />
      <path
        d="M123.86 19.187C121.745 21.027 122.481 24.887 124.393 28.694C126.617 27.546 129.784 25.714 134.287 23.38C132.246 21.34 126.95 16.522 123.86 19.187Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.538 62.954C151.977 65.501 154.295 64.196 146.056 68.854C147.634 71.02 150.021 73.526 152.474 74.536C155.233 75.695 157.182 74.812 157.66 71.778C158.083 69.096 157.255 65.455 156.538 62.954Z"
        fill="#279D59"
      />
      <path
        d="M156.538 62.954C151.977 65.501 154.295 64.196 146.056 68.854C147.634 71.02 150.021 73.526 152.474 74.536C155.233 75.695 157.182 74.812 157.66 71.778C158.083 69.096 157.255 65.455 156.538 62.954Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
