import { BILLION, MILLION, THOUSAND } from '@/constants';
import { isNumber } from '@/types';

export function numberFormat(locale = 'en-US') {
  return Intl.NumberFormat(locale).format;
}

export function currencyFormat(
  currency = 'USD',
  locale = 'en-US',
  style: 'long' | 'short' = 'long',
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
) {
  const currencyFormatter = Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format;

  return function (value?: number | null) {
    if (!isNumber(value)) {
      return null;
    }

    if (style === 'short') {
      if (value >= BILLION) {
        return `${currencyFormatter(value / BILLION)}B`;
      } else if (value >= MILLION) {
        return `${currencyFormatter(value / MILLION)}M`;
      } else if (value >= THOUSAND) {
        return `${currencyFormatter(value / THOUSAND)}K`;
      }
    }

    return currencyFormatter(value);
  };
}
