import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function CameraNoPicture({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="camera-no-picture"
    >
      <path
        d="M19.0002 20.8H5.0002C3.5002 20.8 2.2002 19.6 2.2002 18V9.00001C2.2002 7.50001 3.4002 6.20001 5.0002 6.20001H6.60019L7.60019 4.60001C8.0002 3.80001 8.9002 3.20001 9.9002 3.20001H14.1002C15.1002 3.20001 16.0002 3.70001 16.5002 4.50001L17.5002 6.20001H19.0002C20.5002 6.20001 21.8002 7.40001 21.8002 9.00001V18C21.8002 19.5 20.5002 20.8 19.0002 20.8ZM5.0002 7.80001C4.3002 7.80001 3.8002 8.30001 3.8002 9.00001V18C3.8002 18.7 4.4002 19.2 5.0002 19.2H19.0002C19.7002 19.2 20.2002 18.6 20.2002 18V9.00001C20.2002 8.30001 19.6002 7.80001 19.0002 7.80001H17.0002C16.7002 7.80001 16.5002 7.70001 16.4002 7.40001L15.2002 5.40001C15.0002 5.00001 14.6002 4.80001 14.1002 4.80001H9.9002C9.5002 4.80001 9.0002 5.00001 8.8002 5.40001L7.60019 7.40001C7.5002 7.60001 7.3002 7.80001 7.0002 7.80001H5.0002Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13C8 12.4477 8.44772 12 9 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H9C8.44772 14 8 13.5523 8 13Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9C12.5523 9 13 9.44772 13 10L13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16L11 10C11 9.44772 11.4477 9 12 9Z"
        fill="currentColor"
      />
    </svg>
  );
}
