import { api } from '@/features/auth';

import {
  CreateOfferRequest,
  GetOfferRequest,
  GetOfferResponse,
  GetOffersResponse,
  UpdateOfferFeedbackRequest,
  UpdateOfferStatusRequest,
} from './types';

export const offersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMyOffers: builder.query<GetOffersResponse['data'], void>({
      query: () => ({
        url: `items/offers`,
        method: 'GET',
        params: {
          fields: '*, opportunity.*, opportunity.projects.*.*, opportunity.projects.project.technologies.*.*',
          filter: `{"company": {"users": {"_eq": "$CURRENT_USER"}}, "status": {"_neq": "archived"}}`,
          sort: '-created_at',
        },
      }),
      transformResponse: ({ data }: GetOffersResponse) => data,
      providesTags: [{ type: 'Offers', id: 'me' }],
    }),
    getOffer: builder.query<GetOfferResponse['data'], GetOfferRequest>({
      query: ({ id }: GetOfferRequest) => ({
        url: `items/offers/${id}`,
        method: 'GET',
        params: {
          fields:
            '*, milestones.*, opportunity.*, opportunity.projects.*.*, opportunity.projects.project.technologies.*.*, company.id, company.name',
        },
      }),
      transformResponse: ({ data }: GetOfferResponse) => data,
      providesTags: (_result, _error, args) => [{ type: 'Offers', id: args.id }],
    }),
    createOffer: builder.mutation<void, CreateOfferRequest>({
      query: (data: CreateOfferRequest) => ({
        url: `items/offers`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Offers', id: 'me' }],
    }),
    updateOfferStatus: builder.mutation<void, UpdateOfferStatusRequest>({
      query: ({ id, data }: UpdateOfferStatusRequest) => ({
        url: `items/offers/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (_result, _error, args) => [
        { type: 'Offers', id: args.id },
        { type: 'Offers', id: args.opportunity },
      ],
    }),
    updateOfferFeedback: builder.mutation<void, UpdateOfferFeedbackRequest>({
      query: ({ id, data }: UpdateOfferFeedbackRequest) => ({
        url: `items/offers/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (_result, _error, args) => [{ type: 'Offers', id: args.id }],
    }),
  }),
});

export const {
  useGetOfferQuery,
  useGetMyOffersQuery,
  useCreateOfferMutation,
  useUpdateOfferStatusMutation,
  useUpdateOfferFeedbackMutation,
} = offersApi;

export type { GetOfferRequest, GetOfferResponse, GetOffersResponse } from './types';
