import { Offer, OfferEntryTable, SaleOption } from '../types';

import { getColorFromOfferStatus } from './helpers';

export function offerEntryTableTransformer(offer: Offer): OfferEntryTable {
  return {
    id: offer.id,
    status: {
      name: offer.status,
      color: getColorFromOfferStatus(offer.status),
    },
    company: {
      id: offer.company.id,
      name: offer.company.name,
    },
    price: offer.price,
    option: offer.option as SaleOption,
    milestones: offer.milestones,
    dueDiligenceType: offer.due_diligence_type,
    dueDiligenceWeeks: offer.due_diligence_weeks,
    opportunity: offer.opportunity.id,
  };
}
