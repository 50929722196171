import { useEffect, useRef } from 'react';

import { AnimationShell, Container } from '@/components';
import { useSendServiceStats } from '@/features/stats';
import { cn } from '@/utils/styles';

import { ServiceDetails } from '../../types';

import { ServiceContact } from './service-contact';
import { ServiceCredentials } from './service-credentials';
import { ServiceDescription } from './service-description';
import { ServiceDocuments } from './service-documents';
import { ServiceItems } from './service-items';
import { ServiceLogo } from './service-logo';

export type ServiceContentProps = {
  readonly service: ServiceDetails;
};

export function ServiceContent({ service }: ServiceContentProps) {
  const sendViewStats = useSendServiceStats({ type: 'view' });
  const hasSentStats = useRef(false);

  useEffect(() => {
    if (!service) return;
    if (!hasSentStats.current) {
      sendViewStats(service.id);
      hasSentStats.current = true;
    }
  }, []);

  return (
    <div>
      <Container className="flex py-12">
        <AnimationShell type="opacity" className={cn('w-3/5 shrink-0 space-y-12')}>
          <ServiceDescription service={service} />
          <ServiceItems service={service} />
          <ServiceDocuments service={service} />
          <ServiceCredentials service={service} />
        </AnimationShell>
        <AnimationShell type="opacity" className="w-2/5 shrink-0">
          <div className="ml-10 space-y-12 border-l-2 border-neutral-50 pl-10">
            <ServiceLogo service={service} />
            <ServiceContact service={service} />
          </div>
        </AnimationShell>
      </Container>
    </div>
  );
}
