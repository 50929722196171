import { OpportunityDetails } from '../../types';

import { AboutTheSellerCollapsable } from './about-the-seller-collapsable';
import { FilesCollapsable } from './files-collapsable';
import { SaleProcessCollapsable } from './sale-process-collapsable';

export type OpportunityTabDetailsProps = { readonly opportunity: OpportunityDetails };
export function OpportunityTabDetails({ opportunity }: OpportunityTabDetailsProps) {
  return (
    <>
      <SaleProcessCollapsable opportunity={opportunity} />
      <AboutTheSellerCollapsable opportunity={opportunity} />
      {opportunity.files?.length > 0 && <FilesCollapsable opportunity={opportunity} initialExpandedState={true} />}
    </>
  );
}
