import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnimationShell, Button, Typography, CustomMarkdown } from '@/components';

import { ServiceDetails } from '../../types';

export type ServiceItemsProps = {
  readonly service: ServiceDetails;
};

export function ServiceItems({ service }: ServiceItemsProps) {
  const { t } = useTranslation(['services']);
  const [isExpanded, setIsExpanded] = useState(0);

  const animationCollapseVariants = {
    open: {
      opacity: 1,
      height: 'auto',
    },
    closed: {
      opacity: 0,
      height: 0,
    },
  };

  return (
    <section className="">
      <AnimationShell type="slide-left" className="flex flex-col ">
        <Typography as="h4" size="heading-2" color="primary" weight="semibold" className="mb-6">
          {t('Services')}
        </Typography>
        {service.services.map((item, index) => (
          <div key={`service-${index}`} className="border">
            <div className="flex justify-between">
              <Typography as="h5" size="body-1" color="primary" weight="semibold" className="p-4">
                {item.title}
              </Typography>

              <Button
                size="md"
                color="link"
                icon={isExpanded === index ? 'minus' : 'plus'}
                className="z-50 p-4"
                onClick={() => setIsExpanded(index)}
              ></Button>
            </div>
            <motion.div
              variants={animationCollapseVariants}
              initial="closed"
              animate={isExpanded === index ? 'open' : 'closed'}
              transition={{ duration: 0.3 }}
            >
              <CustomMarkdown text={item.description} className="p-4" />
            </motion.div>
          </div>
        ))}
      </AnimationShell>
    </section>
  );
}
