import { useCallback, useMemo } from 'react';

import { useGetCountriesQuery } from '@/features/app/api';
import { useCurrentUser } from '@/features/users/hooks';

export function useCountries() {
  const { data: countries } = useGetCountriesQuery();
  const user = useCurrentUser();

  const options = useMemo(() => {
    if (!countries) return [];
    if (!user) return [];

    return countries.map((country) => ({
      label: country.labels.find((l) => l.language.code === user.language)?.label ?? country.labels[0].label,
      value: country.code,
    }));
  }, [countries, user]);

  const kv = useMemo(
    () => options.reduce((map, option) => ({ ...map, [option.value]: option.label }), {} as Record<string, string>),
    [options]
  );

  const map = useCallback((value: string) => kv[value], [kv]);

  return { options, kv, map };
}
