import { useTranslation } from 'react-i18next';

import { AnimationShell, SuccessConfirm, Typography } from '@/components';
import { useRegisterGAPageView } from '@/hooks';

export function RecoveryPasswordSuccessPage() {
  const { t } = useTranslation(['reset-password']);
  useRegisterGAPageView('Recovery password success');

  return (
    <AnimationShell type="opacity" className="flex flex-col items-center justify-center space-y-8 text-center">
      <SuccessConfirm className="w-64" />
      <Typography size="heading-2">{t('Check your email')}</Typography>
      <Typography size="body-2" color="secondary" align="center">
        {t('We have just sent you instructions on how to reset your password and regain access to the application.')}
      </Typography>
    </AnimationShell>
  );
}
