import { AnimationShell } from '@/components';
import { ServiceItemList } from '@/features/services/types';

import { ServicesItem } from '../item/item';

export type ServicesItemsProps = {
  readonly services: ServiceItemList[];
};

export function ServicesItems({ services }: ServicesItemsProps) {
  return (
    <AnimationShell
      type="opacity"
      className="mt-8 grid w-full grid-cols-1 place-items-stretch gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
    >
      {services.map((service, index) => (
        <ServicesItem key={service.id} index={index} service={service} />
      ))}
    </AnimationShell>
  );
}
