import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Technology({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="technology"
    >
      <path
        d="M6.0002 21.8H4.0002C3.0002 21.8 2.2002 21 2.2002 20V14.1V14C2.2002 13 3.0002 12.3 3.9002 12.2H5.9002C6.9002 12.2 7.60019 13 7.60019 14V20C7.60019 20.8 6.2002 21.7 6.2002 21.7C6.2002 21.7 6.1002 21.8 6.0002 21.8ZM3.7002 13.9C3.7002 14 3.7002 14 3.7002 13.9V20C3.7002 20.1 3.8002 20.2 4.0002 20.2H5.8002C5.9002 20.2 5.9002 20.2 6.0002 20.2C6.1002 20.2 6.3002 20.1 6.3002 19.9V13.9C6.3002 13.8 6.2002 13.7 6.1002 13.7H4.0002C3.9002 13.7 3.8002 13.8 3.7002 13.9Z"
        fill="currentColor"
      />
      <path
        d="M13.0003 21.6C12.6003 21.6 12.2003 21.6 11.8003 21.5L9.20027 20.8C8.90027 20.7 8.70027 20.7 8.40027 20.7H6.90027C6.50027 20.7 7.10027 20.4 7.10027 19.9C7.10027 19.4 6.40027 19.1 6.90027 19.1H8.40027C8.80027 19.1 9.10027 19.2 9.60027 19.3L12.2003 20C12.7003 20.1 13.1003 20.1 13.6003 20H13.7003L16.5003 19.4C17.1003 19.3 17.7003 19 18.2003 18.5C18.2003 18.5 18.2003 18.5 18.3003 18.4L20.3003 16.4C20.6003 16.1 20.6003 15.6 20.3003 15.3C20.1003 15 19.7003 15 19.4003 15.2C19.4003 15.2 19.4003 15.2 19.3003 15.2L16.9003 17C16.4003 17.3 15.9003 17.5 15.3003 17.6C15.3003 17.6 15.3003 17.6 15.2003 17.6H13.0003C12.6003 17.6 12.2003 17.3 12.2003 16.8C12.2003 16.3 12.5003 16 13.0003 16H15.3003C15.6003 16 15.8003 15.9 16.1003 15.7L18.4003 14C18.4003 14 18.4003 14 18.5003 13.9C19.4003 13.2 20.7003 13.3 21.5003 14.1C22.4003 15 22.4003 16.4 21.5003 17.3L19.4003 19.4L19.3003 19.5C18.6003 20.1 17.8003 20.5 17.0003 20.7L14.1003 21.3H14.0003C13.5003 21.6 13.3003 21.6 13.0003 21.6Z"
        fill="currentColor"
      />
      <path
        d="M13 17.4L13 16H14.5C14.9 16 15.3 15.6 15.3 15.1V14.9V14.8C15.3 14.4 15.0999 14.1 14.7 14.1L12.4 13.5C12.0999 13.4 11.8 13.4 11.4 13.4C10.5999 13.4 9.89995 13.6 9.19995 14.1L7.39995 15.3C7.09995 15.5 6.59995 15.4 6.39995 15.1C6.19995 14.8 6.29995 14.3 6.59995 14.1L8.39995 12.9C9.29995 12.3 10.4 12 11.4 12C11.9 12 12.3 12.1 12.7 12.2L15 12.6C16 12.8 16.6 13.7 16.7 14.7V14.8V15.1C16.7 15.7 16.5 16.3 16.1 16.7C15.7 17.1 15.1 17.4 14.5 17.4H13Z"
        fill="currentColor"
      />
      <path
        d="M14.2296 11.5711C13.7296 11.5711 13.2296 11.3711 12.9296 11.0711C11.6296 9.77108 13.0296 7.07108 15.0296 5.07108C16.0296 4.07108 17.1296 3.27108 18.1296 2.87108C19.8296 2.17108 20.6296 2.67108 21.0296 2.97108C22.3296 4.27108 20.9296 6.97108 18.9296 8.97108C17.4296 10.4711 15.6296 11.5711 14.2296 11.5711ZM19.7296 3.97108C19.5296 3.97108 19.2296 4.07108 18.7296 4.17108C17.9296 4.57108 17.0296 5.27108 16.1296 6.07108C14.1296 8.07108 13.8296 9.77108 14.0296 9.97108C14.2296 10.1711 15.9296 9.87108 17.9296 7.87108C19.9296 5.87108 20.2296 4.17108 20.0296 3.97108C19.9296 3.97108 19.9296 3.97108 19.7296 3.97108Z"
        fill="currentColor"
      />
      <path
        d="M19.7296 11.5711C18.3296 11.5711 16.5296 10.4711 15.0296 8.97114C13.0296 6.97114 11.6296 4.27114 12.9296 2.97114C14.2296 1.67114 16.9296 3.07114 18.9296 5.07114C20.9296 7.07114 22.3296 9.77114 21.0296 11.0711C20.7296 11.3711 20.2296 11.5711 19.7296 11.5711ZM14.0296 3.97114C13.8296 4.17114 14.2296 5.87114 16.1296 7.87114C18.0296 9.87114 19.8296 10.2711 20.0296 9.97114C20.2296 9.77114 19.8296 8.07114 17.9296 6.07114C15.9296 4.17114 14.2296 3.77114 14.0296 3.97114Z"
        fill="currentColor"
      />
    </svg>
  );
}
