import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '@/store';

import { clear, set } from '../store';
import { HeaderState } from '../store/types';

export function useHeader() {
  const { breadcrumb } = useAppSelector((state) => state.header);

  const dispatch = useDispatch();

  return {
    breadcrumb,
    set: useCallback((breadcrumb: HeaderState['breadcrumb']) => dispatch(set(breadcrumb)), [dispatch]),
    clear: useCallback(() => dispatch(clear()), [dispatch]),
  } as const;
}
