import { ReactNode } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { cn } from '@/utils/styles';

import { sizes } from './constants';
import { TogglePosition, ToggleSize } from './types';

export type ToggleProps = {
  readonly id?: string;
  readonly position?: TogglePosition;
  readonly size?: ToggleSize;
  readonly defaultChecked?: boolean;
  readonly registration?: Partial<UseFormRegisterReturn>;
  readonly children?: ReactNode;
};

export function Toggle({ id, position = 'right', size = 'sm', defaultChecked, registration, children }: ToggleProps) {
  const isSmall = size === 'sm';
  const isLeftSide = position === 'left';
  const isRightSide = position === 'right';
  const isDisabled = registration?.disabled ?? false;

  return (
    <label
      htmlFor={id}
      className={cn(
        'group flex select-none items-center justify-start gap-x-2 font-normal',
        isDisabled ? 'cursor-not-allowed text-neutral-disabled' : 'cursor-pointer text-base-primary'
      )}
    >
      {isLeftSide && children ? children : null}

      <div className="relative">
        <input
          id={id}
          type="checkbox"
          defaultChecked={defaultChecked}
          className={cn('peer sr-only')}
          {...registration}
        />

        <div
          className={cn(
            'transform rounded-full transition-transform ease-in-out',
            sizes[size],
            isDisabled
              ? 'bg-base-disabled'
              : 'bg-neutral-50 group-hover:bg-neutral-100 peer-checked:bg-primary-700 group-hover:peer-checked:bg-primary-800'
          )}
        />

        <div
          className={cn(
            'absolute left-1 top-0.5  rounded-full shadow-md',
            'transform transition peer-checked:translate-x-full',
            'peer-enabled:cursor-pointer peer-enabled:bg-white',
            'peer-disabled:cursor-not-allowed peer-disabled:bg-neutral-50',
            isSmall ? 'h-4 w-4' : 'h-5 w-5'
          )}
        />
      </div>

      {isRightSide && children ? children : null}
    </label>
  );
}
