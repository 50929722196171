import { AnimationShell, Typography } from '@/components';

export function TermsAndConditions() {
  return (
    <AnimationShell type="scale" className="mt-8 flex flex-col items-center justify-center space-y-8 text-center">
      <Typography as="h1" size="heading-1" color="primary" weight="semibold">
        <b>NTEASER</b>
      </Typography>
      <Typography as="h1" size="heading-1" color="primary" weight="semibold">
        <b>Terms And Conditions</b>
      </Typography>

      <div className="mx-20 text-left ">
        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          <b>1. INTRODUCTION</b>
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          This document includes the terms and conditions of use (&quot;Terms&quot;) of nTeaser platform
          (&quot;Platform&quot;) hosted on the website www.nteaser.com (&quot;Website&quot;) and owned by NTEASER
          PLATFORM S.L. (&quot;nTeaser&quot; or &quot;We&quot;) with registered office at calle Meléndez Valdés 45, 4D
          Madrid 28015 Spain, registered in the Commercial Register of Madrid, volume 43790, Page 104, sheet M-772821
          and contact at contact@nteaser.com
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          By accessing or using the Platform the user hereby agrees to accept the Terms set forth in this agreement as
          individual or company that register to use the Platform as (i) a renewable energy assets seller (“Seller”)
          (ii) a renewable energy assets buyer (“Buyer”). Therefore, User shall be bound by these Terms with respect to
          its access or use of this Platform and any further upgrade, modification, addition or change to this Platform.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          For the purposes of these Terms and the Platform, the Seller, Buyer, Service Provider as well as its advisors
          shall be collectively referred to as &quot;User/s&quot;. These Terms are entered into between the User and
          nTeaser.
        </Typography>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          <b>2. DESCRIPTION AND PURPOSE OF THE PLATFORM</b>
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          nTeaser is a platform that connects Sellers, Buyers and Service Providers of renewable energy projects and/or
          assets (&quot;Projects&quot; and &quot;Other Deals&quot;) being set up as a marketplace where nTeaser or
          Sellers upload such Projects and Other Deals, which may include but are not limited to solar PV, wind power,
          hydrogen, and energy storage technologies.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          Projects and Other Deals uploaded by Sellers will be presented by nTeaser on an anonymous basis to potential
          Buyers who may submit Expressions of Interest for the asset through the Platform (the &quot;Expressions of
          Interest&quot;, hereinafter &quot;EOIs&quot;).
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          On the Platform, there are two types of subscriptions (&quot;Plan/s&quot;):
        </Typography>
        <ul className="ml-8 list-decimal">
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal">
              <b>Free access Plan: </b>the registered User may see and submit EOIs for all Projects and Other Deals
              published by Sellers free of charge. The User will be unable to access advanced features. The User may at
              any time obtain full access to the nTeaser platform by upgrading to a Business Plan.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
              <b>Business Plan: </b>the registered User may see and submit EOIs for all Projects and Other Deals
              published by Sellers and have full access to the Platform, including benefits sush as information on price
              trends and market dynamics, review the track record of industry transactions, access to a Q&A service with
              Sellers, comprehensive feedback on rejected EOIs and dedicated transaction support from the nTeaser team.
            </Typography>
          </li>
        </ul>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          <b>3. PLATFORM REGISTRATION AND USE</b>
        </Typography>
        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          a) Registration
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          The User must register for free on the Platform by completing in the Registration Form its name, surname,
          company, email, password and accepting these Terms and Privacy Policy.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          nTeaser may contact the User for further informatino, if neccessary. nTeaser reserves its right to refuse the
          User from the Platform if it considers that the User does not meet the profile intended for use of the
          Platform.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          When the User has completed the registration process, he/she will receive an email to access the Platform.
        </Typography>
        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          b) How to use the Platform?
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          Once the User has registered on the Platform the User will be able to start using the Platform according to
          the role or roles performed by it:
        </Typography>
        <Typography as="h3" size="body-2" color="primary" weight="semibold" className="mb-4">
          b.1) As a Seller
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          The Seller will be able to upload its Projects in the &quot;As Sellers&quot; tab following the steps indicated
          below
        </Typography>
        <Typography as="h3" size="body-2" color="primary" weight="semibold" className="mb-2">
          i) Upload Project Information
        </Typography>

        <div className="ml-8">
          <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
            <b>1. Fill in the Project Information Form:</b> The Seller is required to complete the provided form
            (&quot;Project Form&quot;) with accurate and detailed information about the Project. The Project Form will
            typically include fields for essential Project details such as project type, location, capacity, technical
            specifications, financial data, and any other relevant information. It is crucial for the Seller to provide
            as much information as possible to ensure a comprehensive understanding of the Project.
          </Typography>
          <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
            <b>2. Submit Project for Review:</b> Once the Seller has filled in all the necessary details in the Project
            Form, they can proceed to click the &quot;Submit Project for Review&quot; button. By doing so, the Project
            submission will be forwarded to the nTeaser team for review. This step initiates the evaluation process of
            the Project by the Platform&apos;s experts. nTeaser will anonymize and remove any details of the Project
            and/or Asset that make it identifiable.
          </Typography>
          <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
            <b>3. Project Review by nTeaser Team:</b> The nTeaser team will carefully review the submitted Project
            information. They will assess the Project&apos;s viability, relevance, and compliance with nTeaser&apos;s
            criteria. During the review process, nTeaser team may contact the Seller for clarification or request
            additional information if needed. The Seller shall provide all necessary information during this phase to
            ensure a smooth and efficient review process.
          </Typography>
          <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
            <b>4. Communication and Clarification:</b> In case nTeaser team has any doubts or requires further
            information to evaluate the Project properly, they will communicate with the Seller. Clear and prompt
            communication between the seller and the nTeaser team helps streamline the review process and ensures
            accurate evaluation.
          </Typography>
          <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
            <b>5. Verification of Ownership:</b> As part of the review process, nTeaser verifies that only the actual
            owners or authorized representatives of the renewable energy Project and/or Assets are submitting the
            Project Form. This step is crucial to maintain the integrity and authenticity of the Projects listed on the
            Platform.
          </Typography>
        </div>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          To upload Other Deals (biogas, green hydrogen, co-developments, self-consuption, distributed generation,
          reversible pumping, hydropower projects and more), Sellers may contact{' '}
          <a href="mailto:projects@nteaser.com">projects@nteaser.com</a> and the nTeaser team will upload the project
          information directly to the Platform.
        </Typography>
        <Typography as="h3" size="body-2" color="primary" weight="semibold" className="mb-2">
          ii) Receipt of EOI
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          The Seller will receive the EOI made by Buyers that match its previously indicated interests.
        </Typography>
        <Typography as="h3" size="body-2" color="primary" weight="semibold" className="mb-2">
          iii) Selection of EOI
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          The Seller will select the most interesting EOIs.
        </Typography>
        <div className="ml-8">
          <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
            <b>(i) Accepted EOI:</b> in the event the EOI is accepted by the Seller nTeaser will coordinate a meeting
            between Seller and Buyer.
          </Typography>
          <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
            <b>(i) Rejected EOI:</b> in the event the EOI is rejected by the Seller, the Seller must provide feedback
            through the form provided by nTeaer
          </Typography>
        </div>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          The Buyer will be able to access the feedback provided by the Seller if he subscribes to the Business plan.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          When the EOI is acepted the Seller and Buyer will meet in order to stablish contact and define the next steps,
          such as the definition of the Due Diligence process with an external consultant. Once the next steps have been
          defined by the Buyer and the Seller, each EOI might enter into an external Due Diligence process to be decided
          by the parties and, in the event that it culminates favorably, the Seller and the Buyer will proceed to
          negotiate and sign the correponding Sales and Purchase Agreement (&quot;SPA&quot;). nTeaser is limited solely
          to the contract between the Sellers and Buyers and, in no case, will assume any liability that may arise from
          the Due Diligence process (if any) and formalization of the SPA.
        </Typography>
        <Typography as="h3" size="body-2" color="primary" weight="semibold" className="mb-2">
          b.2. As a Buyer
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
          The Buyer will be able to review and select the Projects and Other Deals in the &quot;Projects&quot; and
          &quot;Other Deals&quot; tabs following the steps indicated below:
        </Typography>
        <Typography as="h3" size="body-2" color="primary" weight="semibold" className="mb-2">
          i) Review of Projects
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
          The Buyer should review the Projects and Other Deals in the &quot;Projects&quot; and/or &quot;Other
          Deals&quot; section in order to find those that fit his/her interests. To do so, the Buyer will be able to
          filter by the categories indicated in the Platform.
        </Typography>
        <Typography as="h3" size="body-2" color="primary" weight="semibold" className="mb-2">
          ii) EOI Submission
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
          In the case that the Buyer is interested in the Project and wants to send a EOI, the Buyer must click on the
          Project in question in which all the information of the Project is provided anonymously by nTeaser. For
          avoidance of doubt, the Buyer must have the necessary authorization to send the EOI.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
          On the Project page, the Buyer must click on the &quot;Express Interest&quot; button and proceed to complete
          the EOI Form. Once the EOI Form is completed, the Buyer must click on &quot;Submit&quot;. The EOI indicates
          Buyer&apos;s initial interest in the Project, it is non-binding and is subject to an additional Due Diligence
          process that will be strictly optional, and will be carried out only if expressly agreed to by both parties.
          Each EOI must be prepared with the best efforts to reflect accurate and genuine interest, ensuring that the
          information provided is reliable and not misleading.
        </Typography>
        <Typography as="h3" size="body-2" color="primary" weight="semibold" className="mb-2">
          iii) Negotiations and investment
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          If the Seller rejects the EOI, the Buyer will receive an email notification, and detailed feedback will only
          be provided if the Buyer has a Business subscription. If the Seller accepts the EOI, the Buyer will receibe an
          email confirming the acceptance, and a meeting will be coordinated to establish contact between the parties.
          Once negotiations begin,en external Due Diligence process may begin if agreed by the Parties with the
          applicable Offer options. At this stage, the Buyer shall either confirm its initial offer or make the
          necessary reasonable adjustments based on the information obtained in the external Data Room. nTeaser
          disclaims any liability that may arise from any Due Diligence process or equivalent process (if any) that the
          parties may have agreed upon and is carried outside the Platform in order to proceed with the purchase process
          of the corresponding Project.
        </Typography>
        <Typography as="h3" size="body-2" color="primary" weight="semibold" className="mb-2">
          b.3. As a Service Provider
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          The Service Provider may use the Platform to advertise their services Service providers submit their
          information to the nTeaser team, who organizes and publishes it. This includes contact details, past
          transactions, track record, areas of expertise, and other relevant details. All published content is
          thoroughly reviewed and approved by the service providers before being made public. Once published, the
          service offerings will be visible to all Users on the Platform.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          nTeaser disclaims any liability arising from agreements or negotiations carried out outside the Platform in
          relation to the services offered by the Service Providers.
        </Typography>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          c) User obligations
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          The User agrees to make proper and lawful use of the content and Platform and by way of example, but not
          limited to, not to use them to (i) engage in illegal activities or contrary to good faith and public order;
          (ii) cause damage to physical and logical systems nTeaser or other Users; (iii) attempt to access and, where
          appropriate, use the email accounts of other Users and modify or registration data.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          The User agrees that nTeaser grants him/her a non-exclusive right to access the Services and therefore the
          User&apos;s account is personal and non-transferable.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          In particular, the User assumes the following obligations
        </Typography>
        <ul className="ml-8">
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              a) To be of legal age, an indispensable requirement to access the Platform.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              b) Make good use of the Platform without causing any damage to the physical and logical systems of nTeaser
              without contravening current legislation, good faith and public order or damage the rights and interests
              of others.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              c) not alter, copy, download, modify, decompile, disassemble, reverse engineer, license, lease, sell or
              imitate the Platform or its underlying software. nTeaser may exercise all the judicial and extrajudicial
              actions it deems appropriate in the event of any breach of its rights.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              d) Guarantee the truthfulness and accuracy of the data and information provided when filling out the forms
              required to proceed with the contracting of services, thus avoiding any damage to nTeaser as a result of
              the incorrectness of these.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
              e) Make good use of the User profile and the password to access the Platform, pledging to make diligent
              use of them, as well as keep them properly so that they are not available to third parties, communicating
              in any case to nTeaser their loss, theft or possible access by an unauthorized third party, so that
              nTeaser can proceed to the immediate blocking.
            </Typography>
          </li>
        </ul>
        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          d) Invite a member
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          In the Settings Panel, in the &quot;Team&quot; section, the User will be able to invite new members from its
          Company by clicking on the &quot;Invite a member&quot; button to participate in the Projects and Other Deals,
          filling in ther details (name, surname and email). For more information about the processing of personal data,
          please visit nTeaser&apos;s Privacy Policy.
        </Typography>
        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          e) Account deactivation
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          If the User wishes to deactivate its Account on the Platform, the User should send an email to mail to:
          <a href="mailto:contact@nteaser.com">contact@nteaser.com</a> indicating his or her willingness to deactivate
          the User&apos;s account. Once the User&apos;s account is deactivated, the User will not be able to access its
          account. For information on the retention of User data, the User should refer to our Privacy Policy.
        </Typography>
        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          4. FEES AND PAYMENT METHOD
        </Typography>
        <Typography as="p" size="body-1" color="secondary" weight="semibold" className="mb-4">
          a) Per Plan subscription
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          Unless the User decides to maintain his Free Access Plan, which is free of charge, the User must pay at the
          frequency selected on the Platform (monthly, every 3 months or annual) and must include its bank details in
          the payment gateway (Stripe Inc). For those who have subscribed to the annual plan, nTeaser offers the option
          to contact us directly to request an invoice and make payment via bank transfer.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          nTeaser will never keep this information. For more information about the processing of personal data, please
          visit nTeaser&apos;s Privacy Policy.
        </Typography>
        <Typography as="p" size="body-1" color="secondary" weight="semibold" className="mb-4">
          a) Per transaction
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          By submitting EOI, the Buyer agrees to pay nTeaser 1% of the final transaction price (&quot;Contact
          Fee&quot;). This percentage will be applicable to all transactions, with no possibility of modification. To
          reinforce this agreement, if the EOI goes forward and is positively evaluated, nTeaser will send a contract
          confirming these terms.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          The Contact Fee will be paid in full at the time of signing the SPA, without splitting the payment into
          milestones, in accordance with the Special Conditions.
        </Typography>
        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          5. INTELLECTUAL AND INDUSTRIAL PROPERTY
        </Typography>
        <Typography as="h3" size="body-2" color="primary" weight="semibold" className="mb-4 ml-8">
          a) License grant
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          Subject to the compliance with these Terms of Use, nTeaser will grant User access to the Platform, granting
          User a worldwide, non-exclusive, non-transferable, non-assignable, non- sublicensable and revocable license to
          use the Platform, solely in connection with the purpose of the Platform as established in these Terms.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          In addition, the User by using the Platform or providing any nTeaser Data through the Platform, shall grant a
          worldwide, non-exclusive, transferable, assignable, royalty-free, fully paid-up license to use, reproduce,
          adapt, translate and make derivative works of all such data in whole or in part solely for the purpose of
          supporting and enhancing the Platform. For more information on the processing of data that may be carried out
          by nTeaser, the User may refer to the Privacy Policy.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          nTeaser expressly reserves all rights in the Platform not granted to User herein.
        </Typography>
        <Typography as="h3" size="body-2" color="primary" weight="semibold" className="mb-4 ml-8">
          b) Platform ownership
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          nTeaser shall own all intellectual and industrial property rights over the Platform, including, without
          limitation, all software used herein, the graphic design, programming and structure of the Platform,
          documents, as well as other rights and know-how related the Platform (including any modifications or
          enhancements) and all associated rights under copyright, trademarks and patents (hereinafter the
          &quot;Content&quot;).
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          The Users will not challenge nTeaser&apos;s ownership of the Platform and will not alter or delete any
          copyright notices or trademarks included in the Platform. All rights in relation to such Content are reserved
          and express permission must be requested to nTeaser for its use and exploitation not expressly provided for in
          these Terms.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          Any use of the Content for which nTeaser has not given express authorization, whether directly or indirectly
          for profit or not, including downloading, storage, transmission, public disclosure, distribution, reproduction
          or transformation, reuse, the use of scraping, data mining, data harvesting or similar techniques, for private
          or commercial purposes, in whole or in part, is expressly prohibited. It is expressly stated that in
          accordance with art. 67 inc 3 of Royal Decree Law 24/2021, nTeaser reserves the right to use the works, thus
          not authorizing the mining of texts and data.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          The User agrees to: (i) serve as a reference for nTeaser; (ii) collaborate on case studies or other marketing
          material.
        </Typography>
        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          6. USER WARRANTIES
        </Typography>
        <Typography as="h3" size="body-2" color="primary" weight="semibold" className="mb-4 ml-8">
          a) Seller warranties
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          Seller represents and warrants that:
        </Typography>
        <ul className="ml-8 list-disc">
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              is the legal owner or has the necessary authority to upload the Projects and/or Other Deals onto the
              Platform.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              all required consents, permissions, and approvals necessary for the Project and/or Assets on the Platform
              have been obtained.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              will provide all the information requested by nTeaser in order to proceed with the proper evaluation of
              the Project.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              all information provided regarding the Projects and/or Other Deals is accurate, complete, and up to date
              to the best of their knowledge. Sellers acknowledge that any misrepresentation or inaccurate information
              may negatively affect the investment decisions made by the Buyers on the Platform
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              the inclusion of the Projects and/or Other Deals complies with all applicable laws, regulations, and
              contractual obligations, and that they have fulfilled any disclosure requirements, regulatory filings, or
              other obligations pertaining to the shares being offered on the Platform.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              will promptly disclose any material information or changes that may impact the value or viability of the
              Project and/or Assets. Seller understands the importance of providing accurate and timely information to
              Buyers and acknowledges that failure to disclose material information may result in legal consequences.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
              is solely responsible for making their own investment decisions and will not hold the Platform responsible
              for any profits or losses that may result from the transaction.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              will inform the progress of the negotiations and the sale of the Projects and/or Other Deals, as well as
              about the final price of the transaction and any other information that nTeaser requires for the
              collection of the Contact Fee. In addition, the Seller shall use its best efforts to ensure that nTeaser
              receives the appropriate Contact Fee.
            </Typography>
          </li>
        </ul>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          Seller acknowledges and agrees that both, nTeaser and the Platform, do not provide financial, legal and/or
          investment advice or recommendations.
        </Typography>

        <Typography as="h3" size="body-2" color="primary" weight="semibold" className="mb-4 ml-8">
          b) Buyer warranties
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          Buyer represents and warrants that:
        </Typography>

        <ul className="ml-8 list-disc">
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              Has all consents, authorizations and approvals necessary to carry out an OEI and, if the course of
              negotiations is favourable, to formalize an SPA.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              will provide (if applicable) all the information requested by nTeaser in order to proceed with the proper
              evaluation of the transaction.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              will promptly disclose any material information or changes that may impact the value or viability of the
              Project and/or Assets. Buyer understands the importance of providing accurate and timely information to
              Buyers and acknowledges that failure to disclose material information may result in legal consequences.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
              is solely responsible for making their own investment decisions and will not hold the Platform responsible
              for any profits or losses that may result from the transaction.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              will inform the progress of the negotiations and the sale of the Projects and/or Other Deals, as well as
              about the final price of the transaction and any other information that nTeaser requires for the
              collection of the Contact Fee. In addition, the Buyer shall use its best efforts to ensure that nTeaser
              receives the appropriate Contact Fee.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
              Buyer acknowledges and agrees that both nTeaser and the Platform, do not provide financial, legal and/or
              investment advice or recommendations.
            </Typography>
          </li>
        </ul>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          7. WARRANTY AND LIABILITY DISCLAIMER
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          a) The features and services on the Platform are provided on an &quot;AS IS&quot; and &quot;AS AVAILABLE&quot;
          basis, and nTeaser hereby expressly disclaims any and all warranties, express or implied, including but not
          limited to any warranties of condition, quality, durability, performance, accuracy, reliability,
          merchantability or fitness for a particular purpose. All such warranties, representations, conditions,
          undertakings, and terms are hereby excluded.
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          b) nTeaser makes no representations or warranties about the validity, accuracy, correctness, reliability,
          quality, stability, completeness or correctness of any information provided on or through the Platform.
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          c) Any material downloaded or otherwise obtained through the Platform is done at each User&apos;s sole
          discretion and risk and each User is solely responsible for any damage to its computer system or loss of data
          that may result from the download of any such material. No advice or information, whether oral or written,
          obtained by any User from nTeaser or through or from the Platform shall create any warranty not expressly
          stated herein.
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          d) Under no circumstances shall nTeaser be held liable for an delay or failure or disruption of the content or
          services delivered through the Platform resulting directly or indirectly from acts of nature, forces or causes
          beyond its reasonable control, including without limitation, Internet failures, computer, telecommunications
          or any other equipment failures, electrical power failures, strikes, labour disputes, riots, insurrections,
          civil disturbances, shortages of labour or materials, fires, flood, storms, explosions, war, governmental
          actions, orders of domestic or foreign courts or tribunals or non-performance of third parties.
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          e) nTeaser shall not be liable for direct or indirect damages or any damages whatsoever (including but not
          limited to damages for loss of profits or savings, business interruption, loss of information), whether in
          contract, negligence, tort, strict liability or otherwise or any other damages resulting from any of the
          following:
        </Typography>
        <ul className="ml-8">
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              - the use or the inability to use the Platform;
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              - any defect in data, information or services purchased or obtained from a User or a third-party service
              provider through the Platform;
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              - violation of third party rights or claims or demands that Users&apos; Offer may violate or may be
              asserted to violate third party rights;
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
              - or claims by any party that they are entitled to defense or indemnification in relation to assertions of
              rights, demands or claims by third party rights claimants;
            </Typography>
          </li>
        </ul>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          To the maximum extent permitted by applicable law, nTeaser shall not be liable for any indirect or
          consequential damages or lost profits including, but not limited to, any consequential damages, work stoppage,
          breakdown, failure or loss, or for any claim by any third party as a result of the foregoing. nTeaser&apos;
          liability for damages arising out of these Terms and Conditions however caused, regardless of the form of
          action, whether in contract or tort, shall be limited to, at most, the Contact Fee received by nTeaser in
          connection with the transaction from which liability has arisen.
        </Typography>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          8. INDEMNITY
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          a) User hereby agrees to indemnify and hold nTeaser, its affiliates, directors, officers and employees
          harmless, from any and all losses, claims, liabilities (including reasonable legal costs on a full indemnity
          basis) which may arise:
        </Typography>

        <ul className="ml-8 list-disc">
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              From User&apos;s use of the Platform (including but not limited to the display of such User&apos;s
              information on the Platform);
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              From breach of these Terms.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              From User&apos;s breach of any representations and warranties made by User to nTeaser, including but not
              limited to those set forth in Sections 5 above.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
              Directly or indirectly, as a result of any claims asserted by third party rights claimants or other third
              parties relating to products offered or displayed on the Platform. Each User hereby further agrees that
              nTeaser is not responsible and shall have no liability to it, for any material posted by others, including
              defamatory, offensive or illicit material and that the risk of damages from such material rests entirely
              with each User. nTeaser reserves the right, at its own expense, to assume the exclusive defense and
              control of any matter otherwise subject to indemnification by User, in which event User shall cooperate
              with nTeaser in asserting any available defences.
            </Typography>
          </li>
        </ul>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          b) Provided that the User who first becomes aware of such circumstance immediately notifies nTeaser in writing
          of such claim, gives nTeaser full control of the defense and legal direction of the claim, immediately
          provides nTeaser with all reasonably available information and assistance, and has not compromised or settled
          such claim.
        </Typography>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          9. END OF THE RELATIONSHIP
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          Without affecting any other right or remedy available to it, nTeaser may terminate its relationship with
          immediate effect by giving written notice to User if the User commits a material breach of any other term of
          these Terms and Conditions. For avoidance of doubt, nTeaser reserves its right to cease access to the Platform
          and terminate the relationship with User in its sole discretion if it believes that the User is not using the
          Platform properly or does not meet the profile intended for use of the Platform.
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          On termination of the relationship: (a) the right granted under clause 5.a) (right to permit User to use the
          Platform) and all other licenses granted under or pursuant to these Terms and Conditions shall immediately
          terminate; (b) all User&apos;s rights to access and use the Platform shall cease immediately; (c) User shall
          return and make no further use of any confidential information, which shall be returned or destroyed, unless
          retention of any such item is required under the applicable laws; (d) any rights, remedies, obligations or
          liabilities of the Parties that have accrued up to the date of termination, including the right to claim
          damages in respect of any breach of the agreement which existed at or before the date of termination shall not
          be affected or prejudiced; and any provision of these Terms and Conditions which expressly or by implication
          is intended to come into or continue in force on or after termination of these Terms and Conditions including
          5 (Intellectual Property), 9 (Data protection), 11 (Confidentiality), 7 (Warranty and liability disclaimer), 8
          (Indemnity), 14 (Governing law and jurisdiction).
        </Typography>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          10. DATA PROTECTION
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          The personal data collected though the Platform related to access and use of the Users, as well as
          communications or requests addressed between Users and nTeaser, will be processed by nTeaser, as owner of the
          Platform and responsible for processing, in accordance with the applicable regulations at all times and
          ensuring the confidentiality of the same, as set out in the Privacy Policy and Cookies Policy.
        </Typography>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          11. CONFIDENTIALITY
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          Each Party agrees, during the execution of these Terms and thereafter, to keep confidential, and not to use
          for its own purposes (other than implementation of these Terms) nor without the prior written consent of the
          other to disclose to any third party (except its professional advisors under obligations of confidentiality)
          any information of a confidential nature (including trade secrets and information of commercial value) which
          may become known to such Party from the other Party and which relates to the other Party or any of its
          customers, suppliers or other trading partners. Without limiting the generality of this clause, the technology
          and design of the Platform and the Platform services and the Content shall constitute confidential information
          of nTeaser.
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          The restrictions above shall not apply to the extent that: (i) such information is public knowledge or already
          known to such Party at the time of disclosure, or subsequently becomes public knowledge, in each case other
          than by breach of this Platform Agreement; (ii) subsequently comes lawfully into the possession of such Party
          from a third Party; or (iii) such information is required to be disclosed by law or competent authority.
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          Neither Party shall be responsible for any loss, destruction, alteration or disclosure of confidential
          information caused by any third Party.
        </Typography>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          12. SUPPORT
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          nTeaser shall provide reasonable support services to Users regarding the Platform and its functionalities,
          subject to these Terms. The support services may include assistance with technical issues, clarifications on
          the Platform features and general guidance related to the use of the Platform.
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          nTeaser will endeavor to respond to the User inquiries and requests for support within a reasonable timeframe,
          but response times may vary based on the complexity and volume of requests. Users may report to nTeaser any
          incident related to the use of the Platform at: clients@nteaser.com.
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          Support services will be provided on a best-effort basis and may be subject to certain limitations. nTeaser
          does not guarantee that all support inquiries or issues will be resolved, and it reserves the right to
          determine the level and extent of support provided.
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          nTeaser&apos;s support services do not extend to general business, investment or legal advice. Users are
          responsible for making their own investment decisions and seeking professional advice when necessary.
        </Typography>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          13. THIRD PARTIES LINKS
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          nTeaser is not responsible for the content posted by third parties or third-party websites that can be
          accessed from the Website; nor for the accuracy, truthfulness and validity of the information that is not of
          its own creation, and whose content belongs to third parties outside nTeaser.
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          However, pursuant to Article 17 of Law 34/2002 of July 11, 2002, on information society services and
          electronic commerce, when nTeaser becomes aware of the unlawfulness of such content or links, it will proceed
          to remove them, as well as blocking the content that may be unlawful or violate the rights of third parties,
          pursuant to Articles 11 and 16 of that Law.
        </Typography>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          14. MODIFICATIONS TO THE TERMS
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          nTeaser reserves the right to modify or update the Terms at any time, prior notice to Users through the
          notification systems enabled on the Platform, for a period of 15 days prior to their entry into force.
        </Typography>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          15. GOVERNING LAW AND JURISDICTION
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          These Terms and any dispute or claim arising out of or in connection with it or its subject matter or
          formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with
          the laws of Spain.
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          Each Party irrevocably agrees that the courts of the city of Madrid shall have exclusive jurisdiction to
          settle any dispute or claim arising out of or in connection with these Terms or its subject matter or
          formation (including non-contractual disputes or claims).
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          Last update: November 2024
        </Typography>
      </div>
    </AnimationShell>
  );
}
