import { ChangeEvent, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { cn } from '@/utils/styles';

import { Icon } from '../icons';
import { Radio } from '../radio';

export type RatingProps = {
  readonly id: string;
  readonly maxRating?: number;
  readonly registration?: Partial<UseFormRegisterReturn>;
};

export function Rating({ id, maxRating = 5, registration }: RatingProps) {
  const ratingOptions = [...Array(maxRating + 1).keys()].splice(1);
  const [selectedRating, setSelectedRating] = useState(0);

  return (
    <div className="50 flex w-full gap-2">
      {ratingOptions.map((option) => (
        <Radio
          key={option.toString()}
          value={option.toString()}
          registration={{
            ...registration,
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              registration?.onChange?.(event);
              setSelectedRating(parseInt(event.target.value));
            },
          }}
          id={`${id}-${option}`}
          classes={{ input: 'hidden' }}
        >
          <Icon
            name="start-rating"
            className={cn(
              'h-5 w-5 ',
              selectedRating && option <= selectedRating ? 'text-gold-chip' : 'text-neutral-300'
            )}
          />
        </Radio>
      ))}
    </div>
  );
}
