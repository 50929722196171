import { useTranslation } from 'react-i18next';

import { Chip, Icon, Link, Typography } from '@/components';
import { Route } from '@/constants';
import { useCanViewOffer } from '@/features/offers/hooks/use-can-view-offer';
import { OfferEntryTable } from '@/features/offers/types';
import { eurosMWp } from '@/helpers';
import { useMilestones } from '@/hooks';

export type OpportunityOffersItemProps = {
  readonly offer: OfferEntryTable;
  readonly index: number;
};

export function OpportunityOffersItem({ offer, index }: OpportunityOffersItemProps) {
  const { t } = useTranslation(['offers']);
  const { kv: milestones } = useMilestones();
  const offerCanView = useCanViewOffer(offer.id);

  return (
    <Link
      to={Route.Offer.replace(':offerId', offer.id)}
      className="flex w-full items-start justify-start border-t border-neutral-50 p-2 hover:bg-neutral-25 hover:no-underline"
    >
      <Typography as="p" size="body-3" color="primary" weight="normal" className="w-1/6">
        {offer.company.name}
      </Typography>
      <Typography as="p" size="body-3" color="primary" weight="normal" className="w-1/6">
        {eurosMWp(offer.price)}
      </Typography>
      <Typography as="p" size="body-3" color="primary" weight="normal" className="w-1/6">
        {offer.milestones[0].percentage}% {milestones[offer.milestones[0].type]}
      </Typography>
      <Typography as="p" size="body-3" color="primary" weight="normal" className="w-1/6">
        {`${offer.dueDiligenceWeeks} weeks`}
      </Typography>
      <Typography as="p" size="body-3" color="primary" weight="normal" className="w-1/6">
        <Chip color={offer.status.color}>{offer.status.name}</Chip>
      </Typography>

      <div className="flex w-1/6 gap-1">
        <Icon name="eye-open" className="h-8 w-8 rounded bg-primary-700 p-1 text-white hover:bg-primary-800" />
      </div>
    </Link>

    // Code to display an item as offer but with no rights to view details.
    // <div
    //   key={`offer-${index}`}
    //   className="flex w-full items-start justify-start border-t border-neutral-50 bg-gray-100 p-2"
    // >
    //   <Typography as="p" size="body-3" color="secondary" weight="normal">
    //     {t('This offer will be available after the opportunity deadline.')}
    //   </Typography>
    // </div>
  );
}
