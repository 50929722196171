import { Project, ProjectItemList } from '../types';

import {
  getCapacityFromTechnologies,
  getColorFromProjectStatus,
  getDevelopmentStatusFromTechnology,
  getIconNamesFromTechnologies,
  getIconStatesFromTechnologies,
  getTechnologyTypesFromTechnologies,
} from './helpers';

export function projectItemListTransformer(project: Project): ProjectItemList {
  return {
    id: project.id,
    title: project.title,
    location: {
      country: project.country,
      city: [project.city],
    },
    technologies: {
      types: getTechnologyTypesFromTechnologies(project.technologies),
      icons: {
        name: getIconNamesFromTechnologies(project.technologies),
        states: getIconStatesFromTechnologies(project.technologies),
      },
      state: {
        list: getDevelopmentStatusFromTechnology(project.technologies),
      },
      capacity: getCapacityFromTechnologies(project.technologies),
    },
    status: {
      name: project.status,
      color: getColorFromProjectStatus(project.status),
    },
    isDraft: project.status === 'draft',
    isPending: project.status === 'pending',
    isPublished: project.status === 'published',
    isArchived: project.status === 'archived',
  };
}
