import { Button, Icon, Typography } from '@/components';
import { cn } from '@/utils/styles';

export type CollapsableHeaderProps = {
  readonly title: string;
  readonly description?: string;
  readonly isExpanded: boolean;
  readonly action?: {
    readonly title: string;
    readonly onClick?: () => void;
  };
  readonly onToggle?: () => void;
};

export function CollapsableHeader({ title, description, isExpanded, action, onToggle }: CollapsableHeaderProps) {
  return (
    <div className="flex items-center justify-between gap-4 p-6">
      <div className="cursor-pointer space-y-1" onClick={() => onToggle?.()}>
        <Typography as="h4" size="title" color="primary" weight="semibold">
          {title}
        </Typography>

        <Typography as="p" size="body-3" color="secondary" weight="normal" className="italic">
          {description}
        </Typography>
      </div>

      <div className="inline-flex items-center justify-start gap-4">
        {action ? (
          <Button size="md" color="neutral" icon="edit" onClick={() => action.onClick?.()} disabled={!action.onClick}>
            {action.title}
          </Button>
        ) : null}

        <button
          type="button"
          className="flex h-10 w-10 items-center justify-center rounded-full border border-neutral-50 bg-base-white transition hover:bg-neutral-25 hover:shadow-sm"
          onClick={() => onToggle?.()}
        >
          <Icon
            name="chevron-left"
            className={cn('h-6 w-6 text-base-primary transition', isExpanded ? 'rotate-90' : '-rotate-90')}
          />
        </button>
      </div>
    </div>
  );
}
