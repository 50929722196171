import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SaleOption } from '@/features/opportunities/types';

export function useSaleOptions() {
  const { t } = useTranslation(['saleStatus']);

  const options = useMemo(
    () => [
      { label: t(`As is`), value: 'as-is' as const },
      { label: t(`Ready to Build`), value: 'rtb' as const },
      { label: t(`COD`), value: 'cod' as const },
      { label: t(`Operation`), value: 'operation' as const },
      { label: t(`Co-development`), value: 'co-development' as const },
    ],
    [t]
  );

  const kv = useMemo(
    () => options.reduce((map, option) => ({ ...map, [option.value]: option.label }), {}) as Record<SaleOption, string>,
    [options]
  );

  const map = useCallback((value: SaleOption) => kv[value], [kv]);

  return { options, kv, map };
}
