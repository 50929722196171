import { useTranslation } from 'react-i18next';

import { Button, EmptyState, WorkOfficeDesk } from '@/components';

export type MyOpportunitiesEmptyStateProps = {
  readonly onCreateOpportunity?: () => void;
};

export function MyOpportunitiesEmptyState({ onCreateOpportunity }: MyOpportunitiesEmptyStateProps) {
  const { t } = useTranslation(['opportunity']);

  return (
    <EmptyState
      artwork={<WorkOfficeDesk className="w-64" />}
      title={t('You don’t have any projects on sale')}
      description={t(
        'Press “Create Project” to upload your first project. If you have any doubts, please send an email to '
      )}
      email={'projects@nteaser.com'}
    >
      <Button size="lg" color="primary" icon="plus" onClick={() => onCreateOpportunity?.()}>
        {t('Create Project')}
      </Button>
    </EmptyState>
  );
}
