import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Form, Input, Select, TextArea, Typography } from '@/components';
import { usePanelConfiguration, useStructureType } from '@/features/projects/hooks';
import { SolarProjectTechnology } from '@/features/projects/types';
import { nullableStringSetter } from '@/helpers';

export type SolarBasicDesignFormValues = {
  structure_type: string | null;
  module_type: string | null;
  module_power: number | null;
  panel_configuration: string | null;
  pitch_distance: number | null;
  basic_design_comments: string | null;
};

export type SolarBasicDesignFormFields = {
  structure_type: string | null;
  module_type: string | null;
  module_power: number | null;
  panel_configuration: string | null;
  pitch_distance: number | null;
  basic_design_comments: string | null;
};

export type SolarBasicDesignFormProps = {
  readonly id: string;
  readonly item: SolarProjectTechnology;
  readonly onSubmit: (data: SolarBasicDesignFormValues) => void;
};

export function SolarBasicDesignForm({ id, item, onSubmit }: SolarBasicDesignFormProps) {
  const { t } = useTranslation(['projects']);

  const { options: structure_typeOptions } = useStructureType();
  const { options: panel_configurationOptions } = usePanelConfiguration();

  const options = useMemo(
    () => ({
      defaultValues: {
        structure_type: item?.structure_type ?? null,
        module_type: item?.module_type ?? null,
        module_power: item?.module_power ?? null,
        panel_configuration: item?.panel_configuration ?? null,
        pitch_distance: item?.pitch_distance ?? null,
        basic_design_comments: item?.basic_design_comments ?? null,
      },
    }),
    [item]
  );

  return (
    <Form<SolarBasicDesignFormFields> id={id} onSubmit={onSubmit} options={options}>
      {({ formState, register }) => (
        <div className="space-y-6">
          <Typography as="p" color="secondary" size="body-3" weight="normal" className="mt-1 italic">
            {t(
              'Please complete this section with the main information of the project design and technology, including module type, module power, panel configuration, pitch distance, and any additional comments.'
            )}
          </Typography>
          <Field
            id="structure_type"
            label={t('Structure type')}
            error={formState.errors['structure_type']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Select
              id="structure_type"
              options={structure_typeOptions}
              placeholder={t('Select an option')}
              color={formState.errors['structure_type'] ? 'error' : 'primary'}
              registration={register('structure_type', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <Field
            id="module_type"
            label={t('Module type')}
            error={formState.errors['module_type']}
            delay={0.15}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="module_type"
              type="text"
              color={formState.errors['module_type'] ? 'error' : 'primary'}
              registration={register('module_type', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <Field
            id="module_power"
            label={t('Module power')}
            error={formState.errors['module_power']}
            delay={0.25}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="module_power"
              type="number"
              step="0.1"
              suffix="W"
              color={formState.errors['module_power'] ? 'error' : 'primary'}
              registration={register('module_power', { min: 0, valueAsNumber: true })}
            />
          </Field>

          <Field
            id="panel_configuration"
            label={t('Panel configuration')}
            error={formState.errors['panel_configuration']}
            delay={0.35}
            classes={{ field: 'space-y-1' }}
          >
            <Select
              id="panel_configuration"
              options={panel_configurationOptions}
              placeholder={t('Select an option')}
              color={formState.errors['panel_configuration'] ? 'error' : 'primary'}
              registration={register('panel_configuration', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <Field
            id="pitch_distance"
            label={t('Pitch distance')}
            error={formState.errors['pitch_distance']}
            delay={0.45}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="pitch_distance"
              type="number"
              suffix="m"
              color={formState.errors['pitch_distance'] ? 'error' : 'primary'}
              registration={register('pitch_distance', { min: 0, valueAsNumber: true })}
            />
          </Field>

          <Field
            id="comments"
            label={t('Comments')}
            error={formState.errors['basic_design_comments']}
            delay={0.55}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="comments"
              color={formState.errors['basic_design_comments'] ? 'error' : 'primary'}
              registration={register('basic_design_comments', { setValueAs: nullableStringSetter })}
            />
          </Field>
        </div>
      )}
    </Form>
  );
}
