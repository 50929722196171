import { useCallback } from 'react';

import { useAddDealsStatsMutation } from '../api';
import { AddDealsStatsRequest } from '../api/types';

export type useSendDealsStatsProps = {
  readonly type: string;
};

export function useSendDealsStats({ type }: useSendDealsStatsProps) {
  const [sendStats] = useAddDealsStatsMutation();

  return useCallback(
    (id: string) => {
      sendStats({
        deal: id,
        key: type,
      } as AddDealsStatsRequest);
    },
    [sendStats, type]
  );
}
