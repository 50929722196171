import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { useCollapsableAction } from '@/features/projects/hooks';
import { eurosMWp, eurosMW, km, kv } from '@/helpers';
import { useBooleanDisplayNormalizer, useConnectionTo, useConnectionType } from '@/hooks';
import { isEmpty, isNullish } from '@/types';

import { Technology } from '../../../types';

export type ConnectionInfrastructureCollapsableProps = {
  readonly technology: Technology;
  readonly initialExpandedState?: boolean;
  readonly onEdit?: () => void;
  readonly hidden?: boolean;
};

export function ConnectionInfrastructureCollapsable({
  technology,
  onEdit,
  initialExpandedState = false,
  hidden,
}: ConnectionInfrastructureCollapsableProps) {
  const { t } = useTranslation(['projects']);

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const action = useCollapsableAction({ onEdit, setIsExpanded });

  const item = technology.details.connectionInfrastructure;
  const suffix = technology.type === 'solar' ? eurosMWp : eurosMW;

  const booleanFormatter = useBooleanDisplayNormalizer();

  const { map: mapConnectionTo } = useConnectionTo();
  const { map: mapConnectionType } = useConnectionType();

  const description = onEdit
    ? t(
        "Please complete this section with detailed information about the project's connection infrastructure, including the line distance, voltage, type (underground/overhead), connection to (substation/power line), expected costs, and any additional comments."
      )
    : t(
        "This section provides detailed information about the project's connection infrastructure, including the line distance, voltage and type, as well as the expected costs and additional comments."
      );

  const header = (
    <CollapsableHeader
      title={t('Connection Infrastructure')}
      description={description}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
      action={action}
    />
  );

  const data = [
    {
      label: t('Evacuation line distance'),
      value: km(item.evacuationLineTotalDistance),
    },
    {
      label: t('Evacuation line voltatge'),
      value: kv(item.evacuationLineVoltage),
    },
    { label: t('Connection type'), value: item.connectionType?.map(mapConnectionType).join(', ') },
    { label: t('Connection to'), value: item.connectionTo?.map(mapConnectionTo).join(', ') },
    {
      label: t('Connection costs'),
      value: suffix(item?.connectionCosts),
    },
    {
      label: t('Connection costs detail'),
      value: item.connectionCostsComments,
    },
    {
      label: t('Shared infrastructure'),
      value: booleanFormatter(item.sharedInfrastructure),
    },
    {
      label: t('Shared infrastructure detail'),
      value: item.sharedInfrastructureComments,
    },
    { label: t('Comments'), value: item.connectionInfrastructureComments },
  ];
  const filteredData = isNullish(onEdit) ? data.filter(({ value }) => !(isNullish(value) || isEmpty(value))) : data;

  return filteredData.length ? (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody data={filteredData} hidden={hidden} />
    </Collapsable>
  ) : null;
}
