import { ClassValue } from 'clsx';

import { cn } from '@/utils/styles';

import { TypographyColor } from '../typography';
import { colors } from '../typography/constants';

export type LogoProps = {
  readonly color?: TypographyColor;
  readonly className?: ClassValue;
};

export function Logo({ color = 'brand', className }: LogoProps) {
  return (
    <svg className={cn(colors[color], className)} viewBox="0 0 427 74" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.5156 15.1979C24.0208 15.1979 17.349 18.3802 13.1406 23.3073L10.2656 16.4271H0V71.8646H14.9896V42.8125C14.9896 33.8802 19.7083 28.3386 27.4115 28.3386C33.875 28.3386 37.0573 32.3386 37.0573 40.7604V71.8646H52.0469V38.2969C52.0469 24.2292 44.1406 15.1979 31.5156 15.1979Z"
        fill="currentColor"
      />
      <path d="M113.448 0H60.7812V8.52083H82.6458V71.8646H91.5781V8.52083H113.448V0Z" fill="currentColor" />
      <path
        d="M176.27 44.4531C176.27 28.2344 165.186 15.2969 148.348 15.2969C132.228 15.2969 120.119 27.7187 120.119 44.1458C120.119 60.6771 132.228 73.099 148.348 73.099C160.358 73.099 170.113 66.4219 174.832 55.026H165.592C162.207 60.9844 156.046 64.5781 148.348 64.5781C137.879 64.5781 130.176 57.8021 128.842 47.5365H176.066C176.171 46.5052 176.27 45.4792 176.27 44.4531ZM148.348 23.5104C158.202 23.5104 165.697 29.6719 167.546 39.526H129.046C130.79 29.875 138.285 23.5104 148.348 23.5104Z"
        fill="currentColor"
      />
      <path
        d="M236.633 16.4271L235.503 24.9479C230.372 18.8906 222.669 15.2969 213.638 15.2969C197.211 15.2969 184.893 27.6198 184.893 44.25C184.893 60.6771 197.211 73.099 213.638 73.099C222.669 73.099 230.268 69.5052 235.404 63.5469L236.326 71.8646H243.513V16.4271H236.633ZM214.253 64.6771C202.55 64.6771 193.826 55.849 193.826 44.1458C193.826 32.5469 202.55 23.7188 214.253 23.7188C226.06 23.7188 234.581 32.4427 234.581 44.1458C234.581 55.9531 226.06 64.6771 214.253 64.6771Z"
        fill="currentColor"
      />
      <path
        d="M278.619 73.099C291.759 73.099 300.587 66.4219 300.587 56.0573C300.587 42.5052 289.296 40.6562 279.749 39.1146C272.358 37.9844 265.994 36.8594 265.994 31.0052C265.994 26.1823 270.712 23.099 278.207 23.099C286.009 23.099 290.733 26.3854 291.041 32.4427H299.254C298.947 21.974 290.936 15.2969 278.416 15.2969C265.89 15.2969 257.473 21.7656 257.473 31.3125C257.473 43.8385 268.145 45.7865 277.593 47.4323C285.087 48.6615 291.962 49.8958 291.962 56.4635C291.962 61.8021 286.525 65.0885 278.926 65.0885C271.124 65.0885 265.478 61.4948 265.067 54.9271H256.442C256.957 66.1146 265.478 73.099 278.619 73.099Z"
        fill="currentColor"
      />
      <path
        d="M365.568 44.4531C365.568 28.2344 354.485 15.2969 337.646 15.2969C321.526 15.2969 309.417 27.7187 309.417 44.1458C309.417 60.6771 321.526 73.099 337.646 73.099C349.656 73.099 359.412 66.4219 364.13 55.026H354.891C351.505 60.9844 345.344 64.5781 337.646 64.5781C327.177 64.5781 319.474 57.8021 318.141 47.5365H365.365C365.469 46.5052 365.568 45.4792 365.568 44.4531ZM337.646 23.5104C347.5 23.5104 354.995 29.6719 356.844 39.526H318.344C320.089 29.875 327.584 23.5104 337.646 23.5104Z"
        fill="currentColor"
      />
      <path
        d="M403.962 16.224C395.957 16.224 390.207 19.198 386.202 24.948L384.869 16.4271H378.603V71.8646H387.332V41.7865C387.332 31.0053 393.082 24.4375 403.244 24.4375H408.889V16.224H403.962Z"
        fill="currentColor"
      />
      <path d="M416.484 71.8646H426.442V60.9844H416.484V71.8646Z" fill="currentColor" />
    </svg>
  );
}
