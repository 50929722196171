import { useTranslation } from 'react-i18next';

import { Field, Form, TextArea, Rating } from '@/components';
import { nullableStringSetter } from '@/helpers';

export type RatingFormProps = {
  readonly id: string;
  readonly onSubmit: (data: RatingFormFields) => void;
};

export type RatingFormFields = {
  price_score: number | null;
  milestones_score: number | null;
  speed_score: number | null;
  rating_comments: string | null;
};

export function RatingForm({ id, onSubmit }: RatingFormProps) {
  const { t } = useTranslation(['offers']);

  return (
    <Form<RatingFormFields> id={id} onSubmit={onSubmit} className="px-6">
      {({ formState, register }) => (
        <div className="space-y-6">
          <div className="flex w-full">
            <Field
              id="price_score"
              label={t('Price score')}
              error={formState.errors['price_score']}
              delay={0}
              classes={{ field: 'space-y-1 w-1/3' }}
            >
              <Rating
                id="price_score"
                registration={register('price_score', { required: t('This field is required'), valueAsNumber: true })}
                maxRating={5}
              ></Rating>
            </Field>

            <Field
              id="milestones_score"
              label={t('Milestone score')}
              error={formState.errors['milestones_score']}
              delay={0}
              classes={{ field: 'space-y-1 w-1/3' }}
            >
              <Rating
                id="milestones_score"
                registration={register('milestones_score', {
                  required: t('This field is required'),
                  valueAsNumber: true,
                })}
                maxRating={5}
              ></Rating>
            </Field>

            <Field
              id="speed_score"
              label={t('Speed score')}
              error={formState.errors['speed_score']}
              delay={0}
              classes={{ field: 'space-y-1 w-1/3' }}
            >
              <Rating
                id="speed_score"
                registration={register('speed_score', { required: t('This field is required'), valueAsNumber: true })}
                maxRating={5}
              ></Rating>
            </Field>
          </div>

          <Field
            id="rating_comments"
            label={t('Rating comments')}
            error={formState.errors['rating_comments']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="rating_comments"
              color="primary"
              placeholder="Type here..."
              registration={register('rating_comments', {
                required: t('This field is required'),
                setValueAs: nullableStringSetter,
              })}
            />
          </Field>
        </div>
      )}
    </Form>
  );
}
