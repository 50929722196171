import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { api } from '@/features/auth';
import apiCacheMiddleware from '@/features/auth/api/middlewares/api-cache';
import { authSlice } from '@/features/auth/store';
import { headerSlice } from '@/features/header/store';
import { notificationsSlice } from '@/features/notifications/store';
import { sidebarSlice } from '@/features/sidebar/store';

export const store = configureStore({
  reducer: {
    [notificationsSlice.name]: notificationsSlice.reducer,
    [sidebarSlice.name]: sidebarSlice.reducer,
    [headerSlice.name]: headerSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, apiCacheMiddleware),
});

setupListeners(store.dispatch);
