import { AnimationShell } from '@/components';
import { ProjectItemList } from '@/features/projects/types';

import { MyProjectsItem } from '../item';

export type MyProjectsItemsProps = {
  readonly projects?: ProjectItemList[];
  readonly names?: string[];
};

export function MyProjectsItems({ projects, names }: MyProjectsItemsProps) {
  return (
    <AnimationShell
      type="opacity"
      className="grid w-full grid-cols-1 place-items-stretch gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
    >
      {projects?.map((project, index) => (
        <MyProjectsItem key={project.id} index={index} project={project} name={names?.[index]} />
      ))}
    </AnimationShell>
  );
}
