import { OfferTabDetails } from './offer-tab-details';
import { OfferTabOpportunity } from './offer-tab-opportunity';

export type OfferViewProps = {
  readonly id: string;
  readonly tabActive: number;
};

export function OfferView({ id, tabActive }: OfferViewProps) {
  return <>{tabActive === 1 ? <OfferTabOpportunity id={id} /> : <OfferTabDetails id={id} />}</>;
}
