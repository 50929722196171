import { ClassValue } from 'clsx';
import { ReactNode } from 'react';
import { FieldValues, SubmitHandler, UseFormProps, UseFormReturn, useForm } from 'react-hook-form';

import { cn } from '@/utils/styles';

export type FormProps<TFieldValues extends FieldValues> = {
  readonly children: (methods: UseFormReturn<TFieldValues>) => ReactNode;
  readonly onSubmit: SubmitHandler<TFieldValues>;
  readonly id?: string;
  readonly className?: ClassValue;
  readonly options?: UseFormProps<TFieldValues>;
};

export function Form<TFieldValues extends FieldValues>({
  children,
  onSubmit,
  id,
  className,
  options,
}: FormProps<TFieldValues>) {
  const methods = useForm<TFieldValues>({ ...options });

  return (
    <form id={id} className={cn(className)} onSubmit={methods.handleSubmit(onSubmit)}>
      {children(methods)}
    </form>
  );
}
