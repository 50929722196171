import { ClassValue } from 'clsx';
import { ReactNode } from 'react';

import { AnimationShell } from '@/components';
import { cn } from '@/utils/styles';

export type LabelProps = {
  readonly children: ReactNode;
  readonly id?: string;
  readonly className?: ClassValue;
};

export function Label({ children, id, className }: LabelProps) {
  return (
    <AnimationShell type="slide-right" delay={0.15}>
      <label
        htmlFor={id}
        className={cn('block text-sm font-medium leading-6 tracking-wide text-neutral-900', className)}
      >
        {children}
      </label>
    </AnimationShell>
  );
}
