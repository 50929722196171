import { MouseEvent, ReactNode, useCallback } from 'react';

import { Icon } from '@/components/icons';
import type { IconName } from '@/components/icons/types';
import { cn } from '@/utils/styles';

import { colors, sizes } from './constants';
import type { ButtonColor, ButtonSize, ButtonType } from './types';

export type ButtonProps = {
  readonly id?: string;
  readonly type?: ButtonType;
  readonly form?: string;
  readonly color?: ButtonColor;
  readonly size?: ButtonSize;
  readonly rounded?: boolean;
  readonly icon?: IconName;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  readonly children?: ReactNode;
};

export function Button({
  id,
  type = 'button',
  form,
  color = 'primary',
  size = 'md',
  rounded = false,
  icon,
  disabled = false,
  className,
  onClick,
  children,
}: ButtonProps) {
  const handleOnClick = useCallback((event: MouseEvent<HTMLButtonElement>) => onClick?.(event), [onClick]);

  return (
    <button
      id={id}
      form={form}
      type={type}
      className={cn(
        'flex shrink-0 items-center justify-center text-center',
        rounded ? 'rounded-full' : 'rounded-md',
        'border border-solid transition',
        sizes[size],
        colors[color],
        'cursor-pointer select-none disabled:cursor-not-allowed',
        className
      )}
      disabled={disabled || (!onClick && type !== 'submit')}
      onClick={handleOnClick}
    >
      {icon ? (
        <span className="flex items-center justify-center gap-x-1">
          <Icon name={icon} className={cn('fill-inherit text-inherit', size === 'sm' ? 'h-5 w-5' : 'h-6 w-6')} />

          {children}
        </span>
      ) : (
        children
      )}
    </button>
  );
}
