import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Wind({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="wind"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0392 8.31027C11.5754 7.77403 12.3027 7.47278 13.061 7.47278C13.8194 7.47278 14.5467 7.77403 15.0829 8.31027C15.6192 8.84651 15.9204 9.5738 15.9204 10.3322C15.9204 11.0905 15.6192 11.8178 15.0829 12.354C14.5467 12.8903 13.8194 13.1915 13.061 13.1915C12.3027 13.1915 11.5754 12.8903 11.0392 12.354C10.5029 11.8178 10.2017 11.0905 10.2017 10.3322C10.2017 9.5738 10.5029 8.84651 11.0392 8.31027ZM13.061 8.97278C12.7005 8.97278 12.3547 9.116 12.0998 9.37093C11.8449 9.62586 11.7017 9.97162 11.7017 10.3322C11.7017 10.6927 11.8449 11.0384 12.0998 11.2934C12.3547 11.5483 12.7005 11.6915 13.061 11.6915C13.4216 11.6915 13.7673 11.5483 14.0223 11.2934C14.2772 11.0384 14.4204 10.6927 14.4204 10.3322C14.4204 9.97162 14.2772 9.62586 14.0223 9.37093C13.7673 9.116 13.4216 8.97278 13.061 8.97278Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.061 11.6915C13.4752 11.6915 13.811 12.0273 13.811 12.4415V22.5468C13.811 22.9611 13.4752 23.2968 13.061 23.2968C12.6468 23.2968 12.311 22.9611 12.311 22.5468V12.4415C12.311 12.0273 12.6468 11.6915 13.061 11.6915Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.72168 22.5469C6.72168 22.1327 7.05747 21.7969 7.47168 21.7969H18.6495C19.0637 21.7969 19.3995 22.1327 19.3995 22.5469C19.3995 22.9611 19.0637 23.2969 18.6495 23.2969H7.47168C7.05747 23.2969 6.72168 22.9611 6.72168 22.5469Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0167 2.4235C15.6245 2.31534 15.2152 2.27314 14.8057 2.30042C14.1437 2.3445 13.5063 2.56849 12.9623 2.9482C12.4182 3.32792 11.9882 3.84896 11.7185 4.45513C11.4488 5.06131 11.3497 5.7296 11.4319 6.38795C11.4832 6.79897 11.1916 7.17378 10.7806 7.2251C10.3696 7.27643 9.99478 6.98483 9.94345 6.57381C9.82741 5.64447 9.96728 4.70108 10.348 3.84539C10.7287 2.9897 11.3358 2.25419 12.1038 1.71817C12.8718 1.18215 13.7715 0.865969 14.706 0.803731C15.6405 0.741493 16.5743 0.935559 17.4066 1.365C17.7746 1.5549 17.9191 2.00718 17.7293 2.37527L14.4677 8.69965C14.2778 9.06779 13.8255 9.21232 13.4573 9.02246C13.0892 8.83261 12.9447 8.38026 13.1345 8.01212L16.0167 2.4235Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8264 9.1333C17.0081 8.76104 17.4571 8.60652 17.8294 8.78817C18.6339 9.18076 19.3221 9.77648 19.8259 10.5165C20.3298 11.2565 20.6318 12.1151 20.7022 13.0076C20.7726 13.9 20.6089 14.7954 20.2273 15.6052C19.8456 16.415 19.2593 17.1113 18.5263 17.6251C18.3634 17.7393 18.1617 17.7841 17.9658 17.7496C17.7698 17.7151 17.5956 17.6042 17.4815 17.4412L13.8843 12.3056C13.6467 11.9663 13.7291 11.4987 14.0683 11.261C14.4076 11.0234 14.8753 11.1058 15.1129 11.4451L18.2349 15.9022C18.4931 15.6262 18.7079 15.3105 18.8704 14.9658C19.1407 14.3921 19.2567 13.7578 19.2068 13.1255C19.157 12.4932 18.943 11.8849 18.5861 11.3607C18.2291 10.8364 17.7416 10.4144 17.1716 10.1362C16.7993 9.95459 16.6448 9.50556 16.8264 9.1333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7015 10.0468C11.754 10.4577 11.4635 10.8333 11.0527 10.8859L5.21853 11.632C5.32338 11.9327 5.46752 12.2195 5.64802 12.4845C5.97102 12.9588 6.40136 13.3502 6.90406 13.6269C7.40676 13.9035 7.96764 14.0577 8.54114 14.0769C9.11464 14.0961 9.68457 13.9797 10.2046 13.7372C10.58 13.5621 11.0263 13.7246 11.2013 14.1C11.3764 14.4754 11.2139 14.9216 10.8385 15.0967C10.1046 15.4389 9.30034 15.6031 8.49102 15.5761C7.6817 15.549 6.89017 15.3314 6.18076 14.9409C5.47135 14.5505 4.86406 13.9982 4.40823 13.3289C3.95241 12.6596 3.66091 11.8922 3.55742 11.089C3.53199 10.8916 3.58606 10.6922 3.70773 10.5347C3.82941 10.3772 4.0087 10.2745 4.20613 10.2492L10.8624 9.39799C11.2732 9.34544 11.6489 9.63592 11.7015 10.0468Z"
        fill="currentColor"
      />
    </svg>
  );
}
