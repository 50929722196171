import { useFilteredOpportunities } from '@/features/opportunities/hooks';
import { useRegisterGAPageView } from '@/hooks';
import { useSearchParams } from 'react-router-dom';
import ReactGa from 'react-ga4';
import { OpportunitiesEmptyState } from '../empty-state';
import { OpportunitiesItems } from '../items';
import { useCallback, useState, useEffect } from 'react';
import { OpportunitiesContentHeader } from './header';
import { CreateOpportunityDrawer } from '@/features/opportunities';

export function OpportunitiesContent({ onResetFilters }: any) {
  const { opportunities, reload } = useFilteredOpportunities(); // Ahora tenemos `reload`
  const [searchParams] = useSearchParams();
  useRegisterGAPageView('Home');
  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsOpen(false);

    ReactGa.event({
      category: 'form_closed',
      action: 'Opportunity creation proccess cancelled',
      label: 'User has abandoned the opportunity creation process',
    });
  }, []);

  useEffect(() => {
    reload();
  }, [searchParams, reload]);

  if (!opportunities) return null;

  if (opportunities.length === 0) {
    return <OpportunitiesEmptyState onResetFilters={onResetFilters} />;
  }

  return (
    <>
      <div className="space-y-3">
        <OpportunitiesContentHeader onCreateOpportunity={() => setIsOpen(true)} />
        <OpportunitiesItems opportunities={opportunities} />
      </div>

      <CreateOpportunityDrawer isOpen={isOpen} onClose={onClose} />
    </>
  );
}
