import { useEffect } from 'react';

import { useAppDispatch } from '@/store';

import { set } from '../store';
import { HeaderState } from '../store/types';

export function useBreadcrumbs(callback: () => HeaderState['breadcrumb']) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(set(callback()));
  }, [dispatch, callback]);
}
