import { Category, ServiceCategory } from '../types';

export function categoriesTransformer(categories?: Category[]): ServiceCategory[] {
  if (!categories) return [];

  return categories
    .map((category) => ({
      id: category.code,
      name: category.labels.find((label) => label.language === 'en-US')?.label ?? '',
      order: category.order,
    }))
    .sort((c1, c2) => c1.order - c2.order);
}
