import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Support({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="support"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1529 6.48914C19.5007 6.26426 19.965 6.36396 20.1899 6.71181C22.2701 9.92971 22.269 14.0723 20.191 17.289C19.9662 17.6369 19.502 17.7367 19.1541 17.512C18.8061 17.2872 18.7063 16.8229 18.931 16.475C20.6891 13.7536 20.6899 10.2483 18.9302 7.52616C18.7053 7.17831 18.805 6.71401 19.1529 6.48914Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0173 8.98281C13.3509 7.31644 10.6492 7.31644 8.98281 8.98281C7.31644 10.6492 7.31644 13.3509 8.98281 15.0173C10.6492 16.6836 13.3509 16.6836 15.0173 15.0173C16.6836 13.3509 16.6836 10.6492 15.0173 8.98281ZM7.92215 7.92215C10.1743 5.66999 13.8258 5.66999 16.0779 7.92215C18.3301 10.1743 18.3301 13.8258 16.0779 16.0779C13.8258 18.3301 10.1743 18.3301 7.92215 16.0779C5.66999 13.8258 5.66999 10.1743 7.92215 7.92215Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8581 4.87079C17.7516 4.76474 17.5782 4.77415 17.4841 4.8885L14.9 8.02765C14.6367 8.34745 14.1641 8.39328 13.8443 8.13003C13.5245 7.86678 13.4786 7.39412 13.7419 7.07432L16.3258 3.93547C16.3257 3.93552 16.3258 3.93542 16.3258 3.93547C16.9837 3.13603 18.1862 3.07929 18.9176 3.80902L20.1913 5.08266C20.9237 5.81512 20.8641 7.01791 20.0656 7.67503C20.0656 7.67507 20.0657 7.67499 20.0656 7.67503L16.9266 10.259C16.6068 10.5223 16.1342 10.4764 15.8709 10.1567C15.6076 9.83685 15.6535 9.3642 15.9733 9.10094L19.1123 6.51694C19.2276 6.42204 19.2361 6.24881 19.1306 6.14332L17.8581 4.87079C17.8581 4.87084 17.858 4.87073 17.8581 4.87079Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12905 13.8433C8.3923 14.1631 8.34646 14.6358 8.02667 14.899L4.88766 17.483C4.77231 17.5779 4.76384 17.7511 4.86933 17.8566L6.1417 19.129C6.14167 19.129 6.14173 19.129 6.1417 19.129C6.24818 19.2351 6.42172 19.2258 6.51583 19.1115L9.09996 15.9723C9.36321 15.6525 9.83587 15.6067 10.1557 15.8699C10.4755 16.1332 10.5213 16.6058 10.258 16.9256L7.67417 20.0645C7.67413 20.0645 7.67421 20.0644 7.67417 20.0645C7.01628 20.8639 5.81378 20.9207 5.0823 20.1909L3.80867 18.9173C3.0762 18.1848 3.13583 16.982 3.93434 16.3249C3.93429 16.325 3.93439 16.3249 3.93434 16.3249L7.07334 13.7409C7.39313 13.4777 7.86579 13.5235 8.12905 13.8433Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.51588 4.88751C6.42098 4.77216 6.24787 4.76384 6.14238 4.86933L4.87002 6.1417C4.86995 6.14177 4.87008 6.14163 4.87002 6.1417C4.76401 6.24819 4.77323 6.42176 4.88757 6.51583L8.02763 9.09988C8.34746 9.36309 8.39337 9.83574 8.13017 10.1556C7.86697 10.4754 7.39432 10.5213 7.07448 10.2581L3.93454 7.67417C3.93452 7.67415 3.93456 7.67418 3.93454 7.67417C3.13501 7.01629 3.07833 5.81381 3.80809 5.0823L5.08172 3.80867C5.81419 3.0762 7.01697 3.13583 7.6741 3.93434C7.67405 3.93429 7.67414 3.93439 7.6741 3.93434L10.2581 7.07334C10.5214 7.39313 10.4755 7.86579 10.1557 8.12905C9.83592 8.3923 9.36326 8.34646 9.10001 8.02667L6.51588 4.88751Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8699 13.8443C16.1332 13.5245 16.6058 13.4786 16.9256 13.7419L20.0645 16.3258C20.0644 16.3257 20.0645 16.3258 20.0645 16.3258C20.8639 16.9837 20.9207 18.1862 20.1909 18.9176L18.9173 20.1913C18.1848 20.9237 16.982 20.8641 16.3249 20.0656C16.3249 20.0656 16.325 20.0657 16.3249 20.0656L13.7409 16.9266C13.4777 16.6068 13.5235 16.1342 13.8433 15.8709C14.1631 15.6076 14.6358 15.6535 14.899 15.9733L17.483 19.1123C17.5779 19.2276 17.7511 19.2361 17.8566 19.1306L19.129 17.8582C19.129 17.8583 19.1291 17.8582 19.129 17.8582C19.2351 17.7518 19.2258 17.5782 19.1115 17.4841L15.9723 14.9C15.6525 14.6367 15.6067 14.1641 15.8699 13.8443Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.4892 19.1528C6.71413 18.8049 7.17844 18.7053 7.52626 18.9302C10.2473 20.6899 13.7536 20.6891 16.475 18.931C16.8229 18.7063 17.2872 18.8061 17.512 19.1541C17.7367 19.502 17.6369 19.9662 17.289 20.191C14.0724 22.2689 9.9287 22.2702 6.71171 20.1898C6.36389 19.9649 6.26427 19.5006 6.4892 19.1528Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.84597 6.48803C5.19389 6.71279 5.29374 7.17705 5.06897 7.52498C3.31094 10.2464 3.3101 13.7517 5.06984 16.4738C5.29472 16.8217 5.19502 17.286 4.84717 17.5109C4.49931 17.7357 4.03502 17.636 3.81015 17.2882C1.72989 14.0703 1.73104 9.92767 3.80902 6.71104C4.03378 6.36311 4.49804 6.26327 4.84597 6.48803Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.71104 3.80902C9.92767 1.73104 14.0703 1.72989 17.2882 3.81015C17.636 4.03502 17.7357 4.49931 17.5109 4.84717C17.286 5.19502 16.8217 5.29472 16.4738 5.06984C13.7517 3.3101 10.2464 3.31094 7.52498 5.06897C7.17705 5.29374 6.71279 5.19389 6.48803 4.84597C6.26327 4.49804 6.36311 4.03378 6.71104 3.80902Z"
        fill="currentColor"
      />
    </svg>
  );
}
