import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Container } from '@/components';
import { useBreadcrumbs } from '@/features/header';
import { ProjectView, ProjectViewHeader } from '@/features/projects';

export function Project() {
  const { t } = useTranslation(['projects']);

  useBreadcrumbs(() => [{ title: t(`Project`) }]);

  const { projectId } = useParams();

  if (!projectId) return null;

  return (
    <div className="flex flex-col">
      <div className="border-neutrl-50 w-full border-b bg-neutral-10">
        {/* TODO-PROJECTS : REMOVE THIS VIEW - DO NOT USE */}
        <ProjectViewHeader id={projectId} />
      </div>

      <div className="w-full py-10">
        <Container className="space-y-12">
          {/* TODO-PROJECTS : REMOVE THIS VIEW - DO NOT USE */}
          <ProjectView id={projectId} opportunityId="" />
        </Container>
      </div>
    </div>
  );
}
