import { ClassValue } from 'clsx';
import { CSSProperties, MouseEvent, ReactNode } from 'react';

import { cn } from '@/utils/styles';

import { motionElements } from './constants';
import { getAnimationProps } from './helpers';
import type { AnimationType } from './types';

export type AnimationShellProps = {
  readonly type: AnimationType;
  readonly children: ReactNode;
  readonly id?: string;
  readonly element?: keyof typeof motionElements;
  readonly delay?: number;
  readonly duration?: number;
  readonly disabled?: boolean;
  readonly style?: CSSProperties;
  readonly className?: ClassValue;
  readonly onClick?: (event: MouseEvent<HTMLElement>) => void;
  readonly onMouseEnter?: (event: MouseEvent<HTMLElement>) => void;
  readonly onMouseLeave?: (event: MouseEvent<HTMLElement>) => void;
};

export function AnimationShell({
  type,
  children,
  id,
  element = 'div',
  delay,
  duration,
  disabled = false,
  style,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: AnimationShellProps) {
  const animationProps = getAnimationProps(type, delay, duration);

  const Component = motionElements[element];

  return (
    <Component
      {...animationProps}
      id={id}
      disabled={disabled}
      style={style}
      className={cn(className)}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Component>
  );
}
