export function padStart(length = 2, fill = '0') {
  return function (value: string | number) {
    return String(value).padStart(length, fill);
  };
}

export function padEnd(length = 2, fill = '0') {
  return function (value: string | number) {
    return String(value).padEnd(length, fill);
  };
}

export function removeNonAsciiCharacters(text: string): string {
  return text.normalize('NFKD').replace(/[\u0300-\u036f]/g, '');
}

export function removeExtraSpaces(text: string): string {
  return text.replace(/\s+/g, ' ').trim();
}

export function removePunctuation(text: string, exceptions?: string): string {
  return text
    .split('')
    .filter((char: string) => exceptions?.includes(char) || /[\w\s]/.test(char))
    .join('');
}

export function normalizeString(input: string, exceptions?: string): string {
  let result = removeNonAsciiCharacters(input);
  result = removePunctuation(result, exceptions);
  result = removeExtraSpaces(result);
  return result.toLowerCase();
}

export function searchInStrings(search: string, target: string | string[]): boolean {
  const normalizedSearch = normalizeString(search);

  const targets = Array.isArray(target) ? target : [target];
  return targets.some((target) => normalizeString(target).includes(normalizedSearch));
}
