import { useCallback, useState } from 'react';
import ReactGa from 'react-ga4';

import { useOnUpdateOffer } from '@/features/offers/hooks';
import { OpportunityItemList, OpportunityDetails } from '@/features/opportunities/types';

import { OfferStatus } from '../../types';

import { AcceptOfferModal } from './accept-offer-modal';
import { DeclineOfferModal } from './decline-offer-modal';
import { OfferFeedbackModal } from './offer-feedback-modal';

export type ActionOfferModalsProps = {
  readonly offer: string;
  readonly opportunity: OpportunityItemList | OpportunityDetails;
  readonly isAcceptModalOpen: boolean;
  readonly isDeclineModalOpen: boolean;
  readonly setIsAcceptModalOpen: (state: boolean) => void;
  readonly setIsDeclineModalOpen: (state: boolean) => void;
  readonly onClose?: () => void;
};

export function ActionOfferModals({
  offer,
  opportunity,
  isAcceptModalOpen,
  isDeclineModalOpen,
  setIsAcceptModalOpen,
  setIsDeclineModalOpen,
  onClose,
}: ActionOfferModalsProps) {
  const { updateOfferStatus, updateOfferFeedback } = useOnUpdateOffer({
    id: offer,
    opportunity: opportunity?.id,
    onClose: () => {
      setIsAcceptModalOpen(false);
      setIsDeclineModalOpen(false);
      onClose ? onClose() : null;
    },
  });

  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const onCloseModal = useCallback(
    (modalOpen: string) => {
      modalOpen === 'accept' ? setIsAcceptModalOpen(false) : setIsDeclineModalOpen(false);

      ReactGa.event({
        category: 'dialog_closed',
        action: `Cancel ${modalOpen} Offer`,
        label: `Cancel ${modalOpen} an offer on "${opportunity.name}"`,
      });

      onClose?.();
    },
    [setIsAcceptModalOpen, setIsDeclineModalOpen, onClose, opportunity]
  );

  const onAcceptOffer = useCallback(() => {
    updateOfferStatus({ status: 'accepted' as OfferStatus });
    ReactGa.event({
      category: 'dialog_submitted',
      action: `Accepted Offer`,
      label: `Accepted an offer on "${opportunity.name}"`,
    });
  }, [updateOfferStatus, opportunity]);

  const onDeclineOffer = useCallback(() => {
    updateOfferStatus({ status: 'rejected' as OfferStatus });
    setIsFeedbackModalOpen(true);
    ReactGa.event({
      category: 'dialog_submitted',
      action: `Rejected Offer`,
      label: `Rejected an offer on "${opportunity.name}"`,
    });
  }, [updateOfferStatus, opportunity]);

  return (
    <>
      <AcceptOfferModal
        open={isAcceptModalOpen}
        setOpen={setIsAcceptModalOpen}
        onClose={() => onCloseModal('accept')}
        onAcceptOffer={onAcceptOffer}
      ></AcceptOfferModal>
      <DeclineOfferModal
        open={isDeclineModalOpen}
        setOpen={setIsDeclineModalOpen}
        onClose={() => onCloseModal('reject')}
        onDeclineOffer={onDeclineOffer}
      ></DeclineOfferModal>
      <OfferFeedbackModal
        updateFeedback={updateOfferFeedback}
        open={isFeedbackModalOpen}
        setOpen={setIsFeedbackModalOpen}
      ></OfferFeedbackModal>
    </>
  );
}
