import { ClassValue } from 'clsx';
import { ReactNode } from 'react';

import { AnimationShell } from '@/components';
import { cn } from '@/utils/styles';

import { colors } from './constants';
import { ChipColor } from './types';

export type ChipProps = {
  readonly children: ReactNode | string;
  readonly color?: ChipColor;
  readonly index?: number;
  readonly className?: ClassValue;
};

export function Chip({ children, color = 'default', index = 0, className }: ChipProps) {
  return (
    <AnimationShell
      element="span"
      type="opacity"
      delay={0.1 * index}
      className={cn(
        'rounded-full px-1.5 py-0.5',
        'text-[10px] font-semibold uppercase tracking-wide',
        colors[color],
        className
      )}
    >
      {children}
    </AnimationShell>
  );
}
