import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableHeader } from '@/components';
import { MyProjectsItems } from '@/features/projects';
import { ProjectItemList } from '@/features/projects/types';

export type ProjectsCollapsableProps = {
  readonly projects: ProjectItemList[];
  readonly names?: string[];
  readonly initialExpandedState?: boolean;
};

export function ProjectsCollapsable({ projects, names, initialExpandedState = true }: ProjectsCollapsableProps) {
  const { t } = useTranslation(['opportunities']);

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const header = (
    <CollapsableHeader
      title={t('Projects')}
      description={t('List of projects involved')}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );
  return (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <div className="p-4">
        <MyProjectsItems projects={projects} names={names} />
      </div>
    </Collapsable>
  );
}
