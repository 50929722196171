import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TechnologyType } from '@/features/projects/types';

export function useTechnologies() {
  const { t } = useTranslation(['technologies']);

  const options = useMemo(
    () => [
      { label: t(`Solar PV`), value: 'solar' as TechnologyType, icon: 'solar' },
      { label: t(`Wind`), value: 'wind' as TechnologyType, icon: 'wind' },
      { label: t(`BESS`), value: 'bess' as TechnologyType, icon: 'bess' },
      { label: t(`Biogas`), value: 'biogas' as TechnologyType, icon: 'biogas' },
      { label: t(`Hydrogen`), value: 'hydrogen' as TechnologyType, icon: 'hydrogen' },
      { label: t(`Others`), value: 'others' as TechnologyType, icon: 'others' },
    ],
    [t]
  );

  const filters = useMemo(
    () => [
      { label: t(`Solar PV`), value: 'solar' as TechnologyType, icon: 'solar' },
      { label: t(`Wind`), value: 'wind' as TechnologyType, icon: 'wind' },
      { label: t(`BESS`), value: 'bess' as TechnologyType, icon: 'bess' },
    ],
    [t]
  );

  const kv = useMemo(
    () =>
      options.reduce((map, option) => ({ ...map, [option.value]: option.label }), {}) as Record<TechnologyType, string>,
    [options]
  );

  const map = useCallback((value: TechnologyType) => kv[value], [kv]);

  return { options, filters, kv, map };
}
