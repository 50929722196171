import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Flag({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="flag"
    >
      <path
        d="M4.0002 21.8C3.6002 21.8 3.2002 21.5 3.2002 21V4.50001C3.2002 4.10001 3.5002 3.70001 4.0002 3.70001C4.5002 3.70001 4.8002 4.00001 4.8002 4.50001V21C4.8002 21.4 4.4002 21.8 4.0002 21.8Z"
        fill="currentColor"
      />
      <path
        d="M16.1002 18.3C14.9002 18.3 13.7002 18 12.6002 17.5L11.0002 16.8C10.0002 16.3 8.8002 16.1 7.7002 16.2C6.6002 16.3 5.5002 16.6 4.5002 17.1C4.1002 17.3 3.7002 17.2 3.5002 16.8C3.3002 16.4 3.4002 16 3.8002 15.8C5.0002 15.1 6.3002 14.7 7.7002 14.7C9.1002 14.6 10.4002 14.9 11.7002 15.4L13.3002 16.1C14.3002 16.6 15.5002 16.8 16.6002 16.7C17.6002 16.7 18.6002 16.4 19.4002 16V5.69998C18.5002 5.99998 17.6002 6.29998 16.6002 6.29998C15.2002 6.39998 13.9002 6.09998 12.6002 5.59998L11.0002 4.89998C10.0002 4.39998 8.8002 4.19998 7.7002 4.29998C6.5002 4.29998 5.4002 4.59998 4.4002 5.19998C4.0002 5.39998 3.6002 5.29998 3.3002 4.89998C3.1002 4.49998 3.2002 4.09998 3.6002 3.89998C4.8002 3.19998 6.1002 2.79998 7.5002 2.79998C8.9002 2.69998 10.2002 2.99998 11.5002 3.49998L13.1002 4.19998C14.1002 4.69998 15.3002 4.89998 16.4002 4.79998C17.5002 4.69998 18.6002 4.39998 19.6002 3.89998C19.8002 3.79998 20.1002 3.79998 20.3002 3.89998C20.5002 3.99998 20.7002 4.29998 20.7002 4.49998V16.5C20.7002 16.8 20.6002 17 20.3002 17.2C19.1002 17.9 17.8002 18.3 16.4002 18.3C16.3002 18.3 16.2002 18.3 16.1002 18.3Z"
        fill="currentColor"
      />
    </svg>
  );
}
