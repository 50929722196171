import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function EyeOpen({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="eye-open"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.60848 6.71944C5.39386 7.65811 4.32655 8.94958 3.57618 10.3373L3.02642 10.04L3.57646 10.3368C3.54559 10.394 3.54556 10.465 3.57636 10.5222C4.32673 11.9102 5.39397 13.2018 6.60851 14.1405C7.82654 15.0819 9.15173 15.6383 10.4281 15.6383C11.7044 15.6383 13.0296 15.0819 14.2477 14.1406C15.4623 13.2019 16.5296 11.9104 17.28 10.5227C17.3105 10.4657 17.3105 10.395 17.2799 10.3379C16.5295 8.94986 15.4622 7.65825 14.2476 6.71953C13.0296 5.77813 11.7044 5.22168 10.4281 5.22168C9.15173 5.22168 7.82653 5.77813 6.60848 6.71944ZM5.84413 5.73037C7.20462 4.67898 8.79192 3.97168 10.4281 3.97168C12.0642 3.97168 13.6515 4.67898 15.012 5.7305C16.376 6.78466 17.5533 8.21508 18.3795 9.74363L18.3798 9.74408C18.6106 10.1718 18.6106 10.6891 18.3798 11.1168L18.3795 11.1173C17.5532 12.6454 16.3759 14.0756 15.012 15.1297C13.6515 16.181 12.0642 16.8883 10.4281 16.8883C8.79192 16.8883 7.20461 16.181 5.8441 15.1295C4.48017 14.0754 3.30289 12.645 2.4766 11.1164L2.47636 11.1159C2.24559 10.6882 2.24559 10.171 2.47636 9.74325L2.47663 9.74274C3.30293 8.21461 4.48021 6.78442 5.84413 5.73037Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7746 9.08335C11.0307 8.3395 9.82469 8.3395 9.08084 9.08335C8.337 9.8272 8.337 11.0332 9.08084 11.7771C9.82469 12.5209 11.0307 12.5209 11.7746 11.7771C12.5184 11.0332 12.5184 9.8272 11.7746 9.08335ZM8.23903 8.24154C9.4478 7.03277 11.4076 7.03277 12.6164 8.24154C13.8251 9.4503 13.8251 11.4101 12.6164 12.6189C11.4076 13.8276 9.4478 13.8276 8.23903 12.6189C7.03027 11.4101 7.03027 9.4503 8.23903 8.24154Z"
        fill="currentColor"
      />
    </svg>
  );
}
