import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function QuestionMarkCircle({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="question-mark-circle"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.939 9.09692C12.6655 8.8538 12.3069 8.72871 11.9415 8.74895C11.9174 8.75029 11.8931 8.75046 11.8689 8.74945C11.5359 8.73563 11.2085 8.83856 10.9433 9.04047C10.678 9.24238 10.4916 9.53057 10.4163 9.85528C10.3227 10.2588 9.9197 10.51 9.5162 10.4164C9.11271 10.3227 8.8615 9.91975 8.95512 9.51626C9.10947 8.85103 9.49131 8.26059 10.0347 7.84695C10.5684 7.44063 11.2251 7.22996 11.8949 7.24946C12.6431 7.21746 13.3751 7.47763 13.9355 7.97581C14.505 8.48205 14.8512 9.19298 14.8986 9.95349C14.8996 9.96901 14.9 9.98455 14.9 10.0001C14.9 11.1456 14.0651 11.8507 13.5482 12.271C13.4801 12.3264 13.4167 12.3772 13.3577 12.4245C13.1537 12.5882 13.0011 12.7108 12.8778 12.8395C12.781 12.9407 12.7556 12.9935 12.75 13.0088C12.7453 13.419 12.4114 13.7501 12 13.7501C11.5858 13.7501 11.25 13.4143 11.25 13.0001C11.25 12.462 11.5435 12.0643 11.7941 11.8024C11.9929 11.5947 12.2482 11.3905 12.4625 11.2192C12.5115 11.18 12.5584 11.1425 12.6019 11.1071C13.1485 10.6627 13.3901 10.3707 13.3997 10.0221C13.3712 9.66615 13.2063 9.33457 12.939 9.09692ZM12.7486 13.0136C12.7485 13.0136 12.7487 13.0128 12.7492 13.011C12.7489 13.0127 12.7486 13.0136 12.7486 13.0136Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3737 3.73026C10.3085 3.64023 8.28451 4.3275 6.70112 5.65647C5.11773 6.98545 4.08993 8.85961 3.82051 10.9092C3.55109 12.9587 4.05962 15.0348 5.24576 16.7279C5.36653 16.9003 5.40931 17.1155 5.36365 17.3209L4.98783 19.0122L6.67909 18.6364C6.88455 18.5907 7.09974 18.6335 7.27212 18.7542C8.96516 19.9404 11.0413 20.4489 13.0908 20.1795C15.1404 19.9101 17.0146 18.8823 18.3435 17.2989C19.6725 15.7155 20.3598 13.6915 20.2697 11.6263C20.1797 9.56105 19.3189 7.60455 17.8572 6.14282C16.3955 4.6811 14.439 3.8203 12.3737 3.73026ZM5.73679 4.50753C7.60759 2.93733 9.99895 2.12531 12.4391 2.23168C14.8792 2.33806 17.1908 3.35511 18.9178 5.08216C20.6449 6.80922 21.6619 9.12084 21.7683 11.561C21.8747 14.0011 21.0627 16.3924 19.4925 18.2632C17.9223 20.134 15.7079 21.3484 13.2863 21.6667C10.9759 21.9704 8.63684 21.4374 6.68962 20.1706L4.16273 20.7321C3.91241 20.7877 3.65103 20.7116 3.46971 20.5303C3.28839 20.349 3.21227 20.0876 3.2679 19.8373L3.8294 17.3104C2.56261 15.3632 2.02959 13.0241 2.33331 10.7137C2.65163 8.29209 3.86599 6.07774 5.73679 4.50753Z"
        fill="currentColor"
      />
      <path
        d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
        fill="currentColor"
      />
    </svg>
  );
}
