import { cn } from '@/utils/styles';

import type { ArtworkProps } from '../types';

export function RejectOffer({ className }: ArtworkProps) {
  return (
    <svg className={cn(className)} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M92.311 87.291L81.909 74.2L51.95 66.999L5 94.16L70.001 176.001L133.527 139.163L120.842 123.198"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M65.219 83.821L48.813 68.794L51.95 66.98L65.219 83.821Z" fill="#279D59" />
      <path
        d="M65.219 83.821L48.813 68.794L51.95 66.98L65.219 83.821Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.0052 126.008C86.2602 121.545 81.7382 113.992 81.8292 106.955C76.3366 105.729 70.5854 106.549 65.6542 109.261C55.6362 115.061 53.9542 127.898 61.9112 137.897C69.8682 147.914 84.4412 151.317 94.4592 145.497C97.306 143.906 99.639 141.534 101.184 138.662C102.728 135.789 103.42 132.535 103.178 129.283C99.9456 128.763 96.8362 127.653 94.0052 126.008Z"
        fill="#B7F6D1"
      />
      <path
        d="M94.0052 126.008C86.2602 121.545 81.7382 113.992 81.8292 106.955C76.3366 105.729 70.5854 106.549 65.6542 109.261C55.6362 115.061 53.9542 127.898 61.9112 137.897C69.8682 147.914 84.4412 151.317 94.4592 145.497C97.306 143.906 99.639 141.534 101.184 138.662C102.728 135.789 103.42 132.535 103.178 129.283C99.9456 128.763 96.8362 127.653 94.0052 126.008Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.8902 74.162L65.2192 83.821L51.9502 66.98"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M24.7329 100.51L52.6499 84.35" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.446 111.511L76.881 86.371" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M88.07 92.364L83.78 99.736C78.903 108.165 83.48 119.936 94.005 126.008C103.433 131.466 116.005 131.127 121.922 121.661L126.134 114.355"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.3611 85.509C91.7987 87.0405 88.984 89.9151 87.5281 93.509C83.9121 102.397 89.6951 112.793 98.6551 117.955C106.631 122.561 117.046 123.006 123.529 117.312C124.519 116.443 125.393 115.451 126.129 114.359"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M152.542 56.055L147.854 64.145C146.116 67.169 142.43 68.756 138.309 68.756C126.249 68.756 116.38 56.334 120.864 48.57L125.532 40.48C123.415 44.166 124.644 48.702 126.988 52.124C133.807 62.082 148.342 63.284 152.542 56.055Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.364 87.96C163.529 100.031 151.991 113.728 142.619 121.226C139.328 120.728 136.115 119.813 133.055 118.504C127.114 115.525 121.806 111.423 117.424 106.426L124.152 94.859C123.069 92.8933 122.086 90.8747 121.204 88.811C120.289 89.2565 119.259 89.4081 118.255 89.245C119.805 94.273 117.632 96.39 114.267 95.955C115.723 100.624 112.396 102.555 107.841 100.813C108.483 104.498 103.198 105.051 98.957 101.493C95.585 98.663 90.22 90.668 93.857 87.093C94.3157 86.6482 94.9055 86.3631 95.539 86.28C94.859 83.69 95.039 81.074 97.562 79.551C97.9766 79.2946 98.4402 79.1274 98.923 79.06C98.2 75.011 100.331 72.208 103.459 71.291C103.489 70.264 103.785 69.2624 104.318 68.384C104.851 67.5057 105.603 66.7807 106.5 66.28C104.906 63.8263 103.665 61.1605 102.814 58.361C101.845 54.369 104.914 52.002 108.995 55.148C110.339 56.185 112.113 57.68 114.23 59.4C114.816 59.06 120.354 56.338 120.354 56.338C120.571 57.014 120.843 57.672 121.167 58.304C121.167 58.304 120.167 60.043 118.559 62.784C123.719 66.64 130.088 70.76 136.874 72.99L139.312 68.79C142.184 68.7178 144.915 67.5319 146.929 65.483C153.289 71.058 150.216 68.079 169.364 87.96Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.932 59.363C139.65 64.088 125.056 55.596 124.474 45.281C124.143 39.423 128.874 35.869 135.074 35.869C142.389 35.869 150.123 40.864 152.747 47.569C154.621 52.35 153.516 57.029 148.932 59.363Z"
        fill="#279D59"
      />
      <path
        d="M148.932 59.363C139.65 64.088 125.056 55.596 124.474 45.281C124.143 39.423 128.874 35.869 135.074 35.869C142.389 35.869 150.123 40.864 152.747 47.569C154.621 52.35 153.516 57.029 148.932 59.363Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.074 118.5C131.713 121.922 131.486 124.4 132.639 125.361C134.189 126.647 137.931 124.945 142.639 121.222C139.365 120.647 136.16 119.735 133.074 118.5Z"
        fill="#279D59"
      />
      <path
        d="M133.074 118.5C131.713 121.922 131.486 124.4 132.639 125.361C134.189 126.647 137.931 124.945 142.639 121.222C139.365 120.647 136.16 119.735 133.074 118.5Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M163.221 81.572L169.364 87.96C173.547 78.83 170.485 76.688 163.221 81.572Z" fill="#279D59" />
      <path
        d="M163.221 81.572L169.364 87.96C173.547 78.83 170.485 76.688 163.221 81.572Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126.118 114.346C125.72 114.968 125.271 115.556 124.776 116.104L117.424 106.426C120.017 109.383 122.933 112.039 126.118 114.346Z"
        fill="#279D59"
      />
      <path
        d="M126.118 114.346C125.72 114.968 125.271 115.556 124.776 116.104L117.424 106.426C120.017 109.383 122.933 112.039 126.118 114.346Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.708 100.794C182.715 101.131 182.654 101.466 182.53 101.779C182.405 102.092 182.22 102.378 181.984 102.618C181.748 102.859 181.466 103.05 181.155 103.18C180.845 103.311 180.511 103.378 180.174 103.378C179.837 103.378 179.503 103.31 179.193 103.18C178.882 103.049 178.601 102.858 178.365 102.617C178.129 102.377 177.943 102.091 177.819 101.778C177.695 101.465 177.634 101.13 177.641 100.793C177.654 100.13 177.927 99.4982 178.401 99.034C178.875 98.5697 179.512 98.3097 180.175 98.3098C180.838 98.31 181.475 98.5702 181.949 99.0347C182.423 99.4991 182.695 100.131 182.708 100.794Z"
        fill="#279D59"
      />
      <path
        d="M182.708 100.794C182.715 101.131 182.654 101.466 182.53 101.779C182.405 102.092 182.22 102.378 181.984 102.618C181.748 102.859 181.466 103.05 181.155 103.18C180.845 103.311 180.511 103.378 180.174 103.378C179.837 103.378 179.503 103.31 179.193 103.18C178.882 103.049 178.601 102.858 178.365 102.617C178.129 102.377 177.943 102.091 177.819 101.778C177.695 101.465 177.634 101.13 177.641 100.793C177.654 100.13 177.927 99.4982 178.401 99.034C178.875 98.5697 179.512 98.3097 180.175 98.3098C180.838 98.31 181.475 98.5702 181.949 99.0347C182.423 99.4991 182.695 100.131 182.708 100.794Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.33 68.738C135.629 68.8502 131.971 67.9279 128.765 66.0748C125.56 64.2216 122.935 61.5112 121.185 58.248C121.185 58.248 120.185 59.986 118.577 62.728C123.737 66.583 130.106 70.703 136.892 72.934L139.33 68.738Z"
        fill="#B7F6D1"
      />
      <path
        d="M139.33 68.738C135.629 68.8502 131.971 67.9279 128.765 66.0748C125.56 64.2216 122.935 61.5112 121.185 58.248C121.185 58.248 120.185 59.986 118.577 62.728C123.737 66.583 130.106 70.703 136.892 72.934L139.33 68.738Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.134 94.859C123.051 92.8935 122.066 90.8749 121.185 88.811C120.27 89.2565 119.24 89.4081 118.236 89.245C119.786 94.273 117.613 96.39 114.248 95.955C115.704 100.624 112.377 102.555 107.822 100.813C108.465 104.498 103.179 105.051 98.9378 101.493C98.7378 101.321 98.5038 101.093 98.2378 100.851C99.3396 103.901 101.527 106.437 104.381 107.976C108.159 110.15 114.538 111.189 116.591 107.768L124.134 94.859Z"
        fill="#B7F6D1"
      />
      <path
        d="M124.134 94.859C123.051 92.8935 122.066 90.8749 121.185 88.811C120.27 89.2565 119.24 89.4081 118.236 89.245C119.786 94.273 117.613 96.39 114.248 95.955C115.704 100.624 112.377 102.555 107.822 100.813C108.465 104.498 103.179 105.051 98.9378 101.493C98.7378 101.321 98.5038 101.093 98.2378 100.851C99.3396 103.901 101.527 106.437 104.381 107.976C108.159 110.15 114.538 111.189 116.591 107.768L124.134 94.859Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.134 94.859C126.458 99.017 129.483 103.043 132.96 104.517"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.577 62.727C114.124 59.401 112.025 57.418 109.013 55.11C104.938 51.987 101.873 54.334 102.832 58.323C104.469 65.128 114.677 78.194 119.163 83.462C119.163 83.462 119.881 85.73 121.204 88.811"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.244 74.5C141.735 74.2652 139.261 73.739 136.873 72.932"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.8818 78.736C99.6938 84.218 105.229 90.345 106.801 91.854C108.811 93.95 111.418 95.3754 114.267 95.936"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.2 88.811C119.026 89.926 115.361 89.244 111.145 85.352C109.198 83.552 103.282 76.652 103.471 71.252"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.5552 86.259C96.6322 90.304 99.9382 94.634 101.282 96.087C103.088 98.1321 105.33 99.747 107.841 100.813"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M170.517 79.417L194.011 99.698" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M132.658 125.384L154.753 144.701" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M133.716 160.9C134.199 160.9 134.671 161.042 135.072 161.31C135.474 161.578 135.787 161.959 135.972 162.405C136.157 162.851 136.205 163.342 136.111 163.815C136.017 164.289 135.785 164.724 135.444 165.065C135.102 165.406 134.667 165.639 134.194 165.733C133.721 165.827 133.23 165.779 132.784 165.594C132.338 165.409 131.957 165.097 131.689 164.695C131.421 164.294 131.278 163.822 131.278 163.339C131.279 162.693 131.537 162.074 131.994 161.616C132.45 161.159 133.07 160.902 133.716 160.9Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M80.0751 23.999L80.0562 40.48" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M88.3159 32.258L71.8149 32.24" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M174.581 51.16L176.263 54.543L179.986 55.091L177.283 57.718L177.926 61.442L174.581 59.684L171.254 61.442L171.878 57.718L169.175 55.091L172.917 54.543L174.581 51.16Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M68.0977 122.633L94.0165 133.331" stroke="#186A3B" />
      <path d="M74.3037 137.967L87 119.5" stroke="#186A3B" />
    </svg>
  );
}
