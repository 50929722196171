import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Clock({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="clock"
    >
      <path
        d="M9.99995 19.8C4.59995 19.8 0.199951 15.4 0.199951 10C0.199951 4.60001 4.59995 0.200012 9.99995 0.200012C15.4 0.200012 19.7999 4.60001 19.7999 10C19.7999 15.4 15.4 19.8 9.99995 19.8ZM9.99995 1.80001C5.49995 1.80001 1.79995 5.50001 1.79995 10C1.79995 14.5 5.49995 18.2 9.99995 18.2C14.5 18.2 18.2 14.5 18.2 10C18.2 5.50001 14.5 1.80001 9.99995 1.80001Z"
        fill="currentColor"
      />
      <path
        d="M13.5 11.9C13.4 11.9 13.3 11.9 13.3 11.9L9.80005 10.7C9.50005 10.6 9.30005 10.3 9.30005 10V4.00001C9.30005 3.60001 9.60005 3.20001 10.1 3.20001C10.6 3.20001 10.9 3.50001 10.9 4.00001V9.50001L13.8 10.5C14.2 10.6 14.4 11.1 14.3 11.4C14.1 11.7 13.8 11.9 13.5 11.9Z"
        fill="currentColor"
      />
    </svg>
  );
}
