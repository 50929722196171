import { useGetCurrentUserQuery, UserSchema } from '@/features/users';
import { skipToken } from '@reduxjs/toolkit/query';
import { Route } from '@/constants';
import { useGetCompanyQuery } from '../api';
import { useSuscriptions } from './use-suscriptions';

export function useDisplayTrialBanner() {
  const inviteMember = () => {
    const { data: user } = useGetCurrentUserQuery();
    const { data: company } = useGetCompanyQuery(user ? { id: user.company.id } : skipToken);

    const { hasTrial, hasPremiumActive } = useSuscriptions();

    const hasThreeActive = (users: UserSchema[]) => {
      return users.filter((user) => user.status === 'active').length >= 3;
    };

    if (!company) return;
    if (hasTrial(company.suscriptions) || hasPremiumActive(company.suscriptions)) return;

    if (company.users.length < 3 || (company.users.length >= 3 && !hasThreeActive(company.users))) {
      return {
        id: new Date().getTime(),
        title: 'Bussines Free Trial!',
        label: 'Get 1 month access by inviting 2 teammates',
        link: { href: Route.TeamSettings, target: '_self', caption: 'Invite' },
      };
    }
  };

  const expiredTrial = () => {
    const { data: user } = useGetCurrentUserQuery();
    const { data: company } = useGetCompanyQuery(user ? { id: user.company.id } : skipToken);

    const { hasTrial, hasTrialActive, hasPremiumActive, getExpiredDays } = useSuscriptions();

    if (!company) return;

    const trial = hasTrial(company.suscriptions);
    const days = trial ? getExpiredDays(trial) : 0;
    if (trial && !hasPremiumActive(company.suscriptions) && days > 0 && days < 14) {
      return {
        id: new Date().getTime(),
        title: 'Free trial ends soon!',
        label: 'Upgrade now to keep all Business features',
        link: { href: Route.PlanSettings, target: '_self', caption: 'Upgrade' },
      };
    }
  };

  return { inviteMember, expiredTrial };
}
