import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { OpportunityDetails } from '@/features/opportunities/types';
import { displayDate } from '@/helpers';
import { useSaleOptions } from '@/hooks';

import { getRangeOfPercentage } from '../../helpers';

export type SaleProcessCollapsableProps = {
  readonly opportunity: OpportunityDetails;
  readonly initialExpandedState?: boolean;
};

export function SaleProcessCollapsable({ opportunity, initialExpandedState = true }: SaleProcessCollapsableProps) {
  const { t } = useTranslation(['opportunities']);
  const { map: mapSaleOption } = useSaleOptions();
  const saleOptions = opportunity.options.map(mapSaleOption).join(', ');

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const header = (
    <CollapsableHeader
      title={t('Sale Process')}
      description={t(
        'In this section, you will find comprehensive details about the sale process for the project, including deadlines, sale options, and key milestones to help you make an informed decision.'
      )}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );

  return (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody
        data={[
          { label: t('Deadline'), value: displayDate(opportunity.deadline.date) },
          {
            label: t('Sale'),
            value: getRangeOfPercentage(opportunity.percentage.minimum, opportunity.percentage.maximum),
          },
          { label: t('Sale Options'), value: saleOptions },
          { label: t('Comments'), value: opportunity?.comments },
        ]}
      />
    </Collapsable>
  );
}
