import { useTranslation } from 'react-i18next';
import { MarketDetails } from '../../types';
import { useState } from 'react';
import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { useCompanyRole } from '@/features/company/hooks';
import { ASSETS_BASE_URL } from '@/config';

export function MarketListItem({ market }: { market: MarketDetails }) {
  const { t } = useTranslation(['insights']);
  const [isExpanded, setIsExpanded] = useState(true);
  const { isFree } = useCompanyRole();

  const header = (
    <CollapsableHeader
      title={market.title}
      description={market.description}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );
  const data = [
    {
      label: market.imageTitle,
      value: <img src={`${ASSETS_BASE_URL}/${market.image}`} alt={market.imageTitle} className="h-96" />,
    },
    { label: market.textTitle, value: market.text },
  ];

  return (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody data={data} hidden={isFree} />
    </Collapsable>
  );
}
