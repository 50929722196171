import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Container } from '@/components';
import { useBreadcrumbs } from '@/features/header';
import { OfferHeaderView, OfferView } from '@/features/offers';

export function Offer() {
  const { t } = useTranslation(['offers']);
  const [tabActive, setTabActive] = useState(0);

  useBreadcrumbs(() => [{ title: t(`Offers`) }]);

  const { offerId: id } = useParams();
  if (!id) return null;

  return (
    <div className="flex flex-col">
      <div className="border-neutrl-50 w-full border-b bg-neutral-10">
        <Container className="space-y-6 pt-6">
          <OfferHeaderView id={id} tabActive={tabActive} setTabActive={setTabActive} />
        </Container>
      </div>

      <div className="w-full py-10">
        <Container className="space-y-12">
          <OfferView id={id} tabActive={tabActive} />
        </Container>
      </div>
    </div>
  );
}
