import { useMemo } from 'react';

import { useGetMyOpportunitiesQuery, useGetOpportunitiesQuery } from '../api';
import { opportunityItemListTransformer } from '../helpers';

export function useMyOpportunityList() {
  const { data: opportunities } = useGetMyOpportunitiesQuery();
  return useMemo(() => opportunities?.map(opportunityItemListTransformer), [opportunities]);
}

export function usePublishedOpportunityList() {
  const { data: opportunities } = useGetOpportunitiesQuery();
  return useMemo(() => opportunities?.map(opportunityItemListTransformer), [opportunities]);
}
