import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Status({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="status"
    >
      <path
        d="M20.0002 21.7999H18.0002C17.0002 21.7999 16.2002 21 16.2002 20V18C16.2002 17 17.0002 16.2 18.0002 16.2H20.0002C21.0002 16.2 21.8002 17 21.8002 18V20C21.8002 21 21.0002 21.7999 20.0002 21.7999ZM18.0002 17.7999C17.9002 17.7999 17.8002 17.9 17.8002 18V20C17.8002 20.1 17.9002 20.2 18.0002 20.2H20.0002C20.1002 20.2 20.2002 20.1 20.2002 20V18C20.2002 17.9 20.1002 17.7999 20.0002 17.7999H18.0002Z"
        fill="currentColor"
      />
      <path
        d="M6.0002 7.79995H4.0002C3.0002 7.79995 2.2002 6.99995 2.2002 5.99995V3.99995C2.2002 2.99995 3.0002 2.19995 4.0002 2.19995H6.0002C7.0002 2.19995 7.8002 2.99995 7.8002 3.99995V5.99995C7.8002 6.99995 7.0002 7.79995 6.0002 7.79995ZM4.0002 3.79995C3.9002 3.79995 3.8002 3.89995 3.8002 3.99995V5.99995C3.8002 6.09995 3.9002 6.19995 4.0002 6.19995H6.0002C6.1002 6.19995 6.2002 6.09995 6.2002 5.99995V3.99995C6.2002 3.89995 6.1002 3.79995 6.0002 3.79995H4.0002Z"
        fill="currentColor"
      />
      <path
        d="M13.0002 14.8H11.0002C10.0002 14.8 9.2002 14 9.2002 13V11C9.2002 9.99995 10.0002 9.19995 11.0002 9.19995H13.0002C14.0002 9.19995 14.8002 9.99995 14.8002 11V13C14.8002 14 14.0002 14.8 13.0002 14.8ZM11.0002 10.8C10.9002 10.8 10.8002 10.9 10.8002 11V13C10.8002 13.1 10.9002 13.2 11.0002 13.2H13.0002C13.1002 13.2 13.2002 13.1 13.2002 13V11C13.2002 10.9 13.1002 10.8 13.0002 10.8H11.0002Z"
        fill="currentColor"
      />
      <path
        d="M19.0002 12.8H17.0002C16.6002 12.8 16.2002 12.5 16.2002 12C16.2002 11.5 16.5002 11.2 17.0002 11.2H19.0002C19.7002 11.2 20.2002 10.6 20.2002 9.99995V6.99995C20.2002 6.29995 19.6002 5.79995 19.0002 5.79995H11.0002C10.6002 5.79995 10.2002 5.49995 10.2002 4.99995C10.2002 4.49995 10.5002 4.19995 11.0002 4.19995H19.0002C20.5002 4.19995 21.8002 5.39995 21.8002 6.99995V9.99995C21.8002 11.5 20.5002 12.8 19.0002 12.8Z"
        fill="currentColor"
      />
      <path
        d="M13.0002 19.8H5.0002C3.5002 19.8 2.2002 18.6 2.2002 17V14C2.2002 12.5 3.4002 11.2 5.0002 11.2H7.0002C7.4002 11.2 7.8002 11.5 7.8002 12C7.8002 12.5 7.4002 12.8 7.0002 12.8H5.0002C4.3002 12.8 3.8002 13.4 3.8002 14V17C3.8002 17.7 4.4002 18.2 5.0002 18.2H13.0002C13.4002 18.2 13.8002 18.5 13.8002 19C13.8002 19.5 13.4002 19.8 13.0002 19.8Z"
        fill="currentColor"
      />
    </svg>
  );
}
