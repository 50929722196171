import { ClassValue } from 'clsx';

import { cn } from '@/utils/styles';

import { TypographyColor } from '../typography';
import { colors } from '../typography/constants';

export type LogoIconProps = {
  readonly color?: TypographyColor;
  readonly className?: ClassValue;
};

export function LogoIcon({ color = 'brand', className }: LogoIconProps) {
  return (
    <svg className={cn(colors[color], className)} viewBox="0 0 345 187" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M81.8646 39.3021C62.3958 39.3021 45.0625 47.5677 34.1302 60.3698L26.6667 42.5052H0V186.5H38.9323V111.036C38.9323 87.8333 51.1979 73.4375 71.1979 73.4375C87.9948 73.4375 96.2656 83.8333 96.2656 105.703V186.5H135.198V99.3021C135.198 62.7708 114.661 39.3021 81.8646 39.3021Z"
        fill="currentColor"
      />
      <path d="M294.667 0H157.87V22.1302H214.672V186.661H237.87V22.1302H294.667V0Z" fill="currentColor" />
      <path d="M318.669 186.5H344.534V158.234H318.669V186.5Z" fill="currentColor" />
    </svg>
  );
}
