import React, { useEffect } from 'react';

import { STRIPE_PRICING_TABLE_ID, STRIPE_PUBLISHABLE_KEY } from '@/config';

const StripePricingTable = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return React.createElement('stripe-pricing-table', {
    'pricing-table-id': STRIPE_PRICING_TABLE_ID,
    'publishable-key': STRIPE_PUBLISHABLE_KEY,
  });
};

export default StripePricingTable;
