import { useTranslation } from 'react-i18next';

import { EmptyState, SearchOpportunities } from '@/components';

export function ServicesEmptyState() {
  const { t } = useTranslation(['services']);

  return (
    <EmptyState
      artwork={<SearchOpportunities className="w-64" />}
      title={t('Here you will see all submitted services.')}
      description={t('This list will be enabled once we have some services submitted.')}
    ></EmptyState>
  );
}
