import { api } from '@/features/auth';

import { CompanyRequest, CompanyResponse, UpdateCompanyRequest } from './types';

export const companyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCompany: builder.query<CompanyResponse['data'], CompanyRequest>({
      query: ({ id }: CompanyRequest) => ({
        url: `items/companies/${id}`,
        method: 'GET',
        params: {
          fields: [
            'id',
            'name',
            'description',
            'website',
            'logo',
            'role.*.*',
            'activity_interests',
            'technology_interests',
            'capacity_interests',
            'development_status_interests',
            'location_interests',
            'suscriptions.*.*',
            'users.*.*',
          ],
        },
      }),
      transformResponse: ({ data }: CompanyResponse) => data,
      providesTags: (_result, _error, args) => [{ type: 'Companies', id: args.id }],
    }),
    updateCompany: builder.mutation<CompanyResponse['data'], UpdateCompanyRequest>({
      query: ({ id, data }: UpdateCompanyRequest) => ({
        url: `items/companies/${id}`,
        params: {
          fields: [
            'id',
            'name',
            'description',
            'website',
            'logo',
            'activity_interests',
            'technology_interests',
            'capacity_interests',
            'development_status_interests',
            'location_interests',
          ],
        },
        method: 'PATCH',
        body: data,
      }),
      transformResponse: ({ data }: CompanyResponse) => data,
      invalidatesTags: (_result, _error, args) => [
        { type: 'Companies', id: args.id },
        { type: 'Users', id: 'me' },
      ],
    }),
  }),
});

export const { useGetCompanyQuery, useUpdateCompanyMutation } = companyApi;
