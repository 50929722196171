import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function MyOffers({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="my-offers"
    >
      <path
        d="M11.7001 13.1C11.5001 13.1 11.3001 13 11.2001 12.9L7.2001 8.89995C6.9001 8.59995 6.9001 8.09995 7.2001 7.79995C7.5001 7.49995 8.0001 7.49995 8.3001 7.79995L12.3001 11.8C12.6001 12.1 12.6001 12.6 12.3001 12.9C12.0001 13.1 11.8001 13.1 11.7001 13.1Z"
        fill="currentColor"
      />
      <path
        d="M15.4003 9.4C15.2003 9.4 15.0003 9.3 14.9003 9.2L10.9003 5.2C10.6003 4.9 10.6003 4.4 10.9003 4.1C11.2003 3.8 11.7003 3.8 12.0003 4.1L16.0003 8.1C16.3003 8.4 16.3003 8.9 16.0003 9.2C15.8003 9.3 15.6003 9.4 15.4003 9.4Z"
        fill="currentColor"
      />
      <path
        d="M13.6002 21.7999H3.0002C2.6002 21.7999 2.2002 21.5 2.2002 21C2.2002 20.5 2.5002 20.2 3.0002 20.2H13.6002C14.0002 20.2 14.4002 20.5 14.4002 21C14.4002 21.5 14.0002 21.7999 13.6002 21.7999Z"
        fill="currentColor"
      />
      <path
        d="M12.7003 21.8C12.4003 21.8 12.1003 21.6 12.0003 21.3L11.5003 19.7C11.3003 19.2 10.9003 18.8 10.3003 18.8H6.30029C5.80029 18.8 5.30029 19.1 5.10029 19.7L4.60029 21.3C4.50029 21.7 4.00029 21.9 3.70029 21.8C3.30029 21.7 3.10029 21.2 3.20029 20.9L3.70029 19.3C4.10029 18.2 5.10029 17.4 6.30029 17.4H10.2003C11.4003 17.4 12.4003 18.2 12.8003 19.3L13.3003 20.9C13.4003 21.3 13.2003 21.7 12.8003 21.8C12.8003 21.7 12.8003 21.8 12.7003 21.8Z"
        fill="currentColor"
      />
      <path
        d="M9.50019 14.7C8.80019 14.7 8.20019 14.4 7.70019 13.9L6.00019 12.3C5.90019 12.2 5.90019 12.2 5.80019 12.1C4.90019 11 5.10019 9.4 6.20019 8.5L8.40019 6.7C8.90019 6.3 9.30019 5.9 9.70019 5.4L11.5002 3.2C11.6002 3.1 11.6002 3.1 11.7002 3C12.7002 2 14.3002 2 15.3002 3L17.0002 4.7C17.1002 4.8 17.1002 4.8 17.2002 4.9C17.6002 5.4 17.8002 6.1 17.7002 6.8C17.6002 7.5 17.3002 8.1 16.7002 8.5L14.5002 10.3C14.0002 10.7 13.6002 11.1 13.2002 11.6L11.4002 13.8C11.3002 13.9 11.3002 13.9 11.2002 14C10.8002 14.5 10.2002 14.7 9.50019 14.7ZM9.40019 7.9L7.20019 9.6C7.00019 9.8 6.80019 10 6.80019 10.3C6.80019 10.6 6.80019 10.9 7.00019 11.1L7.10019 11.2L8.80019 12.9C9.20019 13.3 9.90019 13.3 10.3002 12.9L10.4002 12.8L12.2002 10.6C12.7002 10 13.2002 9.5 13.7002 9.1L15.9002 7.3C16.1002 7.1 16.3002 6.9 16.3002 6.6C16.3002 6.3 16.3002 6 16.1002 5.8L16.0002 5.7L14.3002 4C13.9002 3.6 13.2002 3.6 12.8002 4L12.7002 4.1L10.9002 6.3C10.4002 6.9 9.90019 7.4 9.40019 7.9Z"
        fill="currentColor"
      />
      <path
        d="M19.0002 18.7C18.4002 18.7 17.7002 18.5 17.2002 18.1C17.1002 18 17.0002 17.9 16.9002 17.9L11.7002 12.1C11.4002 11.8 11.4002 11.3 11.8002 11C12.1002 10.7 12.6002 10.7 12.9002 11.1L18.1002 16.9L18.2002 17C18.7002 17.4 19.5002 17.4 19.9002 16.9C20.1002 16.6 20.2002 16.3 20.2002 16C20.2002 15.7 20.0002 15.4 19.8002 15.2L13.9002 10.2C13.6002 9.89999 13.5002 9.49999 13.8002 9.09999C14.1002 8.79999 14.5002 8.69999 14.9002 8.99999L20.8002 14C21.4002 14.5 21.7002 15.1 21.8002 15.9C21.9002 16.6 21.6002 17.3 21.2002 17.9C20.6002 18.4 19.8002 18.7 19.0002 18.7Z"
        fill="currentColor"
      />
    </svg>
  );
}
