import { AnimationShell, Typography } from '@/components';
import { cn } from '@/utils/styles';

import { TabProps } from '../types';

export function Tab({ label, active, index = 0, onClick, inHeader = true, className }: TabProps) {
  const classNameTabButton = inHeader
    ? cn(
        'relative -bottom-[1px] border border-transparent px-4 py-1 transition',
        active
          ? 'border-x-neutral-50 border-t-neutral-50 bg-neutral-25 text-base-primary hover:bg-neutral-25 hover:text-base-primary'
          : 'border-b-neutral-50 bg-base-white text-base-primary',
        className
      )
    : cn(
        'border-y-2 border-transparent px-4 py-1 transition bg-neutral-25 hover:bg-neutral-25 hover:text-base-primary',
        active ? 'border-b-primary-700 text-base-primary' : 'border-b-neutral-50 text-base-primary'
      );
  return (
    <AnimationShell
      type="opacity"
      delay={0.15 * index}
      element="li"
      className={cn('group flex flex-col', 'cursor-pointer disabled:cursor-not-allowed', className)}
      disabled={!onClick}
      onClick={onClick}
    >
      <button type="button" onClick={onClick} className={classNameTabButton}>
        <Typography
          size="body-2"
          color={active ? 'primary' : 'secondary'}
          weight="semibold"
          className="bg-transparent px-4 py-1 transition hover:text-primary-900"
        >
          {label}
        </Typography>
      </button>
    </AnimationShell>
  );
}
