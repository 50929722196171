import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { ASSETS_BASE_URL } from '@/config';

import { OfferDetails } from '../../types';

export type OthersCollapsableProps = {
  readonly offer: OfferDetails;
  readonly initialExpandedState?: boolean;
};

export function OthersCollapsable({ offer, initialExpandedState = true }: OthersCollapsableProps) {
  const { t } = useTranslation(['offers']);

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const header = (
    <CollapsableHeader
      title={t('Additional Information & Documents')}
      description={t(
        'This section provides additional information and documents relevant to clarify the buyer’s position, terms, or requirements.'
      )}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );

  return (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody
        data={[
          { label: t('Additional Information'), value: offer?.comments },
          {
            label: t('Supporting Documents'),
            value: offer?.file ? (
              <a
                href={`${ASSETS_BASE_URL}/${offer.file}`}
                target="_blank"
                rel="noreferrer"
                className="text-primary-700"
              >
                {t('Press to see the document')}
              </a>
            ) : null,
          },
        ]}
      />
    </Collapsable>
  );
}
