import { ReactNode } from 'react';

import { AnimationShell } from '@/components';
import { Header } from '@/features/header';
import { MainSidebar } from '@/features/sidebar';
import { cn } from '@/utils/styles';

export type AppLayoutProps = {
  readonly children: ReactNode;
};

export function AppLayout({ children }: AppLayoutProps) {
  return (
    <AnimationShell type="opacity" className="inline-flex h-full w-full items-start justify-start">
      <MainSidebar />

      <div className="flex h-full w-main-content grow flex-col items-start justify-start">
        <Header />

        <main className={cn('relative h-full w-full grow overflow-auto bg-neutral-25')}>{children}</main>
      </div>
    </AnimationShell>
  );
}
