import { useCallback } from 'react';

import { useAppDispatch } from '@/store';

import { useLogoutMutation } from '../api';
import { logout } from '../store';

export function useLogout() {
  const [mutation] = useLogoutMutation();
  const dispatch = useAppDispatch();

  return useCallback(() => {
    mutation()
      .unwrap()
      .then(() => {
        dispatch(logout());
      })
      .catch((error) => {
        console.error('Failed to login:', error);
      });
  }, [mutation, dispatch]);
}
