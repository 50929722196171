import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AnimationShell, Link, Typography } from '@/components';
import { Route } from '@/constants';
import { PasswordForm, PasswordFormFields, useAcceptInvitationMutation, useLogin } from '@/features/auth';
import { useRegisterGAPageView, useTokenParam } from '@/hooks';
import { jwtDecode } from 'jwt-decode';

export function SignUpInvitationPage() {
  const { t } = useTranslation(['signup']);

  const navigate = useNavigate();
  useRegisterGAPageView('Sign up invitation');

  const [mutation] = useAcceptInvitationMutation();
  const login = useLogin();

  const token = useTokenParam();
  const decodeToken = jwtDecode(token);
  const email = 'email' in decodeToken ? (decodeToken.email as string) : null;

  const onSubmitForm = useCallback(
    ({ password }: PasswordFormFields) => {
      mutation({ token, password })
        .unwrap()
        .then(() => {
          if (email) {
            login({ email: email, password: password });
          } else {
            navigate(Route.SignIn);
          }

          navigate(Route.Home);
        });
    },
    [token, mutation, navigate]
  );

  return (
    <div className="w-full space-y-8">
      <Typography as="h1" size="heading-1" color="primary" weight="semibold">
        {t(`Create your account`)}
      </Typography>
      <Typography as="p" size="body-1" color="primary" weight="semibold">
        {email}
      </Typography>

      <PasswordForm onSubmit={onSubmitForm} inviteForm />

      <AnimationShell type="opacity" delay={0.35} className="inline-flex w-full items-center justify-center gap-x-1">
        <Typography weight="normal" size="body-2">
          {t(`Already have an account?`)}
        </Typography>
        <Link to={Route.SignIn}>{t(`Sign in`)}</Link>
      </AnimationShell>
    </div>
  );
}
