import { Market, MarketDetails } from '../types';

export function marketTransformer(market: Market): MarketDetails {
  return {
    id: market.id,
    status: market.status,
    createdAt: new Date(market.date_created).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }),
    updatedAt: new Date(market.date_updated).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }),
    hidden: market.hidden,
    title: market.title,
    description: market.description,
    imageTitle: market.image_title,
    image: market.image,
    textTitle: market.text_title,
    text: market.text,
  };
}
