import { cn } from '@/utils/styles';

import type { ArtworkProps } from '../types';

export function WorkOfficeDesk({ className }: ArtworkProps) {
  return (
    <svg className={cn(className)} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M122.61 108.657C120.236 110.119 112.708 111.087 109.509 111.265H123.937C123.557 110.365 123.114 109.494 122.61 108.657Z"
        fill="#B7F6D1"
      />
      <path
        d="M124.975 114.557H77.6734L60.2808 150.666C61.106 151.396 61.9829 152.064 62.9046 152.667L60.2997 158.152C64.0422 159.18 67.9847 159.238 71.7561 158.322L72.7153 156.084C73.6718 156.178 74.6328 156.218 75.5938 156.205L82.4056 143.738L80.3579 154.086C81.2782 154.54 82.2248 154.939 83.1926 155.28L82.4374 158.32C85.8473 159.294 89.4621 159.294 92.873 158.32L93.1934 156.888C94.1149 156.884 95.036 156.844 95.9545 156.77L103.46 126.408L105.728 126.749L121.514 125.482C124.76 122.828 126.174 119.53 124.975 114.557Z"
        fill="#B7F6D1"
      />
      <path
        d="M65.026 84.4412C64.969 85.1347 64.7112 85.7965 64.2842 86.3459C63.8572 86.8953 63.2794 87.3084 62.6214 87.5348C61.9635 87.7612 61.2538 87.791 60.5792 87.6207C59.9046 87.4504 59.2941 87.0872 58.8225 86.5757C58.3508 86.0641 58.0384 85.4262 57.9233 84.74C57.8083 84.0537 57.8956 83.3489 58.1746 82.7114C58.4536 82.074 58.9122 81.5316 59.4944 81.1506C60.0765 80.7695 60.7571 80.5663 61.4529 80.5657C62.4406 80.606 63.3718 81.0369 64.0418 81.7637C64.7119 82.4904 65.0658 83.4535 65.026 84.4412Z"
        fill="#B7F6D1"
      />
      <path
        d="M63.6081 78.9398C63.035 79.3627 62.3345 79.5766 61.623 79.5448C61.6927 78.8364 62.0071 78.1747 62.5116 77.6732C63.0845 77.2503 63.7852 77.0367 64.4966 77.0682C64.4269 77.7764 64.1128 78.4382 63.6081 78.9398Z"
        fill="#B7F6D1"
      />
      <path
        d="M105.18 75.125C105.148 75.6217 105.048 76.1116 104.881 76.5807L106.696 74.8792C106.203 75.0241 105.694 75.1066 105.18 75.125Z"
        fill="#279D59"
      />
      <path
        d="M112.401 91.1525V99.9622C112.893 99.8866 113.384 99.8109 113.876 99.7164C113.613 96.8271 113.12 93.9633 112.401 91.1525Z"
        fill="#279D59"
      />
      <path
        d="M101.38 104.216L99.0734 103.365C98.8061 103.136 98.5475 102.896 98.2983 102.647C98.9053 100.019 100.529 101.002 101.38 104.216Z"
        fill="#279D59"
      />
      <path
        d="M83.7603 71.6992V95.5951C83.7598 96.4674 83.4131 97.3038 82.7963 97.9206C82.1795 98.5373 81.3431 98.8841 80.4709 98.8846H79.2231C80.0954 98.8841 80.9318 98.5373 81.5486 97.9206C82.1653 97.3038 82.5121 96.4674 82.5126 95.5951V71.6992C82.5121 70.827 82.1653 69.9906 81.5486 69.3738C80.9318 68.757 80.0954 68.4103 79.2231 68.4097H80.4709C81.3431 68.4103 82.1795 68.757 82.7963 69.3738C83.4131 69.9906 83.7598 70.827 83.7603 71.6992Z"
        fill="#279D59"
      />
      <path
        d="M71.8317 110.436H69.3741C68.98 110.439 68.5988 110.296 68.3045 110.034C68.0103 109.772 67.824 109.409 67.7821 109.018L66.7463 98.9055H65.4985L66.8786 111.496H72.8715C72.8744 111.358 72.8496 111.22 72.7987 111.092C72.7477 110.963 72.6716 110.846 72.5747 110.747C72.4779 110.648 72.3623 110.57 72.2347 110.516C72.1071 110.463 71.9701 110.435 71.8317 110.436Z"
        fill="#279D59"
      />
      <path
        d="M113.82 68.2963C114.043 68.0281 114.163 67.689 114.158 67.3401C114.153 66.9912 114.024 66.6555 113.794 66.3933C113.564 66.1312 113.248 65.9598 112.902 65.9101C112.557 65.8604 112.205 65.9357 111.91 66.1222C112.071 65.8347 112.13 65.5014 112.077 65.1764C112.024 64.8513 111.864 64.5535 111.621 64.3312C111.378 64.1088 111.068 63.9743 110.74 63.9494C110.412 63.9244 110.085 64.0106 109.812 64.1939C110.693 62.6069 108.166 61.2507 107.225 62.7761C106.643 64.4982 105.915 66.1679 105.051 67.767C107.471 64.384 109.096 67.9749 108.945 70.0923C108.964 70.5082 109.172 70.6216 109.853 70.1301C111.252 69.1092 112.254 71.4913 109.645 72.6634C108.841 74.2099 108.606 75.9902 108.983 77.6921C108.964 77.4274 108.662 72.1529 114.617 71.0564C114.925 70.9913 115.202 70.8226 115.401 70.5784C115.6 70.3342 115.709 70.0292 115.711 69.7142C115.714 69.4841 115.662 69.2567 115.56 69.0504C115.458 68.8442 115.308 68.6649 115.124 68.5272C114.94 68.389 114.726 68.2961 114.5 68.2559C114.273 68.2158 114.04 68.2297 113.82 68.2963Z"
        fill="#279D59"
      />
      <path
        d="M107.846 83.8362C107.846 84.017 107.774 84.1903 107.646 84.3182C107.518 84.446 107.345 84.5178 107.164 84.5178C106.983 84.5178 106.81 84.446 106.682 84.3182C106.554 84.1903 106.482 84.017 106.482 83.8362C106.482 83.6555 106.554 83.4821 106.682 83.3543C106.81 83.2265 106.983 83.1546 107.164 83.1546C107.345 83.1546 107.518 83.2265 107.646 83.3543C107.774 83.4821 107.846 83.6555 107.846 83.8362Z"
        fill="#279D59"
      />
      <path
        d="M101.305 90.1316C101.305 90.3124 101.233 90.4857 101.105 90.6135C100.977 90.7414 100.804 90.8132 100.623 90.8132C100.442 90.8132 100.269 90.7414 100.141 90.6135C100.013 90.4857 99.9414 90.3124 99.9414 90.1316C99.9414 89.9508 100.013 89.7775 100.141 89.6496C100.269 89.5218 100.442 89.45 100.623 89.45C100.804 89.45 100.977 89.5218 101.105 89.6496C101.233 89.7775 101.305 89.9508 101.305 90.1316Z"
        fill="#279D59"
      />
      <path
        d="M106.39 97.2588C106.39 97.4395 106.318 97.6129 106.19 97.7407C106.063 97.8685 105.889 97.9404 105.708 97.9404C105.528 97.9404 105.354 97.8685 105.226 97.7407C105.099 97.6129 105.027 97.4395 105.027 97.2588C105.027 97.078 105.099 96.9046 105.226 96.7768C105.354 96.649 105.528 96.5772 105.708 96.5772C105.889 96.5772 106.063 96.649 106.19 96.7768C106.318 96.9046 106.39 97.078 106.39 97.2588Z"
        fill="#279D59"
      />
      <path
        d="M143.78 99.395H136.974C136.734 99.395 136.503 99.4906 136.333 99.6608C136.163 99.831 136.067 100.062 136.067 100.302V110.36C136.067 110.601 136.163 110.831 136.333 111.002C136.503 111.172 136.734 111.267 136.974 111.267H143.78C144.02 111.264 144.249 111.168 144.418 110.998C144.588 110.829 144.684 110.6 144.688 110.36V100.298C144.686 100.059 144.589 99.8294 144.42 99.6603C144.25 99.4911 144.02 99.3958 143.78 99.395Z"
        fill="#279D59"
      />
      <path
        d="M142.785 51.5435L133.578 54.0579C133.414 54.1027 133.264 54.1899 133.144 54.3106C133.024 54.4313 132.938 54.5814 132.894 54.7459C132.85 54.9104 132.85 55.0835 132.894 55.2481C132.937 55.4126 133.024 55.5628 133.144 55.6837L141.424 63.8885C141.514 63.9789 141.621 64.0506 141.739 64.0995C141.857 64.1485 141.983 64.1737 142.111 64.1737C142.239 64.1737 142.365 64.1485 142.483 64.0995C142.601 64.0506 142.708 63.9789 142.798 63.8885C142.918 63.7679 143.005 63.618 143.05 63.4537L145.507 54.0768L142.785 51.5435Z"
        fill="#279D59"
      />
      <path
        d="M149.164 47.6968C148.83 47.3689 148.38 47.1868 147.912 47.1906C147.444 47.1943 146.997 47.3835 146.668 47.7167L142.831 51.5913L145.418 53.9922L149.18 50.1923C149.504 49.8569 149.684 49.4078 149.681 48.9413C149.678 48.4748 149.492 48.028 149.164 47.6968Z"
        fill="#279D59"
      />
      <path
        d="M67.9751 35.0265C67.9747 35.1855 67.943 35.3429 67.8817 35.4897C67.8205 35.6364 67.731 35.7697 67.6183 35.8819C67.5056 35.994 67.3718 36.0829 67.2248 36.1434C67.0777 36.2039 66.9202 36.2348 66.7612 36.2344C66.6021 36.234 66.4447 36.2023 66.298 36.1411C66.1512 36.0799 66.0179 35.9904 65.9058 35.8777C65.7936 35.7649 65.7048 35.6312 65.6443 35.4842C65.5838 35.3371 65.5528 35.1796 65.5532 35.0205C65.554 34.6994 65.6824 34.3917 65.91 34.1652C66.1377 33.9386 66.446 33.8118 66.7671 33.8126C67.0883 33.8134 67.396 33.9417 67.6225 34.1694C67.849 34.397 67.9758 34.7053 67.9751 35.0265Z"
        fill="#279D59"
      />
      <path
        d="M92.8728 158.322C89.4619 159.296 85.8481 159.296 82.4372 158.322C79.2423 159.248 72.9658 163.71 72.9658 163.71H94.0827C93.8829 161.874 93.477 160.067 92.8728 158.322Z"
        fill="#279D59"
      />
      <path
        d="M71.7556 158.322C67.9846 159.238 64.0414 159.18 60.2992 158.152C56.873 159.275 53.7539 161.178 51.187 163.71H72.9467C72.6815 161.887 72.2834 160.086 71.7556 158.322Z"
        fill="#279D59"
      />
      <path d="M109.471 126.448L105.728 126.751L103.459 126.41L103.932 124.501L109.471 126.448Z" fill="#279D59" />
      <path
        d="M174.939 111.267H27.9719C27.5357 111.267 27.1173 111.441 26.8089 111.749C26.5004 112.058 26.3271 112.476 26.3271 112.912C26.3271 113.348 26.5004 113.767 26.8089 114.075C27.1173 114.384 27.5357 114.557 27.9719 114.557H48.1137L38.8423 165.884C38.8263 165.977 38.8204 166.073 38.8234 166.167H42.1506L51.4678 114.557H151.478L160.798 166.186H164.126C164.107 166.092 164.107 165.997 164.088 165.888L154.817 114.561H174.939C175.366 114.546 175.77 114.366 176.066 114.059C176.362 113.753 176.528 113.343 176.528 112.916C176.528 112.49 176.362 112.08 176.066 111.773C175.77 111.466 175.366 111.286 174.939 111.271V111.267Z"
        fill="#279D59"
      />
      <path
        d="M67.9751 35.0265C67.9747 35.1855 67.943 35.3429 67.8817 35.4897C67.8205 35.6364 67.731 35.7697 67.6183 35.8819C67.5056 35.994 67.3718 36.0829 67.2248 36.1434C67.0777 36.2039 66.9202 36.2348 66.7612 36.2344C66.6021 36.234 66.4447 36.2023 66.298 36.1411C66.1512 36.0799 66.0179 35.9904 65.9058 35.8777C65.7936 35.7649 65.7048 35.6312 65.6443 35.4842C65.5838 35.3371 65.5528 35.1796 65.5532 35.0205C65.554 34.6994 65.6824 34.3917 65.91 34.1652C66.1377 33.9386 66.446 33.8118 66.7671 33.8126C67.0883 33.8134 67.396 33.9417 67.6225 34.1694C67.849 34.397 67.9758 34.7053 67.9751 35.0265Z"
        stroke="#186A3B"
      />
      <path
        d="M149.164 47.6968C148.83 47.3689 148.38 47.1868 147.912 47.1906C147.445 47.1943 146.997 47.3835 146.669 47.7167L142.831 51.5913L145.418 53.9922L149.18 50.1923C149.505 49.8569 149.684 49.4078 149.682 48.9413C149.679 48.4748 149.493 48.028 149.164 47.6968Z"
        stroke="#186A3B"
        strokeLinejoin="round"
      />
      <path
        d="M174.939 111.267H27.9719C27.5357 111.267 27.1173 111.441 26.8089 111.749C26.5004 112.058 26.3271 112.476 26.3271 112.912C26.3271 113.348 26.5004 113.767 26.8089 114.075C27.1173 114.384 27.5357 114.557 27.9719 114.557H48.1137L38.8423 165.884C38.8263 165.977 38.8204 166.073 38.8234 166.167H42.1506L51.4678 114.557H151.478L160.798 166.186H164.126C164.107 166.092 164.107 165.997 164.088 165.888L154.817 114.561H174.939C175.366 114.546 175.77 114.366 176.066 114.059C176.362 113.753 176.528 113.343 176.528 112.916C176.528 112.49 176.362 112.08 176.066 111.773C175.77 111.466 175.366 111.286 174.939 111.271V111.267Z"
        stroke="#186A3B"
        strokeLinejoin="round"
      />
      <path
        d="M97.0887 108.696C96.304 109.759 95.2252 110.569 93.9855 111.026C92.7459 111.483 91.3996 111.567 90.1128 111.267"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.3296 105.52C54.3296 105.52 45.1368 104.671 43.8701 111.267"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.5961 106.995C57.3126 106.844 57.0837 106.696 56.8588 106.56C55.7902 105.914 55.4032 105.482 54.4579 104.859C53.5489 104.256 52.7061 103.559 51.9435 102.779C49.8441 100.625 50.5446 100.246 52.9077 101.664C52.922 101.666 52.9352 101.672 52.9455 101.683C54.5146 102.633 56.1511 103.467 57.8419 104.179"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.8587 106.56C54.7933 105.646 52.6652 104.882 50.4907 104.272C48.658 103.756 48.9783 103.343 49.2808 103.288C50.3976 103.204 51.5204 103.248 52.627 103.421"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.256 110.436C56.8734 110.385 56.4885 110.353 56.1028 110.341C52.8342 110.222 53.9864 109.494 55.1197 109.169C55.7068 109.001 56.67 108.81 57.4451 108.545"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M127.96 128.376L133.348 166.186" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M133.802 100.926L124.955 100.284" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M93.1934 156.89L92.873 158.322" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M82.4185 158.322L83.1926 155.282" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M72.72 156.072L71.7559 158.322" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M60.2808 158.171L62.9046 152.667" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M82.4185 143.689L75.5938 156.205C69.967 156.398 64.4822 154.414 60.2808 150.666L77.6734 114.557"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.5288 156.815L97.5048 123.745C97.6063 123.262 97.8027 122.804 98.0826 122.397C98.3625 121.99 98.7204 121.643 99.1354 121.376C99.5504 121.109 100.014 120.926 100.5 120.84C100.986 120.753 101.485 120.764 101.966 120.871C107.836 122.184 118.87 123.389 119.981 114.601"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.9434 102.842L87.3717 99.3592C90.9069 93.9862 95.1058 81.5408 101.191 79.8114C102.189 79.557 103.098 79.0339 103.819 78.299"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.18 75.125C105.681 75.1071 106.179 75.0337 106.664 74.9061L104.873 76.5807"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.282 78.4483C109.575 78.9856 110.061 79.3916 110.643 79.5826C113.667 80.7113 116.47 82.3614 118.924 84.4581C121.724 87.0272 124.197 96.4409 125.276 102.645C125.537 104.103 125.238 105.606 124.439 106.854C123.64 108.101 122.399 109.002 120.966 109.375C117.225 110.414 113.385 111.047 109.509 111.265"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.031 99.2816C110.544 100.34 104.981 100.959 99.395 101.134"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.857 99.7015C113.612 96.8099 113.118 93.9448 112.383 91.1375V99.9662"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.2041 102.306C70.9265 104.947 71.5379 108.16 70.9055 111.248"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.5705 103.705C91.1148 104.348 87.3905 105.408 85.3866 106.05C84.4413 106.348 86.1239 107.43 91.8332 105.785"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.8542 105.18C91.4742 106.05 89.4712 107.051 87.6554 108.016C86.8046 108.468 87.5797 109.452 92.6085 107.373"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.2511 106.56C92.4551 107.543 91.7655 108.496 90.4342 109.925C89.8143 110.591 90.9526 110.995 95.1605 108.035C95.9366 107.488 96.3545 108.087 97.3724 108.942C98.7186 110.071 99.622 111.381 100.605 111.248"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.7087 102.155C94.1963 98.6727 95.1794 98.4737 95.7087 99.3572C96.606 100.806 97.6925 102.128 98.9395 103.288"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.2988 102.647C98.7705 100.597 99.864 100.744 100.726 102.434C101.721 104.388 102.628 111.267 100.549 111.267"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.311 104.19C99.0867 103.36 95.5187 101.909 94.5555 101.909C94.1963 101.909 90.0939 103.103 88.1089 103.649C86.8045 104.009 86.1816 104.611 88.2979 104.537C89.1865 104.506 90.1695 104.386 91.0581 104.273"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.1643 89.453C96.3444 93.4564 96.396 97.5894 97.3156 101.571"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.8783 111.248H57.1611L58.3711 98.9035H65.5171L66.8783 111.248Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.026 84.4412C64.969 85.1347 64.7112 85.7965 64.2842 86.3459C63.8572 86.8953 63.2794 87.3084 62.6214 87.5348C61.9635 87.7612 61.2538 87.791 60.5792 87.6207C59.9046 87.4504 59.2941 87.0872 58.8225 86.5757C58.3508 86.0641 58.0384 85.4262 57.9233 84.74C57.8083 84.0537 57.8956 83.3489 58.1746 82.7114C58.4536 82.074 58.9122 81.5316 59.4944 81.1506C60.0765 80.7695 60.7571 80.5663 61.4529 80.5657C62.4406 80.606 63.3718 81.0369 64.0418 81.7637C64.7119 82.4904 65.0658 83.4535 65.026 84.4412Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.6081 78.9398C63.035 79.3627 62.3345 79.5766 61.623 79.5448C61.6927 78.8364 62.0071 78.1747 62.5116 77.6732C63.0845 77.2503 63.7852 77.0367 64.4966 77.0682C64.4269 77.7764 64.1128 78.4382 63.6081 78.9398Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.048 67.7859C105.911 66.1859 106.638 64.5164 107.222 62.795C107.357 62.5977 107.536 62.4353 107.746 62.3214C107.956 62.2074 108.19 62.1452 108.429 62.1399C108.668 62.1345 108.905 62.1862 109.12 62.2906C109.334 62.395 109.521 62.5492 109.665 62.7402C109.856 62.9959 109.96 63.3074 109.963 63.6268C109.961 63.8315 109.909 64.0327 109.812 64.2128C110.085 64.0304 110.412 63.945 110.739 63.9704C111.067 63.9957 111.377 64.1304 111.619 64.3527C111.861 64.575 112.021 64.8722 112.074 65.1965C112.127 65.5208 112.069 65.8534 111.91 66.1411C112.205 65.9546 112.557 65.8793 112.902 65.929C113.248 65.9787 113.564 66.1501 113.794 66.4122C114.024 66.6744 114.153 67.0101 114.158 67.359C114.163 67.7079 114.043 68.047 113.82 68.3152C114.002 68.2571 114.193 68.2359 114.383 68.253C114.573 68.2701 114.758 68.325 114.927 68.4146C115.095 68.5041 115.244 68.6265 115.365 68.7745C115.485 68.9226 115.575 69.0932 115.629 69.2764C115.708 69.5528 115.702 69.8467 115.612 70.1199C115.523 70.3931 115.353 70.6331 115.125 70.8087C114.974 70.9246 114.8 71.0089 114.616 71.0564C113.006 71.2308 111.524 72.0162 110.476 73.2508C109.428 74.4854 108.893 76.075 108.982 77.6921"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.282 78.4483L108.923 81.284L106.031 78.9965L102.76 80.6413L103.819 78.2971"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.031 78.9965C103.649 85.0083 102.798 92.5514 102.76 100.945"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.691 102.628C145.409 102.628 146.096 102.912 146.603 103.42C146.854 103.671 147.054 103.968 147.19 104.296C147.326 104.624 147.396 104.976 147.396 105.331C147.396 105.686 147.326 106.038 147.19 106.366C147.054 106.694 146.854 106.992 146.603 107.243C146.096 107.75 145.409 108.035 144.691 108.035"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.476 77.5409C169.476 77.787 169.422 78.0301 169.317 78.2526C169.212 78.4752 169.059 78.6716 168.868 78.8278C168.678 78.9839 168.456 79.0958 168.217 79.1555C167.978 79.2151 167.729 79.221 167.488 79.1727C167.246 79.1251 167.018 79.0244 166.821 78.8779C166.623 78.7315 166.46 78.5429 166.344 78.3258C166.228 78.1087 166.161 77.8686 166.149 77.6227C166.138 77.3768 166.18 77.1313 166.275 76.9041C166.401 76.6004 166.615 76.3409 166.889 76.1584C167.162 75.9759 167.484 75.8784 167.813 75.8783C168.142 75.8782 168.463 75.9755 168.737 76.1578C169.011 76.3402 169.225 76.5995 169.351 76.9031C169.435 77.1051 169.477 77.322 169.476 77.5409Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M156.11 106.466L167.813 79.2045" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M167.812 75.8772L150.76 56.7454" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M148.718 55.4409L145.826 53.5882" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M139.02 61.5094C138.714 61.7493 138.351 61.9069 137.966 61.9672C137.581 62.0276 137.188 61.9887 136.822 61.8543C136.457 61.7198 136.132 61.4943 135.878 61.1991C135.624 60.9038 135.45 60.5486 135.372 60.1672C135.305 59.8453 135.31 59.5129 135.385 59.193C135.46 58.8731 135.604 58.5733 135.806 58.3145"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.043 55.9703C151.044 56.1521 151.004 56.3318 150.926 56.4963C150.849 56.6608 150.736 56.806 150.595 56.9213C150.455 57.0367 150.29 57.1193 150.114 57.1632C149.937 57.2071 149.753 57.2112 149.575 57.1752C149.274 57.1177 149.005 56.9497 148.822 56.7043C148.639 56.4588 148.554 56.1537 148.584 55.8488C148.614 55.5439 148.757 55.2613 148.984 55.0564C149.212 54.8515 149.508 54.7391 149.815 54.7414C150.139 54.7461 150.449 54.877 150.678 55.1065C150.908 55.3359 151.039 55.6458 151.043 55.9703Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.32 107.694C157.32 107.876 157.281 108.056 157.203 108.22C157.125 108.385 157.012 108.53 156.872 108.645C156.731 108.761 156.567 108.843 156.39 108.887C156.214 108.931 156.03 108.935 155.851 108.899C155.613 108.852 155.394 108.735 155.223 108.563C155.094 108.434 154.996 108.279 154.935 108.108C154.874 107.937 154.852 107.755 154.87 107.574C154.887 107.393 154.945 107.219 155.038 107.063C155.131 106.907 155.258 106.774 155.409 106.672C155.645 106.514 155.929 106.442 156.212 106.47C156.495 106.498 156.76 106.623 156.961 106.825C157.075 106.939 157.165 107.074 157.227 107.224C157.289 107.373 157.32 107.533 157.32 107.694Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M105.729 130.966L103.46 166.186" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M68.6936 51.7355H57.4263V40.4682H68.6936V51.7355Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M63.0596 43.0392L66.349 36.1011" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.6374 132.923H22.0356" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.3271 137.224V128.622" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M169.816 127.469C169.85 127.838 169.802 128.21 169.675 128.558C169.547 128.906 169.344 129.221 169.08 129.481C168.816 129.741 168.498 129.94 168.148 130.062C167.798 130.184 167.426 130.227 167.057 130.187C166.565 130.135 166.098 129.937 165.718 129.62C165.337 129.303 165.059 128.88 164.918 128.405C164.813 128.05 164.789 127.676 164.846 127.31C164.903 126.944 165.041 126.595 165.249 126.289C165.457 125.982 165.73 125.726 166.049 125.538C166.368 125.349 166.725 125.234 167.094 125.2C167.589 125.149 168.087 125.249 168.525 125.485C168.962 125.722 169.319 126.085 169.547 126.527C169.698 126.819 169.789 127.14 169.816 127.469Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.4751 166.186H194.525" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M72.9658 163.71H94.0827" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M51.1685 163.71H72.9281" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M72.9651 163.68C72.7648 161.854 72.3592 160.056 71.7562 158.321C67.9851 159.237 64.0419 159.179 60.2998 158.151C55.8146 159.674 51.8634 162.457 48.9189 166.167C57.6531 166.167 63.4569 166.186 70.0358 166.167"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.8729 158.322C89.462 159.296 85.8482 159.296 82.4373 158.322C77.729 159.687 71.7043 164.499 70.0356 166.167C80.0175 166.167 86.2939 166.073 94.1395 166.073C94.2087 163.433 93.779 160.803 92.8729 158.322Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.46 126.41L103.933 124.501L109.472 126.448"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.46 126.41L105.729 126.751L126.143 125.083"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M128.773 125.276L122.346 124.803" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M69.3931 155.505L85.3867 128.679" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M104.292 100.832C105.993 103.346 106.428 106.825 105.558 111.267"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.42 130.588L105.728 130.966L126.424 129.246C126.859 129.212 127.272 129.042 127.604 128.761C127.937 128.479 128.172 128.1 128.277 127.677L137.219 84.3278C137.367 83.6263 137.281 82.8956 136.974 82.248C136.666 81.6003 136.155 81.0715 135.518 80.7428C135.096 80.5237 134.631 80.3988 134.156 80.3766L109.925 79.2045"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.5744 113.99C92.6382 114.363 90.8574 115.305 89.4597 116.696C88.0621 118.087 87.1112 119.863 86.7288 121.797L80.3608 154.087C85.2182 156.386 90.611 157.315 95.9575 156.774C95.9575 156.68 101.747 133.331 103.463 126.412"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.2235 68.4097C80.0957 68.4103 80.9321 68.757 81.5489 69.3738C82.1657 69.9906 82.5124 70.827 82.5129 71.6992V95.614C82.5129 96.4866 82.1664 97.3235 81.5496 97.9408C80.9327 98.558 80.0961 98.905 79.2235 98.9055H46.5367C45.6636 98.905 44.8263 98.5585 44.2081 97.942C43.5899 97.3255 43.2412 96.4891 43.2383 95.616V71.6992C43.2375 71.2675 43.3219 70.8399 43.4865 70.4408C43.6512 70.0418 43.8929 69.6791 44.1979 69.3736C44.5029 69.068 44.8651 68.8256 45.2639 68.6603C45.6626 68.4949 46.0901 68.4097 46.5218 68.4097H79.2235Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.7608 71.6992V95.5951C83.7603 96.4674 83.4136 97.3038 82.7968 97.9206C82.18 98.5373 81.3436 98.8841 80.4714 98.8846H79.2236C80.0959 98.8841 80.9323 98.5373 81.5491 97.9206C82.1658 97.3038 82.5126 96.4674 82.5131 95.5951V71.6992C82.5126 70.827 82.1658 69.9906 81.5491 69.3738C80.9323 68.757 80.0959 68.4103 79.2236 68.4097H80.4714C81.3436 68.4103 82.18 68.757 82.7968 69.3738C83.4136 69.9906 83.7603 70.827 83.7608 71.6992Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.851 70.15C111.244 69.1232 112.239 71.4962 109.643 72.6833C109.282 72.8484 108.168 76.4832 109.284 78.4543C107.502 79.0403 105.351 79.3896 103.82 78.303C104.629 77.4339 105.11 76.3102 105.18 75.125C100.265 75.2006 104.953 67.771 105.048 67.771C107.451 64.4108 109.071 67.9461 108.945 70.0515C108.908 70.6734 109.344 70.5211 109.851 70.15Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.615 125.422C122.871 124.481 123.874 123.243 124.534 121.819C125.194 120.395 125.49 118.83 125.396 117.263C125.328 116.368 125.197 115.479 125.005 114.603"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.61 108.656C123.122 109.491 123.57 110.364 123.949 111.266"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.691 110.36C144.691 110.601 144.596 110.831 144.426 111.002C144.256 111.172 144.025 111.267 143.784 111.267H136.978C136.738 111.267 136.507 111.172 136.337 111.002C136.166 110.831 136.071 110.601 136.071 110.36V100.298C136.071 100.058 136.166 99.827 136.337 99.6568C136.507 99.4867 136.738 99.3911 136.978 99.3911H143.78C144.021 99.3913 144.251 99.487 144.421 99.6571C144.592 99.8273 144.687 100.058 144.688 100.298L144.691 110.36Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.655 108.904C164.73 108.906 164.801 108.934 164.854 108.982C164.88 109.005 164.901 109.033 164.915 109.064C164.93 109.095 164.938 109.129 164.939 109.163V111.007C164.938 111.041 164.93 111.075 164.915 111.106C164.901 111.137 164.88 111.165 164.854 111.188C164.799 111.236 164.729 111.264 164.655 111.265H147.546C147.473 111.264 147.402 111.236 147.347 111.188C147.322 111.165 147.301 111.137 147.286 111.106C147.272 111.075 147.264 111.041 147.263 111.007V109.163C147.264 109.129 147.272 109.095 147.286 109.064C147.301 109.033 147.322 109.005 147.347 108.982C147.402 108.933 147.473 108.906 147.546 108.904H164.655Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M105.729 130.966V126.751" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M142.785 51.5435L133.578 54.0579C133.414 54.1027 133.264 54.1899 133.144 54.3106C133.024 54.4313 132.938 54.5814 132.894 54.7459C132.85 54.9104 132.85 55.0835 132.894 55.2481C132.937 55.4126 133.024 55.5628 133.144 55.6837L141.424 63.8885C141.514 63.9789 141.621 64.0506 141.739 64.0995C141.857 64.1485 141.983 64.1737 142.111 64.1737C142.239 64.1737 142.365 64.1485 142.483 64.0995C142.601 64.0506 142.708 63.9789 142.798 63.8885C142.918 63.7679 143.005 63.618 143.05 63.4537L145.507 54.0768L142.785 51.5435Z"
        stroke="#186A3B"
        strokeLinejoin="round"
      />
    </svg>
  );
}
