import { useMemo } from 'react';

import { useGetOpportunityOffersQuery, useGetOpportunityQuery } from '../api';
import { opportunityDetailsTransformer } from '../helpers';

export function useOpportunityDetails(id: string) {
  const { data: opportunity } = useGetOpportunityQuery({ id });
  const { data: offers } = useGetOpportunityOffersQuery({ id });
  const normalizedOpportunity = useMemo(
    () => opportunityDetailsTransformer(opportunity, offers?.offers),
    [opportunity, offers]
  );

  return { opportunity, normalizedOpportunity } as const;
}
