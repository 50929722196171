import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { isBoolean } from '@/types';

export function useBooleanDisplayNormalizer(defaultValue: string | null = '') {
  const { t } = useTranslation(['generic']);

  return useCallback(
    (value?: boolean | null) => {
      if (!isBoolean(value)) {
        return defaultValue;
      }

      return value ? t('Yes') : t('No');
    },
    [defaultValue, t]
  );
}
