import { PayloadAction } from '@reduxjs/toolkit';

import { Notification } from '../types';

import { NotificationsState } from './types';

export function add(state: NotificationsState, action: PayloadAction<Notification>) {
  state.notifications.push(action.payload);
}

export function remove(state: NotificationsState, action: PayloadAction<string | number>) {
  state.notifications = state.notifications.filter((notification) => notification.id !== action.payload);
}
