import { ClassValue } from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { Icon, Typography } from '@/components';
import { cn } from '@/utils/styles';

const modalVariants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '640px' },
};

const overlayVariants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

export type DrawerProps = {
  readonly title: string;
  readonly open: boolean;
  readonly onClose?: () => void;
  readonly footer?: ReactNode;
  readonly children?: ReactNode;
  readonly classes?: {
    readonly drawer?: ClassValue;
    readonly overlay?: ClassValue;
    readonly main?: ClassValue;
    readonly header?: ClassValue;
    readonly content?: ClassValue;
    readonly footer?: ClassValue;
  };
};

export function Drawer({ title, open, footer, children, onClose, classes }: DrawerProps) {
  const drawer = (
    <div className={cn('fixed left-0 top-0 z-40 flex h-full w-full items-start justify-end', classes?.drawer)}>
      <motion.div
        initial="closed"
        animate="open"
        exit="closed"
        variants={overlayVariants}
        className={cn('absolute left-0 top-0 h-full w-full bg-base-black/50', classes?.overlay)}
      />

      <motion.div
        initial="closed"
        animate="open"
        exit="closed"
        variants={modalVariants}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        className={cn(
          'relative flex h-full w-drawer flex-col items-stretch justify-start overflow-hidden bg-white',
          classes?.main
        )}
      >
        <header
          className={cn(
            'flex items-center justify-between gap-x-2 border-b border-neutral-50 py-4 pl-7 pr-4',
            classes?.header
          )}
        >
          <Typography as="h5" color="primary" size="title" weight="semibold">
            {title}
          </Typography>

          <button
            type="button"
            className="shrink-0 cursor-pointer p-1 text-neutral-500 transition hover:text-base-primary"
            onClick={onClose}
          >
            <Icon name="arrow-right" className="h-8 w-8" />
          </button>
        </header>

        <div className={cn('w-full overflow-auto px-7 pb-32 pt-6', classes?.content)}>{children}</div>

        <footer
          className={cn(
            'absolute bottom-0 z-[2] flex w-full items-center justify-end gap-x-3 overflow-hidden border-t border-neutral-50 bg-white p-6',
            classes?.footer
          )}
        >
          {footer}
        </footer>
      </motion.div>
    </div>
  );

  return createPortal(<AnimatePresence>{open && drawer}</AnimatePresence>, document.getElementById('root') as Element);
}
