import { api } from '@/features/auth';

import {
  UserStatsResponse,
  OpportunityStatsResponse,
  AddUserStatsRequest,
  AddOpportunityStatsRequest,
  GetOpportunityStatsRequest,
  AddServiceStatsRequest,
  AddDealsStatsRequest,
} from './types';

export const statsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserStats: builder.query<UserStatsResponse['data'], void>({
      query: () => ({
        url: `items/users_stats`,
        method: 'GET',
        params: {
          filter: `{"created_by": {"_eq": "$CURRENT_USER"}}`,
          aggregate: `{ "count": "*" }`,
        },
      }),
      transformResponse: ({ data }: UserStatsResponse) => data,
    }),
    addUserStats: builder.mutation<void, AddUserStatsRequest>({
      query: (data: AddUserStatsRequest) => ({
        url: `items/users_stats`,
        method: 'POST',
        body: data,
      }),
    }),
    getOpportunityStats: builder.query<OpportunityStatsResponse['data'], GetOpportunityStatsRequest>({
      query: ({ id }: GetOpportunityStatsRequest) => ({
        url: `items/opportunities_stats`,
        method: 'GET',
        params: {
          filter: `{"opportunity": {"_eq": "${id}"}}`,
          groupBy: 'key',
          aggregate: `{ "count": "*" }`,
        },
      }),
      transformResponse: ({ data }: OpportunityStatsResponse) => data,
    }),
    addOpportunityStats: builder.mutation<void, AddOpportunityStatsRequest>({
      query: (data: AddOpportunityStatsRequest) => ({
        url: `items/opportunities_stats`,
        method: 'POST',
        body: data,
      }),
    }),
    addServiceStats: builder.mutation<void, AddServiceStatsRequest>({
      query: (data: AddServiceStatsRequest) => ({
        url: `items/services_stats`,
        method: 'POST',
        body: data,
      }),
    }),
    addDealsStats: builder.mutation<void, AddDealsStatsRequest>({
      query: (data: AddDealsStatsRequest) => ({
        url: `items/deals_stats`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetUserStatsQuery,
  useAddUserStatsMutation,
  useGetOpportunityStatsQuery,
  useAddOpportunityStatsMutation,
  useAddServiceStatsMutation,
  useAddDealsStatsMutation,
} = statsApi;
