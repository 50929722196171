import { TabsProps } from '../types';

import { Tab } from './tab';

export function Tabs({ tabs, activeTabIndex = 0, onTabChange, inHeader = true }: TabsProps) {
  return (
    <div className="flex justify-between">
      <ul className="inline-flex items-center justify-start ">
        {tabs.left.map(({ label, className, index }) => (
          <Tab
            key={label}
            index={index}
            label={label}
            active={activeTabIndex === index}
            onClick={() => onTabChange(index)}
            inHeader={inHeader}
            className={className}
          />
        ))}
      </ul>
      {tabs.right.length ? (
        <ul className="inline-flex items-center justify-end">
          {tabs.right.map(({ label, className, index }) => (
            <Tab
              key={label}
              index={index + tabs.left.length}
              label={label}
              active={activeTabIndex === index}
              onClick={() => onTabChange(index)}
              inHeader={inHeader}
              className={className}
            />
          ))}
        </ul>
      ) : null}
    </div>
  );
}
