import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Calendar({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="calendar"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 2C7.66421 2 8 2.33579 8 2.75V5.75C8 6.16421 7.66421 6.5 7.25 6.5C6.83579 6.5 6.5 6.16421 6.5 5.75V2.75C6.5 2.33579 6.83579 2 7.25 2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.25 2C16.6642 2 17 2.33579 17 2.75V5.75C17 6.16421 16.6642 6.5 16.25 6.5C15.8358 6.5 15.5 6.16421 15.5 5.75V2.75C15.5 2.33579 15.8358 2 16.25 2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9.25C2 8.83579 2.33579 8.5 2.75 8.5H20.75C21.1642 8.5 21.5 8.83579 21.5 9.25C21.5 9.66421 21.1642 10 20.75 10H2.75C2.33579 10 2 9.66421 2 9.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 5C4.50736 5 3.5 6.00736 3.5 7.25V17.75C3.5 18.9926 4.50736 20 5.75 20H17.75C18.9926 20 20 18.9926 20 17.75V7.25C20 6.00736 18.9926 5 17.75 5H5.75ZM2 7.25C2 5.17893 3.67893 3.5 5.75 3.5H17.75C19.8211 3.5 21.5 5.17893 21.5 7.25V17.75C21.5 19.8211 19.8211 21.5 17.75 21.5H5.75C3.67893 21.5 2 19.8211 2 17.75V7.25Z"
        fill="currentColor"
      />
    </svg>
  );
}
