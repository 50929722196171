import { useNavigate } from 'react-router-dom';

import { Button, TypographySize } from '@/components';
import { cn } from '@/utils/styles';

import { AnimationShell } from '../animation-shell';
import { Typography } from '../typography';

export type BreadcrumbItem = {
  readonly title: string;
  onClick?: () => void;
};

export type BreadcrumbProps = {
  readonly items: BreadcrumbItem[];
  readonly size?: TypographySize;
};

export function Breadcrumb({ items, size = 'body-2' }: BreadcrumbProps) {
  const navigate = useNavigate();
  const canGoBack = window.history.length > 1;

  return (
    <nav className="flex items-center space-x-1" aria-label="breadcrumb">
      {canGoBack && (
        <>
          <Button
            icon="arrow-left"
            color="link"
            size="sm"
            className="cursor-pointer p-0 transition hover:underline hover:underline-offset-2"
            onClick={() => navigate(-1)}
          ></Button>
          <Typography as="span" size={size} weight="normal" color="secondary" className="p-2">
            |
          </Typography>
        </>
      )}
      {items.map(({ title, onClick }, index, { length }) => (
        <AnimationShell
          key={title}
          element="span"
          type="scale"
          delay={0.15 * index}
          className={cn(onClick ? 'group cursor-pointer' : 'cursor-text select-none')}
          onClick={() => onClick?.()}
        >
          <Typography
            as="span"
            size={size}
            weight="semibold"
            color={index === length - 1 ? 'primary' : 'secondary'}
            className="group-hover:text-neutral-60 underline-offset-2 transition group-hover:underline dark:group-hover:text-neutral-10"
          >
            {title}
          </Typography>

          {index < length - 1 ? (
            <Typography as="span" size={size} weight="normal" color="secondary">
              /
            </Typography>
          ) : null}
        </AnimationShell>
      ))}
    </nav>
  );
}
