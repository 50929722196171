import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { Container } from '@/components';
import { useBreadcrumbs } from '@/features/header';
import { CreateOfferDrawer } from '@/features/offers';
import { OpportunityHeaderView, OpportunityView } from '@/features/opportunities/components/opportunity-view';

export function Opportunity() {
  const { t } = useTranslation(['opportunities']);

  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabActive, setTabActive] = useState(parseInt(searchParams.get('tab') ?? '0'));

  useBreadcrumbs(() => [{ title: t(`Project`) }]);
  const { opportunityId: id } = useParams();

  if (!id) return null;

  return (
    <div className="flex flex-col">
      <div className="w-full border-b border-neutral-50 bg-neutral-10">
        <Container className="space-y-6 pt-6">
          <OpportunityHeaderView id={id} setIsOpen={setIsOpen} tabActive={tabActive} onTabChange={setTabActive} />
        </Container>
      </div>

      <div className="w-full py-10">
        <Container className="space-y-12">
          <OpportunityView id={id} tabActive={tabActive} />
        </Container>

        <CreateOfferDrawer id={id} isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </div>
  );
}
