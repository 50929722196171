import { useState } from 'react';

import { Tabs } from '@/components/tabs';
import { useServicesCategories } from '@/features/services/hooks';
import { useServicesList } from '@/features/services/hooks/use-services-list';

import { ServicesCommingSoon } from '../comming-soon';
import { ServicesEmptyState } from '../empty-state';
import { ServicesItems } from '../items/items';

export function ServicesContent() {
  const services = useServicesList();
  const {
    categories: allTabsCategories,
    kv: categoriesKv,
    normalizedServicesCategories: servicesCategories,
  } = useServicesCategories();

  const [tabActive, setTabActive] = useState(0);

  const tabs = {
    left: allTabsCategories.map((category, index) => ({ label: categoriesKv[category], index: index })),
    right: [],
  };

  if (!services) return null;

  if (servicesCategories.length === 0) {
    return <ServicesCommingSoon />;
  }

  if (services.length === 0) {
    return <ServicesEmptyState />;
  }

  const servicesInPublishedCategories = services.filter((service) => allTabsCategories.includes(service.category));

  const servicesCategoryActive =
    tabActive === 0
      ? servicesInPublishedCategories
      : servicesInPublishedCategories.filter((service) => service.category === allTabsCategories[tabActive]);

  return (
    <>
      <Tabs tabs={tabs} activeTabIndex={tabActive} onTabChange={setTabActive} inHeader={false}></Tabs>
      <ServicesItems services={servicesCategoryActive} />
    </>
  );
}
