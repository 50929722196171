import { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { useAddUserStatsMutation } from '@/features/stats/api';
import { useUserStats } from '@/features/stats/hooks/use-user-stats';
import { useGetCompanyUsersQuery } from '@/features/users';

export type useInviteProps = {
  readonly preferencesModal: boolean;
};

export function useInvite({ preferencesModal }: useInviteProps) {
  const [inviteOpen, setInviteOpen] = useState(false);
  const [inSession, setInSession] = useSessionStorage('invite-reminder', false);
  const { data: users = [] } = useGetCompanyUsersQuery();

  const [postStat] = useAddUserStatsMutation();

  const userStats = useUserStats();

  useEffect(() => {
    if (inSession) return;
    if (!userStats) return;
    if (!users.length) return;

    const enableInviteModal = !preferencesModal && userStats?.logins && userStats?.logins < 5;

    if (enableInviteModal && users.length === 1) {
      setInviteOpen(true);
      setInSession(true);
      postStat({ key: 'user_invitation_reminder' });
    }
  }, [postStat, userStats, inSession, setInSession, users]);

  return {
    inviteOpen,
    setInviteOpen,
  };
}
