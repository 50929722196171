import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AnimationShell, Container } from '@/components';
import { useBreadcrumbs } from '@/features/header';
import { useNotifications } from '@/features/notifications';
import { BasicInfoForm, BasicInfoFormFields, useGetCurrentUserQuery, useUpdateUserMutation } from '@/features/users';
import { useRegisterGAPageView } from '@/hooks';
import { cn } from '@/utils/styles';

export function ProfileSettingsPage() {
  const { t } = useTranslation(['settings']);

  useBreadcrumbs(() => [{ title: t(`Profile settings`) }]);
  useRegisterGAPageView('Profile settings');
  const { add } = useNotifications();

  const { data: user } = useGetCurrentUserQuery();

  const [userMutation] = useUpdateUserMutation();

  const onSubmit = useCallback(
    (data: BasicInfoFormFields) => {
      userMutation(data)
        .unwrap()
        .then(() => {
          add({
            id: new Date().toString(),
            title: t('Profile updated'),
            description: t('Your profile has been updated'),
          });
        })
        .catch(() => {
          add({
            id: new Date().toString(),
            title: t('ERROR'),
            description: t('There was an error updating your profile. Please try again later.'),
          });
        });
    },
    [userMutation, add, t]
  );

  return (
    <div className="flex flex-col">
      <Container className="space-y-12">
        <div className="space-y-4">
          <AnimationShell
            element="article"
            type="opacity"
            duration={0}
            className={cn(
              'mt-8 h-auto max-w-2xl overflow-hidden rounded-lg border border-neutral-50 bg-base-white shadow-sm'
            )}
          >
            <div className="items-center p-6">{user ? <BasicInfoForm user={user} onSubmit={onSubmit} /> : null}</div>
          </AnimationShell>
        </div>
      </Container>
    </div>
  );
}
