import { useCallback } from 'react';

import { useNotifications } from '@/features/notifications';
import { useAppDispatch } from '@/store';

import { LoginRequest, useLoginMutation } from '../api';
import { error, loading, login } from '../store';

export function useLogin() {
  const { add } = useNotifications();

  const [mutation] = useLoginMutation();

  const dispatch = useAppDispatch();

  return useCallback(
    (data: LoginRequest) => {
      dispatch(loading());

      mutation(data)
        .unwrap()
        .then(() => {
          dispatch(login());
        })
        .catch((err) => {
          err.status === 401
            ? add({
                id: new Date().toISOString(),
                title: 'ERROR',
                description: 'Error: Incorrect credentials. Please, try again.',
              })
            : add({
                id: new Date().toISOString(),
                title: 'ERROR',
                description: 'There was an error logging in. Please try again later.',
              });
          console.error('Failed to login:', err);
          dispatch(error(err));
        });
    },
    [add, mutation, dispatch]
  );
}
