import { AnimationShell, Typography, TypographyProps } from '@/components';
import { cn } from '@/utils/styles';

export type HintProps = TypographyProps;

export function Hint({
  children,
  family = 'sans',
  color = 'secondary',
  size = 'body-3',
  weight = 'normal',
  align = 'left',
  as,
  className,
}: HintProps) {
  return (
    <AnimationShell type="slide-right">
      <Typography
        family={family}
        color={color}
        size={size}
        weight={weight}
        align={align}
        as={as}
        className={cn('whitespace-pre-wrap', className)}
      >
        {children}
      </Typography>
    </AnimationShell>
  );
}
