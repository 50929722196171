import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { displayQuarter, mw, mwhmwp, mwn, mwp } from '@/helpers';
import { useDevelopmentStatus, useTechnologies } from '@/hooks';
import { isEmpty, isNullish } from '@/types';

import { useCollapsableAction, useLocation, useProjectDetails } from '../../../hooks';
import { BessItem, SolarItem, Technology, WindItem } from '../../../types';

export type GeneralCollapsableProps = {
  readonly technology: Technology;
  readonly initialExpandedState?: boolean;
  readonly onEdit?: () => void;
};

export function GeneralCollapsable({ technology, onEdit, initialExpandedState = false }: GeneralCollapsableProps) {
  const { t } = useTranslation(['projects']);
  const { normalizedProject: project } = useProjectDetails(technology.project);

  const { map: mapTechnology } = useTechnologies();
  const { map: mapDevelopmentStatus } = useDevelopmentStatus();

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const action = useCollapsableAction({ onEdit, setIsExpanded });

  const status = technology.details.developmentStatus.status;
  const location = useLocation({ location: project ? [project.location] : [] });
  const description = onEdit
    ? t(
        'Please fill in this section with the main details about the project, including technology, development status, location, installed capacity, ready-to-build (RtB) and COD dates, etc.'
      )
    : t(
        'This section includes the main details about the project, including technology, development status, location, installed capacity, ready-to-build (RtB) and COD dates, etc.'
      );
  const header = (
    <CollapsableHeader
      title={t('General Information')}
      description={description}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
      action={action}
    />
  );

  const data = useMemo(() => {
    let item = null;
    switch (technology.collection) {
      case 'projects_solar_technologies':
        item = technology.details.item as SolarItem;
        return [
          { label: t('Technology'), value: mapTechnology('solar') },
          { label: t('Development status'), value: mapDevelopmentStatus(status) },
          { label: t('Location'), value: location },
          { label: t('Peak Installed Capacity'), value: mwp(item.installedCapacityPeak) },
          { label: t('Nominal Installed Capacity'), value: mwn(item.installedCapacityNominal) },
          { label: t('Ready to Build Date'), value: displayQuarter(item.readyToBuild) },
          {
            label: t('Commercial Operation Date'),
            value: displayQuarter(item.commercialOperationDate),
          },
          { label: t('Specific production'), value: mwhmwp(item.specificProduction) },
          {
            label: t('Comments'),
            value: item.generalComments,
          },
        ];
      case 'projects_wind_technologies':
        item = technology.details.item as WindItem;
        return [
          { label: t('Technology'), value: mapTechnology('wind') },
          { label: t('Development status'), value: mapDevelopmentStatus(status) },
          { label: t('Location'), value: location },
          { label: t('Installed Capacity'), value: mw(item.installedCapacity) },
          { label: t('Ready to Build Date'), value: displayQuarter(item.readyToBuild) },
          {
            label: t('Commercial Operation Date'),
            value: displayQuarter(item.commercialOperationDate),
          },
          { label: t('Specific production'), value: mwhmwp(item.specificProduction) },
          {
            label: t('Comments'),
            value: item.generalComments,
          },
        ];
      case 'projects_bess_technologies':
        item = technology.details.item as BessItem;
        return [
          { label: t('Technology'), value: mapTechnology('bess') },
          { label: t('Development status'), value: mapDevelopmentStatus(status) },
          { label: t('Location'), value: location },
          { label: t('Installed Capacity'), value: mw(item.installedCapacity) },
          { label: t('Ready to Build Date'), value: displayQuarter(item.readyToBuild) },
          {
            label: t('Commercial Operation Date'),
            value: displayQuarter(item.commercialOperationDate),
          },
          {
            label: t('Comments'),
            value: item.generalComments,
          },
        ];
      default:
        return [];
    }
  }, [t, technology, mapTechnology, mapDevelopmentStatus, location, status]);

  const filteredData = isNullish(onEdit) ? data.filter(({ value }) => !(isNullish(value) || isEmpty(value))) : data;

  return filteredData.length ? (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody data={filteredData} />
    </Collapsable>
  ) : null;
}
