import { Contact, Service, ServiceCredential, ServiceItem } from '../types';

export function serviceDetailsTransformer(service: Service) {
  return {
    id: service.id,
    status: service.status,
    name: service.name,
    description: service.description,
    logo: service.logo?.id ?? null,
    category: {
      id: service.category.code,
      name: service.category.labels.find((label) => label.language === 'en-US')?.label ?? '',
      order: service.category.order,
    },
    services: service.items.map(serviceItemTransformer),
    documents: service.documents.map((document) => document.file),
    credentials: service.credentials.map(serviceCredentialTransformer),
    primaryPhone: service.primary_phone_number,
    secondaryPhone: service.secondary_phone_number,
    website: service.website,
    linkedin: service.linkedin,
    address: service.address,
    contacts: service.contacts.map(serviceContactTransformer),
    presentationId: service.documents.find((document) => document.type === 'presentation')?.file,
  };
}

const serviceItemTransformer = (serviceItem: ServiceItem) => {
  return {
    id: serviceItem.id,
    status: serviceItem.status,
    title: serviceItem.title,
    company: serviceItem.company,
    description: serviceItem.description,
  };
};

const serviceCredentialTransformer = (serviceCredential: ServiceCredential) => {
  return {
    id: serviceCredential.id,
    status: serviceCredential.status,
    order: serviceCredential.order,
    company: serviceCredential.company,
    title: serviceCredential.title,
    description: serviceCredential.description,
  };
};

const serviceContactTransformer = (serviceContact: Contact) => {
  return {
    status: serviceContact.status,
    order: serviceContact.order,
    company: serviceContact.company,
    firstName: serviceContact.first_name,
    lastName: serviceContact.last_name,
    fullName: [serviceContact.first_name, serviceContact.last_name].join(' '),
    email: serviceContact.email_address,
    avatar: serviceContact.avatar,
    position: serviceContact.position,
    linkedin: serviceContact.linkedin,
  };
};
