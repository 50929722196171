import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useRemoveFileMutation, useRemoveAllFilesMutation } from '@/features/files';
import { useNotifications } from '@/features/notifications';

export function useRemoveAttachment() {
  const [removeFile] = useRemoveFileMutation();
  const [removeAllFiles] = useRemoveAllFilesMutation();
  const { t } = useTranslation(['projects']);
  const { add } = useNotifications();

  const deleteAttachment = useCallback(
    (id: string | null, onSuccess?: () => void) => {
      if (!id) return;

      removeFile({ id })
        .unwrap()
        .then(() => {
          onSuccess?.();
          add({
            id: new Date().toString(),
            title: t('File delete.'),
            description: t('Attachment removed successfully'),
          });
        })
        .catch(() => {
          add({
            id: new Date().toString(),
            title: t('ERROR'),
            description: t('There was an error removing the file. Please try again later.'),
          });
        });
    },
    [removeFile, t, add]
  );

  const deleteAllAttachments = useCallback(
    (ids: string[], onSuccess?: () => void) => {
      if (!ids.length) return;

      removeAllFiles(ids)
        .unwrap()
        .then(() => {
          onSuccess?.();
          add({
            id: new Date().toString(),
            title: t('Files deleted.'),
            description: t('All attachments removed successfully'),
          });
        })
        .catch(() => {
          add({
            id: new Date().toString(),
            title: t('ERROR'),
            description: t('There was an error removing the files. Please try again later.'),
          });
        });
    },
    [removeAllFiles, t, add]
  );

  return { deleteAttachment, deleteAllAttachments };
}
