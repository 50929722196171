import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Field, Form, Input, Select, TextArea, Typography } from '@/components';
import { ASSETS_BASE_URL } from '@/config';
import { ImageUpload } from '@/features/files';
import { Languages, UserSchema } from '@/features/users';
import { nullableStringSetter } from '@/helpers';
import { useLanguages } from '@/hooks/use-language';

import { useUploadAvatar } from '../../hooks';

export type BasicInfoFormProps = {
  readonly user: UserSchema;
  readonly onSubmit: (user: BasicInfoFormFields) => void;
};

export type BasicInfoFormFields = {
  avatar: string | null;
  first_name: string;
  last_name: string;
  title: string | null;
  description: string | null;
  language: Languages;
  phone_number: string | null;
  //   password?: string;
};
export function BasicInfoForm({ user, onSubmit }: BasicInfoFormProps) {
  const { t } = useTranslation(['settings']);

  const uploadAvatar = useUploadAvatar();

  const options = useMemo(
    () => ({
      defaultValues: {
        avatar: user.avatar,
        first_name: user.first_name,
        last_name: user.last_name,
        title: user.title,
        description: user.description,
        language: user.language ?? 'en-US',
        phone_number: user.phone_number,
      },
    }),
    [user]
  );

  const { options: languagesOptions } = useLanguages();

  return (
    <Form<BasicInfoFormFields> id="basic-info-form" onSubmit={onSubmit} options={options}>
      {({ formState, register, watch, setValue }) => (
        <div className="space-y-6">
          <Typography as="h5" color="primary" size="title" weight="normal">
            {t('Basic info')}
          </Typography>

          <Field label={t('Avatar')} delay={0} classes={{ field: 'space-y-1' }}>
            <ImageUpload
              baseUrl={ASSETS_BASE_URL}
              value={watch('avatar')}
              onSelectFile={(file) => {
                uploadAvatar(file, (data) => {
                  setValue('avatar', data.id);
                });
              }}
            />
          </Field>

          <div className="flex w-full space-x-3">
            <Field
              id="first_name"
              label={t('Name')}
              error={formState.errors['first_name']}
              delay={0}
              classes={{ field: 'space-y-1 w-1/2' }}
            >
              <Input
                id="first_name"
                color={formState.errors['first_name'] ? 'error' : 'primary'}
                registration={register('first_name', {
                  required: t('This field is required'),
                  setValueAs: nullableStringSetter,
                })}
              />
            </Field>

            <Field
              id="last_name"
              label={t('Last Name')}
              error={formState.errors['last_name']}
              delay={0}
              classes={{ field: 'space-y-1 w-1/2' }}
            >
              <Input
                id="last_name"
                color={formState.errors['last_name'] ? 'error' : 'primary'}
                registration={register('last_name', {
                  required: t('This field is required'),
                  setValueAs: nullableStringSetter,
                })}
              />
            </Field>
          </div>

          <Field
            id="phone_number"
            label={t('Phone number')}
            error={formState.errors['phone_number']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="phone_number"
              type="tel"
              color={formState.errors['phone_number'] ? 'error' : 'primary'}
              registration={register('phone_number')}
            />
          </Field>

          <Field
            id="title"
            label={t('Title')}
            error={formState.errors['title']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input id="title" placeholder="e.g. Director comercial" registration={register('title')} />
          </Field>

          <Field
            id="bio"
            label={t('Bio')}
            error={formState.errors['description']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="bio"
              placeholder="Type here..."
              registration={register('description', {
                setValueAs: nullableStringSetter,
              })}
            />
          </Field>

          <Field
            id="language"
            label={t('Language')}
            error={formState.errors['language']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Select
              id="language"
              color={formState.errors['language'] ? 'error' : 'primary'}
              registration={register('language')}
              options={languagesOptions}
              className="w-full"
            />
          </Field>

          <div className="flex justify-end">
            <Button type="submit" size="lg" color="primary">
              {t('Save changes')}
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
}
