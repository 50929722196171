import { useTranslation } from 'react-i18next';

import { Button, Typography, RejectOffer } from '@/components';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from '@/components/dialog';

export type DeclineOfferModalProps = {
  readonly open?: boolean;
  readonly setOpen?: (state: boolean) => void;
  readonly onClose: () => void;
  readonly onDeclineOffer: () => void;
};

export function DeclineOfferModal({ open = false, setOpen, onClose, onDeclineOffer }: DeclineOfferModalProps) {
  const { t } = useTranslation(['offers']);

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogPortal>
          <DialogOverlay />
          <DialogContent onPointerDownOutside={(event) => event.preventDefault()}>
            <DialogHeader>
              <DialogTitle>{t('Decline offer')}</DialogTitle>
            </DialogHeader>
            <div className=" my-6 items-center justify-center text-center">
              <RejectOffer className="mx-auto w-40" />
              <Typography as="h5" size="title" color="primary" weight="normal" className=" text-center">
                {t('Are you sure you want to decline this offer?')}
              </Typography>
              <Typography as="p" size="body-3" color="secondary" weight="normal" className="text-center">
                {t('You are about to decline this offer. Consider it carefully, as this action cannot be undone.')}
              </Typography>
            </div>

            <DialogFooter>
              <div className="flex space-x-4">
                <Button type="button" color="neutral" onClick={onClose}>
                  {t('Close')}
                </Button>
                <Button type="button" color="secondary" icon="thumb-down" onClick={onDeclineOffer}>
                  {t('Decline')}
                </Button>
              </div>
            </DialogFooter>
          </DialogContent>
        </DialogPortal>
      </Dialog>
    </>
  );
}
