import { OfferStatus } from '../types';

export function getColorFromOfferStatus(status: OfferStatus) {
  switch (status) {
    case 'pending':
      return 'warning';
    case 'published':
      return 'info';
    case 'accepted':
      return 'display';
    case 'rejected':
      return 'error';
    default:
      return 'default';
  }
}
