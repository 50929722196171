import { MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Route } from '@/constants';

export function useOnClickViewOpportunities() {
  const navigate = useNavigate();

  return useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      navigate(Route.Home);
    },
    [navigate]
  );
}
