export const BILLION = 1.0e9;
export const MILLION = 1.0e6;
export const THOUSAND = 1.0e3;

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export * from './languages';
export * from './route';
export * from './time-zones';
