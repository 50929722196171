import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Form, Input, TextArea, Typography } from '@/components';
import { BESSProjectTechnology } from '@/features/projects/types';
import { nullableStringSetter } from '@/helpers';

export type BESSBasicDesignFormFields = {
  storage_technology: string | null;
  storage_capacity: number | null;
  hours_cycles: number | null;
  basic_design_comments: string | null;
};

export type BESSBasicDesignFormProps = {
  readonly id: string;
  readonly item: BESSProjectTechnology;
  readonly onSubmit: (data: BESSBasicDesignFormFields) => void;
};

export function BESSBasicDesignForm({ id, item, onSubmit }: BESSBasicDesignFormProps) {
  const { t } = useTranslation(['projects']);

  const options = useMemo(
    () => ({
      defaultValues: {
        storage_technology: item?.storage_technology ?? null,
        storage_capacity: item?.storage_capacity ?? null,
        hours_cycles: item?.hours_cycles ?? null,
        basic_design_comments: item?.basic_design_comments ?? null,
      },
    }),
    [item]
  );

  return (
    <Form<BESSBasicDesignFormFields> id={id} onSubmit={onSubmit} options={options}>
      {({ formState, register }) => (
        <div className="space-y-6">
          <Typography as="p" color="secondary" size="body-3" weight="normal" className="mt-1 italic">
            {t(
              'Please complete this section with the main information of the plant design and technology, including BESS technology, storage capacity, hours cycles, and any additional comments.'
            )}
          </Typography>
          <Field
            id="storage_technology"
            label={t('Storage technology')}
            error={formState.errors['storage_technology']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="storage_technology"
              type="text"
              color={formState.errors['storage_technology'] ? 'error' : 'primary'}
              registration={register('storage_technology')}
            />
          </Field>

          <Field
            id="storage_capacity"
            label={t('Storage capacity')}
            error={formState.errors['storage_capacity']}
            delay={0.15}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="storage_capacity"
              type="number"
              step="0.1"
              suffix="MWh"
              color={formState.errors['storage_capacity'] ? 'error' : 'primary'}
              registration={register('storage_capacity', { min: 0, valueAsNumber: true })}
            />
          </Field>

          <Field
            id="hours_cycles"
            label={t('Hours cycles')}
            error={formState.errors['hours_cycles']}
            delay={0.3}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="hours_cycles"
              type="number"
              suffix="h"
              color={formState.errors['hours_cycles'] ? 'error' : 'primary'}
              registration={register('hours_cycles', { min: 0, valueAsNumber: true })}
            />
          </Field>

          <Field
            id="comments"
            label={t('Comments')}
            error={formState.errors['basic_design_comments']}
            delay={0.45}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="comments"
              color={formState.errors['basic_design_comments'] ? 'error' : 'primary'}
              registration={register('basic_design_comments', { setValueAs: nullableStringSetter })}
            />
          </Field>
        </div>
      )}
    </Form>
  );
}
