import { defaultAnimationOpacity } from '../constants';
import { AnimationType } from '../types';

export function getOpacityAnimation(delay: number = 0, duration: number = 0.5) {
  return {
    ...defaultAnimationOpacity,
    transition: { delay, duration },
  };
}

export function getRotateAnimation() {
  return {
    animate: { rotate: [0, 360] },
    transition: { repeat: Infinity, duration: 2 },
  };
}

export function getScaleAnimation(delay: number = 0, duration: number = 0.2) {
  return {
    initial: { scale: 0 },
    animate: { scale: 1 },
    exit: { scale: 0 },
    transition: { delay, duration },
  };
}

export function getLoadingAnimation(delay: number = 0, duration: number = 1.2) {
  return {
    animate: { scale: [1, 0.8, 1] },
    transition: { repeat: Infinity, delay, duration },
  };
}

export function getTooltipAnimation(delay: number = 0, duration: number = 0.2) {
  return {
    ...defaultAnimationOpacity,
    initial: { y: 10 },
    animate: { y: 0 },
    transition: { delay, duration },
  };
}

export function getSlideLeftAnimation(delay: number = 0, duration: number = 0.5) {
  return {
    ...defaultAnimationOpacity,
    initial: { x: '-25px' },
    animate: { x: 0 },
    transition: { delay, duration },
  };
}

export function getSlideRightAnimation(delay: number = 0, duration: number = 0.5) {
  return {
    ...defaultAnimationOpacity,
    initial: { x: '25px' },
    animate: { x: 0 },
    transition: { delay, duration },
  };
}

export function getSlideUpAnimation(delay: number = 0, duration: number = 0.5) {
  return {
    ...defaultAnimationOpacity,
    initial: { y: '50px' },
    animate: { y: 0 },
    transition: { delay, duration },
  };
}

export function getSlideDownAnimation(delay: number = 0, duration: number = 0.5) {
  return {
    ...defaultAnimationOpacity,
    initial: { y: '-50px' },
    animate: { y: 0 },
    transition: { delay, duration },
  };
}

export function getAnimationProps(type: AnimationType, delay?: number, duration?: number) {
  switch (type) {
    case 'opacity':
      return getOpacityAnimation(delay, duration);
    case 'rotate':
      return getRotateAnimation();
    case 'scale':
      return getScaleAnimation(delay, duration);
    case 'loading':
      return getLoadingAnimation(delay, duration);
    case 'tooltip':
      return getTooltipAnimation(delay, duration);
    case 'slide-left':
      return getSlideLeftAnimation(delay, duration);
    case 'slide-right':
      return getSlideRightAnimation(delay, duration);
    case 'slide-up':
      return getSlideUpAnimation(delay, duration);
    case 'slide-down':
      return getSlideDownAnimation(delay, duration);
    default:
      return {};
  }
}
