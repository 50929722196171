import { ChangeEvent, useCallback, useMemo } from 'react';

import { Icon } from '@/components';
import { cn } from '@/utils/styles';

export type ImageUploadProps = {
  readonly baseUrl: string;
  readonly value: string | null;
  readonly onSelectFile?: (file: File | null) => void;
  readonly square?: boolean;
};

export function ImageUpload({ baseUrl, value, onSelectFile, square = false }: ImageUploadProps) {
  const url = useMemo(() => (value ? `${baseUrl}/${value}` : null), [value, baseUrl]);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files ? event.target.files[0] : null;
      onSelectFile?.(file);
    },
    [onSelectFile]
  );

  return (
    <label
      className={cn(
        'border border-dashed border-neutral-50 text-center',
        'h-28 w-28',
        square ? 'rounded-md' : 'rounded-full',
        'flex flex-col items-center justify-center',
        'cursor-pointer transition hover:border-base-primary'
      )}
    >
      {url ? (
        <img src={url} className={cn('h-full w-full', square ? 'rounded-md' : 'rounded-full')} alt="uploaded" />
      ) : (
        <Icon name="camera-no-picture" className="h-12 w-12 text-neutral-500" />
      )}

      <input type="file" className="hidden" onChange={handleOnChange} />
    </label>
  );
}
