import { api } from '@/features/auth';

import {
  GetServicesResponse,
  GetServicesCategoriesResponse,
  GetServicesTagsResponse,
  GetServiceResponse,
  GetServiceRequest,
} from './types';

export const serviceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getServicesCategories: builder.query<GetServicesCategoriesResponse['data'], void>({
      query: () => ({
        url: `items/service_categories`,
        method: 'GET',
        params: {
          fields: '*, labels.*',
          filter: `{"status": {"_eq": "published"}}`,
          sort: 'order',
        },
      }),
      transformResponse: ({ data }: GetServicesCategoriesResponse) => data,
      // providesTags: [{ type: 'ServicesCategories', id: 'all' }],
    }),
    getServicesTags: builder.query<GetServicesTagsResponse['data'], void>({
      query: () => ({
        url: `items/service_tags`,
        method: 'GET',
        params: {
          fields: '*, labels.*',
          filter: `{"status": {"_eq": "published"}}`,
        },
      }),
      transformResponse: ({ data }: GetServicesTagsResponse) => data,
      // providesTags: [{ type: 'ServicesCategories', id: 'all' }],
    }),
    getServices: builder.query<GetServicesResponse['data'], void>({
      query: () => ({
        url: `items/service_companies`,
        method: 'GET',
        params: {
          fields: '*, *.*, categories.*, categories.labels.*, tags.*, tags.labels.*',
          filter: `{"status": {"_eq": "published"}}`,
          sort: '-created_at',
        },
      }),
      transformResponse: ({ data }: GetServicesResponse) => data,
      // providesTags: (_result, _error, args) => [{ type: 'Offers', id: args.id }],
    }),
    getService: builder.query<GetServiceResponse['data'], GetServiceRequest>({
      query: ({ id }) => ({
        url: `items/service_companies/${id}`,
        method: 'GET',
        params: {
          fields: '*, *.*, category.*, category.labels.*, tags.*, tags.labels.*',
          filter: `{"status": {"_eq": "published"}}`,
        },
      }),
      transformResponse: ({ data }: GetServiceResponse) => data,
    }),
  }),
});

export const { useGetServicesQuery, useGetServicesCategoriesQuery, useGetServicesTagsQuery, useGetServiceQuery } =
  serviceApi;
