import { Navigate, Outlet, useSearchParams } from 'react-router-dom';

import { AuthLayout } from '@/features/auth';
import { useTokenParam } from '@/hooks';
import { useAppSelector } from '@/store';

export function AuthPage() {
  const [searchParams] = useSearchParams();

  const token = useTokenParam();

  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

  if (isLoggedIn && !token) {
    return <Navigate to={searchParams.get('redirectTo') ?? '/'} />;
  }

  return (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  );
}
