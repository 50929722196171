import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Download({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="download"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.77366 8.40427C6.79026 5.96724 9.19057 4.25 12 4.25C15.493 4.25 18.3659 6.90253 18.7145 10.3034C20.9988 10.648 22.75 12.6196 22.75 15C22.75 17.6232 20.6232 19.75 18 19.75H7C3.82479 19.75 1.25 17.1752 1.25 14C1.25 11.2423 3.1937 8.9661 5.77366 8.40427ZM12 5.75C9.69501 5.75 7.73911 7.23814 7.034 9.30967C6.94247 9.57856 6.70689 9.77268 6.42546 9.81111C4.3475 10.0948 2.75 11.8555 2.75 14C2.75 16.3468 4.65321 18.25 7 18.25H18C19.7948 18.25 21.25 16.7948 21.25 15C21.25 13.2052 19.7948 11.75 18 11.75C17.5858 11.75 17.25 11.4142 17.25 11C17.25 8.10021 14.8998 5.75 12 5.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.30268 12.8027C9.59557 12.5098 10.0704 12.5098 10.3633 12.8027L12 14.4393L13.6367 12.8027C13.9296 12.5098 14.4044 12.5098 14.6973 12.8027C14.9902 13.0956 14.9902 13.5704 14.6973 13.8633L12.5303 16.0303C12.2374 16.3232 11.7626 16.3232 11.4697 16.0303L9.30268 13.8633C9.00978 13.5704 9.00978 13.0956 9.30268 12.8027Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.75C12.4142 9.75 12.75 10.0858 12.75 10.5V15.5C12.75 15.9142 12.4142 16.25 12 16.25C11.5858 16.25 11.25 15.9142 11.25 15.5V10.5C11.25 10.0858 11.5858 9.75 12 9.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
