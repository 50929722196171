import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Phone({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      viewBox="0 0 48 48"
      height="24"
      width="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="plus"
    >
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        d="M45 13c0 -5.523 -4.477 -10 -10 -10h-0.569a3 3 0 0 0 -2.785 1.886L29.18 11.05a4 4 0 0 0 0.885 4.314l3.077 3.077c0.493 0.493 0.7 1.21 0.498 1.878a20.045 20.045 0 0 1 -13.32 13.32c-0.668 0.203 -1.385 -0.005 -1.878 -0.498l-3.077 -3.077a4 4 0 0 0 -4.314 -0.885l-6.165 2.466A3 3 0 0 0 3 34.43V35c0 5.523 4.477 10 10 10h0.5c17.11 0 31.034 -13.642 31.489 -30.642 0 -0.005 0.002 -0.01 0.005 -0.013a0.018 0.018 0 0 0 0.006 -0.013V13Z"
        strokeWidth="3"
      ></path>
    </svg>
  );
}
