import { AnimatePresence } from 'framer-motion';

import { MenuItem, MenuItemProps } from './menu-item';
import { Typography } from '../typography';
import { cn } from '@/utils/styles';

export type MenuItems = {
  title: string;
  menuitems: MenuItemProps[];
}[];

export type MenuProps = {
  readonly collapsed: boolean;
  readonly items: MenuItems;
};

export function Menu({ collapsed, items }: MenuProps) {
  return (
    <nav>
      <div className="w-full space-y-4">
        <AnimatePresence>
          {items.map(({ title, menuitems }, index) => (
            <div key={`${title}-${index}`}>
              {title && (
                <Typography
                  as="h3"
                  size="body-3"
                  color="white"
                  weight="semibold"
                  className={cn('mb-2 truncate uppercase opacity-70', collapsed ? 'opacity-0' : 'block')}
                >
                  {title}
                </Typography>
              )}
              <ul className="w-full space-y-2">
                {menuitems.map(({ to, label, icon, active }, index) => (
                  <MenuItem
                    key={to}
                    to={to}
                    label={label}
                    icon={icon}
                    active={active}
                    collapsed={collapsed}
                    index={index}
                  />
                ))}
              </ul>
            </div>
          ))}
        </AnimatePresence>
      </div>
    </nav>
  );
}
