import { useTranslation } from 'react-i18next';

import { Button, Typography, AcceptOffer } from '@/components';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from '@/components/dialog';

export type AcceptOfferModalProps = {
  readonly open?: boolean;
  readonly setOpen?: (state: boolean) => void;
  readonly onClose: () => void;
  readonly onAcceptOffer: () => void;
};

export function AcceptOfferModal({ open = false, setOpen, onClose, onAcceptOffer }: AcceptOfferModalProps) {
  const { t } = useTranslation(['offers']);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent onPointerDownOutside={(event) => event.preventDefault()}>
          <DialogHeader>
            <DialogTitle>{t('Accept offer')}</DialogTitle>
          </DialogHeader>
          <div className="my-6 items-center justify-center">
            <AcceptOffer className="mx-auto my-8 w-40" />
            <Typography as="h5" size="title" color="primary" weight="normal" className="text-center">
              {t('Are you sure you want to accept this offer?')}
            </Typography>
            <Typography as="p" size="body-3" color="secondary" weight="normal" className="text-center">
              {t(
                'You can accept up to three offers, and it cannot be undone. Once accepted, we will schedule a meeting to go through the details of the offer.'
              )}
            </Typography>
          </div>

          <DialogFooter>
            <div className="flex space-x-4">
              <Button type="button" color="neutral" onClick={onClose}>
                {t('Close')}
              </Button>
              <Button type="button" color="primary" icon="thumb-up" onClick={() => onAcceptOffer()}>
                {t('Accept')}
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
