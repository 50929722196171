import { useTranslation } from 'react-i18next';

import { Button, Drawer } from '@/components';

import { TeamForm, UserFormFields } from './form';

export type TeamDrawerProps = {
  readonly isOpen: boolean;
  readonly close: () => void;
  readonly onSubmit: (data: UserFormFields) => void;
};

export function TeamDrawer({ isOpen, close, onSubmit }: TeamDrawerProps) {
  const { t } = useTranslation(['team']);

  const footer = (
    <>
      <Button size="lg" color="neutral" onClick={close}>
        {t('Cancel')}
      </Button>
      <Button type="submit" form="invite-member-form" size="lg" color="primary">
        {t('Invite')}
      </Button>
    </>
  );

  return (
    <Drawer title={t('Invite a member')} open={isOpen} onClose={close} footer={footer}>
      <TeamForm onSubmit={onSubmit} />
    </Drawer>
  );
}
