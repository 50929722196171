import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@/components';
import { cn } from '@/utils/styles';
import { TransactionDetails } from '../../types';
import { TransactionListItems } from './transaction-list-items';

export type PaginatedTransactionsListProps = {
  readonly items: TransactionDetails[];
};

export function PaginatedTransactionsList({ items }: PaginatedTransactionsListProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = searchParams.get('page') ? parseInt(searchParams.get('page') ?? '1') : 1;

  const [activePage, setActivePage] = useState(initialPage);
  const pages = Math.ceil(items.length / 15);
  const itemsPerPage = 15;
  const displayItems = items.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage);
  const pagesIndexList = [...Array(pages).keys()];
  const paginationList =
    pagesIndexList.length > 5
      ? pagesIndexList.splice(
          activePage < 3 ? 0 : activePage > pages - 3 ? pages - 5 : activePage - 3, // Only visible 5 links in pagination
          5
        )
      : pagesIndexList;

  const updatePage = (page: number) => {
    setActivePage(page);
    setSearchParams((searchParams) => {
      searchParams.set('page', page.toString());
      return searchParams;
    });
  };

  return (
    <>
      <TransactionListItems transactions={displayItems} />
      <div className="mt-8 flex justify-center">
        <Button
          onClick={() => updatePage(1)}
          color="neutral"
          className="mr-2 bg-neutral-25 enabled:hover:bg-neutral-50"
          disabled={activePage === 1}
        >
          <span>{'<<'}</span>
        </Button>
        {paginationList.map((index) => (
          <Button
            key={index}
            onClick={() => updatePage(index + 1)}
            color="neutral"
            className={cn(
              'mr-2 bg-neutral-25 enabled:hover:bg-neutral-50',
              index + 1 === activePage ? 'bg-neutral-50' : ''
            )}
            disabled={index + 1 === activePage}
          >
            {index + 1}
          </Button>
        ))}
        <Button
          onClick={() => updatePage(pages)}
          color="neutral"
          className="mr-2 bg-neutral-25  enabled:hover:bg-neutral-50"
          disabled={activePage === pages}
        >
          <span>{'>>'}</span>
        </Button>
      </div>
    </>
  );
}
