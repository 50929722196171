import { useTranslation } from 'react-i18next';

import { AnimationShell, CustomMarkdown, Typography } from '@/components';

import { ServiceDetails } from '../../types';

export type ServiceDescriptionProps = {
  readonly service: ServiceDetails;
};

export function ServiceDescription({ service }: ServiceDescriptionProps) {
  const { t } = useTranslation(['services']);

  return (
    <section>
      <AnimationShell type="slide-left" className="flex flex-col space-y-4">
        <Typography as="h4" size="heading-2" color="primary" weight="semibold">
          {t('Description')}
        </Typography>
        <CustomMarkdown text={service.description} />
      </AnimationShell>
    </section>
  );
}
