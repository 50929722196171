import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function MyProjects({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="my-projects"
    >
      <path
        d="M7.00001 21.8C4.40001 21.8 2.20001 19.7 2.20001 17C2.20001 14.3 4.30001 12.2 7.00001 12.2C9.70001 12.2 11.8 14.3 11.8 17C11.8 19.7 9.60001 21.8 7.00001 21.8ZM7.00001 13.8C5.20001 13.8 3.80001 15.3 3.80001 17C3.80001 18.7 5.30001 20.2 7.00001 20.2C8.70001 20.2 10.2 18.7 10.2 17C10.2 15.3 8.80001 13.8 7.00001 13.8Z"
        fill="currentColor"
      />
      <path
        d="M19 21.8H7.00001C6.60001 21.8 6.20001 21.5 6.20001 21C6.20001 20.5 6.50001 20.2 7.00001 20.2H19C19.7 20.2 20.2 19.6 20.2 19V8.00001C20.2 7.30001 19.6 6.80001 19 6.80001H11C10.6 6.80001 10.2 6.50001 10.2 6.00001C10.2 5.50001 10.5 5.20001 11 5.20001H19C20.5 5.20001 21.8 6.40001 21.8 8.00001V19C21.8 20.5 20.5 21.8 19 21.8Z"
        fill="currentColor"
      />
      <path
        d="M11 17.6C10.6 17.6 10.2 17.3 10.2 16.8V6.00001C10.2 4.20001 8.70001 2.80001 7.00001 2.80001C5.30001 2.80001 3.80001 4.20001 3.80001 6.00001V16.8C3.80001 17.2 3.50001 17.6 3.00001 17.6C2.50001 17.6 2.20001 17.3 2.20001 16.8V6.00001C2.20001 3.40001 4.30001 1.20001 7.00001 1.20001C9.70001 1.20001 11.8 3.30001 11.8 6.00001V16.8C11.8 17.2 11.4 17.6 11 17.6Z"
        fill="currentColor"
      />
      <path
        d="M7.00001 17.8C6.60001 17.8 6.20001 17.4 6.20001 17C6.20001 16.6 6.60001 16.2 7.00001 16.2C7.40001 16.2 7.80001 16.6 7.80001 17C7.80001 17.4 7.40001 17.8 7.00001 17.8Z"
        fill="currentColor"
      />
    </svg>
  );
}
