import { api } from '@/features/auth';

import { GetDealsResponse, GetDealRequest, GetDealResponse } from './types';

export const dealsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    
    getDeals: builder.query<GetDealsResponse['data'], void>({
      query: () => ({
        url: `items/deals`,
        method: 'GET',
        params: {
          filter: `{"status": {"_eq": "published"}}`,
        },
      }),
      transformResponse: ({ data }: GetDealsResponse) => data,
      providesTags: [{ type: 'Deals', id: 'me' }],
    }),

    getDeal: builder.query<GetDealResponse['data'], GetDealRequest>({
      query: ({ id }: GetDealRequest) => ({
        url: `items/deals/${id}`,
        method: 'GET',
        params: {
          fields: '*,attachments.*.*',
        },
      }),
      transformResponse: ({ data }: GetDealResponse) => data,
      providesTags: (_result, _error, args) => [{ type: 'Deals', id: args.id }],
    }),
  }),
});

export const { useGetDealQuery, useGetDealsQuery } = dealsApi;

export type { GetDealsResponse, GetDealRequest } from './types';
