import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LOGO_FOLDER_ID } from '@/config';
import { FileSchema, useUploadFileMutation } from '@/features/files';
import { useNotifications } from '@/features/notifications';

import { useUpdateCompanyMutation } from '../api';

export type UseUploadLogoProps = {
  id: string;
};

export function useUploadLogo({ id }: UseUploadLogoProps) {
  const [updateCompany] = useUpdateCompanyMutation();
  const [uploadFile] = useUploadFileMutation();
  const { t } = useTranslation(['company']);
  const { add } = useNotifications();

  return useCallback(
    (file: File | null, onSuccess?: (file: FileSchema) => void) => {
      if (!id) return;
      if (!file) return;

      uploadFile({ file, folder: LOGO_FOLDER_ID })
        .unwrap()
        .then((data) => {
          onSuccess?.(data);
          updateCompany({ id, data: { logo: data?.id } });
          add({ id: new Date().toString(), title: t('Logo uploaded'), description: t('Your logo has been uploaded') });
        })
        .catch(() => {
          add({
            id: new Date().toString(),
            title: t('ERROR'),
            description: t('There was an error uploading the logo. Please try again later.'),
          });
        });
    },
    [id, uploadFile, updateCompany, t, add]
  );
}
