import { ReactNode } from 'react';

import { AnimationShell, Link, Typography } from '@/components';
import { cn } from '@/utils/styles';

export type EmptyStateProps = {
  readonly title: string;
  readonly description?: string;
  readonly email?: string;
  readonly size?: 'sm' | 'lg';
  readonly artwork?: ReactNode;
  readonly children?: ReactNode;
};

export function EmptyState({ title, description, email, size = 'lg', artwork, children }: EmptyStateProps) {
  const largeSize = size === 'lg';

  return (
    <AnimationShell type="slide-up" className="flex flex-col items-center justify-center space-y-8">
      {artwork ? (
        <AnimationShell type="opacity" delay={0.15}>
          {artwork}
        </AnimationShell>
      ) : null}

      <AnimationShell
        type="opacity"
        delay={0.25}
        className={cn('mx-auto w-full space-y-2 text-center', largeSize ? 'max-w-2xl' : 'max-w-xl')}
      >
        <Typography
          as="h2"
          size={largeSize ? 'heading-2' : 'title'}
          color="primary"
          weight={largeSize ? 'semibold' : 'normal'}
          align="center"
        >
          {title}
        </Typography>

        {description ? (
          <Typography as="p" size="body-2" color="secondary" weight="normal" align="center">
            {description}
            {email ? <Link to={`mailto:${email}`}>{email}</Link> : null}
          </Typography>
        ) : null}
      </AnimationShell>

      {children ?? null}
    </AnimationShell>
  );
}
