import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableHeader, WorkOfficeDesk } from '@/components';

export function OpportunityQuestionsList() {
  const { t } = useTranslation(['opportunities']);
  const [isExpanded, setIsExpanded] = useState(true);

  const header = (
    <CollapsableHeader
      title={t('Q&A')}
      description={t(
        'We are working on a Q&A system for Business Users like you. Once available, you will be able to see all questions asked by potential buyers for this opportunity.'
      )}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );

  return (
    <Collapsable index={0} isExpanded={true} header={header}>
      <div className="flex w-full  gap-8 border-t border-neutral-50 p-6">
        <WorkOfficeDesk className="mx-auto w-64" />
      </div>
    </Collapsable>
  );
}
