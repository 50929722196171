import { opportunityItemListTransformer } from '@/features/opportunities/helpers';

import { Offer, OfferItemList, SaleOption } from '../types';

import { getColorFromOfferStatus } from './helpers';

export function offerItemListTransformer(offer: Offer): OfferItemList {
  return {
    id: offer.id,
    status: {
      name: offer.status,
      color: getColorFromOfferStatus(offer.status),
    },
    opportunity: opportunityItemListTransformer(offer.opportunity),
    company: offer.company.id,
    price: offer.price,
    option: offer.option as SaleOption,
    createdAt: new Date(offer.created_at),
  };
}
