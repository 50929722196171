import { useCallback } from 'react';

import { useAddOpportunityStatsMutation } from '../api';
import { AddOpportunityStatsRequest } from '../api/types';

export type useSendOpportunityStatsProps = {
  readonly type: string;
};

export function useSendOpportunityStats({ type }: useSendOpportunityStatsProps) {
  const [sendStats] = useAddOpportunityStatsMutation();

  return useCallback(
    (id: string) => {
      sendStats({
        opportunity: id,
        key: type,
      } as AddOpportunityStatsRequest);
    },
    [sendStats, type]
  );
}
