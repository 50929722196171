import { useMyOffersList } from '@/features/offers/hooks';
import { useRegisterGAPageView } from '@/hooks';

import { MyOffersEmptyState } from '../empty-state';
import { MyOffersItems } from '../items';

export function MyOffersContent() {
  const offers = useMyOffersList();
  useRegisterGAPageView('My offers list');
  if (!offers) return null;

  if (offers.length === 0) {
    return <MyOffersEmptyState />;
  }

  return <MyOffersItems offers={offers} />;
}
