import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Person({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="person"
    >
      <path
        d="M19 20.2C18.8762 20.2 18.8 20.1238 18.8 20C18.8 17.7238 16.8762 15.8 14.6 15.8H9.40005C7.12391 15.8 5.20005 17.7238 5.20005 20C5.20005 20.1238 5.12391 20.2 5.00005 20.2C4.87619 20.2 4.80005 20.1238 4.80005 20C4.80005 17.3761 6.87619 15.3 9.50005 15.3H14.6C17.2011 15.3 19.2639 17.3402 19.2996 19.9317C19.2325 20.1323 19.1019 20.2 19 20.2Z"
        stroke="currentColor"
      />
      <path
        d="M8.8716 11.3655L8.8628 11.3557L8.8535 11.3464C7.15507 9.64799 7.14096 6.76142 8.85803 4.94901C9.67438 4.13502 10.8486 3.59998 12 3.59998C13.1763 3.59998 14.3346 4.05258 15.1283 4.93446L15.13 4.93631C16.0592 5.95842 16.5 7.04224 16.5 8.19998C16.5 9.37637 16.0473 10.5346 15.1655 11.3283C14.2267 12.1732 13.1407 12.7 12 12.7C10.8236 12.7 9.66528 12.2474 8.8716 11.3655ZM9.17456 5.42035L9.15998 5.43284L9.1464 5.44642C7.55114 7.04168 7.55114 9.55827 9.1464 11.1535C10.6417 12.6488 13.2582 12.6488 14.7535 11.1535C15.556 10.3511 15.9 9.41974 15.9 8.29998C15.9 7.1494 15.4342 6.23829 14.7796 5.47458C14.0596 4.63456 13.0025 4.29998 12 4.29998C10.9561 4.29998 9.94463 4.76028 9.17456 5.42035Z"
        stroke="currentColor"
      />
    </svg>
  );
}
