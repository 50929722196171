import { getUrgencyFromDeadline } from '../helpers';
import { Deal, DealItemList } from '../types';

export function dealItemListTransformer(deal: Deal): DealItemList {
  
  const deadline = new Date(deal.deadline);
  const isPaid = deal.type.toLowerCase() === 'paid';

  return {
    id: deal.id,
    title: deal.title,
    country: deal.country,
    city: deal.city,
    technologies: deal.technologies,
    category: deal.category,
    deadline: {
      date: deadline,
      category: getUrgencyFromDeadline(deadline),
    },
    isPaid,
    status: {
      name: deal.status,
      color: 'info',
    },
  };
}
