import { BASE_URL } from '@/config';
import { Route } from '@/constants';
import { api } from '@/features/auth';
import { logout } from '@/features/auth/store';
import { store } from '@/store';

import { UserSchema } from '../types';

import { InviteUserRequest, UpdateUserRequest } from './types';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<UserSchema, void>({
      query: () => ({
        url: `users/me`,
        method: 'GET',
        params: {
          fields: [
            'id',
            'status',
            'first_name',
            'last_name',
            'phone_number',
            'email',
            'language',
            'avatar',
            'title',
            'description',
            'role.id',
            'role.name',
            'company.id',
            'company.name',
            'company.activity_interests',
            'company.technology_interests',
            'company.development_status_interests',
            'company.capacity_interests',
            'company.location_interests',
          ],
        },
      }),
      transformResponse: ({ data }) => {
        if (data.role.name === 'Administrator' || data.role.name === 'Manager') {
          store.dispatch(logout());
        }
        return data;
      },
      providesTags: [{ type: 'Users', id: 'me' }],
    }),
    updateUser: builder.mutation<void, UpdateUserRequest>({
      query: (data: UpdateUserRequest) => ({
        url: `users/me`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [{ type: 'Users', id: 'me' }],
    }),
    getCompanyUsers: builder.query<UserSchema[], void>({
      query: () => ({
        url: `users`,
        method: 'GET',
        params: {
          fields: ['id', 'status', 'first_name', 'last_name', 'email', 'language', 'avatar', 'title', 'description'],
          filter: `{"company": {"users": {"_eq": "$CURRENT_USER"}}}`,
        },
      }),
      transformResponse: ({ data }) => data,
      providesTags: [{ type: 'Users', id: 'company' }],
    }),
    inviteUser: builder.mutation<void, InviteUserRequest>({
      query: (data) => ({
        url: `accounts/invite`,
        method: 'POST',
        body: { ...data, url: `${BASE_URL}${Route.InvitationSignUp}` },
      }),
      invalidatesTags: [{ type: 'Users', id: 'company' }],
    }),
  }),
});

export const { useGetCurrentUserQuery, useUpdateUserMutation, useGetCompanyUsersQuery, useInviteUserMutation } =
  userApi;
