import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initial-state';
import { clear, set } from './reducers';

export const headerSlice = createSlice({
  name: 'header',
  initialState: initialState(),
  reducers: { set, clear },
});
