import { useMemo } from 'react';

import { useGetOfferQuery } from '../api';
import { offerHeaderDetailsTransformer } from '../helpers';
import { OfferHeaderDetails } from '../types';

export function useOfferHeaderDetails(id: string): OfferHeaderDetails | null {
  const { data: offer } = useGetOfferQuery({ id });
  return useMemo(() => offerHeaderDetailsTransformer(offer), [offer]);
}
