import { ReactNode } from 'react';

import { Logo } from '@/components';

export type AuthLayoutProps = {
  readonly children: ReactNode;
};

export function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <div className="flex h-full w-full">
      <div className="flex min-h-full w-full max-w-screen-lg flex-col">
        <div className="flex  w-full shrink-0 items-start bg-white py-8 pl-10">
          <Logo className="h-auto w-44" />
        </div>

        <div className="flex h-full w-full items-start justify-center overflow-auto">
          <div className="my-auto w-full max-w-xl space-y-8 py-12">{children}</div>
        </div>
      </div>

      <div className="h-full w-full flex-grow bg-red-50 bg-wind-turbines bg-cover bg-left-top bg-no-repeat" />
    </div>
  );
}
