import { useMemo } from 'react';

import { useGetProjectQuery } from '../api';
import { projectDetailsTransformer } from '../helpers/detail-view-transformer';

export function useProjectDetails(id: string) {
  const { data: project } = useGetProjectQuery({ id: id });
  const normalizedProject = useMemo(() => projectDetailsTransformer(project), [project]);
  return { project, normalizedProject } as const;
}

export function useTechnologyDetails(projectId: string, technologyId: number) {
  const { normalizedProject: project } = useProjectDetails(projectId);
  return useMemo(
    () => project?.technologies.find((technology) => technology.id === technologyId),
    [project, technologyId]
  );
}
