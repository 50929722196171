import { Link } from 'react-router-dom';

import { AnimationShell, Container, Typography } from '@/components';

export function ForbiddenPage() {
  return (
    <Container size="lg" className="my-12 flex flex-col items-center justify-center space-y-8 text-center">
      <AnimationShell type="scale" className="flex flex-col items-center justify-center space-y-8 text-center">
        <Typography as="h1" size="heading-1" color="primary" weight="semibold">
          <b>Error 403</b> <small>Forbidden!</small>
        </Typography>

        <Typography as="p" size="body-2" color="secondary" align="center">
          It seems that you do not have permissions to access to this link. Contact us for more information.
        </Typography>

        <Link to="/" className="cursor-pointer text-primary-700 transition hover:underline hover:underline-offset-2">
          <Typography as="span" size="body-2" color="inherit">
            Back to home
          </Typography>
        </Link>
      </AnimationShell>
    </Container>
  );
}
