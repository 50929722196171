import { useCompanyRole } from '@/features/company/hooks';
import { TransactionDetails } from '../../types';
import { TransactionListItem } from './transaction-list-item';
import { Overlay } from '@/components';

export function TransactionListItems({ transactions }: { transactions: TransactionDetails[] }) {
  const { isPremium } = useCompanyRole();
  return (
    <>
      {transactions?.map((transaction) => <TransactionListItem transaction={transaction} key={transaction.id} />)}
      {!isPremium && <Overlay />}
    </>
  );
}
