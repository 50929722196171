import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function HandShake({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="biogas"
    >
      <g>
        <g>
          <path
            d="m20.29 14.19 -5.54 1.1"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          ></path>
          <path
            d="M3.68 14.2h2.45l3.51 3.94a1.41 1.41 0 0 0 1 0.46 46.18 46.18 0 0 0 5.74 -4.89c0.11 -0.31 -0.1 -0.62 -0.29 -0.88l-2.51 -3.24"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          ></path>
          <path
            d="m10.58 7.08 -0.35 -0.3A2.16 2.16 0 0 0 9 6.38a2.08 2.08 0 0 0 -0.78 0.15L3.75 8.31"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          ></path>
          <path
            d="M0.75 6.6h2a1 1 0 0 1 1 0.92v6.42a1 1 0 0 1 -1 0.91h-2"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          ></path>
          <path
            d="M23.25 14.85h-2a1 1 0 0 1 -1 -0.91V7.52a1 1 0 0 1 1 -0.92h2"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          ></path>
        </g>
        <path
          d="m14.75 9.2 -3.45 1.14a1.61 1.61 0 0 1 -2 -0.86 1.63 1.63 0 0 1 0.74 -2.14l3.36 -1.69a2.3 2.3 0 0 1 1.06 -0.25 2.45 2.45 0 0 1 0.8 0.14l5 1.82"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
      </g>
    </svg>
  );
}
