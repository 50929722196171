import { motion } from 'framer-motion';

import { AnimationShell, Chip, Link, Typography } from '@/components';
import { ASSETS_BASE_URL } from '@/config';
import { Route } from '@/constants/route';
import { useServicesCategories, useServicesTags } from '@/features/services/hooks';
import { ServiceItemList } from '@/features/services/types';

export type ServicesItemProps = {
  readonly service: ServiceItemList;
  readonly index?: number;
};

export function ServicesItem({ service, index = 0 }: ServicesItemProps) {
  const { kv: tags } = useServicesTags();
  const { kv: categories } = useServicesCategories();

  return (
    <motion.div
      initial={{ y: 0 }}
      whileHover={{ y: -6 }}
      transition={{ type: 'spring', stiffness: 100 }}
      className="w-full"
      key={service.id}
    >
      <Link to={Route.Service.replace(':serviceId', service.id)} className="hover:no-underline">
        <AnimationShell
          type="scale"
          delay={0.1 * index}
          className="h-full w-full cursor-pointer space-y-4 rounded-md border border-neutral-50 bg-base-white p-4 shadow-sm transition hover:shadow-xl"
        >
          <div className="inline-flex w-full items-center justify-between gap-4">
            <div className="inline-flex items-center justify-start gap-2">
              <img src={service.logo ? `${ASSETS_BASE_URL}/${service.logo}` : ''} alt={service.name} className="h-12" />
              {/* <img src={service.logo ? `${ASSETS_BASE_URL}/${service.logo}` : ''} className="h-8 w-8 rounded-full" /> */}
            </div>
            <div className="inline-flex items-center justify-end gap-2">
              <Chip color="neutral" index={index} className=" border border-neutral-50">
                {categories[service.category]}
              </Chip>
            </div>
          </div>

          <div className="items-center justify-start gap-2">
            <Typography as="p" size="body-1" color="primary" weight="semibold">
              {service.name}
            </Typography>
            <Typography as="p" size="body-3" color="secondary" weight="normal">
              {service.location}
            </Typography>
          </div>

          <div className="inline-flex w-full flex-wrap items-center">
            {service.tags.map((tag, index) => (
              <Chip color="display" index={index} key={`${service.id}-${index}`} className="no-uppercase mt-1">
                {tags[tag]}
              </Chip>
            ))}
          </div>
        </AnimationShell>
      </Link>
    </motion.div>
  );
}
