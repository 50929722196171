import { cn } from '@/utils/styles';

import { icons } from './constants';
import { IconProps as IconCommonProps, IconName } from './types';

export type IconProps = IconCommonProps & {
  readonly name: IconName;
};

export function Icon({ name, className }: IconProps) {
  const Component = icons[name];

  return <Component className={cn(`nteaser-icon-${name}`, className)} />;
}
