import { useRegisterGAPageView } from '@/hooks';
import { DealsItems } from '../items';
import { DealsEmptyState } from '../empty-state';
import { useFilteredDeals } from '@/features/deals/hooks/use-filtered-deals';
import { Typography } from '@/components';
import { useTranslation } from 'react-i18next';

export function DealsContent() {
  const { t } = useTranslation(['deals']);
  const { deals } = useFilteredDeals();
  useRegisterGAPageView('Deals');

  if (!deals) return null;

  if (deals.length === 0) {
    return <DealsEmptyState />;
  }

  return (
    <div className="space-y-3">
      <Typography as="h1" size="heading-1" color="primary" weight="semibold">
        {t('Other Deals')}
      </Typography>
      <Typography as="p" size="body-2" color="secondary" weight="normal">
        {t(
          'Discover opportunities in emerging technologies like hydrogen and biogas, new geographies, co-development opportunities, and specific investor requests.'
        )}
      </Typography>

      <DealsItems deals={deals} />
    </div>
  );
}
