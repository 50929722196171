import { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { UserSchema } from '@/features/users';
import { isNullish } from '@/types';

export type UsePreferencesProps = {
  readonly user?: UserSchema;
};

export function usePreferences({ user }: UsePreferencesProps) {
  const [open, setOpen] = useState(false);
  const [inSession, setInSession] = useSessionStorage('company-preferences', false);

  useEffect(() => {
    if (inSession) return;
    if (!user) return;

    const keys = [
      'activity_interests',
      'technology_interests',
      'development_status_interests',
      'capacity_interests',
      'location_interests',
    ] as const;

    if (user.company && keys.every((key) => isNullish(user.company[key]))) {
      setOpen(true);
      setInSession(true);
    }
  }, [user, inSession, setInSession]);

  return {
    open,
    setOpen,
  };
}
