import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';

import { OfferDetails } from '../../types';

export type BuyerTypeCollapsableProps = {
  readonly offer: OfferDetails;
  readonly initialExpandedState?: boolean;
};

export function BuyerTypeCollapsable({ offer, initialExpandedState = true }: BuyerTypeCollapsableProps) {
  const { t } = useTranslation(['offers']);

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const header = (
    <CollapsableHeader
      title={t('Prospective Buyer Information')}
      description={t(
        'Here you will find the information about the prospective buyer, and whether is the final purchaser or a representative acting for a third party.'
      )}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );

  const buyerType = useCallback(() => {
    switch (offer?.buyerType) {
      case 'intermediary':
        return t('Third-party representative: Acting on behalf of another buyer or entity. ');
      case 'final-buyer':
        return t('Final Buyer: Direct purchaser of the project and will hold ownership upon completion.');
      default:
        return null;
    }
  }, [offer?.buyerType, t]);

  return (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody
        data={[
          { label: t('Role'), value: buyerType() },
          { label: t('Final Buyer Information'), value: offer?.buyerComments },
        ]}
      />
    </Collapsable>
  );
}
