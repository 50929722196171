export const colors = {
  inherit: '',
  default: 'bg-neutral-50 text-neutral-900',
  display: 'bg-primary-light text-neutral-900',
  info: 'bg-info-chip text-neutral-900',
  success: 'bg-success-chip text-neutral-900',
  warning: 'bg-warning-chip text-neutral-900',
  error: 'bg-error-chip text-neutral-900',
  disabled: 'bg-base-disabled text-neutral-500',
  gold: 'bg-gold-chip text-neutral-900',
  neutral: 'bg-white text-neutral-900',
};
