import { Container, EmptyState, WorkOfficeDesk } from '@/components';
import { useTransactionsView } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { TransactionsDetails } from './transactions-details';

export function TransactionsView() {
  const { t } = useTranslation(['insights']);
  const transactions = useTransactionsView();
  if (!transactions) return null;

  return (
    <div className="flex flex-col">
      <div className="w-full">
        <Container className="space-y-6 pt-6">
          {!transactions.length ? (
            <EmptyState
              artwork={<WorkOfficeDesk className="w-64" />}
              title={t('Market Insights: Coming Soon!')}
              description={t(
                'Exclusive market insights for Business users coming soon, featuring data, trends, and analysis to support your investment decisions.'
              )}
            ></EmptyState>
          ) : (
            <TransactionsDetails transactions={transactions} />
          )}
        </Container>
      </div>
    </div>
  );
}
