import { useMemo } from 'react';

import { useGetUserStatsQuery } from '../api';
import { userStatsTransformer } from '../helpers';
import { UserStatsDetails } from '../types';

export function useUserStats(): UserStatsDetails | null {
  const { data: userStats } = useGetUserStatsQuery();
  return useMemo(() => userStatsTransformer({ userStats }), [userStats]);
}
