import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { OFFERS_FOLDER_ID } from '@/config';
import { FileSchema, useUploadFileMutation } from '@/features/files';
import { useNotifications } from '@/features/notifications';

export function useUploadOffer() {
  const [uploadFile] = useUploadFileMutation();
  const { t } = useTranslation(['offers']);
  const { add } = useNotifications();

  return useCallback(
    (file: File | null, onSuccess?: (file: FileSchema) => void) => {
      if (!file) return;

      uploadFile({ file, folder: OFFERS_FOLDER_ID })
        .unwrap()
        .then((data) => {
          onSuccess?.(data);
          add({
            id: new Date().toLocaleString(),
            title: t('File uploaded'),
            description: t('The file has been uploaded to the offer.'),
          });
        })
        .catch(() => {
          add({
            id: new Date().toLocaleString(),
            title: t('ERROR'),
            description: t('There was an error uploading the file. Please try again later.'),
          });
        });
    },
    [uploadFile, add, t]
  );
}
