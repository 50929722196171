import { api } from '@/features/auth';

import { CountriesResponse, LanguagesResponse, DevelopmentStatusResponse } from './types';

export const appApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query<CountriesResponse['data'], void>({
      query: () => ({
        url: `items/countries`,
        method: 'GET',
        params: { fields: '*.*,labels.*.*' },
      }),
      transformResponse: ({ data }: CountriesResponse) => data,
    }),
    getLanguages: builder.query<LanguagesResponse['data'], void>({
      query: () => ({
        url: `items/languages`,
        method: 'GET',
        params: { fields: '*.*' },
      }),
      transformResponse: ({ data }: LanguagesResponse) => data,
    }),
    getDevelopmentStatus: builder.query<DevelopmentStatusResponse['data'], void>({
      query: () => ({
        url: `items/development_status`,
        method: 'GET',
        params: { fields: '*.*,labels.*' },
      }),
      transformResponse: ({ data }: DevelopmentStatusResponse) => data,
    }),
  }),
});

export const { useGetCountriesQuery, useGetLanguagesQuery, useGetDevelopmentStatusQuery } = appApi;
