import { Field, Radio, TextArea, Typography } from '@/components';
import { SendInterestFormFields } from '@/features/interest';
import { CreateOfferFormFields } from '@/features/offers';
import { CreateOpportunityFormFields } from '@/features/opportunities';
import { nullableStringSetter } from '@/helpers';
import { FormState, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type AdvisoryServiceFormFields = SendInterestFormFields | CreateOfferFormFields | CreateOpportunityFormFields;

export type AdvisoryServiceProps = {
  readonly register: UseFormRegister<AdvisoryServiceFormFields>;
  readonly formState: FormState<AdvisoryServiceFormFields>;
};

export function AdvisoryServiceForm({ register, formState }: AdvisoryServiceProps) {
  const { t } = useTranslation(['services']);
  return (
    <>
      <Field
        id="legal_advisors"
        label={t('Do you need a Legal Advisor?')}
        error={formState.errors['advisory_service_information']?.legal_advisors}
        delay={0.9}
        classes={{ input: 'flex gap-4', field: 'space-y-1' }}
      >
        <Radio
          id="legal_advisors_yes"
          color={formState.errors['advisory_service_information']?.legal_advisors ? 'error' : 'primary'}
          registration={register('advisory_service_information.legal_advisors', {
            required: t('This field is required'),
          })}
          value="yes"
        >
          <Typography as="p" color="secondary" size="body-3" weight="normal">
            {t('Yes')}
          </Typography>
        </Radio>
        <Radio
          id="legal_advisors_no"
          color={formState.errors['advisory_service_information']?.legal_advisors ? 'error' : 'primary'}
          registration={register('advisory_service_information.legal_advisors', {
            required: t('This field is required'),
          })}
          value="no"
        >
          <Typography as="p" color="secondary" size="body-3" weight="normal">
            {t('No')}
          </Typography>
        </Radio>
        <Radio
          id="legal_advisors_maybe"
          color={formState.errors['advisory_service_information']?.legal_advisors ? 'error' : 'primary'}
          registration={register('advisory_service_information.legal_advisors', {
            required: t('This field is required'),
          })}
          value="maybe"
        >
          <Typography as="p" color="secondary" size="body-3" weight="normal">
            {t('Maybe')}
          </Typography>
        </Radio>
      </Field>

      <Field
        id="financial_advisors"
        label={t('Do you need a Financial Advisor?')}
        delay={0.9}
        classes={{ input: 'flex gap-4', field: 'space-y-1' }}
      >
        <Radio
          id="financial_advisors_yes"
          color={formState.errors['advisory_service_information']?.financial_advisors ? 'error' : 'primary'}
          registration={register('advisory_service_information.financial_advisors', {
            required: t('This field is required'),
          })}
          value="yes"
        >
          <Typography as="p" color="secondary" size="body-3" weight="normal">
            {t('Yes')}
          </Typography>
        </Radio>
        <Radio
          id="financial_advisors_no"
          color={formState.errors['advisory_service_information']?.financial_advisors ? 'error' : 'primary'}
          registration={register('advisory_service_information.financial_advisors', {
            required: t('This field is required'),
          })}
          value="no"
        >
          <Typography as="p" color="secondary" size="body-3" weight="normal">
            {t('No')}
          </Typography>
        </Radio>
        <Radio
          id="financial_advisors_maybe"
          color={formState.errors['advisory_service_information']?.financial_advisors ? 'error' : 'primary'}
          registration={register('advisory_service_information.financial_advisors', {
            required: t('This field is required'),
          })}
          value="maybe"
        >
          <Typography as="p" color="secondary" size="body-3" weight="normal">
            {t('Maybe')}
          </Typography>
        </Radio>
      </Field>

      <Field
        id="technical_advisors"
        label={t('Do you need a Technical Advisor?')}
        delay={0.9}
        classes={{ input: 'flex gap-4', field: 'space-y-1' }}
      >
        <Radio
          id="technical_advisors_yes"
          color={formState.errors['advisory_service_information']?.technical_advisors ? 'error' : 'primary'}
          registration={register('advisory_service_information.technical_advisors', {
            required: t('This field is required'),
          })}
          value="yes"
        >
          <Typography as="p" color="secondary" size="body-3" weight="normal">
            {t('Yes')}
          </Typography>
        </Radio>
        <Radio
          id="technical_advisors_no"
          color={formState.errors['advisory_service_information']?.technical_advisors ? 'error' : 'primary'}
          registration={register('advisory_service_information.technical_advisors')}
          value="no"
        >
          <Typography as="p" color="secondary" size="body-3" weight="normal">
            {t('No')}
          </Typography>
        </Radio>
        <Radio
          id="technical_advisors_maybe"
          color={formState.errors['advisory_service_information']?.technical_advisors ? 'error' : 'primary'}
          registration={register('advisory_service_information.technical_advisors', {
            required: t('This field is required'),
          })}
          value="maybe"
        >
          <Typography as="p" color="secondary" size="body-3" weight="normal">
            {t('Maybe')}
          </Typography>
        </Radio>
      </Field>

      <Field
        id="other"
        label={t('Do you need any other service?')}
        error={formState.errors['advisory_service_information']?.other}
        delay={0}
        classes={{ field: 'space-y-1' }}
      >
        <TextArea
          id="other"
          color={formState.errors['advisory_service_information']?.other ? 'error' : 'primary'}
          registration={register('advisory_service_information.other', {
            setValueAs: nullableStringSetter,
          })}
        />
      </Field>
    </>
  );
}
