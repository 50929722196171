import { OpportunityView } from '@/features/opportunities';

import { useOfferDetails } from '../../hooks';

export function OfferTabOpportunity({ id }: { id: string }) {
  const offer = useOfferDetails(id);

  if (!offer?.opportunity) return null;
  if (!offer) return null;

  return (
    <div className="space-y-4">
      <OpportunityView id={offer.opportunity.id} />
    </div>
  );
}
