import { getColorFromProjectStatus, getIconStatesFromTechnologies } from '../helpers';
import { Project } from '../types';

export function projectHeaderTransformer(project?: Project) {
  if (!project) return null;

  return {
    id: project.id,
    title: project.title,
    company: project.company,
    location: {
      country: project.country,
      city: [project.city],
    },
    technologies: {
      icons: getIconStatesFromTechnologies(project.technologies),
    },
    status: {
      name: project.status,
      color: getColorFromProjectStatus(project.status),
    },
  };
}
