import { cn } from '@/utils/styles';

export const colors = {
  primary: cn(
    'border-primary-700 bg-primary-700 text-white fill-white',
    'enabled:visited:border-primary-800 enabled:hover:border-primary-800 enabled:active:border-primary-800',
    'enabled:visited:bg-primary-800 enabled:hover:bg-primary-800 enabled:active:bg-primary-800',
    'disabled:border-primary-100 disabled:bg-primary-100 disabled:text-neutral-disabled'
  ),
  secondary: cn(
    'border-secondary-accent bg-secondary-accent text-white fill-white',
    'enabled:visited:border-secondary-500 enabled:hover:border-secondary-500 enabled:active:border-secondary-500',
    'enabled:visited:bg-secondary-500 enabled:hover:bg-secondary-500 enabled:active:bg-secondary-500',
    'disabled:border-secondary-200 disabled:bg-secondary-200'
  ),
  neutral: cn(
    'border-neutral-50 bg-neutral-50 text-primary-900 fill-primary-900',
    'enabled:visited:border-neutral-100 enabled:hover:border-neutral-100 enabled:active:border-neutral-100',
    'enabled:visited:bg-neutral-100 enabled:hover:bg-neutral-100 enabled:active:bg-neutral-100',
    'disabled:border-neutral-50 disabled:bg-neutral-50 disabled:text-neutral-disabled'
  ),
  ghost: cn(
    'border-secondary-accent bg-white text-secondary-accent fill-secondary-accent',
    'enabled:visited:bg-secondary-accent enabled:hover:bg-secondary-accent enabled:active:bg-secondary-accent',
    'enabled:visited:text-white enabled:hover:text-white enabled:active:text-white',
    'enabled:visited:fill-secondary-accent enabled:hover:fill-secondary-accent enabled:active:fill-secondary-accent',
    'disabled:border-neutral-50 disabled:bg-white disabled:text-neutral-disabled'
  ),
  link: cn(
    'border-transparent bg-transparent text-neutral-500 fill-neutral-500',
    'enabled:visited:text-base-primary enabled:hover:text-base-primary enabled:active:text-base-primary',
    'enabled:visited:fill-base-primary enabled:hover:fill-base-primary enabled:active:fill-base-primary',
    'disabled:text-neutral-disabled'
  ),
};
