import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { weeks } from '@/helpers';
import { isEmpty, isNullish } from '@/types';

import { OfferDetails } from '../../types';

export type DueDiligenceCollapsableProps = {
  readonly offer: OfferDetails;
  readonly initialExpandedState?: boolean;
};

export function DueDiligenceCollapsable({ offer, initialExpandedState = true }: DueDiligenceCollapsableProps) {
  const { t } = useTranslation(['offers']);

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const header = (
    <CollapsableHeader
      title={t('Due Diligence Requirements')}
      description={t(
        'This section provides information about the due diligence requirements, including the scope and expected timeline.'
      )}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );

  const dueDiligenceType = useCallback(() => {
    switch (offer?.dueDiligenceType) {
      case 'external':
        return t('External: Outsourced to a third-party provider.');
      case 'internal':
        return t('In-house: Conducted by an internal team.');
      default:
        return null;
    }
  }, [offer?.dueDiligenceType, t]);

  const data = [
    { label: t('Due Diligence Type'), value: dueDiligenceType() },
    { label: t('Due Diligence required time'), value: weeks(offer?.dueDiligenceWeeks) },
  ];

  if (!isNullish(offer?.dueDiligenceRequirements) && !isEmpty(offer?.dueDiligenceRequirements)) {
    data.push({ label: t('Due Diligence requirements'), value: offer?.dueDiligenceRequirements });
  }
  data.push({ label: t('Additional Comments'), value: offer?.dueDiligenceComments });

  return (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody data={data} />
    </Collapsable>
  );
}
