import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function BookEdit({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="biogas"
    >
      <path
        d="M8.249 23.25H3A2.25 2.25 0 0 1 0.751 21"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      ></path>
      <path
        d="M18.751 9V1.5A0.75 0.75 0 0 0 18 0.75H3.751a3 3 0 0 0 -3 3V21A2.25 2.25 0 0 1 3 18.75h6"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      ></path>
      <path
        d="M22.629 14.87 15 22.5l-3.75 0.75 0.75 -3.75 7.63 -7.63a2.114 2.114 0 0 1 2.992 0l0.008 0.008a2.116 2.116 0 0 1 -0.001 2.992Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      ></path>
    </svg>
  );
}
