import { useTranslation } from 'react-i18next';

import { Chip, Table, Typography } from '@/components';

import { UserSchema } from '../../types';

export type TeamTableProps = {
  readonly users: UserSchema[];
};

export function TeamTable({ users }: TeamTableProps) {
  const { t } = useTranslation(['team']);

  return (
    <Table
      rows={users}
      columns={[
        {
          key: 'full_name',
          title: t('Full name'),
          render: (row) => (
            <Typography size="body-3" weight="normal">
              {row.first_name + ' ' + row.last_name}
            </Typography>
          ),
        },
        {
          key: 'email',
          title: t('Email'),
          render: (row) => (
            <Typography size="body-3" weight="normal">
              {row.email}
            </Typography>
          ),
        },
        {
          key: 'status',
          title: t('Status'),
          render: (row) => <Chip color={row.status === 'active' ? 'success' : 'warning'}>{row.status}</Chip>,
        },
      ]}
    />
  );
}
