import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type UseCollapsableActionProps = {
  readonly setIsExpanded: (isExpanded: boolean) => void;
  readonly onEdit?: () => void;
};

export function useCollapsableAction({ onEdit, setIsExpanded }: UseCollapsableActionProps) {
  const { t } = useTranslation(['projects']);

  return useMemo(
    () =>
      onEdit
        ? {
            title: t('Edit'),
            onClick: () => {
              setIsExpanded(true);
              onEdit();
            },
          }
        : undefined,
    [t, onEdit, setIsExpanded]
  );
}
