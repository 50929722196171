import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Field, Form, Input } from '@/components';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from '@/components/dialog';
import { useDevelopmentStatus } from '@/hooks';

import { useUpdateCompanyMutation } from '../../api';
import { CompanySchema } from '../../types';

export type PreferencesModalProps = {
  readonly company?: CompanySchema;
  readonly open?: boolean;
  readonly setOpen?: (state: boolean) => void;
};

export type PreferencesFormFields = {
  activity_interests: string[];
  technology_interests: string[];
  development_status_interests: string[];
  capacity_interests: string[];
  location_interests: string;
};

export function PreferencesModal({ company, open = false, setOpen }: PreferencesModalProps) {
  const { t } = useTranslation(['settings']);

  const [setPreferences] = useUpdateCompanyMutation();

  const handleOnSubmit = useCallback(
    ({ location_interests, ...data }: PreferencesFormFields) => {
      if (!company) return;

      setPreferences({ id: company.id, data: { ...data, location_interests: location_interests.split(',') } })
        .unwrap()
        .finally(() => setOpen?.(false));
    },
    [company, setOpen, setPreferences]
  );

  const { options: developmentStatusOptions } = useDevelopmentStatus();

  const options = useMemo(
    () => ({
      defaultValues: {
        activity_interests: company?.activity_interests ?? [],
        technology_interests: company?.technology_interests ?? [],
        development_status_interests: company?.development_status_interests ?? [],
        capacity_interests: company?.capacity_interests ?? [],
        location_interests: company?.location_interests?.join(',') ?? '',
      },
    }),
    [company]
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent onPointerDownOutside={(event) => event.preventDefault()}>
          <DialogHeader>
            <DialogTitle>{t('Preferences')}</DialogTitle>
            <DialogDescription>{t('Set your preferences')}</DialogDescription>
          </DialogHeader>

          <Form<PreferencesFormFields> id="company-form" onSubmit={handleOnSubmit} options={options}>
            {({ register, formState }) => (
              <div className="space-y-6">
                <Field label={t('Activity')} delay={0.15} classes={{ field: 'space-y-3', input: 'flex' }}>
                  <Checkbox
                    id="activity_interests.seller"
                    value="seller"
                    registration={register('activity_interests')}
                    classes={{ main: 'w-1/3' }}
                  >
                    {t('Seller')}
                  </Checkbox>
                  <Checkbox
                    id="activity_interests.buyer"
                    value="buyer"
                    registration={register('activity_interests')}
                    classes={{ main: 'w-1/3' }}
                  >
                    {t('Buyer')}
                  </Checkbox>
                  <Checkbox
                    id="activity_interests.service-provider"
                    value="service-provider"
                    registration={register('activity_interests')}
                    classes={{ main: 'w-1/3' }}
                  >
                    {t('Service provider')}
                  </Checkbox>
                </Field>

                <Field label={t('Technology')} delay={0.25} classes={{ field: 'space-y-3', input: 'flex' }}>
                  <Checkbox
                    id="technology_interests.solar"
                    value="solar"
                    registration={register('technology_interests')}
                    classes={{ main: 'w-1/3' }}
                  >
                    {t('Solar')}
                  </Checkbox>
                  <Checkbox
                    id="technology_interests.wind"
                    value="wind"
                    registration={register('technology_interests')}
                    classes={{ main: 'w-1/3' }}
                  >
                    {t('Wind')}
                  </Checkbox>
                  <Checkbox
                    id="technology_interests.bess"
                    value="bess"
                    registration={register('technology_interests')}
                    classes={{ main: 'w-1/3' }}
                  >
                    {t('BESS')}
                  </Checkbox>
                </Field>

                <Field label={t('Capacity')} delay={0.25} classes={{ field: 'space-y-3', input: 'flex' }}>
                  <Checkbox
                    id="capacity_interests.1-5"
                    value="1-5"
                    registration={register('capacity_interests')}
                    classes={{ main: 'w-1/3' }}
                  >
                    {t('1 - 5 MW')}
                  </Checkbox>
                  <Checkbox
                    id="capacity_interests.5-50"
                    value="5-50"
                    registration={register('capacity_interests')}
                    classes={{ main: 'w-1/3' }}
                  >
                    {t('5 - 50 MW')}
                  </Checkbox>
                  <Checkbox
                    id="capacity_interests.>50"
                    value=">50"
                    registration={register('capacity_interests')}
                    classes={{ main: 'w-1/3' }}
                  >
                    {t('> 50 MW')}
                  </Checkbox>
                </Field>

                <Field
                  label={t('Development status')}
                  delay={0.35}
                  classes={{ field: 'space-y-3', input: 'flex flex-wrap gap-y-3' }}
                >
                  {developmentStatusOptions.map((option) => (
                    <Checkbox
                      key={option.value}
                      value={option.value}
                      classes={{ main: 'w-1/3' }}
                      registration={register('development_status_interests')}
                    >
                      {option.label}
                    </Checkbox>
                  ))}
                </Field>

                <Field
                  label={t('Location')}
                  error={formState.errors['location_interests']}
                  delay={0.45}
                  classes={{ field: 'space-y-1' }}
                >
                  <Input
                    id="location_interests"
                    color={formState.errors['location_interests'] ? 'error' : 'primary'}
                    autoComplete="off"
                    registration={register('location_interests')}
                  />
                </Field>
              </div>
            )}
          </Form>

          <DialogFooter>
            <div className="flex space-x-4">
              <Button type="button" color="secondary" onClick={() => setOpen?.(false)}>
                {t('Close')}
              </Button>
              <Button type="submit" form="company-form" size="lg" color="primary">
                {'Save changes'}
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
