import { ReactNode } from 'react';

import { isString } from '@/types';

import { Overlay } from '../overlay';
import { Typography } from '../typography';

export type CollapsableContentData = {
  readonly label: string;
  readonly value: string | ReactNode;
};

export type CollapsableBodyProps = {
  readonly data: CollapsableContentData[];
  readonly hidden?: boolean;
};

export function CollapsableBody({ data, hidden }: CollapsableBodyProps) {
  return (
    <>
      {hidden ? (
        <Overlay />
      ) : (
        data.map(({ label, value }) => (
          <div key={label} className="flex w-full items-start justify-start gap-8 border-t border-neutral-50 p-6">
            <div className="w-64 shrink-0">
              <Typography as="p" size="body-2" color="primary" weight="semibold">
                {label}
              </Typography>
            </div>
            <div className="grow">
              {isString(value) ? (
                <Typography as="p" size="body-2" color="secondary" weight="normal" className="whitespace-pre-line">
                  {value}
                </Typography>
              ) : (
                value
              )}
            </div>
          </div>
        ))
      )}
    </>
  );
}
