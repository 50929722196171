import { motion } from 'framer-motion';

import { AnimationShell } from '@/components';
import { cn } from '@/utils/styles';

const animationCollapseVariants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  closed: {
    opacity: 0,
    height: 0,
  },
};

export type CollapsableProps = {
  readonly header: React.ReactNode;
  readonly children: React.ReactNode;
  readonly index?: number;
  readonly isExpanded?: boolean;
};

export function Collapsable({ index = 0, isExpanded = false, header, children }: CollapsableProps) {
  return (
    <AnimationShell
      element="article"
      type="opacity"
      duration={0.15 * index}
      className={cn('h-auto w-full overflow-hidden rounded-lg border border-neutral-50 bg-base-white shadow-sm')}
    >
      {header}

      <motion.div
        variants={animationCollapseVariants}
        initial="closed"
        animate={isExpanded ? 'open' : 'closed'}
        transition={{ duration: 0.15 }}
      >
        {children}
      </motion.div>
    </AnimationShell>
  );
}
