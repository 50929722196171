import { useTranslation } from 'react-i18next';

import { Tabs } from '@/components/tabs';

export type OfferHeaderTabsProps = {
  activeTabIndex: number;
  onTabChange: (index: number) => void;
};

export function OfferHeaderTabs({ activeTabIndex, onTabChange }: OfferHeaderTabsProps) {
  const { t } = useTranslation(['offers']);
  const tabs = {
    left: [
      {
        label: t('Submitted Offer'),
        index: 0,
      },
      {
        label: t('Opportunity'),
        index: 1,
      },
    ],
    right: [],
  };

  return <Tabs tabs={tabs} activeTabIndex={activeTabIndex} onTabChange={onTabChange} />;
}
