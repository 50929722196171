import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { useCollapsableAction } from '@/features/projects/hooks';
import { displayQuarter, eurosPerHa, ha, percentage } from '@/helpers';
import { useLandRight } from '@/hooks';
import { isEmpty, isNullish } from '@/types';

import { Technology } from '../../../types';

export type LandRightsCollapsableProps = {
  readonly technology: Technology;
  readonly initialExpandedState?: boolean;
  readonly onEdit?: () => void;
  readonly hidden?: boolean;
};

export function LandRightsCollapsable({
  technology,
  onEdit,
  initialExpandedState = false,
  hidden,
}: LandRightsCollapsableProps) {
  const { t } = useTranslation(['projects']);

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const action = useCollapsableAction({ onEdit, setIsExpanded });
  const { map: landRightsmap } = useLandRight();

  const item = technology.details.landRights;
  const description = onEdit
    ? t(
        "Please provide detailed information about the project's land rights in this section, including surface area, type and terms of the agreement, and any additional information related to the lands."
      )
    : t(
        "This section provides detailed information about the project's land rights, including surface area, type and terms of the agreement, and additional information related to the lands."
      );

  const header = (
    <CollapsableHeader
      title={t('Land Rights')}
      description={description}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
      action={action}
    />
  );

  const data = [
    { label: t('Surface'), value: ha(item?.surface) },
    { label: t('Type'), value: item?.type?.map(landRightsmap).join(', ') },
    { label: t('Price'), value: eurosPerHa(item?.price) },
    { label: t('Status'), value: percentage(item?.contractedPercentage) },
    { label: t('Contract duration'), value: item?.contractDuration },
    { label: t('DUP Status'), value: item.dupStatus },
    {
      label: t('DUP date'),
      value: item.dupDate ? displayQuarter(item.dupDate) : null,
    },
    { label: t('DUP detail'), value: item.dupComments },
    { label: t('Comments'), value: item.comments },
  ];

  const filteredData = isNullish(onEdit) ? data.filter(({ value }) => !(isNullish(value) || isEmpty(value))) : data;

  return filteredData.length ? (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody data={filteredData} hidden={hidden} />
    </Collapsable>
  ) : null;
}
