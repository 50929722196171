import { MarketDetails } from '../../types';
import { MarketListItem } from './market-list-item';

export function MarketListItems({ markets }: { markets: MarketDetails[] }) {
  return (
    <div className="my-10 space-y-3">
      {markets.map((market) => (
        <MarketListItem key={market.id} market={market} />
      ))}
    </div>
  );
}
