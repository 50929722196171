import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AnimationShell, Link, Typography } from '@/components';
import { Route } from '@/constants';
import { CredentialsForm, CredentialsFormFields, useLogin } from '@/features/auth';
import { useRegisterGAPageView } from '@/hooks';

export function SignInPage() {
  const { t } = useTranslation(['signin']);

  const login = useLogin();
  useRegisterGAPageView('Sign in');

  const onSubmit = useCallback(
    (credentials: CredentialsFormFields) => {
      login(credentials);
    },
    [login]
  );

  return (
    <div className="w-full space-y-8">
      <Typography as="h1" size="heading-1" color="primary" weight="semibold">
        {t(`Hello again 👋`)}
      </Typography>

      <CredentialsForm onSubmit={onSubmit} />

      <AnimationShell type="opacity" delay={0.35} className="inline-flex w-full items-center justify-center gap-x-1">
        <Typography weight="normal" size="body-2">
          {t(`Don't have an account?`)}
        </Typography>

        <Link to={Route.SignUp}>{t(`Register`)}</Link>

        <Typography weight="normal" size="body-2">
          /
        </Typography>

        <Link to={Route.RecoveryPassword}>{t(`Forgot your password?`)}</Link>
      </AnimationShell>
    </div>
  );
}
