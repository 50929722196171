import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Search({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="search"
    >
      <path
        d="M16.9421 16.0575L13.0304 12.1466C14.1642 10.7854 14.7295 9.03953 14.6089 7.27214C14.4882 5.50475 13.6908 3.85192 12.3825 2.65748C11.0743 1.46304 9.35591 0.818951 7.58486 0.859201C5.81382 0.899451 4.12647 1.62094 2.87383 2.87358C1.62118 4.12622 0.899695 5.81357 0.859445 7.58462C0.819195 9.35566 1.46328 11.074 2.65772 12.3823C3.85217 13.6906 5.505 14.488 7.27238 14.6086C9.03977 14.7293 10.7856 14.1639 12.1468 13.0302L16.0577 16.9419C16.1158 16.9999 16.1848 17.046 16.2606 17.0774C16.3365 17.1089 16.4178 17.125 16.4999 17.125C16.5821 17.125 16.6634 17.1089 16.7392 17.0774C16.8151 17.046 16.8841 16.9999 16.9421 16.9419C17.0002 16.8838 17.0463 16.8149 17.0777 16.739C17.1091 16.6631 17.1253 16.5818 17.1253 16.4997C17.1253 16.4176 17.1091 16.3363 17.0777 16.2604C17.0463 16.1845 17.0002 16.1156 16.9421 16.0575ZM2.12493 7.74969C2.12493 6.63717 2.45483 5.54963 3.07292 4.62461C3.691 3.69958 4.56951 2.97861 5.59734 2.55287C6.62517 2.12712 7.75617 2.01573 8.84732 2.23277C9.93846 2.44981 10.9407 2.98554 11.7274 3.77221C12.5141 4.55888 13.0498 5.56116 13.2669 6.65231C13.4839 7.74345 13.3725 8.87445 12.9468 9.90228C12.521 10.9301 11.8 11.8086 10.875 12.4267C9.94999 13.0448 8.86245 13.3747 7.74993 13.3747C6.2586 13.373 4.82882 12.7799 3.77429 11.7253C2.71975 10.6708 2.12659 9.24102 2.12493 7.74969Z"
        fill="currentColor"
      />
    </svg>
  );
}
