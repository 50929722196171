import { cn } from '@/utils/styles';

import type { ArtworkProps } from '../types';

export function SuccessConfirm({ className }: ArtworkProps) {
  return (
    <svg className={cn(className)} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.4257 89.6435C47.6338 89.7231 50.6706 89.7916 53.9859 89.8823C54.0997 89.7796 54.212 89.6777 54.3242 89.5742C51.7683 89.3853 49.2249 89.0547 46.7057 88.584C37.6878 86.766 40.1204 72.1021 51.1713 73.3932C55.6488 73.6535 59.6359 73.8915 63.1909 74.0825C64.5232 67.9845 63.9037 61.6219 61.42 55.8954C58.9363 50.169 54.7143 45.3689 49.3516 42.1747C43.989 38.9805 37.7574 37.554 31.5392 38.0973C25.3211 38.6405 19.4313 41.126 14.7038 45.2017C9.9763 49.2775 6.6506 54.737 5.19754 60.8074C3.74449 66.8778 4.23769 73.2514 6.60734 79.026C8.97698 84.8006 13.103 89.6835 18.4012 92.9834C23.6995 96.2833 29.9015 97.8331 36.1292 97.4133C36.4421 93.4181 39.5632 89.4214 45.4257 89.6435Z"
        fill="#B7F6D1"
      />
      <path
        d="M45.4257 89.6435C47.6338 89.7231 50.6706 89.7916 53.9859 89.8823C54.0997 89.7796 54.212 89.6777 54.3242 89.5743C51.7683 89.3853 49.2249 89.0547 46.7057 88.584C37.6878 86.766 40.1204 72.1021 51.1713 73.3932C55.6488 73.6535 59.6359 73.8915 63.1909 74.0825C64.5232 67.9845 63.9037 61.6219 61.42 55.8954C58.9363 50.169 54.7143 45.3689 49.3516 42.1747C43.989 38.9805 37.7574 37.554 31.5392 38.0973C25.3211 38.6405 19.4313 41.126 14.7038 45.2017C9.9763 49.2775 6.6506 54.737 5.19754 60.8074C3.74449 66.8778 4.23769 73.2514 6.60734 79.026C8.97698 84.8006 13.103 89.6835 18.4012 92.9834C23.6995 96.2833 29.9015 97.8331 36.1292 97.4133C36.4421 93.4181 39.5632 89.4214 45.4257 89.6435Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1833 64.8585L33.4555 77.0039L48.2332 57.15"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.103 76.9895C123.232 75.4198 118.413 71.3554 114.689 64.1023C110.668 56.2728 108.587 33.9506 108.871 29.1221C109.155 24.2935 98.183 25.7311 95.2593 45.8484C94.2078 53.0753 93.9539 61.5726 92.0299 68.306C89.0481 75.9706 80.234 75.0839 51.1736 73.3964C40.1196 72.1021 37.6902 86.7692 46.7081 88.5817C51.8423 89.6165 58.2565 89.8035 67.0276 90.5159C59.5364 90.0941 50.6061 89.8362 45.4281 89.6404C34.3008 89.2264 33.036 104.007 42.1558 105.131C47.3409 105.767 62.5891 105.615 49.7918 105.639C38.2864 105.661 37.7053 121.036 47.1737 121.798C52.659 122.24 61.3425 121.464 53.4422 122.03C42.3412 122.826 42.7687 137.573 51.9585 137.783C73.4234 138.273 97.3584 143.196 114.807 137.419C121.233 135.293 125.93 135.66 132.814 135.674C137.258 122.586 137.412 91.1042 133.103 76.9895ZM54.8217 105.631C57.5711 105.617 60.334 105.617 63.3302 105.646C60.4184 105.642 57.5416 105.642 54.8217 105.631ZM59.694 121.675C58.6911 121.733 57.6722 121.776 56.6398 121.819C57.6579 121.758 58.676 121.715 59.694 121.671V121.675Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.4399 122.049C42.3436 122.915 42.7639 137.736 51.9561 137.801C57.5122 137.84 58.4435 137.437 69.0622 136.303C80.6535 135.066 80.6678 120.988 69.4251 121.395C59.1026 121.768 59.8907 121.546 53.4399 122.049Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.9556 76.0877C63.6772 74.7886 57.7869 74.1693 51.1713 73.3972C40.1164 72.1053 37.6886 86.7588 46.7057 88.5817C52.1607 89.6841 59.078 89.8815 68.7414 90.6616C80.4044 91.6025 83.0248 77.8237 71.9556 76.0877Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.4358 105.657C59.7712 105.588 51.4427 105.639 49.7894 105.642C38.284 105.665 37.7029 121.043 47.1713 121.801C52.8556 122.257 59.4234 121.578 70.0357 121.205C82.0783 120.781 83.0686 106.246 71.4318 105.831C69.1297 105.747 66.8268 105.689 64.5233 105.657"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.5377 105.658C66.6033 105.658 68.6681 105.658 70.6757 105.672C82.3259 105.758 83.9537 91.7904 72.7262 90.8941C64.5799 90.2438 52.269 89.9007 45.4234 89.6436C34.2961 89.2257 33.0312 104.01 42.151 105.134C46.7957 105.706 56.9566 105.642 64.4334 105.642"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.73 85.1485C149.729 85.7497 149.551 86.3373 149.217 86.8373C148.883 87.3372 148.409 87.727 147.853 87.9576C147.438 88.1293 146.99 88.2069 146.541 88.1847C146.092 88.1626 145.654 88.0413 145.258 87.8296C144.861 87.6178 144.517 87.3209 144.249 86.9601C143.981 86.5994 143.796 86.1838 143.708 85.7431C143.591 85.1534 143.651 84.5422 143.882 83.9868C144.112 83.4313 144.501 82.9564 145.001 82.622C145.501 82.2878 146.088 82.1094 146.69 82.1094C147.495 82.1117 148.267 82.4326 148.836 83.002C149.406 83.5714 149.727 84.3431 149.73 85.1485Z"
        fill="#279D59"
      />
      <path
        d="M149.73 85.1485C149.729 85.7497 149.551 86.3373 149.217 86.8373C148.883 87.3372 148.409 87.727 147.853 87.9576C147.438 88.1293 146.99 88.2069 146.541 88.1847C146.092 88.1626 145.654 88.0413 145.258 87.8296C144.861 87.6178 144.517 87.3209 144.249 86.9601C143.981 86.5994 143.796 86.1838 143.708 85.7431C143.591 85.1534 143.651 84.5422 143.882 83.9868C144.112 83.4313 144.501 82.9564 145.001 82.622C145.501 82.2878 146.088 82.1094 146.69 82.1094C147.495 82.1117 148.267 82.4326 148.836 83.002C149.406 83.5714 149.727 84.3431 149.73 85.1485Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.83 75.025C128.167 75.9671 130.609 76.6266 133.103 76.9896C131.122 70.4918 128.317 68.7024 125.83 75.025Z"
        fill="#279D59"
      />
      <path
        d="M125.83 75.0258C128.167 75.9676 130.609 76.6269 133.103 76.9895C131.123 70.4918 128.317 68.7047 125.83 75.0258Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.452 135.692C127.721 142.339 130.557 142.31 132.811 135.692C125.49 135.692 125.578 135.682 125.452 135.692Z"
        fill="#279D59"
      />
      <path
        d="M125.452 135.692C127.721 142.339 130.557 142.31 132.811 135.692C125.49 135.692 125.578 135.682 125.452 135.692Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.9418 137.802C57.876 138.034 63.9853 138.558 70.1081 139.052C71.8538 138.017 76.5661 133.016 77.1925 131.012C76.0582 133.615 73.3534 135.831 69.0622 136.292C58.4284 137.433 57.5122 137.845 51.9418 137.802Z"
        fill="#279D59"
      />
      <path
        d="M51.9418 137.802C57.876 138.034 63.9853 138.558 70.1081 139.052C71.8538 138.017 76.5661 133.016 77.1925 131.012C76.0582 133.615 73.3534 135.831 69.0622 136.292C58.4284 137.433 57.5122 137.845 51.9418 137.802Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.0275 68.3068C91.5798 69.8473 91.0605 71.3661 90.4714 72.8583C81.7281 94.9075 91.6351 115.053 108.779 118.122"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M129.132 71.0848H149.815" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M129.219 140.682H149.727" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.4817 110.995L14.576 115.242L19.2596 115.926L15.8702 119.228L16.6702 123.896L12.4817 121.686L8.29233 123.896L9.09231 119.228L5.70374 115.926L10.3874 115.242L12.4817 110.995Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M79.0241 19.3185L79.0098 27.7983" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M83.2572 23.5659L74.7774 23.5508" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
