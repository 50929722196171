import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Bess({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="bess"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.86311 1.12874C9.13562 0.856221 9.50523 0.703125 9.89062 0.703125H14.1094C14.4948 0.703125 14.8644 0.856221 15.1369 1.12874C15.4094 1.40125 15.5625 1.77086 15.5625 2.15625V2.8125H16.9219C17.4937 2.8125 18.0422 3.03968 18.4466 3.44405C18.851 3.84843 19.0781 4.39688 19.0781 4.96875V21.1406C19.0781 21.7125 18.851 22.2609 18.4466 22.6653C18.0422 23.0697 17.4937 23.2969 16.9219 23.2969H7.07812C6.50625 23.2969 5.9578 23.0697 5.55343 22.6653C5.14905 22.2609 4.92188 21.7125 4.92188 21.1406V4.96875C4.92188 4.39688 5.14905 3.84843 5.55343 3.44405C5.9578 3.03968 6.50625 2.8125 7.07812 2.8125H8.4375V2.15625C8.4375 1.77086 8.5906 1.40125 8.86311 1.12874ZM9.9375 2.20312V3.5625C9.9375 3.97671 9.60171 4.3125 9.1875 4.3125H7.07812C6.90408 4.3125 6.73716 4.38164 6.61409 4.50471C6.49102 4.62778 6.42188 4.7947 6.42188 4.96875V21.1406C6.42188 21.3147 6.49102 21.4816 6.61409 21.6047C6.73716 21.7277 6.90408 21.7969 7.07812 21.7969H16.9219C17.0959 21.7969 17.2628 21.7277 17.3859 21.6047C17.509 21.4816 17.5781 21.3147 17.5781 21.1406V4.96875C17.5781 4.7947 17.509 4.62778 17.3859 4.50471C17.2628 4.38164 17.0959 4.3125 16.9219 4.3125H14.8125C14.3983 4.3125 14.0625 3.97671 14.0625 3.5625V2.20312H9.9375Z"
        fill="currentColor"
      />
      <path
        d="M14.7952 11.3343H13.0881L14.97 8.31695C14.9896 8.28574 15 8.24962 15 8.21276C15 8.1759 14.9896 8.13979 14.97 8.10857C14.9524 8.07716 14.9272 8.05074 14.8966 8.03179C14.866 8.01283 14.8311 8.00197 14.7952 8.00021H11.4644C11.4167 7.99803 11.3697 8.01268 11.3316 8.04163C11.2936 8.07057 11.2669 8.11196 11.2562 8.15858L10.0072 13.1597C9.99873 13.1904 9.99772 13.2227 10.0042 13.2539C10.0108 13.285 10.0246 13.3142 10.0447 13.3389C10.0647 13.3649 10.0906 13.3856 10.1203 13.3994C10.1501 13.4132 10.1826 13.4196 10.2154 13.4181H11.6101L10.4235 17.7399C10.4102 17.787 10.4143 17.8373 10.4349 17.8817C10.4555 17.926 10.4914 17.9615 10.5359 17.9816C10.5812 18.0016 10.6319 18.0054 10.6796 17.9925C10.7273 17.9796 10.7692 17.9508 10.7982 17.9108L14.9617 11.6594C14.9826 11.6284 14.9948 11.5925 14.9971 11.5552C14.9993 11.5179 14.9915 11.4807 14.9745 11.4475C14.9575 11.4143 14.9319 11.3862 14.9003 11.3663C14.8688 11.3464 14.8324 11.3354 14.7952 11.3343Z"
        fill="currentColor"
      />
    </svg>
  );
}
