import { useBelongsToMyCompany } from '@/features/company/hooks';
import { useGetOpportunityQuery } from '../api';
import { skipToken } from '@reduxjs/toolkit/query';
import { useCallback } from 'react';
import { useGetCurrentUserQuery } from '@/features/users';

export function useIsOpportunityAndIsMine({ id, path }: { id?: string | null; path?: string }) {
  const { data: user } = useGetCurrentUserQuery();
  const { data: opportunity } = useGetOpportunityQuery(
    id && user && path?.includes('opportunities') ? { id: id } : skipToken
  );
  return useBelongsToMyCompany(opportunity?.company);
}
