import { ChangeEvent, useCallback } from 'react';

import { Button, Icon, Typography } from '@/components';
import { useNotifications } from '@/features/notifications';
import { cn } from '@/utils/styles';

import { FileSchema } from '../../types';

export type DocumentUploadProps = {
  readonly multiple?: boolean;
  readonly files?: FileSchema[];
  readonly onSelectFiles: (file: File[]) => void;
  readonly onUnSelectFiles?: (file: FileSchema) => void;
};

export function DocumentUpload({ multiple = false, files = [], onSelectFiles, onUnSelectFiles }: DocumentUploadProps) {
  const { add } = useNotifications();

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = event.target.files ? Array.from(event.target.files) : [];
      const newFiles = selectedFiles.filter((file) => !files?.some((f) => f.filename_download === file.name));

      if (!multiple && files.length) {
        add({ id: new Date().toString(), title: 'File limit reached', description: 'You can only upload one files.' });
      } else if (newFiles.length) {
        onSelectFiles?.(newFiles);
      } else {
        add({
          id: new Date().toString(),
          title: 'File duplicated',
          description: 'The file has been already added to this technology.',
        });
      }
    },
    [onSelectFiles, add, files, multiple]
  );

  const handleOnRemoveFile = useCallback(
    (file: FileSchema) => {
      onUnSelectFiles?.(file);
    },
    [onUnSelectFiles]
  );

  return (
    <div className="flex flex-col space-y-3">
      {files?.map((file, index) => (
        <div
          key={`file-${index}`}
          className={cn(
            'border border-dashed border-neutral-50 text-center',
            'min-h-12 rounded py-1.5',
            'flex flex-col items-center justify-center',
            'cursor-pointer transition hover:border-base-primary'
          )}
        >
          <div className="flex w-full justify-between px-3" key={file.title}>
            <Typography as="p" size="body-2" weight="normal">
              {file.title}
            </Typography>
            <Button
              icon="trash"
              color="neutral"
              className="h-6 w-6 border-0 bg-white p-2 text-base-primary hover:bg-white hover:text-neutral-500 active:hover:bg-white enabled:hover:bg-white enabled:hover:text-neutral-500 enabled:active:bg-white enabled:active:text-neutral-500"
              onClick={() => handleOnRemoveFile(file)}
            />
          </div>
        </div>
      ))}
      {multiple || !files?.length ? (
        <label className="flex w-full items-center justify-center border  border-dashed  border-neutral-50 p-2">
          <Icon name="upload" className="h-12 w-12  text-neutral-500 hover:text-black active:text-black" />
          <input type="file" className="hidden" accept=".pdf" multiple={multiple} onChange={handleOnChange} />
        </label>
      ) : undefined}
    </div>
  );
}
