import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Web({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.0119 4.5119C6.13048 2.39333 9.00388 1.20312 12 1.20312C14.9961 1.20312 17.8695 2.39333 19.9881 4.5119C22.1067 6.63048 23.2969 9.50388 23.2969 12.5C23.2969 15.4961 22.1067 18.3695 19.9881 20.4881C17.8695 22.6067 14.9961 23.7969 12 23.7969C9.00388 23.7969 6.13048 22.6067 4.0119 20.4881C1.89333 18.3695 0.703125 15.4961 0.703125 12.5C0.703125 9.50388 1.89333 6.63048 4.0119 4.5119ZM12 2.70312C9.40171 2.70312 6.90983 3.73529 5.07256 5.57256C3.23529 7.40983 2.20312 9.90171 2.20312 12.5C2.20312 15.0983 3.23529 17.5902 5.07256 19.4274C6.90983 21.2647 9.40171 22.2969 12 22.2969C14.5983 22.2969 17.0902 21.2647 18.9274 19.4274C20.7647 17.5902 21.7969 15.0983 21.7969 12.5C21.7969 9.90171 20.7647 7.40983 18.9274 5.57256C17.0902 3.73529 14.5983 2.70312 12 2.70312Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4548 1.58179C10.7893 1.82607 10.8625 2.29527 10.6182 2.62979C9.28284 4.45847 8.29688 8.23544 8.29688 12.5469C8.29688 16.8511 9.28018 20.5421 10.623 22.4237C10.8636 22.7609 10.7853 23.2292 10.4482 23.4699C10.111 23.7105 9.64262 23.6322 9.40201 23.295C7.80107 21.0517 6.79688 16.9988 6.79688 12.5469C6.79688 8.10204 7.79841 3.94777 9.4068 1.74519C9.65108 1.41067 10.1203 1.33752 10.4548 1.58179Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5451 1.58179C13.8796 1.33752 14.3488 1.41067 14.5931 1.74519C16.2015 3.94777 17.203 8.10204 17.203 12.5469C17.203 16.9988 16.1988 21.0517 14.5979 23.295C14.3572 23.6322 13.8889 23.7105 13.5517 23.4699C13.2145 23.2292 13.1363 22.7609 13.3769 22.4237C14.7197 20.5421 15.703 16.8511 15.703 12.5469C15.703 8.23544 14.717 4.45847 13.3817 2.62979C13.1374 2.29527 13.2105 1.82607 13.5451 1.58179Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.703125 12.5C0.703125 12.0858 1.03891 11.75 1.45312 11.75H22.5469C22.9611 11.75 23.2969 12.0858 23.2969 12.5C23.2969 12.9142 22.9611 13.25 22.5469 13.25H1.45312C1.03891 13.25 0.703125 12.9142 0.703125 12.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.92188 17.4219C1.92188 17.0077 2.25766 16.6719 2.67188 16.6719H21.3281C21.7423 16.6719 22.0781 17.0077 22.0781 17.4219C22.0781 17.8361 21.7423 18.1719 21.3281 18.1719H2.67188C2.25766 18.1719 1.92188 17.8361 1.92188 17.4219Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.92188 7.57812C1.92188 7.16391 2.25766 6.82812 2.67188 6.82812H21.3281C21.7423 6.82812 22.0781 7.16391 22.0781 7.57812C22.0781 7.99234 21.7423 8.32812 21.3281 8.32812H2.67188C2.25766 8.32812 1.92188 7.99234 1.92188 7.57812Z"
        fill="currentColor"
      />
    </svg>
  );
}
