import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Services({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="services"
    >
      <path
        d="M8.5 12.8C7.7 12.8 6.9 12.5 6.4 11.9C5.8 11.3 5.5 10.6 5.5 9.8C5.5 9 5.8 8.2 6.4 7.7C7.5 6.6 9.5 6.6 10.6 7.7C11.2 8.3 11.5 9 11.5 9.8C11.5 10.6 11.2 11.4 10.6 11.9C10.1 12.4 9.3 12.8 8.5 12.8ZM8.5 8.2C8.1 8.2 7.7 8.4 7.4 8.6C7.1 8.8 7 9.3 7 9.8C7 10.3 7.2 10.6 7.4 10.9C8 11.5 9 11.5 9.5 10.9C9.8 10.6 9.9 10.2 9.9 9.8C9.9 9.4 9.8 9 9.6 8.7C9.3 8.4 8.9 8.2 8.5 8.2Z"
        fill="currentColor"
      />
      <path
        d="M20 21.2001H4.00001C2.50001 21.2001 1.20001 20 1.20001 18.4V5.40005C1.20001 4.00005 2.50001 2.80005 4.00001 2.80005H20C21.5 2.80005 22.8 4.00005 22.8 5.60005V18.6C22.8 20 21.5 21.2001 20 21.2001ZM4.00001 4.20005C3.30001 4.20005 2.70001 4.80005 2.70001 5.50005V18.5C2.70001 19.2 3.30001 19.7001 3.90001 19.7001H19.9C20.6 19.7001 21.1 19.1 21.1 18.5V5.50005C21.1 4.80005 20.5 4.30005 19.9 4.30005H4.00001V4.20005Z"
        fill="currentColor"
      />
      <path
        d="M19 10.2001H15C14.6 10.2001 14.2 9.9001 14.2 9.4001C14.2 8.9001 14.5 8.6001 15 8.6001H19C19.4 8.6001 19.8 8.9001 19.8 9.4001C19.8 9.9001 19.4 10.2001 19 10.2001Z"
        fill="currentColor"
      />
      <path
        d="M17.4 14.2001H15C14.6 14.2001 14.2 13.9001 14.2 13.4001C14.2 12.9001 14.5 12.6001 15 12.6001H17.4C17.8 12.6001 18.2 12.9001 18.2 13.4001C18.2 13.9001 17.8 14.2001 17.4 14.2001Z"
        fill="currentColor"
      />
      <path
        d="M12.1 17.2001C11.8 17.2001 11.5 17.0001 11.4 16.7001C11.3 16.4001 11.1 16.1001 10.8 15.8001C10.4 15.4001 9.80002 15.2001 9.20002 15.2001H7.90002C7.30002 15.2001 6.70002 15.4001 6.30002 15.8001C6.00002 16.0001 5.80002 16.3001 5.70002 16.7001C5.50002 17.1001 5.10002 17.3001 4.70002 17.1001C4.30002 16.9001 4.10002 16.5001 4.30002 16.1001C4.50002 15.6001 4.80002 15.1001 5.30002 14.7001C6.00002 14.0001 7.00002 13.7001 8.00002 13.7001H9.30002C10.3 13.7001 11.2 14.1001 12 14.7001C12.4 15.1001 12.8 15.6001 13 16.1001C13.2 16.5001 13 16.9001 12.6 17.1001C12.3 17.2001 12.2 17.2001 12.1 17.2001Z"
        fill="currentColor"
      />
    </svg>
  );
}
