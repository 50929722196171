import { useTranslation } from 'react-i18next';

import { EmptyState, WorkOfficeDesk } from '@/components';

export function DealsEmptyState() {
  const { t } = useTranslation(['Deals']);

  return (
    <EmptyState
      artwork={<WorkOfficeDesk className="w-64" />}
      title={t('Discover New Opportunities')}
      description={t(
        'Coming soon: Explore projects in emerging technologies like hydrogen and biogas, as well as co-development requests and opportunities in new regions. Stay tuned for the latest deals to diversify your energy investments.'
      )}
    ></EmptyState>
  );
}
