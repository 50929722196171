import { Typography } from '@/components';
import { cn } from '@/utils/styles';
import { t } from 'i18next';

export function TransactionListHeader() {
  const columnsClassName = ' bg-neutral-500 text-white p-1';
  return (
    <div className="flex w-full">
      <Typography size="body-2" color="white" weight="normal" className={cn('w-1/12', columnsClassName)}>
        {t('Date')}
      </Typography>
      <Typography size="body-2" color="white" weight="normal" className={cn('w-[11%]', columnsClassName)}>
        {t('Technology')}
      </Typography>
      <Typography size="body-2" color="white" weight="normal" className={cn('w-[19.5%]', columnsClassName)}>
        {t('Buyer')}
      </Typography>
      <Typography size="body-2" color="white" weight="normal" className={cn('w-[19.5%]', columnsClassName)}>
        {t('Seller')}
      </Typography>
      <Typography size="body-2" color="white" weight="normal" className={cn('w-1/12', columnsClassName)}>
        {t('Capacity')}
      </Typography>
      <Typography size="body-2" color="white" weight="normal" className={cn('w-3/12', columnsClassName)}>
        {t('Location')}
      </Typography>
      <Typography size="body-2" color="white" weight="normal" className={cn('w-1/12', columnsClassName)}>
        {t('Country')}
      </Typography>
    </div>
  );
}
