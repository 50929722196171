import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Currencies({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="currencies"
    >
      <path
        d="M8.0002 11.8C5.1002 11.8 2.2002 10.9 2.2002 9.00001C2.2002 8.60001 2.5002 8.20001 3.0002 8.20001C3.5002 8.20001 3.8002 8.60001 3.8002 9.00001C3.8002 9.30001 5.3002 10.2 8.0002 10.2C8.4002 10.2 8.8002 10.5 8.8002 11C8.8002 11.5 8.4002 11.8 8.0002 11.8Z"
        fill="currentColor"
      />
      <path
        d="M8.00029 15.8C5.10029 15.8 2.30029 14.9 2.30029 13C2.30029 12.6 2.60029 12.2 3.10029 12.2C3.60029 12.2 3.90029 12.5 3.90029 13C3.90029 13.3 5.40029 14.2 8.10029 14.2C8.50029 14.2 8.90029 14.5 8.90029 15C8.90029 15.5 8.40029 15.8 8.00029 15.8Z"
        fill="currentColor"
      />
      <path
        d="M13.0002 8.00001H12.2002V5.00001C12.2002 4.60001 12.5002 4.20001 12.9002 4.20001C13.3002 4.20001 13.6002 4.50001 13.6002 5.00001V8.00001H13.0002Z"
        fill="currentColor"
      />
      <path
        d="M16.0002 18.8C13.1002 18.8 10.2002 17.9 10.2002 16C10.2002 15.6 10.5002 15.2 11.0002 15.2C11.5002 15.2 11.8002 15.5 11.8002 16C11.8002 16.3 13.3002 17.2 16.0002 17.2C18.7002 17.2 20.2002 16.3 20.2002 16C20.2002 15.6 20.5002 15.2 21.0002 15.2C21.5002 15.2 21.8002 15.5 21.8002 16C21.8002 17.8 18.9002 18.8 16.0002 18.8Z"
        fill="currentColor"
      />
      <path
        d="M16.0002 14.8C13.1002 14.8 10.2002 13.9 10.2002 12C10.2002 11.6 10.5002 11.2 11.0002 11.2C11.5002 11.2 11.8002 11.5 11.8002 12C11.8002 12.3 13.3002 13.2 16.0002 13.2C18.7002 13.2 20.2002 12.3 20.2002 12C20.2002 11.6 20.5002 11.2 21.0002 11.2C21.5002 11.2 21.8002 11.5 21.8002 12C21.8002 13.8 18.9002 14.8 16.0002 14.8Z"
        fill="currentColor"
      />
      <path
        d="M11.0002 12.8C10.6002 12.8 10.3002 12.5 10.2002 12.1C10.2002 11.8 10.3002 11.3 10.8002 10.8C11.7002 9.89999 13.7002 9.29999 16.0002 9.29999C16.4002 9.29999 16.8002 9.59999 16.8002 10.1C16.8002 10.6 16.5002 10.9 16.0002 10.9C13.9002 10.9 12.4002 11.4 11.9002 11.9C11.8002 12 11.8002 12.1 11.8002 12.1C11.8002 12.4 11.4002 12.7 11.0002 12.8Z"
        fill="currentColor"
      />
      <path
        d="M16.0002 22.8C13.1002 22.8 10.2002 21.9 10.2002 20V12C10.2002 11.6 10.5002 11.2 11.0002 11.2C11.5002 11.2 11.8002 11.5 11.8002 12V20C11.8002 20.3 13.3002 21.2 16.0002 21.2C16.4002 21.2 16.8002 21.5 16.8002 22C16.8002 22.5 16.4002 22.8 16.0002 22.8Z"
        fill="currentColor"
      />
      <path
        d="M21.0002 12.8C20.6002 12.8 20.2002 12.5 20.2002 12C20.2002 12 20.2002 11.9 20.1002 11.8C19.6002 11.3 18.1002 10.8 16.0002 10.8C15.6002 10.8 15.2002 10.5 15.2002 10C15.2002 9.50001 15.5002 9.20001 16.0002 9.20001C18.3002 9.20001 20.3002 9.80001 21.2002 10.7C21.7002 11.2 21.8002 11.7 21.8002 12C21.7002 12.4 21.4002 12.8 21.0002 12.8Z"
        fill="currentColor"
      />
      <path
        d="M16.0002 22.8C15.6002 22.8 15.2002 22.5 15.2002 22C15.2002 21.5 15.5002 21.2 16.0002 21.2C18.8002 21.2 20.2002 20.3 20.2002 20V12C20.2002 11.6 20.5002 11.2 21.0002 11.2C21.5002 11.2 21.8002 11.5 21.8002 12V20C21.8002 21.8 18.9002 22.8 16.0002 22.8Z"
        fill="currentColor"
      />
      <path
        d="M8.0002 19.8C5.1002 19.8 2.2002 18.9 2.2002 17C2.2002 16.6 2.5002 16.2 3.0002 16.2C3.5002 16.2 3.8002 16.5 3.8002 17C3.8002 17.3 5.3002 18.2 8.0002 18.2C8.4002 18.2 8.8002 18.5 8.8002 19C8.8002 19.5 8.4002 19.8 8.0002 19.8Z"
        fill="currentColor"
      />
      <path
        d="M8.0002 7.79999C5.1002 7.79999 2.2002 6.79999 2.2002 4.99999C2.2002 1.39999 13.7002 1.39999 13.7002 4.99999C13.8002 6.79999 10.9002 7.79999 8.0002 7.79999ZM8.0002 3.79999C5.2002 3.79999 3.8002 4.69999 3.8002 4.99999C3.8002 5.29999 5.2002 6.19999 8.0002 6.19999C10.8002 6.19999 12.2002 5.29999 12.2002 4.99999C12.2002 4.69999 10.8002 3.79999 8.0002 3.79999Z"
        fill="currentColor"
      />
      <path
        d="M3.0002 17.8C2.6002 17.8 2.2002 17.5 2.2002 17V5.00001C2.2002 4.60001 2.5002 4.20001 3.0002 4.20001C3.5002 4.20001 3.8002 4.60001 3.8002 5.00001V17C3.8002 17.4 3.4002 17.8 3.0002 17.8Z"
        fill="currentColor"
      />
    </svg>
  );
}
