import { Outlet } from 'react-router-dom';

import { AppLayout } from '@/features/app';

export function LegalPage() {
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
}
