import { Tag, ServiceTag } from '../types';

export function tagsTransformer(tags?: Tag[]): ServiceTag[] {
  if (!tags) return [];

  return tags.map((tag) => ({
    id: tag.code,
    name: tag.labels.find((label) => label.language === 'en-US')?.label ?? '',
    order: tag.order,
  }));
}
