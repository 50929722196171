export const colors = {
  // Grey scale
  'inherit': 'text-current',
  'primary': 'text-neutral-900',
  'secondary': 'text-neutral-500',
  'tertiary': 'text-neutral-200',
  'inverse': 'text-white',
  'disabled': 'text-neutral-200',
  'white': 'text-white',
  // Highlights
  'primary-accent': 'text-primary-700',
  'secondary-accent': 'text-secondary-400',
  // Feedback (semantic)
  'info': 'text-info-500',
  'success': 'text-success-500',
  'warning': 'text-warning-400',
  'error': 'text-error-500',
  // Branding
  'brand': 'text-primary-900',
};
