import { useTranslation } from 'react-i18next';

import { Cards } from '@/components';
import { IconName } from '@/components/icons/types';
import { useOpportunityStats } from '@/features/stats/hooks/use-opportunity-stats';

import { OpportunityDetails, OpportunityItemList } from '../../../types';

export type OpportunityStatsProps = {
  opportunity: OpportunityDetails | OpportunityItemList;
};

export function OpportunityStats({ opportunity }: OpportunityStatsProps) {
  const { t } = useTranslation(['opportunities']);
  const stats = useOpportunityStats({ id: opportunity.id });

  const infoCards = [
    {
      icon: 'my-offers' as IconName,
      title: t('Received Offers'),
      value: opportunity.offers.length.toString(),
    },
    {
      icon: 'eye-open' as IconName,
      title: t('Opportunity Views'),
      value: stats?.views.toString() ?? '0',
    },
    {
      icon: 'upload' as IconName,
      title: t('Downloads'),
      value: stats?.downloads.toString() ?? '0',
    },
  ];

  return <Cards info={infoCards} asGroup={false} />;
}
