import { ChipColor } from '@/components/chips/types';
import { offerEntryTableTransformer } from '@/features/offers/helpers';
import { Offer } from '@/features/offers/types';
import { projectDetailsTransformer, projectItemListTransformer } from '@/features/projects/helpers';
import { ProjectDetails } from '@/features/projects/types';

import {
  getIconNamesFromOpportunity,
  getColorFromOpportunityStatus,
  getIconStatesFromOpportunity,
  getUrgencyFromDeadline,
  getLocationFromOpportunityProjects,
  getTechnologyTypesFromOpportunityProjects,
  getDevelopmentStatusFromOpportunityProjects,
  getCapacityFromOpportunityProjects,
  checkOpportunityHasPv,
  getChipFromOpportunity,
} from '../helpers';
import { Opportunity, OpportunityDetails } from '../types';

export function opportunityDetailsTransformer(opportunity?: Opportunity, offers?: Offer[]): OpportunityDetails | null {
  if (!opportunity) return null;
  if (!offers) return null;

  const isOnlyOneProject = opportunity.projects.length === 1;
  const opportunityProjects = opportunity.projects.map((project, index) => ({
    name: project ? project.name ?? (isOnlyOneProject ? 'Project' : `Project ${index + 1}`) : '',
    details: project ? projectDetailsTransformer(project.project) : ({} as ProjectDetails),
  }));
  const projectsItemList = opportunity.projects.map((project) => projectItemListTransformer(project.project));
  const deadline = new Date(opportunity.deadline);
  const normalizedOffers = offers.map(offerEntryTableTransformer) ?? [];

  return {
    id: opportunity.id,
    name: opportunity.name,
    company: opportunity.company,
    deadline: {
      date: deadline,
      category: getUrgencyFromDeadline(deadline),
    },
    location: getLocationFromOpportunityProjects(projectsItemList),
    technologies: {
      types: getTechnologyTypesFromOpportunityProjects(projectsItemList),
      icons: {
        name: getIconNamesFromOpportunity(opportunity),
        states: getIconStatesFromOpportunity(opportunity),
      },
      state: {
        list: getDevelopmentStatusFromOpportunityProjects(projectsItemList),
      },
      capacity: getCapacityFromOpportunityProjects(projectsItemList),
    },
    status: {
      name: opportunity.status,
      color: getColorFromOpportunityStatus(opportunity.status) as ChipColor,
    },
    isDraft: opportunity.status === 'draft',
    isPending: opportunity.status === 'pending',
    isPublished: opportunity.status === 'published',
    isPaid: opportunity.type.toLowerCase() === 'paid',
    hasPV: checkOpportunityHasPv(projectsItemList),
    projects: opportunityProjects,
    percentage: {
      minimum: opportunity.minimum_percentage,
      maximum: opportunity.maximum_percentage,
    },
    options: opportunity.options,
    comments: opportunity.comments,
    aboutYou: opportunity.about_you,
    offers: normalizedOffers,
    files: opportunity.files?.map((file) => ({ type: file.type, file: file.file })) ?? [],
  };
}
