import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function LogOut({ className }: IconProps) {
  return (
    <svg className={cn(className)} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0002 10.8001H1.0002C0.600195 10.8001 0.200195 10.5001 0.200195 10.0001C0.200195 9.50007 0.500195 9.20007 1.0002 9.20007H12.0002C12.4002 9.20007 12.8002 9.50007 12.8002 10.0001C12.8002 10.5001 12.4002 10.8001 12.0002 10.8001Z"
        fill="currentColor"
      />
      <path
        d="M9.00039 13.8C8.80039 13.8 8.60039 13.7 8.50039 13.6C8.20039 13.3 8.20039 12.8 8.50039 12.5L11.0004 10L8.50039 7.50005C8.20039 7.20005 8.20039 6.70005 8.50039 6.40005C8.80039 6.10005 9.30039 6.10005 9.60039 6.40005L12.6004 9.40005C12.9004 9.70005 12.9004 10.2 12.6004 10.5L9.60039 13.5C9.40039 13.7 9.20039 13.8 9.00039 13.8Z"
        fill="currentColor"
      />
      <path
        d="M17.0002 19.8C16.8002 19.8 16.7002 19.8 16.5002 19.8L5.5002 17.9C4.2002 17.7 3.2002 16.5 3.2002 15.2V13C3.2002 12.6 3.5002 12.2 4.0002 12.2C4.5002 12.2 4.8002 12.5 4.8002 13V15.1C4.8002 15.7 5.2002 16.2 5.8002 16.3L16.8002 18.2C17.2002 18.3 17.6002 18.1 17.9002 17.9C18.1002 17.7 18.3002 17.4 18.3002 17V3.20002C18.3002 3.10002 18.3002 3.10002 18.3002 3.00002C18.3002 2.60002 18.1002 2.30002 17.8002 2.10002C17.5002 1.90002 17.1002 1.80002 16.8002 1.80002L5.8002 4.20002C5.2002 4.30002 4.8002 4.80002 4.8002 5.40002V7.10002C4.8002 7.50002 4.5002 7.90002 4.0002 7.90002C3.5002 7.90002 3.2002 7.60002 3.2002 7.10002V5.40002C3.2002 4.10002 4.1002 3.00002 5.4002 2.70002L16.4002 0.300024C17.2002 0.200024 18.0002 0.400025 18.6002 0.900025C19.2002 1.40002 19.6002 2.10002 19.7002 2.80002C19.7002 2.90002 19.7002 3.10002 19.7002 3.20002V17C19.8002 18.5 18.5002 19.8 17.0002 19.8Z"
        fill="currentColor"
      />
    </svg>
  );
}
