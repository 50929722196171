import { useCallback } from 'react';

import { useNotifications } from '@/features/notifications';
import { OpportunityDetails } from '@/features/opportunities/types';
import { useUpdateProjectMutation } from '@/features/projects';

export function useSendForRevision(opportunity: OpportunityDetails | null) {
  const [projectMutation] = useUpdateProjectMutation();

  const { add } = useNotifications();

  return useCallback(() => {
    if (!opportunity) return;

    const projects = opportunity.projects
      .filter((project) => project.details?.isDraft)
      .map((project) => project.details?.id);

    projects.forEach((projectId) => {
      if (projectId) {
        projectMutation({ id: projectId, status: 'pending' })
          .unwrap()
          .then(() => {
            add({
              id: new Date().toString(),
              title: 'Sent for revision',
              description: 'This project has been successfully sent to review.',
            });
          })
          .catch(() => {
            add({
              id: new Date().toString(),
              title: 'ERROR',
              description: 'There was an error sending the opportunity for revision. Please try again later.',
            });
          });
      }
    });
  }, [opportunity, projectMutation, add]);
}
