import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function StartRating({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="start-rating"
    >
      <path
        d="M21.8451 10.3897C22.1497 10.0932 22.3602 9.71368 22.4505 9.29847C22.5409 8.88325 22.507 8.45066 22.3531 8.05455C22.1901 7.66631 21.914 7.33604 21.5607 7.10669C21.2074 6.87734 20.7932 6.75951 20.372 6.76853H16.1388C16.0534 6.77221 15.9688 6.74993 15.8963 6.70462C15.8237 6.65931 15.7666 6.5931 15.7325 6.51471L13.4635 1.23526C13.2853 0.865731 13.0065 0.553858 12.6591 0.335383C12.3117 0.116907 11.9098 0.000671244 11.4993 0C11.0868 0.00145074 10.6829 0.117817 10.3329 0.336025C9.98297 0.554233 9.70083 0.865638 9.5182 1.23526L7.26617 6.51471C7.23312 6.59038 7.17864 6.65473 7.10943 6.69984C7.04023 6.74494 6.95933 6.76882 6.87672 6.76853H2.62664C2.20494 6.75717 1.78974 6.87404 1.43597 7.10368C1.08221 7.33333 0.806563 7.66491 0.645523 8.05455C0.48981 8.45575 0.458211 8.89443 0.554817 9.31378C0.651423 9.73313 0.871783 10.1138 1.18737 10.4066L4.8956 13.7909C4.96337 13.8506 5.0111 13.9298 5.0323 14.0176C5.05351 14.1054 5.04716 14.1975 5.01413 14.2816L2.93142 18.9688C2.7501 19.3824 2.70487 19.8429 2.80225 20.2838C2.89962 20.7248 3.13458 21.1235 3.47327 21.4224C3.81869 21.7198 4.24576 21.9064 4.69882 21.9577C5.15187 22.009 5.60989 21.9227 6.01315 21.7101L11.2961 18.7488C11.3557 18.7073 11.4267 18.6851 11.4993 18.6851C11.572 18.6851 11.6429 18.7073 11.7025 18.7488L16.9685 21.7101C17.3696 21.9413 17.8342 22.0382 18.2943 21.9864C18.7544 21.9346 19.1858 21.737 19.5254 21.4224C19.864 21.1235 20.099 20.7248 20.1964 20.2838C20.2938 19.8429 20.2485 19.3824 20.0672 18.9688L17.9845 14.2139C17.9494 14.1315 17.9404 14.0404 17.9585 13.9527C17.9767 13.865 18.0212 13.7849 18.0861 13.7232L21.8451 10.3897Z"
        fill="currentColor"
      />
    </svg>
  );
}
