import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Link, Logo, LogoIcon, Sidebar } from '@/components';
import { Banner } from '@/components/banner';
import { Menu, MenuItems } from '@/components/menu';
import { Route } from '@/constants';
import { useDisplayTrialBanner } from '@/features/company/hooks';
import { useSidebar } from '@/features/sidebar';
import { useGetCurrentUserQuery } from '@/features/users';
import { cn } from '@/utils/styles';
import { useIsOpportunityAndIsMine } from '@/features/opportunities/hooks';
import { isId } from '@/helpers';

export function MainSidebar() {
  const { t } = useTranslation(['sidebar']);

  const { isOpen, toogle } = useSidebar();

  const path = useLocation().pathname;

  const id = path.split('/').pop();
  const isMyOpportunity = useIsOpportunityAndIsMine({ id: isId(id) ? id : null, path: path });

  const navbarItems = useMemo(
    () =>
      [
        {
          title: 'Opportunities',
          menuitems: [
            {
              to: Route.Home,
              icon: 'book-search',
              label: t(`Projects`),
              active:
                path === Route.Home ||
                (path.startsWith(Route.Opportunities) && path !== Route.Opportunities && !isMyOpportunity),
            },
            {
              to: Route.Deals,
              icon: 'opportunities',
              label: t(`Other Deals`),
              active: path.startsWith(Route.Deals),
            },
          ],
        },
        {
          title: 'Services',
          menuitems: [
            {
              to: Route.Services,
              icon: 'services',
              label: t(`Advisors`),
              active: path.startsWith(Route.Services),
            },
          ],
        },
        {
          title: 'My deals',
          menuitems: [
            {
              to: Route.Offers,
              icon: 'my-offers',
              label: t(`As Buyer`),
              active: path.startsWith(Route.Offers),
            },
            {
              to: Route.Opportunities,
              icon: 'book-edit',
              label: t(`As Seller`),
              active: path === Route.Opportunities || (path.startsWith(Route.Opportunities) && isMyOpportunity),
            },
          ],
        },
        {
          title: 'Market Insights',
          menuitems: [
            {
              to: Route.Prices,
              icon: 'graph-bar',
              label: t(`Prices`),
              active: path === Route.Prices,
            },
            {
              to: Route.Transactions,
              icon: 'handshake',
              label: t(`Transactions`),
              active: path === Route.Transactions,
            },
          ],
        },
      ] as MenuItems,
    [path, t, isMyOpportunity]
  );

  const { data: user } = useGetCurrentUserQuery();

  const { inviteMember, expiredTrial } = useDisplayTrialBanner();
  const inviteMemberMessage = inviteMember();
  const expriedTrialMessage = expiredTrial();

  return (
    <Sidebar collapsed={isOpen} onChange={() => toogle()} className="overflow-y-auto">
      <div className="space-y-8">
        <Link to={Route.Home}>
          <div className={cn(isOpen ? 'px-1 py-4' : 'p-4')}>
            {isOpen ? <LogoIcon color="white" className="h-5 w-auto" /> : <Logo color="white" className="h-7 w-auto" />}
          </div>
        </Link>

        <Menu collapsed={isOpen} items={navbarItems} />

        {inviteMemberMessage ? <Banner banner={inviteMemberMessage} /> : null}
        {expriedTrialMessage ? <Banner banner={expriedTrialMessage} /> : null}
      </div>
    </Sidebar>
  );
}
