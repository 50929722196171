import { useTranslation } from 'react-i18next';

import { useBreadcrumbs } from '@/features/header';
import { MarketsView } from '@/features/insights';

export function Prices() {
  const { t } = useTranslation(['market']);
  useBreadcrumbs(() => [{ title: t(`Prices`) }]);

  return <MarketsView />;
}
