import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetServicesCategoriesQuery } from '../api';
import { categoriesTransformer } from '../helpers/categories-trasformer';

export function useServicesCategories() {
  const { t } = useTranslation(['services']);
  const { data: servicesCategories } = useGetServicesCategoriesQuery();

  const normalizedServicesCategories = categoriesTransformer(servicesCategories);

  const options = useMemo(() => {
    if (!normalizedServicesCategories) return [];

    const allCategories = [{ label: t(`All`), value: 'all' }];
    return [
      ...allCategories,
      ...normalizedServicesCategories?.map((category) => ({ label: category.name, value: category.id })),
    ];
  }, [normalizedServicesCategories, t]);

  const categories = useMemo(() => {
    if (!normalizedServicesCategories) return [];
    return ['all', ...normalizedServicesCategories?.map((category) => category.id)];
  }, [normalizedServicesCategories]);

  const kv = useMemo(
    () => options.reduce((map, option) => ({ ...map, [option.value]: option.label }), {}) as Record<string, string>,
    [options]
  );

  const map = useCallback((value: string) => kv[value], [kv]);

  return { normalizedServicesCategories, categories, options, kv, map };
}
