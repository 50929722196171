import { useTranslation } from 'react-i18next';

import { AnimationShell, Button, Checkbox, Field, Form, Input, Link, Typography } from '@/components';
import { Route } from '@/constants';

export type UserFormFields = {
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirmed_password: string;
  legal: boolean;
};

export type UserFormProps = {
  readonly onSubmit: (data: UserFormFields) => void;
};

export function UserForm({ onSubmit }: UserFormProps) {
  const { t } = useTranslation(['signup']);

  return (
    <Form<UserFormFields> onSubmit={onSubmit}>
      {({ watch, register, formState }) => (
        <div className="flex flex-col space-y-7">
          <Field
            id="name"
            label={t('Company name')}
            error={formState.errors['name']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              color={formState.errors['name'] ? 'error' : 'primary'}
              autoComplete="off"
              registration={register('name', { required: t('This field is required') })}
            />
          </Field>

          <div className="inline-flex w-full items-start gap-x-4">
            <Field
              id="first_name"
              label={t('First name')}
              error={formState.errors['first_name']}
              delay={0}
              classes={{ field: 'w-1/2 space-y-1' }}
            >
              <Input
                color={formState.errors['first_name'] ? 'error' : 'primary'}
                placeholder="John"
                autoComplete="given-name"
                registration={register('first_name', { required: t('This field is required') })}
              />
            </Field>

            <Field
              id="last_name"
              label={t('Last name')}
              error={formState.errors['last_name']}
              delay={0.15}
              classes={{ field: 'w-1/2 space-y-1' }}
            >
              <Input
                color={formState.errors['last_name'] ? 'error' : 'primary'}
                placeholder="Doe"
                autoComplete="family-name"
                registration={register('last_name', { required: false })}
              />
            </Field>
          </div>

          <Field
            id="email"
            label={t('Email address')}
            error={formState.errors['email']}
            delay={0.25}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              type="email"
              color={formState.errors['email'] ? 'error' : 'primary'}
              placeholder="john.doe@example.com"
              autoComplete="email"
              registration={register('email', { required: t('This field is required') })}
            />
          </Field>

          <Field
            id="password"
            label={t('Password')}
            error={formState.errors['password']}
            delay={0.35}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              type="password"
              autoComplete="new-password"
              color={formState.errors['password'] ? 'error' : 'primary'}
              registration={register('password', { required: t('This field is required') })}
            />
          </Field>

          <Field
            id="confirmed_password"
            label={t('Repeat password')}
            error={formState.errors['confirmed_password']}
            delay={0.45}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              type="password"
              autoComplete="new-password"
              color={formState.errors['confirmed_password'] ? 'error' : 'primary'}
              registration={register('confirmed_password', {
                required: t('This field is required'),
                validate: (value) => value === watch('password') || t('The passwords do not match'),
              })}
            />
          </Field>

          <Field id="legal" error={formState.errors['legal']} delay={0.6} classes={{ field: 'space-y-1' }}>
            <Checkbox
              id="legal"
              color="primary"
              registration={register('legal', {
                required: t('You must accept the terms and conditions to register.'),
              })}
            >
              <Typography as="p" color="secondary" size="body-3" weight="normal">
                {t('I read and accept nTeaser’s ')}
                <Link to={Route.TermsAndConditions} target="_blank">
                  {t('Terms and Conditions')}
                </Link>
                {t(' and ')}
                <Link to={Route.PrivacyPolicy} target="_blank">
                  {t('Privacy Policy')}
                </Link>
                {t('.')}
              </Typography>
            </Checkbox>
          </Field>

          <AnimationShell type="opacity" delay={0.55}>
            <Button type="submit" size="lg" className="w-full">
              {t('Continue')}
            </Button>
          </AnimationShell>
        </div>
      )}
    </Form>
  );
}
