import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { hours, meters, mw, mwh, watts } from '@/helpers';
import { isEmpty, isNullish } from '@/types';

import { useCollapsableAction, usePanelConfiguration, useStructureType } from '../../../hooks';
import { SolarItem, Technology, WindItem, BessItem } from '../../../types';

export type BasicDesignCollapsableProps = {
  readonly technology: Technology;
  readonly onEdit?: () => void;
  readonly initialExpandedState?: boolean;
  readonly hidden?: boolean;
};

export function BasicDesignCollapsable({
  technology,
  onEdit,
  hidden,
  initialExpandedState = false,
}: BasicDesignCollapsableProps) {
  const { t } = useTranslation(['projects']);
  const { map: mapStructureType } = useStructureType();
  const { map: mapPanelConfiguration } = usePanelConfiguration();

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const action = useCollapsableAction({ onEdit, setIsExpanded });

  const editDescription = () => {
    switch (technology.collection) {
      case 'projects_solar_technologies':
        return t(
          'Please complete this section with the main information of the project design and technology, including module type, module power, panel configuration, pitch distance, and any additional comments.'
        );
      case 'projects_wind_technologies':
        return t(
          'Please complete this section with the main information of the plant design and technology, including turbine model, turbine power, number of turbines, and any additional comments.'
        );
      case 'projects_bess_technologies':
        return t(
          'Please complete this section with the main information of the plant design and technology, including BESS technology, storage capacity, hours cycles, and any additional comments.'
        );
      default:
        return t('This section includes the main information of the project design and technology.');
    }
  };

  const description = onEdit
    ? editDescription()
    : t('This section includes the main information of the project design and technology.');

  const header = (
    <CollapsableHeader
      title={t('Basic Design')}
      description={description}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
      action={action}
    />
  );

  const data = useMemo(() => {
    let item = null;
    switch (technology.collection) {
      case 'projects_solar_technologies':
        item = technology.details.item as SolarItem;
        return [
          { label: t('Type of structure'), value: mapStructureType(item.structureType) },
          { label: t('Module type'), value: item.moduleType },
          { label: t('Module power'), value: watts(item.modulePower) },
          { label: t('Panel configuration'), value: mapPanelConfiguration(item.panelConfiguration) },
          { label: t('Pitch distance'), value: meters(item.pitchDistance) },
          { label: t('Comments'), value: item?.basicDesignComments },
        ];
      case 'projects_wind_technologies':
        item = technology.details.item as WindItem;
        return [
          { label: t('Turbine Model'), value: item.turbineModel },
          { label: t('Turbine Power'), value: mw(item.turbinePower) },
          { label: t('Number of turbiness'), value: item.turbiness?.toString() },
          { label: t('Comments'), value: item.basicDesignComments },
        ];
      case 'projects_bess_technologies':
        item = technology.details.item as BessItem;
        return [
          { label: t('BESS technology'), value: item.storageTechnology },
          { label: t('Storage capacity'), value: mwh(item.storageCapacity) },
          { label: t('Hours cycles'), value: hours(item.hoursCycles) },
          { label: t('Comments'), value: item.basicDesignComments },
        ];
      default:
        return [];
    }
  }, [t, technology, mapPanelConfiguration, mapStructureType]);

  const filteredData = isNullish(onEdit) ? data.filter(({ value }) => !(isNullish(value) || isEmpty(value))) : data;

  return filteredData.length ? (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody data={filteredData} hidden={hidden} />
    </Collapsable>
  ) : null;
}
