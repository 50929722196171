import { useMemo } from 'react';

import { useGetLanguagesQuery } from '@/features/app/api';
import { Languages } from '@/features/users';

export function useLanguages() {
  const { data: languages } = useGetLanguagesQuery();

  const options = useMemo(() => {
    if (!languages) return [];

    return languages.map((language) => ({
      label: language.name,
      value: language.code as Languages,
    }));
  }, [languages]);

  return { options };
}
