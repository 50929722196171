import { cn } from '@/utils/styles';

export const colors = {
  primary: cn(
    'border-neutral-50 hover:border-primary-700 focus:border-primary-700 focus-visible:border-primary-700 active:border-primary-700 active:ring-primary-50 active:shadow-primary',
    'bg-white hover:bg-white focus:bg-primary-50 focus-visible:bg-primary-50 active:bg-primary-50',
    'checked:border-primary-700 checked:hover:border-primary-700 checked:focus:border-primary-700 checked:focus-visible:border-primary-700 checked:active:border-primary-700 checked:active:ring-primary-50 checked:active:shadow-primary',
    'checked:bg-primary-700 checked:hover:bg-primary-700 checked:focus:bg-primary-700 checked:focus-visible:bg-primary-700 checked:active:bg-primary-700',
    'checked:hover:border-primary-800 checked:hover:bg-primary-800 ',
    'disabled:border-neutral-50 disabled:bg-base-disabled disabled:checked:border-neutral-50 disabled:checked:bg-base-disabled'
  ),
  secondary:
    'border-neutral-50 hover:border-base-primary focus:border-secondary-accent focus-visible:border-secondary-accent active:border-secondary-accent',
  neutral:
    'border-neutral-50 hover:border-base-primary focus:border-base-primary focus-visible:border-base-primary active:border-base-primary',
  warning:
    'border-warning-400 hover:border-warning-400 focus:border-warning-400 focus-visible:border-warning-400 active:border-warning-400',
  error:
    'border-secondary-accent/80 hover:border-secondary-accent focus:bordersecondary-accent focus-visible:border-secondary-accent active:border-secondary-accent',
};
