import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotifications } from '@/features/notifications';

import { useUpdateProjectTechnologyItemMutation, useUpdateProjectTechnologyMutation } from '../api';
import {
  BESSProjectTechnology,
  ProjectTechnology,
  SolarProjectTechnology,
  TechnologyCollection,
  WindProjectTechnology,
} from '../types';

export type useUpdateTechnologyProps = {
  projectId: string;
  opportunityId: string;
  setDrawer: (drawer: null | string) => void;
};

export function useUpdateTechnology({ projectId, opportunityId, setDrawer }: useUpdateTechnologyProps) {
  const { t } = useTranslation();
  const { add } = useNotifications();

  const [projectTechnologyMutation] = useUpdateProjectTechnologyMutation();
  const [projectTechnologyItemMutation] = useUpdateProjectTechnologyItemMutation();

  const onSubmitItem = useCallback(
    (
      collection: TechnologyCollection,
      data: SolarProjectTechnology | WindProjectTechnology | BESSProjectTechnology,
      description?: string
    ) => {
      projectTechnologyItemMutation({ collection, data, projectId: projectId, opportunityId: opportunityId })
        .unwrap()
        .then(() => {
          setDrawer(null);
          add({
            id: new Date().toString(),
            title: t('Project updated'),
            description: description ?? t('Your project has been updated'),
          });
        });
    },
    [t, projectId, opportunityId, add, projectTechnologyItemMutation, setDrawer]
  );

  const onSubmitTechnology = useCallback(
    (id: number, data: Partial<ProjectTechnology>, description?: string | null) => {
      projectTechnologyMutation({ id, data, projectId: projectId, opportunityId: opportunityId })
        .unwrap()
        .then(() => {
          add({
            id: new Date().toString(),
            title: t('Project updated'),
            description: description ?? t('Your project has been updated'),
          });
        })
        .catch(() => {
          add({
            id: new Date().toString(),
            title: t('ERROR'),
            description: t('There was an error updating the project. Please try again later.'),
          });
        })
        .then(() => {
          setDrawer(null);
        });
    },
    [t, projectId, opportunityId, add, projectTechnologyMutation, setDrawer]
  );

  return { onSubmitItem, onSubmitTechnology };
}
