import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Form, Input, TextArea, Typography } from '@/components';
import { WindProjectTechnology } from '@/features/projects/types';
import { nullableStringSetter } from '@/helpers';

export type WindBasicDesignFormValues = {
  turbine_model: string | null;
  turbine_power: number | null;
  turbiness: number | null;
  basic_design_comments: string | null;
};

export type WindBasicDesignFormFields = {
  turbine_model: string | null;
  turbine_power: number | null;
  turbiness: number | null;
  basic_design_comments: string | null;
};

export type WindBasicDesignFormProps = {
  readonly id: string;
  readonly item: WindProjectTechnology;
  readonly onSubmit: (data: WindBasicDesignFormValues) => void;
};

export function WindBasicDesignForm({ id, item, onSubmit }: WindBasicDesignFormProps) {
  const { t } = useTranslation(['projects']);

  const options = useMemo(
    () => ({
      defaultValues: {
        turbine_model: item?.turbine_model ?? null,
        turbine_power: item?.turbine_power ?? null,
        turbiness: item?.turbiness ?? null,
        basic_design_comments: item?.basic_design_comments ?? null,
      },
    }),
    [item]
  );

  return (
    <Form<WindBasicDesignFormFields> id={id} onSubmit={onSubmit} options={options}>
      {({ formState, register }) => (
        <div className="space-y-6">
          <Typography as="p" color="secondary" size="body-3" weight="normal" className="mt-1 italic">
            {t(
              'Please complete this section with the main information of the plant design and technology, including turbine model, turbine power, number of turbines, and any additional comments.'
            )}
          </Typography>
          <Field
            id="turbine_model"
            label={t('Turbine model')}
            error={formState.errors['turbine_model']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="turbine_model"
              type="text"
              color={formState.errors['turbine_model'] ? 'error' : 'primary'}
              registration={register('turbine_model', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <Field
            id="turbine_power"
            label={t('Turbine power')}
            error={formState.errors['turbine_power']}
            delay={0.15}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="turbine_power"
              type="number"
              suffix="MW"
              color={formState.errors['turbine_power'] ? 'error' : 'primary'}
              registration={register('turbine_power', { min: 0, valueAsNumber: true })}
            />
          </Field>

          <Field
            id="turbiness"
            label={t('Number of turbines')}
            error={formState.errors['turbiness']}
            delay={0.25}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="turbiness"
              type="number"
              step="1"
              color={formState.errors['turbiness'] ? 'error' : 'primary'}
              registration={register('turbiness', { min: 0, valueAsNumber: true })}
            />
          </Field>

          <Field
            id="comments"
            label={t('Comments')}
            error={formState.errors['basic_design_comments']}
            delay={0.35}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="comments"
              color={formState.errors['basic_design_comments'] ? 'error' : 'primary'}
              registration={register('basic_design_comments', { setValueAs: nullableStringSetter })}
            />
          </Field>
        </div>
      )}
    </Form>
  );
}
