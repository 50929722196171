import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function ThumbDown({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="thumb-down"
    >
      <path
        d="M6.944 5.14307H5.056C4.473 5.14307 4 5.61607 4 6.19907V13.5871C4 14.1701 4.473 14.6431 5.056 14.6431H6.944C7.527 14.6431 8 14.1701 8 13.5871V6.19907C8 5.61607 7.527 5.14307 6.944 5.14307Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 13.571L11.649 18.322C12.328 19.207 13.654 19.229 14.363 18.368C14.628 18.047 14.772 17.643 14.772 17.227V13.956H17.868C18.469 13.956 19.03 13.656 19.364 13.157L19.693 12.666C19.988 12.225 20.074 11.676 19.927 11.167L18.568 6.445C18.346 5.674 17.641 5.143 16.839 5.143H10.55C10.05 5.143 9.572 5.351 9.232 5.717L8 7.043"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
