import { useTranslation } from 'react-i18next';

import { Button, Drawer } from '@/components';
import {
  BESSProjectTechnology,
  ProjectTechnology,
  SolarProjectTechnology,
  TechnologyCollection,
  WindProjectTechnology,
} from '@/features/projects/types';

import { BESSBasicDesignForm, BESSBasicDesignFormFields } from './bess-form';
import { SolarBasicDesignForm, SolarBasicDesignFormValues } from './solar-form';
import { WindBasicDesignForm, WindBasicDesignFormValues } from './wind-form';

export type BasicDesignDrawerProps = {
  readonly technology: ProjectTechnology;
  readonly isOpen: boolean;
  readonly onSubmit: (
    collection: TechnologyCollection,
    item: SolarProjectTechnology | WindProjectTechnology | BESSProjectTechnology,
    description?: string
  ) => void;
  readonly onClose: () => void;
};

export function BasicDesignDrawer({ technology, isOpen, onSubmit, onClose }: BasicDesignDrawerProps) {
  const { t } = useTranslation(['projects']);

  const footer = (
    <>
      <Button size="lg" color="neutral" onClick={onClose}>
        {t('Cancel')}
      </Button>
      <Button type="submit" form="basic-design-form" size="lg" color="primary">
        {t('Save')}
      </Button>
    </>
  );

  return (
    <Drawer title={t('Basic Design')} open={isOpen} onClose={onClose} footer={footer}>
      {technology.collection === 'projects_solar_technologies' ? (
        <SolarBasicDesignForm
          id="basic-design-form"
          item={technology.item as SolarProjectTechnology}
          onSubmit={(values: SolarBasicDesignFormValues) =>
            onSubmit(
              'projects_solar_technologies',
              { ...technology.item, ...values },
              t('Basic design has been updated')
            )
          }
        />
      ) : null}
      {technology.collection === 'projects_wind_technologies' ? (
        <WindBasicDesignForm
          id="basic-design-form"
          item={technology.item as WindProjectTechnology}
          onSubmit={(values: WindBasicDesignFormValues) =>
            onSubmit(
              'projects_wind_technologies',
              { ...technology.item, ...values },
              t('Basic design has been updated')
            )
          }
        />
      ) : null}
      {technology.collection === 'projects_bess_technologies' ? (
        <BESSBasicDesignForm
          id="basic-design-form"
          item={technology.item as BESSProjectTechnology}
          onSubmit={(values: BESSBasicDesignFormFields) =>
            onSubmit(
              'projects_bess_technologies',
              { ...technology.item, ...values },
              t('Basic design has been updated')
            )
          }
        />
      ) : null}
    </Drawer>
  );
}
