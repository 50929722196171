import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Form, Input, TextArea, Typography } from '@/components';
import { ProjectTechnology } from '@/features/projects/types';
import { formatStringAsInteger, nullableStringSetter, parseStringToInteger } from '@/helpers';

export type CapexOpexFormFields = {
  estimated_epc: number | null;
  epc_comments: string | null;
  om_price: number | null;
  om_term: number | null;
  om_comments: string | null;
  insurance_price: number | null;
  insurance_comments: string | null;
};

export type CapexOpexFormProps = {
  readonly id: string;
  readonly technology: ProjectTechnology;
  readonly onSubmit: (data: Partial<ProjectTechnology>) => void;
};

export function CapexOpexForm({ id, technology, onSubmit }: CapexOpexFormProps) {
  const { t } = useTranslation(['projects']);

  const options = useMemo(
    () => ({
      defaultValues: {
        estimated_epc: technology.estimated_epc,
        epc_comments: technology.epc_comments,
        om_price: technology.om_price,
        om_term: technology.om_term,
        om_comments: technology.om_comments,
        insurance_price: technology.insurance_price,
        insurance_comments: technology.insurance_comments,
      },
    }),
    [technology]
  );

  return (
    <Form<CapexOpexFormFields> id={id} onSubmit={(data) => onSubmit(data)} options={options}>
      {({ formState, register }) => (
        <div className="space-y-6">
          <Typography as="p" color="secondary" size="body-3" weight="normal" className="mt-1 italic">
            {t(
              "Please provide information about the project's CAPEX and OPEX in this section, including estimated EPC costs, O&M price and contract term, insurance price, and any relevant comments."
            )}
          </Typography>
          <Field
            id="estimated_epc"
            label={t('Estimated EPC')}
            error={formState.errors['estimated_epc']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="estimated_epc"
              type="text"
              mask={formatStringAsInteger}
              placeholder={t('e.g. 15.000.000')}
              color={formState.errors['estimated_epc'] ? 'error' : 'primary'}
              suffix="€"
              registration={register('estimated_epc', { setValueAs: parseStringToInteger })}
            />
          </Field>

          <Field
            id="epc_comments"
            label={t('EPC comments')}
            error={formState.errors['epc_comments']}
            delay={0.15}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="epc_comments"
              placeholder={t('Type here...')}
              color="primary"
              registration={register('epc_comments', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <Field
            id="om_price"
            label={t('O&M price')}
            error={formState.errors['om_price']}
            delay={0.3}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="om_price"
              type="text"
              mask={formatStringAsInteger}
              placeholder={t('e.g. 15.000.000')}
              color="primary"
              suffix="€"
              registration={register('om_price', { setValueAs: parseStringToInteger })}
            />
          </Field>

          <Field
            id="om_term"
            label={t('O&M contract term')}
            error={formState.errors['om_term']}
            delay={0.45}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="om_term"
              type="number"
              step="1"
              placeholder={t('e.g. 5')}
              color="primary"
              suffix="Years"
              registration={register('om_term', { valueAsNumber: true })}
            />
          </Field>

          <Field
            id="om_comments"
            label={t('O&M comments')}
            error={formState.errors['om_comments']}
            delay={0.45}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="om_comments"
              placeholder={t('Type here...')}
              color="primary"
              registration={register('om_comments', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <Field
            id="insurance_price"
            label={t('Insurance price')}
            error={formState.errors['insurance_price']}
            delay={0.6}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="insurance_price"
              type="text"
              mask={formatStringAsInteger}
              placeholder={t('e.g. 15.000.000')}
              color="primary"
              suffix="€"
              registration={register('insurance_price', { setValueAs: parseStringToInteger })}
            />
          </Field>

          <Field
            id="insurance_comments"
            label={t('Insurance comments')}
            error={formState.errors['insurance_comments']}
            delay={0.75}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="insurance_comments"
              placeholder={t('Type here...')}
              color="primary"
              registration={register('insurance_comments', { setValueAs: nullableStringSetter })}
            />
          </Field>
        </div>
      )}
    </Form>
  );
}
