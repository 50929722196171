import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { useCollapsableAction } from '@/features/projects/hooks';
import { useBooleanDisplayNormalizer } from '@/hooks';
import { isEmpty, isNullish } from '@/types';

import { Technology } from '../../../types';

export type OthersCollapsableProps = {
  readonly technology: Technology;
  readonly initialExpandedState?: boolean;
  readonly onEdit?: () => void;
  readonly hidden?: boolean;
};

export function OthersCollapsable({
  technology,
  onEdit,
  initialExpandedState = false,
  hidden,
}: OthersCollapsableProps) {
  const { t } = useTranslation(['projects']);

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const action = useCollapsableAction({ onEdit, setIsExpanded });

  const item = technology.details.others;

  const booleanFormatter = useBooleanDisplayNormalizer();
  const description = onEdit
    ? t(
        'Please provide all additional information regarding the project in this section, such as whether a PPA or debt is included, and provide the relevant details.'
      )
    : t(
        'This section provides all the additional information regarding the project, for example whether a PPA or debt are included.'
      );
  const header = (
    <CollapsableHeader
      title={t('Others')}
      description={description}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
      action={action}
    />
  );

  const data = [
    { label: t('PPA included'), value: booleanFormatter(item.hasPpa) },
    { label: t('PPA Details'), value: item.ppaComments },
    { label: t('Debt included'), value: booleanFormatter(item.hasDebt) },
    { label: t('Debt Details'), value: item.debtComments },
  ];

  const filteredData = isNullish(onEdit) ? data.filter(({ value }) => !(isNullish(value) || isEmpty(value))) : data;

  return filteredData.length ? (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody data={filteredData} hidden={hidden} />
    </Collapsable>
  ) : null;
}
