import { api } from '@/features/auth';

import {
  FileResponse,
  RemoveFileRequest,
  UpdateFileRequest,
  UploadFileRequest,
  GetFileRequest,
  GetFileResponse,
} from './types';

export const fileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFile: builder.query<GetFileResponse['data'], GetFileRequest>({
      query: ({ id }) => ({
        url: `files/${id}`,
        method: 'GET',
        params: {
          fields: '*',
        },
      }),
      transformResponse: ({ data }: GetFileResponse) => data,
    }),

    uploadFile: builder.mutation<FileResponse['data'], UploadFileRequest>({
      query: ({ file, folder }: UploadFileRequest) => {
        const formData = new FormData();

        formData.append('folder', folder);
        formData.append('file', file);

        return {
          url: `files`,
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      transformResponse: ({ data }: FileResponse) => data,
    }),
    replaceFile: builder.mutation<FileResponse['data'], UpdateFileRequest>({
      query: ({ id, file }: UpdateFileRequest) => {
        const formData = new FormData();

        formData.append('file', file);

        return {
          url: `files/${id}`,
          method: 'PATCH',
          body: formData,
          formData: true,
        };
      },
      transformResponse: ({ data }: FileResponse) => data,
    }),
    removeFile: builder.mutation<void, RemoveFileRequest>({
      query: ({ id }: RemoveFileRequest) => {
        return {
          url: `files/${id}`,
          method: 'DELETE',
        };
      },
    }),
    removeAllFiles: builder.mutation<void, string[]>({
      query: (ids: string[]) => {
        return {
          url: `files/`,
          method: 'DELETE',
          body: ids,
        };
      },
    }),
  }),
});

export const {
  useUploadFileMutation,
  useReplaceFileMutation,
  useRemoveFileMutation,
  useRemoveAllFilesMutation,
  useGetFileQuery,
} = fileApi;
