import { Blank, Empty, Nullish, Primitive } from './types';

export function isUndefined(value: unknown): value is undefined {
  return value === undefined;
}

export function isNull(value: unknown): value is null {
  return value === null;
}

export function isNullish(value: unknown): value is Nullish {
  return [isUndefined, isNull].some((guard) => guard(value));
}

export function isEmpty(value: unknown): value is Empty {
  return value === '';
}

export function isBlank(value: unknown): value is Blank {
  return [isUndefined, isNull, isEmpty].some((guard) => guard(value));
}

export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

export function isSymbol(value: unknown): value is symbol {
  return typeof value === 'symbol';
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isPrimitive(value: unknown): value is Primitive {
  return [isUndefined, isNull, isSymbol, isBoolean, isNumber, isString].some((guard) => guard(value));
}

export function isArray(value: unknown): value is Array<any> {
  return Array.isArray(value);
}

export function isObject(value: unknown): value is object {
  return typeof value === 'object' && !isNull(value);
}

export function isDate(value: unknown): value is Date {
  return value instanceof Date;
}

export function isRegex(value: unknown): value is RegExp {
  return value instanceof RegExp;
}

export function isError(value: unknown): value is Error {
  return value instanceof Error;
}

export function isPromise(value: unknown): value is Promise<never> {
  return value instanceof Promise;
}
