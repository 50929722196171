import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Lightning({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="lightning"
    >
      <path
        d="M18.9393 9.73445C18.9063 9.66614 18.8547 9.60874 18.7905 9.56901C18.7263 9.52928 18.6522 9.50888 18.5769 9.5102H12.4987L14.0388 2.54172C14.0671 2.44243 14.0585 2.33612 14.0145 2.24281C13.9705 2.1495 13.8942 2.07562 13.8 2.03508C13.7129 1.99651 13.6153 1.98951 13.5238 2.01524C13.4322 2.04098 13.3523 2.09787 13.2976 2.17627L5.0615 13.8042C5.02131 13.869 5 13.9438 5 14.0202C5 14.0966 5.02131 14.1714 5.0615 14.2361C5.08862 14.3064 5.13455 14.3676 5.19417 14.4131C5.2538 14.4586 5.32477 14.4864 5.39918 14.4936H11.4774L9.93725 21.4621C9.90894 21.5614 9.9176 21.6677 9.96159 21.761C10.0056 21.8543 10.0819 21.9282 10.1761 21.9687C10.2636 22.0043 10.3602 22.0097 10.4511 21.9841C10.5419 21.9586 10.6219 21.9036 10.6785 21.8275L18.9146 10.1996C18.9654 10.1339 18.995 10.0541 18.9994 9.97093C19.0038 9.88775 18.9828 9.80521 18.9393 9.73445Z"
        fill="currentColor"
      />
    </svg>
  );
}
