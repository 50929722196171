import { Service, ServiceItemList } from '../types';

export function serviceItemListTransformer(service: Service): ServiceItemList {
  return {
    id: service.id,
    status: service.status,
    name: service.name,
    logo: service.logo?.id ?? null,
    category: service.category.code,
    tags: service.tags.map((tag) => tag.tag),
    location: service.address,
  };
}
