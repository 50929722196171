import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Trash({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="trash"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.69971 5.86541C4.84165 5.71213 5.04109 5.625 5.25 5.625H18.75C18.9589 5.625 19.1583 5.71213 19.3003 5.86541C19.4422 6.01869 19.5138 6.22423 19.4978 6.43252L18.5326 18.9801C18.4746 19.7337 18.1343 20.4376 17.5798 20.9512C17.0252 21.4647 16.2972 21.75 15.5414 21.75H8.45857C7.70275 21.75 6.97478 21.4647 6.42022 20.9512C5.86566 20.4376 5.52538 19.7337 5.46741 18.9801L4.50221 6.43252C4.48619 6.22423 4.55777 6.01869 4.69971 5.86541ZM6.05991 7.125L6.96299 18.865C6.99198 19.2418 7.16212 19.5938 7.4394 19.8506C7.71668 20.1074 8.08066 20.25 8.45857 20.25H15.5414C15.9193 20.25 16.2833 20.1074 16.5606 19.8506C16.8379 19.5938 17.008 19.2418 17.037 18.865L17.9401 7.125H6.05991Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 6.375C3.25 5.96079 3.58579 5.625 4 5.625H20C20.4142 5.625 20.75 5.96079 20.75 6.375C20.75 6.78921 20.4142 7.125 20 7.125H4C3.58579 7.125 3.25 6.78921 3.25 6.375Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.18748 3.75C9.08802 3.75 8.99264 3.78951 8.92232 3.85984C8.85199 3.93016 8.81248 4.02554 8.81248 4.125V5.625H15.1875V4.125C15.1875 4.02554 15.148 3.93016 15.0776 3.85984C15.0073 3.78951 14.9119 3.75 14.8125 3.75H9.18748ZM7.86165 2.79917C8.21329 2.44754 8.6902 2.25 9.18748 2.25H14.8125C15.3098 2.25 15.7867 2.44754 16.1383 2.79917C16.4899 3.15081 16.6875 3.62772 16.6875 4.125V6.375C16.6875 6.78921 16.3517 7.125 15.9375 7.125H8.06248C7.64827 7.125 7.31248 6.78921 7.31248 6.375V4.125C7.31248 3.62772 7.51002 3.15081 7.86165 2.79917Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 17C9.25 16.5858 9.58579 16.25 10 16.25H14C14.4142 16.25 14.75 16.5858 14.75 17C14.75 17.4142 14.4142 17.75 14 17.75H10C9.58579 17.75 9.25 17.4142 9.25 17Z"
        fill="currentColor"
      />
    </svg>
  );
}
