import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initial-state';
import { add, remove } from './reducers';

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initialState(),
  reducers: { add, remove },
});
