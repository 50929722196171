import { ClassValue } from 'clsx';
import { ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

import { AnimationShell } from '@/components/animation-shell';
import { cn } from '@/utils/styles';

import { Hint } from '../hint';
import { Label } from '../label';

export type FieldProps = {
  readonly children: ReactNode;
  readonly id?: string;
  readonly label?: string;
  readonly error?: FieldError;
  readonly delay?: number;
  readonly duration?: number;
  readonly classes?: {
    field?: ClassValue;
    label?: ClassValue;
    input?: ClassValue;
    hint?: ClassValue;
  };
};

export function Field({ children, id, label, error, delay, duration, classes }: FieldProps) {
  return (
    <AnimationShell type="opacity" delay={delay} duration={duration} className={cn(classes?.field)}>
      {label ? (
        <Label id={id} className={classes?.label}>
          {label}
        </Label>
      ) : null}

      <div className={cn(classes?.input)}>{children}</div>

      {error?.message ? (
        <Hint color="error" className={classes?.hint}>
          {error.message}
        </Hint>
      ) : null}
    </AnimationShell>
  );
}
