import { useTranslation } from 'react-i18next';

import { Container, Link, Overlay, Typography } from '@/components';

import { OpportunityDetails } from '../../types';

import { OpportunityQuestionsList } from './opportunity-questions-list';
import { useBelongsToMyCompany, useCompanyRole } from '@/features/company/hooks';

export type OpportunityQuestionsTabProps = {
  readonly opportunity: OpportunityDetails;
};

export function OpportunityQuestionsTab({ opportunity }: OpportunityQuestionsTabProps) {
  const { t } = useTranslation(['opportunities']);
  const { isPremium } = useCompanyRole();

  const opportunityBelongsToMyCompany = useBelongsToMyCompany(opportunity.company);
  const isPublished = opportunity.isPublished;

  const userPremiumMessage = (
    <Container className="space-y-4 p-0">
      <div className="rounded-xl border border-neutral-50 bg-white p-8">
        <div className="rounded-lgp-8 mt-4">
          <Typography as="p" size="body-1" color="primary" weight="semibold">
            {t('We are working on a Q&A for our premium users, like you! ')}
          </Typography>
          <Typography as="p" size="body-1" color="primary" weight="normal" className="mt-2">
            {t('On the meaitime, you can send your Q&A or doubts to ')}
            <Link to="mailto:projects@nteaser.com">projects@nteaser.com</Link>
          </Typography>
        </div>
      </div>
    </Container>
  );

  const userFreeMessage = <Overlay></Overlay>;

  const isMineAndPublished = <OpportunityQuestionsList />;

  return opportunityBelongsToMyCompany && isPublished
    ? isMineAndPublished
    : isPremium
      ? userPremiumMessage
      : userFreeMessage;
}
