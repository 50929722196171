import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '@/store';

import { add, remove } from '../store';
import { Notification } from '../types';

export function useNotifications() {
  const { notifications } = useAppSelector((state) => state.notifications);

  const dispatch = useDispatch();

  return {
    notifications,
    add: useCallback(
      ({ id = Date.now(), title, description }: Notification, delay = 6000) => {
        dispatch(add({ id, title, description }));
        setTimeout(() => dispatch(remove(id)), delay);
      },
      [dispatch]
    ),
    remove: useCallback((id: string | number) => dispatch(remove(id)), [dispatch]),
  } as const;
}
