import { useMemo } from 'react';

import { useCountries } from '@/hooks';

export type LocationProps = {
  location: {
    country: string;
    city: string[];
  }[];
};

export function useLocation({ location }: LocationProps): string | null {
  const { kv } = useCountries();
  return useMemo(() => {
    if (!location || !location.length) return null;

    return location
      .map(({ country, city }) => {
        return `${kv[country]} (${city.map((c) => c).join(', ')})`;
      })
      .join(', ');
  }, [kv, location]);
}
