import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function People({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="people"
    >
      <path
        d="M14 19.3C13.9215 19.3 13.843 19.2695 13.7878 19.2189C13.7391 19.1742 13.7 19.107 13.7 19C13.7 16.9137 11.9659 15.3 9.99995 15.3H5.99995C3.91373 15.3 2.29995 17.034 2.29995 19C2.29995 19.0784 2.26948 19.1569 2.21887 19.2121C2.1742 19.2608 2.10704 19.3 1.99995 19.3C1.89286 19.3 1.8257 19.2608 1.78103 19.2121C1.73042 19.1569 1.69995 19.0784 1.69995 19C1.69995 16.6693 3.58285 14.7 5.99995 14.7H9.99995C12.3306 14.7 14.3 16.5829 14.3 19C14.3 19.0373 14.2762 19.1166 14.1964 19.1964C14.1166 19.2762 14.0373 19.3 14 19.3Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M5.34254 5.86408L5.34805 5.85888L5.35341 5.85353C6.86428 4.34266 9.23894 4.35855 10.6358 5.84266L10.6409 5.84817L10.6463 5.85353C12.1572 7.3644 12.1413 9.73907 10.6572 11.1359L10.6517 11.1411L10.6463 11.1464C9.95033 11.8424 8.9825 12.2 7.99985 12.2C7.0172 12.2 6.04937 11.8424 5.35341 11.1464C3.84254 9.63555 3.85843 7.26088 5.34254 5.86408ZM10.3534 6.14642L10.3374 6.13038L10.3199 6.11587C9.61663 5.52977 8.79802 5.29998 7.99985 5.29998C7.21694 5.29998 6.36216 5.52347 5.73816 6.25332C4.44827 7.45329 4.45377 9.56971 5.75466 10.7619C7.05023 12.0488 9.06093 12.046 10.3534 10.7535C11.6487 9.45827 11.6487 7.44168 10.3534 6.14642Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M22 17.8C21.6 17.8 21.2 17.5 21.2 17C21.2 15.8 20.2 14.8 19 14.8H16C15.6 14.8 15.2 14.5 15.2 14C15.2 13.5 15.5 13.2 16 13.2H19C21.1 13.2 22.8 14.9 22.8 17C22.8 17.4 22.4 17.8 22 17.8Z"
        fill="currentColor"
      />
      <path
        d="M17.5 11.7C16.7 11.7 15.8 11.4 15.2 10.7C14.6 10.1 14.2 9.3 14.2 8.4C14.2 7.5 14.5 6.7 15.2 6.1C16.5 4.8 18.5 4.8 19.8 6.1C20.4 6.7 20.8 7.5 20.8 8.4C20.8 9.3 20.5 10.1 19.8 10.7C19.2 11.4 18.3 11.7 17.5 11.7ZM17.5 6.8C17.1 6.8 16.6 7 16.3 7.3C16 7.6 15.8 8.1 15.8 8.5C15.8 8.9 16 9.4 16.3 9.7C17 10.4 18.1 10.4 18.8 9.7C19.1 9.4 19.3 8.9 19.3 8.5C19.3 8.1 19.1 7.6 18.8 7.3C18.4 6.9 17.9 6.8 17.5 6.8Z"
        fill="currentColor"
      />
    </svg>
  );
}
