import { AnimationShell, Typography } from '@/components';

export function PrivacyPolicy() {
  return (
    <AnimationShell type="scale" className="mt-8 flex flex-col items-center justify-center space-y-8 text-center">
      <Typography as="h1" size="heading-1" color="primary" weight="semibold">
        <b>NTEASER</b>
      </Typography>
      <Typography as="h1" size="heading-1" color="primary" weight="semibold">
        <b>PRIVACY POLICY</b>
      </Typography>

      <div className="mx-20 text-left ">
        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          1. INTRODUCTION
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          <b>NTEASER PLATFORM S.L.</b> (hereinafter, &quot;<b>nTeaser</b>&quot;) is the owner of the platform (&quot;
          <b>Platform</b>&quot;) available at https://nteaser.com/ and https://app.nteser.com/ (hereinafter the &quot;
          <b>Website</b>&quot;) and is responsible for the processing of the personal data of the Developers, Investors
          and Other Users thereof (hereinafter the &quot;<b>Users</b>&quot;).
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          Through this Privacy Policy, and in compliance with Articles 12 and 13 of Regulation (EU) 2016/679
          (hereinafter &quot;<b>GDPR</b>&quot;) and Article 11 of the Organic Law 3/2018, of December 5, on the
          Protection of Personal Data and guarantee of digital rights (&quot;<b>LOPDPGDD</b>&quot;), nTeaser informs
          Users who use the Website and/or Platform about the processing of their personal data that may be collected
          through this and processed by nTeaser.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          In order to allow the User to access and use the Platform and nTeaser Services, nTeaser may process the
          identification data necessary for the execution of the Terms and Conditions and for the invoicing of the
          contracted services, and more generally to manage our contractual and/or commercial relationship with the
          Users, and to inform them promptly about any aspect related to the Services provided or that can be performed
          by nTeaser in the future.
        </Typography>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          2. DATA CONTROLLER
        </Typography>
        <ul className="ml-8 list-disc">
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              <b>Data Controller</b>: NTEASER PLATFORM S.L.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              <b>VAT Number</b>: B-10871036
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              <b>Registered office</b>: calle Meléndez Valdés 45, 4D Madrid, 28015, España.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
              <b>Contact</b>: <a href="mailto:clients@nteaser.com">clients@nteaser.com</a>
            </Typography>
          </li>
        </ul>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-8">
          3. PURPOSES, DATA PROCESSED, LEGAL BASIS AND RETENTION PERIOD
        </Typography>

        <Typography as="p" size="body-2" color="primary" weight="semibold" className="mb-8 ml-4">
          a) Personal data collected on the nTeaser website:
        </Typography>

        {/* TABLA */}
        <table className="mb-8 w-full">
          <thead className="bg-neutral-100">
            <tr className="border-b border-neutral-500">
              <th className="w-1/4 p-2 text-left">Data processed</th>
              <th className="w-1/4 p-2 text-left">Purposes</th>
              <th className="w-1/4 p-2 text-left">Legal Basis</th>
              <th className="w-1/4 p-2 text-left">Retention period</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-neutral-500">
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  <b>Browsing data.</b> The IP address of User&apos;s computer and the type of browser User is using. We
                  use this information to analyze general trends and improve the service. This information is not shared
                  with third parties without User&apos;s consent.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  Management of the functionality of the Platform and/or Website. Analysis of browsing behavior and
                  statistics: The information collected through cookies and other similar tracking technologies allow an
                  analysis of the navigation made by Users.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  Consent of the User (Not necessary cookies). Legitimate Interest (Necessary Cookies).
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  The retention periods depend on each specific cookie. For more information on the information
                  retention periods for each type of cookie, see the Cookie Policy.
                </Typography>
              </td>
            </tr>
            <tr className="border-b border-neutral-500">
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  <b>Contact data.</b> Email.{' '}
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  To attend and respond to requests, comments, suggestions. As well as providing support to the User
                  during the use of the Website and/or Platform.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  Consent.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  The personal data will be processed by nTeaser for the necessary period of time to answer the question
                  indicated by the User. Such data will be blocked in order to comply with nTeaser&apos; legal
                  obligations and, after that time, will be definitively deleted.
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>

        <Typography as="p" size="body-2" color="primary" weight="semibold" className="mb-8 ml-4">
          b) Personal data collected on the nTeaser platform:
        </Typography>

        <table className="mb-8 w-full">
          <thead className="bg-neutral-100">
            <tr className="border-b border-neutral-500">
              <th className="w-1/4 p-2 text-left">Data processed</th>
              <th className="w-1/4 p-2 text-left">Purposes</th>
              <th className="w-1/4 p-2 text-left">Legal Basis</th>
              <th className="w-1/4 p-2 text-left">Retention period</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-neutral-500">
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  <b>Browsing data.</b> The IP address of User&apos;s computer and the type of browser User is using. We
                  use this information to analyze general trends and improve the service. This information is not shared
                  with third parties without User&apos;s consent.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  Management of the functionality of the Platform and/or Website. Analysis of browsing behavior and
                  statistics: The information collected through cookies and other similar tracking technologies allow an
                  analysis of the navigation made by Users.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  Consent of the User (Not necessary cookies). Legitimate Interest (Necessary Cookies).
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  The retention periods depend on each specific cookie. For more information on the information
                  retention periods for each type of cookie, see the Cookie Policy.
                </Typography>
              </td>
            </tr>
            <tr className="border-b border-neutral-500">
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  <b>User data.</b> User&apos;s name, surname, email, password.{' '}
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  Sign up on the Platform. Manage the registration of Users on the Platform.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  Execution of a contract.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  The personal data provided will be kept for as long as the User maintains its account. In the event
                  that the User wishes to cancel his/her account nTeaser will proceed to delete the data*.
                </Typography>
              </td>
            </tr>
            <tr className="border-b border-neutral-500">
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  <b>Profile data.</b> Name, surname, phone number, image and professional title.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  Manage and personalize the User experience on our Platform.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  Execution of a contract.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  The personal data provided will be kept for as long as the User maintains its account. In the event
                  that the User wishes to cancel his/her account nTeaser will proceed to delete the data*.
                </Typography>
              </td>
            </tr>
            <tr className="border-b border-neutral-500">
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  <b>Invite a member. </b>Name, surname and email address.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  Management of the invitation process to become a member of nTeaser, sending communications related to
                  the invitation and providing access to the services offered by the Platform.
                </Typography>
              </td>

              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  Legitimate interest.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  Personal data will be retained for the time necessary to manage the invitation process and to send
                  related communications. Once the invitation has been completed and a period of six months has passed
                  since the last communication related to the invitation was sent, the data will be securely deleted,
                  unless there is a legal obligation to retain it for an additional period.
                </Typography>
              </td>
            </tr>
            <tr className="border-b border-neutral-500">
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  <b>Transactional emails. </b> User&apos;s e-mail address.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  nTeaser will send communications relating to transactions and services provided by nTeaser.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  Legitimate interest.
                </Typography>
              </td>
              <td className="w-1/4 p-4 text-left align-top">
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  The personal data will be processed by nTeaser for as long as the User has its account actived. In the
                  event that the User wishes to cancel his/her account nTeaser will proceed to delete the data*.
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>

        <Typography as="p" size="body-2" color="primary" weight="normal" className="mb-8">
          <i>
            * nTeaser may also process the User&apos;s personal data during the period of time in which civil,
            commercial, administrative, criminal or tax liabilities may arise, in compliance with the applicable
            regulations in force. Once these periods have expired, nTeaser will delete this personal data.
          </i>
        </Typography>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          4. DATA COMMUNICATION TO THIRD PARTIES AND INTERNATIONAL TRANSFERS
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          nTeaser informs that, for the fulfillment of the purposes described above, it will not transfer the
          User&apos;s personal data to third parties. Notwithstanding the above, nTeaser may give access to such
          personal data to technological service providers with which it has entered into a data processor contract, in
          particular to:
        </Typography>
        <ul className="ml-8">
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              - Database maintenance.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              - Analytics service providers.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              - Hosting service providers.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              - Email service providers.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              - Consent Management Platform providers.
            </Typography>
          </li>
        </ul>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          The aforementioned providers may be located in jurisdictions that generally do not provide adequate safeguards
          in relation to the processing of personal data. For all entities that are not part of the European Economic
          Area (EEA), nTeaser has entered into contracts with such entities that include such safeguards, including the
          European Commission&apos;s standard clauses.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          Also, the personal data may be provided to:
        </Typography>
        <ul className="ml-8 list-disc">
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-2">
              Companies interested in buying or acquiring nTeaser, a part of its business and, consequently, give access
              to any national or international auditor to carry out their due diligence.
            </Typography>
          </li>
          <li>
            <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
              Authorities in order to investigate suspected fraud, harassment or other violations of any law, rule or
              regulation, or of the Platform.
            </Typography>
          </li>
        </ul>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          5. SECURITY AND CONFIDENTIALITY
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          nTeaser undertakes to adopt the necessary technical and organizational measures, in accordance with the
          provisions of the applicable regulations, to ensure the security of personal data and to prevent the
          accidental or unlawful destruction, loss or alteration of personal data transmitted, stored or otherwise
          processed, or unauthorized communication or access to such data. The personal data will be processed
          confidentially by nTeaser, which undertakes to inform and enforce, by legal or contractual obligation, such
          confidentiality to its employees, partners and any person who should have access to the User&apos;s personal
          data.
        </Typography>

        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          6. USERS RIGHTS
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-4">
          The User may exercise the rights of access, rectification, deletion, opposition and, where appropriate,
          limitation of processing and portability of data by sending an email to{' '}
          <a href="mailto:clients@nteaser.com">clients@nteaser.com</a>.
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          Likewise, the User may file a complaint with a supervisory authority and, in particular, with the Spanish Data
          Protection Agency (www.aepd.es) if the User considers that the rights set forth above in this Privacy Policy
          have been violated or the User considers that the processing of personal data concerning him/her violates the
          applicable regulations.
        </Typography>
        <Typography as="h3" size="title" color="primary" weight="semibold" className="mb-4">
          7. CHANGES IN THE PRIVACY POLICY
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          nTeaser reserves the right to modify this Privacy Policy at any time. If the Changes or updates to the Privacy
          Policy will be explicitly notified to the User by means of a notice on the Website and/or Platform, together
          with the updated version of the Privacy Policy.
        </Typography>

        <Typography as="p" size="body-2" color="secondary" weight="normal" className="mb-8">
          Latest version: November 2024
        </Typography>
      </div>

      <div className="mx-20 text-left "></div>
    </AnimationShell>
  );
}
