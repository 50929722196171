import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initial-state';
import { close, open, toogle } from './reducers';

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: initialState(),
  reducers: { toogle, open, close },
});
