import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { useCollapsableAction } from '@/features/projects/hooks';
import { euros, years } from '@/helpers';
import { isEmpty, isNullish } from '@/types';

import { Technology } from '../../../types';

export type CapexOpexCollapsableProps = {
  readonly technology: Technology;
  readonly initialExpandedState?: boolean;
  readonly onEdit?: () => void;
  readonly hidden?: boolean;
};

export function CapexOpexCollapsable({
  technology,
  onEdit,
  initialExpandedState = false,
  hidden,
}: CapexOpexCollapsableProps) {
  const { t } = useTranslation(['projects']);

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const action = useCollapsableAction({ onEdit, setIsExpanded });

  const item = technology.details.capexOpex;

  const description = onEdit
    ? t(
        "Please provide information about the project's CAPEX and OPEX in this section, including estimated EPC costs, O&M price and contract term, insurance price, and any relevant comments."
      )
    : t(
        "This section provides information about the project's CAPEX and OPEX, including estimated EPC, O&M and insurance details."
      );

  const header = (
    <CollapsableHeader
      title={t('CAPEX/OPEX')}
      description={description}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
      action={action}
    />
  );

  const data = [
    { label: t('Estimated EPC'), value: euros(item.estimatedEpc) },
    { label: t('EPC comments'), value: item.epcComments },
    { label: t('O&M Price'), value: euros(item.omPrice) },
    { label: t('O&M Contract term'), value: years(item.omTerm) },
    { label: t('O&M Comments'), value: item.omComments },
    { label: t('Insurance Price'), value: euros(item.insurancePrice) },
    { label: t('Insurance Comments'), value: item.insuranceComments },
  ];
  const filteredData = isNullish(onEdit) ? data.filter(({ value }) => !(isNullish(value) || isEmpty(value))) : data;

  return filteredData.length ? (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody data={filteredData} hidden={hidden} />
    </Collapsable>
  ) : null;
}
