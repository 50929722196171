import { opportunityItemListTransformer } from '@/features/opportunities/helpers';

import { Offer, OfferHeaderDetails } from '../types';

import { getColorFromOfferStatus } from './helpers';

export function offerHeaderDetailsTransformer(offer?: Offer): OfferHeaderDetails | null {
  if (!offer) return null;

  return {
    id: offer.id,
    status: {
      name: offer.status,
      color: getColorFromOfferStatus(offer.status),
    },
    company: offer.company.id,
    opportunity: opportunityItemListTransformer(offer.opportunity),
  };
}
