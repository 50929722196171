import { getTechnologyTypeFromCollection } from '../helpers';
import {
  BessItem,
  BESSProjectTechnology,
  ProjectTechnology,
  SolarItem,
  SolarProjectTechnology,
  TechnologyCollection,
  TechnologyDetails,
  TechnologyType,
  WindItem,
  WindProjectTechnology,
} from '../types';

export type TechnologyTransformerReturn = {
  id: number;
  project: string;
  type: TechnologyType;
  collection: TechnologyCollection;
  details: TechnologyDetails;
};

export function technologyTransformer(technology: ProjectTechnology): TechnologyTransformerReturn {
  const technologyType = getTechnologyTypeFromCollection(technology.collection);
  return {
    id: technology.id,
    project: technology.project.id ?? technology.project,
    type: technologyType,
    collection: technology.collection,
    details: technologyDetailsTransformer({ technologyType, technology }),
  };
}

export type technologyTrasformerProps = {
  technologyType: TechnologyType;
  technology: ProjectTechnology;
};

export function technologyDetailsTransformer({
  technologyType,
  technology,
}: technologyTrasformerProps): TechnologyDetails {
  let item;
  const technologyDetails = {
    id: technology.id,
    developmentStatus: {
      status: technology.item.development_status,
    },
    item: {} as SolarItem | WindItem | BessItem,
    files: technology.files?.map((file) => file.file) ?? [],
    permittingPLA: {
      permits: technology.permits,
      comments: technology.permiting_comments,
    },
    landRights: {
      surface: technology.land_surface,
      type: technology.land_types,
      price: technology.land_price,
      contractDuration: technology.land_contract_duration,
      contractedPercentage: technology.land_contracted_percentage,
      comments: technology.land_comments,
      dupStatus: technology.dup_status,
      dupDate: technology.dup_date,
      dupComments: technology.dup_comments,
    },
    connectionInfrastructure: {
      evacuationLineTotalDistance: technology.evacuation_line_total_distance,
      evacuationLineVoltage: technology.evacuation_line_voltage,
      connectionType: technology.connection_type,
      connectionTo: technology.connection_to,
      connectionCosts: technology.connection_costs,
      connectionCostsComments: technology.connection_costs_comments,
      sharedInfrastructure: technology.shared_infrastructure,
      sharedInfrastructureComments: technology.shared_infrastructure_comments,
      connectionInfrastructureComments: technology.connection_infrastructure_comments,
    },
    capexOpex: {
      estimatedEpc: technology.estimated_epc,
      epcComments: technology.epc_comments,
      omPrice: technology.om_price,
      omTerm: technology.om_term,
      omComments: technology.om_comments,
      insurancePrice: technology.insurance_price,
      insuranceComments: technology.insurance_comments,
    },
    others: {
      hasPpa: technology.has_ppa,
      ppaComments: technology.ppa_comments,
      hasDebt: technology.has_debt,
      debtComments: technology.debt_comments,
    },
  };

  switch (technologyType) {
    case 'solar':
      item = technology.item as SolarProjectTechnology;
      technologyDetails.item = {
        id: item.id,
        installedCapacityPeak: item.installed_capacity_peak,
        installedCapacityNominal: item.installed_capacity_nominal,
        specificProduction: item.specific_production,
        readyToBuild: item.ready_to_build,
        commercialOperationDate: item.commercial_operation_date,
        structureType: item.structure_type,
        moduleType: item.module_type,
        modulePower: item.module_power,
        panelConfiguration: item.panel_configuration,
        pitchDistance: item.pitch_distance,
        generalComments: item.comments,
        basicDesignComments: item.basic_design_comments,
      } as SolarItem;
      break;
    case 'wind':
      item = technology.item as WindProjectTechnology;
      technologyDetails.item = {
        id: item.id,
        installedCapacity: item.installed_capacity,
        specificProduction: item.specific_production,
        readyToBuild: item.ready_to_build,
        commercialOperationDate: item.commercial_operation_date,
        turbineModel: item.turbine_model,
        turbinePower: item.turbine_power,
        turbiness: item.turbiness,
        generalComments: item.comments,
        basicDesignComments: item.basic_design_comments,
      } as WindItem;
      break;
    case 'bess':
      item = technology.item as BESSProjectTechnology;
      technologyDetails.item = {
        id: item.id,
        installedCapacity: item.installed_capacity,
        readyToBuild: item.ready_to_build,
        commercialOperationDate: item.commercial_operation_date,
        storageTechnology: item.storage_technology,
        storageCapacity: item.storage_capacity,
        hoursCycles: item.hours_cycles,
        generalComments: item.comments,
        basicDesignComments: item.basic_design_comments,
      } as BessItem;
      break;
  }

  technologyDetails.item.id = item.id;
  return technologyDetails;
}
