import { useServiceDetails } from '../../hooks';

import { ServiceContent } from './content';
import { ServiceHeader } from './header';

export type ServiceViewProps = {
  readonly id: string;
};

export function ServiceView({ id }: ServiceViewProps) {
  const service = useServiceDetails({ id });
  if (!service) return null;

  return (
    <div>
      <ServiceHeader service={service} />
      <ServiceContent service={service} />
    </div>
  );
}
