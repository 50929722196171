import { useMemo } from 'react';

import { Status } from '@/features/opportunities/types';

import { useOfferHeaderDetails } from './use-offer-header-details';
import { useBelongsToMyCompany } from '@/features/company/hooks';

export function useCanViewOffer(id: string): boolean | null {
  const offer = useOfferHeaderDetails(id);
  const offerBelongsToMyCompany = useBelongsToMyCompany(offer?.company);
  const offerBelongsToMyOpportunity = useBelongsToMyCompany(offer?.opportunity.company);

  return useMemo(() => {
    if (!offer) return null;

    return (
      offerBelongsToMyCompany ||
      offerBelongsToMyOpportunity ||
      offer.opportunity.deadline.category === ('overdue' as Status)
    );
  }, [offer, offerBelongsToMyCompany, offerBelongsToMyOpportunity]);
}
