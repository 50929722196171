import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Icon, SelectableButton, Typography } from '@/components';
import { IconName } from '@/components/icons/types';
import { useTechnologies } from '@/hooks';

export type TechnologyFilterProps = {
  readonly defaultValue?: string[];
  readonly onChange: (value: string[]) => void;
  readonly type?: 'deals' | 'opportunities';
};

export function TechnologyFilter({ defaultValue, onChange, type }: TechnologyFilterProps) {
  const { t } = useTranslation(['home']);

  const { options, filters } = useTechnologies();
  const technologies = type === 'deals' ? options : filters;

  const selectedRef = useRef<string[]>(defaultValue ?? []);

  const handleOnChange = useCallback(
    (value: string) => {
      const currentSelected = selectedRef.current;

      selectedRef.current = currentSelected.includes(value)
        ? currentSelected.filter((item) => item !== value)
        : [...currentSelected, value];

      onChange(selectedRef.current);
    },
    [onChange]
  );

  return (
    <Field
      id="technology"
      label={t(`Technology`)}
      classes={{ field: 'space-y-2', input: 'flex flex-wrap items-center justify-start gap-2' }}
    >
      {technologies.map(({ value, icon, label }) => (
        <SelectableButton
          key={value}
          id={value}
          value={value}
          className="items-center gap-x-1"
          type="checkbox"
          icon={icon as IconName}
          label={label}
          defaultChecked={defaultValue?.includes(value)}
          registration={{
            name: `technology`,
            onChange: (event) => {
              handleOnChange(event.target.value);
              return Promise.resolve();
            },
          }}
        >
          <Icon name={icon as IconName} className="h-5 w-5 text-primary-700" />
          <Typography size="body-3" color="primary" weight="normal">
            {label}
          </Typography>
        </SelectableButton>
      ))}
    </Field>
  );
}
