import { useCallback } from 'react';

import { useAddServiceStatsMutation } from '../api';
import { AddServiceStatsRequest } from '../api/types';

export type useSendOServiceStatsProps = {
  readonly type: string;
};

export function useSendServiceStats({ type }: useSendOServiceStatsProps) {
  const [sendStats] = useAddServiceStatsMutation();

  return useCallback(
    (id: string) => {
      sendStats({
        service_company: id,
        key: type,
      } as AddServiceStatsRequest);
    },
    [sendStats, type]
  );
}
