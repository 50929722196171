import { useTranslation } from 'react-i18next';

import { AnimationShell, Button, Field, Form, Input } from '@/components';

export type EmailAddressFormFields = {
  email: string;
};

export type EmailAddressFormProps = {
  readonly onSubmit: (data: EmailAddressFormFields) => void;
};

export function EmailAddressForm({ onSubmit }: EmailAddressFormProps) {
  const { t } = useTranslation(['reset-password']);

  return (
    <Form<EmailAddressFormFields> onSubmit={onSubmit}>
      {({ register, formState }) => (
        <div className="flex flex-col space-y-7">
          <Field
            id="email"
            label={t(`Email address`)}
            error={formState.errors['email']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              type="email"
              color={formState.errors['email'] ? 'error' : 'primary'}
              placeholder="john.doe@example.com"
              autoComplete="email"
              registration={register('email', { required: t(`This field is required`) })}
            />
          </Field>

          <AnimationShell type="opacity" delay={0.15}>
            <Button type="submit" size="lg" className="w-full">
              {t(`Continue`)}
            </Button>
          </AnimationShell>
        </div>
      )}
    </Form>
  );
}
