import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Opportunities({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="opportunities"
    >
      <path
        d="M12 21.8C6.60001 21.8 2.20001 17.4 2.20001 12C2.20001 6.60001 6.60001 2.20001 12 2.20001C17.4 2.20001 21.8 6.60001 21.8 12C21.8 12.4 21.5 12.8 21 12.8C20.5 12.8 20.2 12.5 20.2 12C20.2 7.50001 16.5 3.80001 12 3.80001C7.50001 3.80001 3.80001 7.50001 3.80001 12C3.80001 16.5 7.50001 20.2 12 20.2C12.4 20.2 12.8 20.5 12.8 21C12.8 21.5 12.4 21.8 12 21.8Z"
        fill="currentColor"
      />
      <path
        d="M20.5 9.80001H3.49999C3.09999 9.80001 2.79999 9.40001 2.79999 9.00001C2.79999 8.60001 3.09999 8.20001 3.59999 8.20001H20.6C21 8.20001 21.4 8.50001 21.4 9.00001C21.4 9.50001 20.9 9.80001 20.5 9.80001Z"
        fill="currentColor"
      />
      <path
        d="M12 15.8H3.50001C3.10001 15.8 2.70001 15.5 2.70001 15C2.70001 14.5 3.00001 14.2 3.50001 14.2H12C12.4 14.2 12.8 14.5 12.8 15C12.8 15.5 12.4 15.8 12 15.8Z"
        fill="currentColor"
      />
      <path
        d="M22 22.8C21.8 22.8 21.6 22.7 21.5 22.6L19.6 20.7C19.3 20.4 19.3 19.9 19.6 19.6C19.9 19.3 20.4 19.3 20.7 19.6L22.6 21.5C22.9 21.8 22.9 22.3 22.6 22.6C22.4 22.7 22.2 22.8 22 22.8Z"
        fill="currentColor"
      />
      <path
        d="M18 21.8C15.9 21.8 14.2 20.1 14.2 18C14.2 15.9 15.9 14.2 18 14.2C20.1 14.2 21.8 15.9 21.8 18C21.8 20.1 20.1 21.8 18 21.8ZM18 15.8C16.8 15.8 15.8 16.8 15.8 18C15.8 19.2 16.8 20.2 18 20.2C19.2 20.2 20.2 19.2 20.2 18C20.2 16.8 19.2 15.8 18 15.8Z"
        fill="currentColor"
      />
      <path
        d="M12 21.8C11 21.8 10.1 21.3 9.5 20.4C6.5 15.3 6.5 8.79999 9.5 3.69999C10.1 2.79999 11 2.29999 12 2.29999C13 2.29999 13.9 2.79999 14.5 3.69999C16 6.19999 16.7 8.99999 16.7 12C16.7 12.4 16.4 12.8 15.9 12.8C15.4 12.8 15.1 12.5 15.1 12C15.1 9.29999 14.4 6.69999 13 4.39999C12.5 3.49999 11.2 3.49999 10.6 4.39999C8.1 8.99999 8.1 15 10.8 19.6C11.1 20 11.5 20.3 12 20.3C12.4 20.3 12.8 20.6 12.8 21.1C12.8 21.6 12.4 21.8 12 21.8Z"
        fill="currentColor"
      />
    </svg>
  );
}
