import { useTranslation } from 'react-i18next';

import { Button, Typography, Evaluation } from '@/components';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from '@/components/dialog';

import { RatingForm, RatingFormFields } from '../rating';

export type OfferFeedbackModalProps = {
  readonly updateFeedback: (data: RatingFormFields) => void;
  readonly open: boolean;
  readonly setOpen: (state: boolean) => void;
};

export function OfferFeedbackModal({ updateFeedback, open = false, setOpen }: OfferFeedbackModalProps) {
  const { t } = useTranslation(['offers']);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent onPointerDownOutside={(event) => event.preventDefault()} canClose={false}>
          <DialogHeader>
            <DialogTitle>{t('Evaluate offer')}</DialogTitle>
          </DialogHeader>
          <div className="my-6 items-center justify-center px-6">
            <Evaluation className="mx-auto my-8 w-40" />
            <Typography as="h5" size="title" color="primary" weight="normal" className="text-center">
              {t('Assessment of Reasons for Rejecting the NBO')}
            </Typography>
            <Typography as="p" size="body-3" color="secondary" weight="normal" className="text-center">
              {t(
                'Please, rate the following points of this NBO, so the buyer can understand why you have decided to dismiss it.'
              )}
            </Typography>
          </div>

          {/* Dialog Form */}
          <RatingForm
            id="rating-form"
            onSubmit={(data: RatingFormFields) => {
              updateFeedback(data);
              setOpen(false);
            }}
          />

          <DialogFooter>
            <div className="flex space-x-4">
              <Button type="submit" color="primary" form="rating-form">
                {t('Send')}
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
