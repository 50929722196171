import { useMemo } from 'react';

import { useGetOfferQuery } from '../api';
import { offerDetailsTransformer } from '../helpers';
import { OfferDetails } from '../types';

export function useOfferDetails(id: string): OfferDetails | null {
  const { data: offer } = useGetOfferQuery({ id });
  return useMemo(() => offerDetailsTransformer(offer), [offer]);
}
