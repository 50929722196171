import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Edit({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="edit"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2531 6.59821C13.2417 6.6083 13.2306 6.6188 13.2197 6.6297C13.2088 6.64061 13.1983 6.65176 13.1882 6.66315L4.76409 15.0872L4.7633 15.088C4.4335 15.4165 4.25 15.8619 4.25 16.325V19C4.25 19.4142 4.58579 19.75 5 19.75H7.675C8.13818 19.75 8.58359 19.5665 8.91206 19.2366L17.3552 10.7951C17.3603 10.7903 17.3653 10.7854 17.3703 10.7804C17.3753 10.7754 17.3802 10.7704 17.3849 10.7653L19.2383 8.91237C19.9212 8.22948 19.9212 7.12155 19.2383 6.43866L17.5623 4.76266C16.8794 4.07976 15.7716 4.07976 15.0887 4.76266L13.2531 6.59821ZM16.1493 5.82332C16.2464 5.72621 16.4046 5.72621 16.5017 5.82332L18.1777 7.49932C18.2748 7.59641 18.2748 7.7545 18.1777 7.85161L16.8398 9.18922L14.8116 7.16101L16.1493 5.82332ZM15.7791 10.2498L7.85072 18.1766L7.84926 18.1781C7.8038 18.2238 7.7415 18.25 7.675 18.25H5.75V16.325C5.75 16.2585 5.77621 16.1962 5.82191 16.1507L5.293 15.619L5.82333 16.1493L13.751 8.22167L15.7791 10.2498Z"
        fill="currentColor"
      />
    </svg>
  );
}
