import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function GraphBar({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="biogas"
    >
      <g>
        <g>
          <path
            d="M6.88 5.12 2.87 9.13"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          ></path>
          <path
            d="M14.31 6.32 9.64 5.08"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          ></path>
          <path
            d="m20.39 2.89 -3.58 2.8"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          ></path>
        </g>
        <path
          d="M6.75 4.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 1 0 -3 0"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
        <path
          d="M20.25 2.25a1.5 1.5 0 1 0 3 0 1.5 1.5 0 1 0 -3 0"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
        <path
          d="M14.25 6.75a1.5 1.5 0 1 0 3 0 1.5 1.5 0 1 0 -3 0"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
        <path
          d="M0.75 10.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 1 0 -3 0"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
        <path
          d="m0.75 23.25 22.5 0"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
        <path
          d="M6 17.75H3a0.76 0.76 0 0 0 -0.75 0.75v4.75h4.5V18.5a0.75 0.75 0 0 0 -0.75 -0.75Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
        <path
          d="M13.5 10.75h-3a0.76 0.76 0 0 0 -0.75 0.75v11.75h4.5V11.5a0.75 0.75 0 0 0 -0.75 -0.75Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
        <path
          d="M21 14.75h-3a0.76 0.76 0 0 0 -0.75 0.75v7.75h4.5V15.5a0.75 0.75 0 0 0 -0.75 -0.75Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
      </g>
    </svg>
  );
}
