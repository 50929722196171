import { useMemo } from 'react';

import { useGetOpportunityStatsQuery } from '../api';
import { opportunityStatsTransformer } from '../helpers';
import { OpportunityStatsDetails } from '../types';

export type useOpportunityStatsProps = {
  readonly id: string;
};

export function useOpportunityStats({ id }: useOpportunityStatsProps): OpportunityStatsDetails | null {
  const { data: opportunityStats } = useGetOpportunityStatsQuery({ id });
  return useMemo(() => opportunityStatsTransformer({ opportunity: id, opportunityStats }), [id, opportunityStats]);
}
