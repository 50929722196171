import { useTranslation } from 'react-i18next';
import { useDealDetails } from '../../hooks';
import { useEffect, useState } from 'react';
import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { ASSETS_BASE_URL } from '@/config';
import { useCountries } from '@/hooks';
import { useSendDealsStats } from '@/features/stats';

export type DealDetailsViewProps = {
  readonly id: string;
};

export function DealDetailsView({ id }: DealDetailsViewProps) {
  const deal = useDealDetails(id);

  const { t } = useTranslation(['offers']);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isFileExpanded, setIsFileExpanded] = useState(true);

  const date = deal?.deadline.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
  const { options: countries } = useCountries();
  const country = countries.find((c) => c.value === deal?.country)?.label || deal?.country;
  const location = `${country} (${deal?.city})`;

  const sendViewStats = useSendDealsStats({ type: 'view' });
  const sendDownloadStats = useSendDealsStats({ type: 'download_teaser' });
  useEffect(() => {
    if (deal) sendViewStats(deal.id);
  }, [deal, sendViewStats]);

  const handleOnClickFile = () => {
    if (deal) sendDownloadStats(deal.id);
  };

  const header = (
    <CollapsableHeader
      title={t('Details')}
      description={t('In this section, you will find the main comprehensive details about this opportunity, including deadlines, sale options, and the counterparty details to help you make an informed decision.')}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );

  const fileHeader = (
    <CollapsableHeader
      title={t('Attachments')}
      description={t('This section includes a teaser and any other relevant documents for the opportunity.')}
      isExpanded={isFileExpanded}
      onToggle={() => setIsFileExpanded((isFileExpanded) => !isFileExpanded)}
    />
  );

  const filesdata = [
    {
      label: t('Files'),
      value: (
        <div className="flex flex-col space-y-1.5">
          {deal?.attachments.map((file) => (
            <a
              key={file.id}
              href={`${ASSETS_BASE_URL}/${file.id}`}
              target="_blank"
              rel="noreferrer"
              className="text-base text-primary-700 hover:underline"
              onClick={handleOnClickFile}
            >
              {file.title}
            </a>
          ))}
        </div>
      ),
    },
  ];

  return (
    <>
      <Collapsable index={0} isExpanded={isExpanded} header={header}>
        <CollapsableBody
          data={[
            { label: t('Technologies'), value: deal?.technologies.join(', ') },
            { label: t('Category'), value: deal?.category },
            { label: t('Location'), value: location },
            { label: t('Deadline'), value: date },
            { label: t('Deal Description '), value: deal?.description },
            { label: t('Company description'), value: deal?.companyDescription },
          ]}
        />
      </Collapsable>
      {filesdata.length ? (
        <Collapsable index={0} isExpanded={isFileExpanded} header={fileHeader}>
          <CollapsableBody data={filesdata} hidden={false} />
        </Collapsable>
      ) : null}
    </>
  );
}
