import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AnimationShell, Breadcrumb, Icon, Link } from '@/components';
import { Route } from '@/constants';
import { useHeader } from '@/features/header';
import { cn } from '@/utils/styles';

import { AvatarDropdown, AvatarDropdownItem } from '../avatar-dropdown';
import { useCompanyRole } from '@/features/company/hooks';

export function Header() {
  const navigate = useNavigate();

  const { t } = useTranslation(['navbar']);

  const { breadcrumb } = useHeader();
  const { isFree } = useCompanyRole();

  const dropdownItems = useMemo(
    () =>
      [
        {
          label: t('Profile settings'),
          icon: 'person',
          onClick: () => navigate(Route.ProfileSettings),
        },
        {
          label: t('Plans & Billing'),
          icon: 'credit-card',
          onClick: () => navigate(Route.PlanSettings),
        },
        {
          label: t('Company'),
          icon: 'buildings',
          onClick: () => navigate(Route.CompanySettings),
        },
        {
          label: t('Team'),
          icon: 'people',
          onClick: () => navigate(Route.TeamSettings),
        },
      ] as AvatarDropdownItem[],
    [t, navigate]
  );

  return (
    <header
      className={cn(
        'z-10 h-14 w-full px-8',
        'flex shrink-0 items-center justify-between',
        'border-b border-neutral-50 bg-white'
      )}
    >
      <Breadcrumb items={breadcrumb} size="body-3" />

      <AnimationShell type="scale" className="flex items-center justify-center gap-4">
        {isFree ? (
          <Link
            to={Route.PlanSettings}
            target="_blank"
            className="flex shrink-0 cursor-pointer select-none items-center justify-center rounded-md border border-solid border-secondary-accent bg-white  fill-secondary-accent px-2 py-1.5 text-center text-sm font-semibold text-secondary-accent transition hover:bg-secondary-accent  hover:fill-secondary-accent hover:text-white hover:no-underline active:bg-secondary-accent active:fill-secondary-accent active:text-white "
          >
            <span className="flex items-center justify-center gap-x-1">
              <Icon name="lightning" className="h-5 w-5 fill-inherit text-inherit" />

              {t(`Upgrade`)}
            </span>
          </Link>
        ) : undefined}
        <AvatarDropdown options={dropdownItems} />
      </AnimationShell>
    </header>
  );
}
