import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AnimationShell, Button, Container, Typography } from '@/components';
import { useNotifications } from '@/features/notifications/hooks';
import {
  TeamDrawer,
  TeamTable,
  UserFormFields,
  useGetCompanyUsersQuery,
  useInviteUserMutation,
} from '@/features/users';
import { useCollapsible, useRegisterGAPageView } from '@/hooks';
import { cn } from '@/utils/styles';

export function TeamSettingsPage() {
  const { t } = useTranslation(['team']);
  const { add } = useNotifications();
  useRegisterGAPageView('Team settings');

  const { isOpen, open, close } = useCollapsible();

  const { data: users = [] } = useGetCompanyUsersQuery();

  const [inviteUser] = useInviteUserMutation();

  const handleSubmit = useCallback(
    (data: UserFormFields) => {
      inviteUser(data)
        .unwrap()
        .then(() => {
          add({
            id: new Date().toString(),
            title: t('Invitation sent'),
            description: t('Your invitation has been sent.'),
          });
          close();
        })
        .catch(() => {
          add({
            id: new Date().toString(),
            title: t('ERROR'),
            description: t('There has been a data inconsistency error. Please contact the administrator.'),
          });
          close();
        });
    },
    [inviteUser, close, add, t]
  );

  return (
    <Container size="md" className="space-y-6 py-14">
      <AnimationShell
        element="section"
        type="opacity"
        className={cn('flex w-full flex-col space-y-3 rounded-lg border border-neutral-50 bg-base-white p-6 shadow-sm')}
      >
        <div className="flex w-full items-start justify-between gap-4">
          <Typography as="h1" size="title" color="primary" weight="normal">
            {t('Users')}
          </Typography>
          <Button size="md" color="primary" icon="plus" onClick={open}>
            {t('Invite a member')}
          </Button>
        </div>

        <TeamTable users={users} />
      </AnimationShell>

      <TeamDrawer isOpen={isOpen} close={close} onSubmit={handleSubmit} />
    </Container>
  );
}
