import { useOfferDetails } from '../../hooks';

import { BuyerTypeCollapsable } from './buyer-type';
import { DueDiligenceCollapsable } from './due-diligence-procedure';
import { OfferFeedback } from './feedback';
import { OthersCollapsable } from './others';
import { PaymentMilestonesCollapsable } from './payment-milestones';
import { PriceCollapsable } from './price';
import { useBelongsToMyCompany } from '@/features/company/hooks';

export function OfferTabDetails({ id }: { id: string }) {
  const offer = useOfferDetails(id);
  const offerBelongsToMyCompany = useBelongsToMyCompany(offer?.company.id);

  if (!offer) return null;

  const isRejected = offer.status.name === 'rejected';
  const isAccepted = offer.status.name === 'accepted';

  const canViewFeedback = offerBelongsToMyCompany && (isRejected || isAccepted);

  return (
    <div className="space-y-4">
      {canViewFeedback && <OfferFeedback offer={offer} />}
      <PriceCollapsable offer={offer} />
      {/* <PaymentMilestonesCollapsable offer={offer} /> */}
      <DueDiligenceCollapsable offer={offer} />
      <BuyerTypeCollapsable offer={offer} />
      <OthersCollapsable offer={offer} />
    </div>
  );
}
