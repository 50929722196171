import { opportunityDetailsTransformer } from '@/features/opportunities/helpers';

import { Offer, OfferDetails, SaleOption } from '../types';

import { getColorFromOfferStatus } from './helpers';

export function offerDetailsTransformer(offer?: Offer): OfferDetails | null {
  if (!offer) return null;

  return {
    id: offer.id,
    status: {
      name: offer.status,
      color: getColorFromOfferStatus(offer.status),
    },
    opportunity: opportunityDetailsTransformer(offer.opportunity, []),
    company: {
      id: offer.company.id,
      name: offer.company.name,
    },
    price: offer.price,
    option: offer.option as SaleOption,
    createdAt: new Date(offer.created_at),
    buyerType: offer.buyer_type,
    buyerComments: offer.buyer_comments,
    percentage: {
      minimum: offer.minimum_percentage,
      maximum: offer.maximum_percentage,
    },
    milestones: offer.milestones,
    dueDiligenceType: offer.due_diligence_type,
    dueDiligenceWeeks: offer.due_diligence_weeks,
    dueDiligenceRequirements: offer.due_diligence_requirements,
    dueDiligenceComments: offer.due_diligence_comments,
    file: offer.file,
    comments: offer.comments,
    rating: {
      priceScore: offer.price_score,
      milestonesScore: offer.milestones_score,
      speedScore: offer.speed_score,
      comments: offer.rating_comments,
    },
  };
}
