import { ProjectView } from '@/features/projects';
import { Project } from '@/features/projects/types';

export type OpportunityTabProjectsProps = {
  readonly projects: Project[];
  readonly tabActive: number;
  readonly opportunityId: string;
  readonly isPaid?: boolean;
};

export function OpportunityTabProjects({
  projects,
  tabActive,
  opportunityId,
  isPaid = false,
}: OpportunityTabProjectsProps) {
  return projects.map(
    (project, index) =>
      tabActive === index + 1 && (
        <div key="project-${index}" className="space-y-4">
          <ProjectView id={project.id} opportunityId={opportunityId} isPaid={isPaid} />
        </div>
      )
  );
}
