import { Route } from '@/constants/route';

import { Icon } from '../icons';
import { Link } from '../link';
import { Typography } from '../typography';

export type OverlayProps = {
  readonly title?: string;
  readonly description?: string;
  readonly callToAction?: {
    label: string;
    path: string;
  };
};

export function Overlay({
  title = 'Have you thought on upgrading?',
  description = 'Upgrade to bussines plan so you can access all the features of the platform.',
  callToAction = { label: 'Go Upgrade', path: Route.PlanSettings },
}: OverlayProps) {
  return (
    <div className="flex items-center justify-center bg-black/30 backdrop-blur-sm">
      <div className="w-full p-16">
        <Typography as="h1" size="heading-1" color="primary" weight="semibold" className="text-center">
          {title}
        </Typography>
        <Typography as="p" size="body-2" color="primary" weight="normal" className="mt-2 text-center">
          {description}
        </Typography>
        {callToAction && (
          <div className="mt-8 flex justify-center">
            <Link
              to={callToAction.path}
              target="_blank"
              className="flex items-center justify-center gap-x-2 rounded-md border border-solid border-secondary-accent bg-secondary-accent px-2 py-1.5 text-center text-sm font-semibold text-white transition hover:bg-white hover:text-secondary-accent hover:no-underline active:bg-secondary-accent active:text-white"
            >
              <span className="flex items-center justify-center gap-x-1">
                <Icon name="lightning" className="h-5 w-5 fill-inherit text-inherit" />

                {callToAction.label}
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
