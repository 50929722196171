import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { ASSETS_BASE_URL } from '@/config';
import { useCollapsableAction } from '@/features/projects/hooks';
import { Technology } from '@/features/projects/types';
import { isNullish } from '@/types';

export type AttachmentsCollapsableProps = {
  readonly technology: Technology;
  readonly initialExpandedState?: boolean;
  readonly onEdit?: () => void;
  readonly hidden?: boolean;
};

export function AttachmentsCollapsable({
  technology,
  onEdit,
  initialExpandedState = false,
  hidden,
}: AttachmentsCollapsableProps) {
  const { t } = useTranslation(['projects']);

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const action = useCollapsableAction({ onEdit, setIsExpanded });
  const description = onEdit
    ? t(
        'Please include any documents you believe may be interesting to complete the project information (ie. teaser,  plant layout, connection infrastructure scheme, historic production, etc.'
      )
    : t('Here is attachments of the project.');

  const header = (
    <CollapsableHeader
      title={t('Attachments')}
      description={description}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
      action={action}
    />
  );

  const data = [
    {
      label: t('Files'),
      value: (
        <div className="flex flex-col space-y-1.5">
          {technology.details.files.map((file) => (
            <a
              key={file.id}
              href={`${ASSETS_BASE_URL}/${file.id}`}
              target="_blank"
              rel="noreferrer"
              className="text-base text-primary-700 hover:underline"
            >
              {file.title}
            </a>
          ))}
        </div>
      ),
    },
  ];

  const filteredData = isNullish(onEdit) ? [] : data;

  return filteredData.length ? (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody data={data} hidden={hidden} />
    </Collapsable>
  ) : null;
}
