import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function CreditCard({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="credit-card"
    >
      <path
        d="M19 19.3H7.99995C6.76438 19.3 5.69995 18.3123 5.69995 17V10C5.69995 8.76444 6.68766 7.70001 7.99995 7.70001H19C20.2355 7.70001 21.2999 8.68772 21.2999 10V17C21.2999 18.2239 20.2238 19.3 19 19.3ZM7.99995 8.30001C6.99727 8.30001 6.29995 9.15118 6.29995 10V17C6.29995 18.0027 7.15112 18.7 7.99995 18.7H19C20.0026 18.7 20.7 17.8488 20.7 17V10C20.7 8.99733 19.8488 8.30001 19 8.30001H7.99995Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M21 12.3H5.99995C5.92151 12.3 5.84302 12.2695 5.78781 12.2189C5.73908 12.1743 5.69995 12.1071 5.69995 12C5.69995 11.8503 5.74291 11.7892 5.766 11.7661C5.7891 11.743 5.85021 11.7 5.99995 11.7H21C21.0784 11.7 21.1569 11.7305 21.2121 11.7811C21.2608 11.8258 21.2999 11.8929 21.2999 12C21.2999 12.1071 21.2608 12.1743 21.2121 12.2189C21.1569 12.2695 21.0784 12.3 21 12.3Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        d="M5.99995 16.8H4.99995C3.49995 16.8 2.19995 15.6 2.19995 14V7.00001C2.19995 5.50001 3.39995 4.20001 4.99995 4.20001H16C17.5 4.20001 18.7999 5.40001 18.7999 7.00001V8.00001C18.7999 8.40001 18.5 8.80001 18 8.80001C17.5 8.80001 17.2 8.40001 17.2 8.00001V7.00001C17.2 6.30001 16.6 5.80001 16 5.80001H4.99995C4.29995 5.80001 3.79995 6.30001 3.79995 7.00001V14C3.79995 14.7 4.39995 15.2 4.99995 15.2H5.99995C6.39995 15.2 6.79995 15.5 6.79995 16C6.79995 16.5 6.39995 16.8 5.99995 16.8Z"
        fill="currentColor"
      />
      <path
        d="M12 16.8H8.99995C8.59995 16.8 8.19995 16.5 8.19995 16C8.19995 15.5 8.49995 15.2 8.99995 15.2H12C12.4 15.2 12.8 15.5 12.8 16C12.8 16.5 12.4 16.8 12 16.8Z"
        fill="currentColor"
      />
    </svg>
  );
}
