import { PayloadAction } from '@reduxjs/toolkit';

import { HeaderState } from './types';

export function set(state: HeaderState, action: PayloadAction<HeaderState['breadcrumb']>) {
  state.breadcrumb = action.payload;
}

export function clear(state: HeaderState) {
  state.breadcrumb = [];
}
