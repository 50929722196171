import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from '@/components/dialog';
import { Route } from '@/constants';

export type InviteProps = {
  readonly open: boolean;
  readonly setOpen: (state: boolean) => void;
};

export function InviteModal({ open, setOpen }: InviteProps) {
  const { t } = useTranslation(['settings']);
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(false);
    navigate(Route.TeamSettings);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent onPointerDownOutside={(event) => event.preventDefault()}>
          <DialogHeader>
            <DialogTitle>{t('Invite Your Team!')}</DialogTitle>
            <DialogDescription>
              {t(
                'Click the button below to invite your colleagues to collaborate, share opportunities, and manage offerss together.'
              )}
            </DialogDescription>
          </DialogHeader>

          <DialogFooter>
            <div className="flex space-x-4">
              <Button type="button" size="lg" color="primary" onClick={handleClick}>
                {t('Invite a member')}
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
