import { useEffect } from 'react';
import { getI18n } from 'react-i18next';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { AppLayout } from '@/features/app';
import { InviteModal } from '@/features/company/components/invite/invite';
import { PreferencesModal } from '@/features/company/components/preferences/preferences';
import { useInvite, usePreferences } from '@/features/company/hooks';
import { useGetCurrentUserQuery } from '@/features/users';
import { useAppSelector } from '@/store';

export function AppPage() {
  const location = useLocation();

  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);

  const { data: user } = useGetCurrentUserQuery();

  const { open, setOpen } = usePreferences({ user });
  const { inviteOpen, setInviteOpen } = useInvite({ preferencesModal: open });

  useEffect(() => {
    getI18n().changeLanguage(user?.language);
  }, [user?.language]);

  if (!isLoggedIn) {
    return <Navigate to={`/auth/signin?redirectTo=${location.pathname}`} />;
  }

  return (
    <AppLayout>
      <PreferencesModal open={open} setOpen={setOpen} company={user?.company} />
      <InviteModal open={inviteOpen} setOpen={setInviteOpen} />
      <Outlet />
    </AppLayout>
  );
}
