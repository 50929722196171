import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Solar({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="solar"
    >
      <g clipPath="url(#clip0_137_9684)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.6824 9.14062L18.2267 9.14063C18.2266 9.14063 18.2267 9.14063 18.2267 9.14063C18.5112 9.1406 18.7897 9.22419 19.0271 9.38101C19.2645 9.5378 19.4507 9.76087 19.5624 10.0225C19.5624 10.0224 19.5624 10.0226 19.5624 10.0225L23.1789 18.4592C23.2734 18.6801 23.3119 18.9215 23.2905 19.1609C23.269 19.4002 23.1886 19.6305 23.0563 19.8311C22.924 20.0317 22.7441 20.1964 22.5325 20.3104C22.321 20.4244 22.0845 20.4842 21.8442 20.4844H6.66958C6.66948 20.4844 6.66969 20.4844 6.66958 20.4844C6.43709 20.4844 6.20766 20.4286 6.0012 20.3217C5.79465 20.2148 5.61681 20.0598 5.48266 19.8697C5.34851 19.6797 5.26196 19.4603 5.23031 19.2298C5.19865 18.9994 5.22282 18.7647 5.30077 18.5455L8.31318 10.11C8.31339 10.1095 8.3136 10.1089 8.31381 10.1083C8.41382 9.8257 8.59884 9.58099 8.84348 9.40773C9.08863 9.23411 9.38199 9.14079 9.6824 9.14062ZM9.71648 10.6406L6.73683 18.9844H21.772L18.1954 10.6406H9.71648Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 18.9844C12.4142 18.9844 12.75 19.3202 12.75 19.7344V22.5469C12.75 22.9611 12.4142 23.2969 12 23.2969C11.5858 23.2969 11.25 22.9611 11.25 22.5469V19.7344C11.25 19.3202 11.5858 18.9844 12 18.9844Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2188 18.9844C16.633 18.9844 16.9688 19.3202 16.9688 19.7344V22.5469C16.9688 22.9611 16.633 23.2969 16.2188 23.2969C15.8045 23.2969 15.4688 22.9611 15.4688 22.5469V19.7344C15.4688 19.3202 15.8045 18.9844 16.2188 18.9844Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.20638 5.08321C8.73071 4.98112 8.23888 4.98024 7.76285 5.08061C7.28681 5.18099 6.83721 5.38038 6.44325 5.66583C6.0493 5.95128 5.71979 6.31641 5.47614 6.7375C5.23249 7.1586 5.08014 7.62623 5.02899 8.11004C4.97784 8.59385 5.02904 9.083 5.17925 9.54574C5.32946 10.0085 5.57533 10.4344 5.90089 10.7959C6.17807 11.1038 6.15325 11.578 5.84545 11.8552C5.53764 12.1323 5.06342 12.1075 4.78624 11.7997C4.3199 11.2819 3.9677 10.6717 3.75254 10.0089C3.53737 9.34604 3.46404 8.64536 3.5373 7.95234C3.61057 7.25932 3.8288 6.58946 4.17781 5.98627C4.52683 5.38308 4.99882 4.86006 5.56314 4.45117C6.12746 4.04228 6.77148 3.75667 7.45337 3.61289C8.13525 3.46911 8.83976 3.47037 9.52113 3.6166C10.2025 3.76283 10.8455 4.05075 11.4083 4.46167C11.9712 4.87259 12.4413 5.3973 12.7881 6.00174C12.9943 6.36101 12.8702 6.81937 12.5109 7.02553C12.1516 7.23169 11.6933 7.10757 11.4871 6.7483C11.245 6.32634 10.9168 5.96003 10.5239 5.67316C10.1309 5.38629 9.68205 5.18529 9.20638 5.08321Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.48438 0.703125C8.89859 0.703125 9.23438 1.03891 9.23438 1.45312V2.15625C9.23438 2.57046 8.89859 2.90625 8.48438 2.90625C8.07016 2.90625 7.73438 2.57046 7.73438 2.15625V1.45312C7.73438 1.03891 8.07016 0.703125 8.48438 0.703125Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6397 3.03217C14.9326 3.32506 14.9326 3.79994 14.6397 4.09283L13.9366 4.79596C13.6437 5.08885 13.1688 5.08885 12.8759 4.79596C12.583 4.50306 12.583 4.02819 12.8759 3.73529L13.579 3.03217C13.8719 2.73928 14.3468 2.73928 14.6397 3.03217Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.32904 3.03217C2.62194 2.73928 3.09681 2.73928 3.38971 3.03217L4.09283 3.73529C4.38572 4.02819 4.38572 4.50306 4.09283 4.79596C3.79994 5.08885 3.32506 5.08885 3.03217 4.79596L2.32904 4.09283C2.03615 3.79994 2.03615 3.32506 2.32904 3.03217Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.703125 8.48438C0.703125 8.07016 1.03891 7.73438 1.45312 7.73438H2.15625C2.57046 7.73438 2.90625 8.07016 2.90625 8.48438C2.90625 8.89859 2.57046 9.23438 2.15625 9.23438H1.45312C1.03891 9.23438 0.703125 8.89859 0.703125 8.48438Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.09283 12.1728C4.38572 12.4657 4.38572 12.9406 4.09283 13.2335L3.38971 13.9366C3.09681 14.2295 2.62194 14.2295 2.32904 13.9366C2.03615 13.6437 2.03615 13.1688 2.32904 12.8759L3.03217 12.1728C3.32506 11.8799 3.79994 11.8799 4.09283 12.1728Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1094 9.14062C14.5236 9.14062 14.8594 9.47641 14.8594 9.89062V19.7344C14.8594 20.1486 14.5236 20.4844 14.1094 20.4844C13.6952 20.4844 13.3594 20.1486 13.3594 19.7344V9.89062C13.3594 9.47641 13.6952 9.14062 14.1094 9.14062Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.93115 14.1094C6.93115 13.6952 7.26694 13.3594 7.68115 13.3594H20.5033C20.9176 13.3594 21.2533 13.6952 21.2533 14.1094C21.2533 14.5236 20.9176 14.8594 20.5033 14.8594H7.68115C7.26694 14.8594 6.93115 14.5236 6.93115 14.1094Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_137_9684">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
