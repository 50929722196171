import { useGetCurrentUserQuery } from '@/features/users';
import { useMemo } from 'react';

export function useBelongsToMyCompany(company?: string | null) {
  const { data: user } = useGetCurrentUserQuery();

  return useMemo(() => {
    if (!user) return null;
    if (!company) return null;

    return user?.company?.id === company;
  }, [user, company]);
}
