import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { AnimationShell, Chip, Icons, Link, Typography, Value } from '@/components';
import { Route } from '@/constants/route';
import { OfferItemList } from '@/features/offers/types';
import { useLocation } from '@/features/projects';
import { displayDate, eurosMWp, mwp } from '@/helpers';
import { useDevelopmentStatus } from '@/hooks';

export type MyOffersItemProps = {
  readonly offer: OfferItemList;
  readonly index?: number;
};

export function MyOffersItem({ offer, index = 0 }: Readonly<MyOffersItemProps>) {
  const { t } = useTranslation(['offers']);
  const { map: mapDevelopmentStatus } = useDevelopmentStatus();

  const location = useLocation({ location: offer ? offer.opportunity.location : [] });
  const state = offer.opportunity.technologies.state.list.map(mapDevelopmentStatus).join(', ');

  return (
    <motion.div
      initial={{ y: 0 }}
      whileHover={{ y: -6 }}
      transition={{ type: 'spring', stiffness: 100 }}
      className="w-full"
    >
      <Link to={Route.Offer.replace(':offerId', offer.id)} className="hover:no-underline">
        <AnimationShell
          type="scale"
          delay={0.1 * index}
          className="h-full w-full cursor-pointer space-y-4 rounded-md border border-neutral-50 bg-base-white p-4 shadow-sm transition hover:shadow-xl"
        >
          <div className="inline-flex w-full items-center justify-between gap-4">
            <Icons icons={offer.opportunity.technologies.icons.states} />

            <div className="inline-flex items-center justify-start gap-2">
              <Chip color={offer.status.color}>{offer.status.name}</Chip>
            </div>
          </div>

          <div className="space-y-0.5">
            <div className="inline-flex items-center justify-start gap-2">
              <Typography as="span" size="body-2" color="primary" weight="semibold">
                {offer.opportunity.name}
              </Typography>
            </div>

            <Typography as="p" size="body-3" color="tertiary" weight="normal">
              {location}
            </Typography>

            <Typography as="p" size="body-1" color="primary" weight="semibold">
              {eurosMWp(offer.price)}
            </Typography>
          </div>

          <div className="inline-flex w-full items-center justify-between">
            <Value label={t('Capacity')}>{mwp(offer.opportunity.technologies.capacity)}</Value>
            <Value label={t('State')}>{state}</Value>
          </div>

          <div className="w-full">
            <hr />
            <div className="mt-2 inline-flex w-full items-center justify-between">
              <Value label={t('Deadline')}>{displayDate(offer.opportunity.deadline.date)}</Value>
              <Value label={t('Offered at')}>{displayDate(offer.createdAt)}</Value>
            </div>
          </div>
        </AnimationShell>
      </Link>
    </motion.div>
  );
}
