import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Others({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="others"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.508 5.0458C13.8035 5.15405 14 5.43531 14 5.75004V10.5H16.75C17.0429 10.5 17.309 10.6705 17.4314 10.9366C17.5538 11.2027 17.5101 11.5157 17.3195 11.7381L11.3195 18.7381C11.1152 18.9764 10.7842 19.0623 10.4898 18.9535C10.1954 18.8446 10 18.5639 10 18.25V13H7.75001C7.45798 13 7.1925 12.8305 7.06963 12.5656C6.94675 12.3007 6.98883 11.9885 7.17747 11.7656L12.6775 5.26558C12.8808 5.02532 13.2125 4.93754 13.508 5.0458ZM9.36709 11.5H10.75C11.1642 11.5 11.5 11.8358 11.5 12.25V16.2226L15.1193 12H13.25C12.8358 12 12.5 11.6643 12.5 11.25V7.7975L9.36709 11.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
