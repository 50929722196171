import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

import { AnimationShell, Avatar, AvatarProps, Icon, Typography } from '@/components';
import type { IconName } from '@/components/icons/types';
import { ASSETS_BASE_URL } from '@/config';
import { useLogout } from '@/features/auth';
import { useGetCurrentUserQuery } from '@/features/users';
import { cn } from '@/utils/styles';

export type AvatarDropdownItem = {
  readonly label: string;
  readonly icon?: IconName;
  readonly onClick?: () => void;
};

export type AvatarDropdownProps = {
  readonly options: AvatarDropdownItem[];
};

export function AvatarDropdown({ options }: AvatarDropdownProps) {
  const { data: user } = useGetCurrentUserQuery();

  const logout = useLogout();

  const [visible, setVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const userFirstName = user?.first_name ?? 'Anonymous';
  const userLastName = user?.last_name ?? 'User';
  const companyName = user?.company?.name ?? 'Company';

  const avatarProps: AvatarProps = {
    firstName: userFirstName,
    lastName: userLastName,
    fullName: `${userFirstName} ${userLastName}`,
    avatar: user?.avatar ? `${ASSETS_BASE_URL}/${user.avatar}` : '',
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setVisible(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  const dropdownVariants = {
    hidden: { opacity: 0, scale: 0.85, y: -30 },
    visible: { opacity: 1, scale: 1, y: 0 },
  };

  return (
    <div className="relative" ref={containerRef}>
      <AnimationShell
        element="button"
        type="slide-right"
        delay={0.25}
        className="inline-flex cursor-pointer items-center gap-1 rounded-full bg-neutral-25 py-0.5 pl-0.5 pr-2 transition hover:bg-neutral-50"
        onClick={() => setVisible(!visible)}
      >
        <Avatar {...avatarProps} />
        <div className="items-start justify-start">
          <Typography as="p" color="secondary" weight="normal" className="truncate text-xs">
            {avatarProps.fullName}
          </Typography>
          <Typography as="p" color="primary" weight="semibold" className="truncate text-xs uppercase">
            {companyName}
          </Typography>
        </div>
        <Icon
          name="chevron-left"
          className={cn('h-4 w-4 text-neutral-500 transition', visible ? 'rotate-90' : '-rotate-90')}
        />
      </AnimationShell>

      <AnimatePresence>
        {visible ? (
          <motion.div
            className="absolute right-0 mt-1 w-52 rounded-lg border border-neutral-50 bg-white p-0.5 shadow-md"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={dropdownVariants}
            transition={{ duration: 0.2 }}
          >
            <ul className="rounded-md">
              <li className="ityems-center flex cursor-pointer gap-3 rounded-t-md bg-neutral-25 p-2 transition hover:bg-primary-light">
                <Avatar {...avatarProps} size="md" />
                <span className="flex flex-col gap-1">
                  <Typography as="span" size="body-3" color="primary" weight="semibold" className="line-clamp-1">
                    {userFirstName} {userLastName}
                  </Typography>
                  <Typography as="span" size="caption" color="secondary" weight="normal" className="line-clamp-1">
                    {user?.email ?? '---'}
                  </Typography>
                </span>
              </li>

              {options.map(({ label, icon, onClick }: AvatarDropdownItem) => (
                <li
                  key={`${icon}-${label}`}
                  className="group flex cursor-pointer items-center gap-2.5 bg-white px-3 py-1 transition hover:bg-primary-light"
                  onClick={onClick ?? undefined}
                >
                  {icon ? (
                    <Icon name={icon} className="h-6 w-6 text-neutral-300 transition group-hover:text-primary-700" />
                  ) : null}
                  <Typography as="span" size="body-3" color="primary" weight="normal">
                    {label}
                  </Typography>
                </li>
              ))}

              <li
                className="group flex cursor-pointer items-center gap-2.5 rounded-b-md border-t border-t-neutral-50 bg-white px-3 py-1 transition hover:bg-primary-light"
                onClick={() => {
                  logout();
                }}
              >
                <Icon name="log-out" className="h-6 w-6 text-neutral-300 transition group-hover:text-primary-700" />
                <Typography as="span" size="body-3" color="primary" weight="normal">
                  Log Out
                </Typography>
              </li>
            </ul>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}
