import { AnimationShell } from '@/components';
import { getOpportunitiesOrderedByDeadline } from '@/features/opportunities/helpers';
import { OpportunityItemList } from '@/features/opportunities/types';

import { PaginatedOpportunitiesList } from './paginated-opportunities-list';

export type OpportunitiesItemsProps = {
  readonly opportunities: OpportunityItemList[];
};

export function OpportunitiesItems({ opportunities }: OpportunitiesItemsProps) {
  const orderedOpportunities = getOpportunitiesOrderedByDeadline([...opportunities]);

  return (
    <AnimationShell type="opacity" className="flex w-fit flex-col">
      <PaginatedOpportunitiesList items={orderedOpportunities} />
    </AnimationShell>
  );
}
