import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableHeader, Typography } from '@/components';

import { OpportunityDetails } from '../../../types';

import { PaginatedOffersList } from './paginated-offers-list';

export function OpportunityOffersList({ opportunity }: { readonly opportunity: OpportunityDetails }) {
  const { t } = useTranslation(['opportunities']);
  const [isExpanded, setIsExpanded] = useState(true);

  const header = (
    <CollapsableHeader
      title={t('Received Offers')}
      description={t('Here you can find all the offers received for this opportunity.')}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );

  if (!opportunity) return null;

  return (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <div className="w-full items-start justify-start gap-8 border-t border-neutral-50 p-6">
        {opportunity.offers.length > 0 ? (
          <PaginatedOffersList items={opportunity.offers} />
        ) : (
          <Typography as="p" size="body-2" color="primary" weight="semibold">
            {t('No offers received yet')}
          </Typography>
        )}
      </div>
    </Collapsable>
  );
}
