import { useTranslation } from 'react-i18next';

import { AnimationShell, CustomMarkdown, Typography } from '@/components';

import { ServiceDetails } from '../../types';

export type ServiceCredentialsProps = {
  readonly service: ServiceDetails;
};

export function ServiceCredentials({ service }: ServiceCredentialsProps) {
  const { t } = useTranslation(['services']);

  return (
    <section className="">
      <AnimationShell type="slide-left" className="flex flex-col space-y-4">
        <Typography as="h4" size="heading-2" color="primary" weight="semibold">
          {t('Credentials')}
        </Typography>
        {service.credentials.map((credential, index) => (
          <div key={`credential-${index}`} className="mt-4 items-end justify-between">
            <Typography as="h5" size="body-1" color="primary" weight="semibold">
              {credential.title}
            </Typography>
            <CustomMarkdown text={credential.description} />
          </div>
        ))}
      </AnimationShell>
    </section>
  );
}
