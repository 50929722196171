import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, SelectableButton, Typography } from '@/components';

export type CapacityFilterProps = {
  readonly defaultValue?: string[];
  readonly onChange: (value: string[]) => void;
};

export function CapacityFilter({ defaultValue = [], onChange }: CapacityFilterProps) {
  const { t } = useTranslation(['home']);

  const capacities = useMemo(() => ['1-5', '5-50', '>50'], []);

  const selectedRef = useRef<string[]>(defaultValue);

  const handleOnChange = useCallback(
    (value: string) => {
      const currentSelected = selectedRef.current;

      selectedRef.current = currentSelected.includes(value)
        ? currentSelected.filter((item) => item !== value)
        : [...currentSelected, value];

      onChange(selectedRef.current);
    },
    [onChange]
  );

  return (
    <Field
      id="capacity"
      label={t(`Capacity`)}
      classes={{ field: 'space-y-2', input: 'flex flex-wrap items-center justify-start gap-2' }}
    >
      {capacities.map((value) => (
        <SelectableButton
          key={value}
          id={value}
          value={value}
          className="items-center gap-x-1"
          type="checkbox"
          defaultChecked={defaultValue?.includes(value)}
          registration={{
            name: `capacity`,
            onChange: (event) => {
              handleOnChange(event.target.value);
              return Promise.resolve();
            },
          }}
        >
          <Typography size="body-3" color="primary" weight="normal">
            {value}
          </Typography>
        </SelectableButton>
      ))}
    </Field>
  );
}
