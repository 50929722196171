import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useDUPStatus() {
  const [t] = useTranslation(['projects']);

  const options = useMemo(
    () => [
      { label: t('Pending'), value: 'pending' as const },
      { label: t('Requested'), value: 'requested' as const },
      { label: t('Obtained'), value: 'obtained' as const },
      { label: t('Not required'), value: 'not-required' as const },
    ],
    [t]
  );

  const kv = useMemo(
    () => options.reduce((map, option) => ({ ...map, [option.value]: option.label }), {}) as Record<string, string>,
    [options]
  );

  const map = useCallback((value?: string | null) => (value ? kv[value] : null), [kv]);

  return { options, kv, map };
}
