import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function CurrencyEuro({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="9"
      height="13"
      viewBox="0 0 9 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="currency-euro"
    >
      <path
        d="M6.36719 10.9375C6.66927 10.9375 6.96354 10.9141 7.25 10.8672C7.53646 10.8203 7.8099 10.7552 8.07031 10.6719L8.22656 11.9219C7.9349 11.9948 7.63021 12.0521 7.3125 12.0938C6.99479 12.1354 6.67448 12.1562 6.35156 12.1562C5.73177 12.1562 5.16406 12.0625 4.64844 11.875C4.13281 11.6823 3.6875 11.3984 3.3125 11.0234C2.9375 10.6432 2.64583 10.1719 2.4375 9.60938C2.23438 9.04167 2.13281 8.38021 2.13281 7.625V5.02344C2.13281 4.26302 2.23438 3.59896 2.4375 3.03125C2.64062 2.46354 2.92969 1.98958 3.30469 1.60938C3.67969 1.22917 4.1224 0.945312 4.63281 0.757812C5.14844 0.565104 5.71875 0.46875 6.34375 0.46875C6.67188 0.46875 6.98698 0.492188 7.28906 0.539062C7.59635 0.580729 7.90885 0.638021 8.22656 0.710938L8.07031 1.96875C7.8151 1.88542 7.54167 1.82031 7.25 1.77344C6.95833 1.72135 6.66146 1.69531 6.35938 1.69531C5.94271 1.69531 5.5651 1.76302 5.22656 1.89844C4.89323 2.03385 4.60677 2.23698 4.36719 2.50781C4.13281 2.77865 3.95312 3.1224 3.82812 3.53906C3.70312 3.95573 3.64062 4.44531 3.64062 5.00781V7.625C3.64062 8.1875 3.70312 8.67708 3.82812 9.09375C3.95833 9.51042 4.14323 9.85417 4.38281 10.125C4.6224 10.3958 4.90885 10.599 5.24219 10.7344C5.58073 10.8698 5.95573 10.9375 6.36719 10.9375ZM6.64062 4.75781V5.73438H0.742188V4.75781H6.64062ZM6.64062 6.79688V7.76562H0.742188V6.79688H6.64062Z"
        fill="currentColor"
      />
    </svg>
  );
}
