import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';

import { Route } from '@/constants';
import { useBreadcrumbs } from '@/features/header';
import { ServiceView } from '@/features/services/components/service';

export function Service() {
  const { t } = useTranslation(['services']);

  useBreadcrumbs(() => [{ title: t(`Advisors`) }]);

  const { serviceId: id } = useParams();

  if (!id) return <Navigate to={Route.Services} />;

  return <ServiceView id={id} />;
}
