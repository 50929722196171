import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotifications } from '@/features/notifications';
import { useGetCurrentUserQuery } from '@/features/users';

import { useCreateInterestMutation } from '../api';
import { SendInterestFormFields } from '../components';

export type useOnCreateOfferProps = {
  id: string;
  onClose: () => void;
};

export function useOnCreateInterest({ id, onClose }: useOnCreateOfferProps) {
  const { t } = useTranslation(['deals']);
  const { data: user } = useGetCurrentUserQuery();
  const [createInterest, { isLoading }] = useCreateInterestMutation();
  const { add } = useNotifications();

  return useCallback(
    (data: SendInterestFormFields) => {
      if (!id) return;
      if (!user) return;
      if (isLoading) return;

      createInterest({
        ...data,
        deal: id,
      })
        .unwrap()
        .then(() =>
          add({
            id: new Date().toString(),
            title: t('Interest sent susscessfully'),
            description: t('Your Interest in this deal has been sent.'),
          })
        )
        .catch(() => {
          add({
            id: new Date().toString(),
            title: t('ERROR'),
            description: t('There was an error creating the offer. Please try again later.'),
          });
        })
        .finally(onClose);
    },
    [id, user, isLoading, t, add, createInterest, onClose]
  );
}
