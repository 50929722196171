import { useTranslation } from 'react-i18next';

import { Cards } from '@/components';
import { IconName } from '@/components/icons/types';
import { displayDate, mw, mwp } from '@/helpers';
import { useDevelopmentStatus, useTechnologies } from '@/hooks';

import { OpportunityDetails, OpportunityItemList } from '../../types';

export type OpportunityCardsProps = {
  opportunity: OpportunityDetails | OpportunityItemList;
};

export function OpportunityCards({ opportunity }: OpportunityCardsProps) {
  const { t } = useTranslation(['opportunities']);
  const { kv: technologies } = useTechnologies();
  const { kv: developmentStatus } = useDevelopmentStatus();

  const suffix = opportunity.hasPV ? mwp : mw;

  const infoCards = [
    {
      icon: 'technology' as IconName,
      title: t('Technology'),
      value: opportunity.technologies.types.map((technology) => technologies[technology]).join(', '),
    },
    {
      icon: 'lightning' as IconName,
      title: t('Installed capacity'),
      value: suffix(opportunity.technologies.capacity),
    },
    { icon: 'calendar' as IconName, title: t('Deadline'), value: displayDate(opportunity.deadline.date) },
    {
      icon: 'status' as IconName,
      title: t('Development status'),
      value: opportunity.technologies.state.list.map((status) => developmentStatus[status]).join(', '),
    },
  ];

  return <Cards info={infoCards} />;
}
