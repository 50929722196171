import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { eurosMWp, percentage } from '@/helpers';
import { useMilestones, useSaleOptions } from '@/hooks';

import { OfferDetails, OfferMilestoneType } from '../../types';

export type PriceCollapsableProps = {
  readonly offer: OfferDetails;
  readonly initialExpandedState?: boolean;
};

export function PriceCollapsable({ offer, initialExpandedState = true }: PriceCollapsableProps) {
  const { t } = useTranslation(['offers']);

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const { map: mapSaleOption } = useSaleOptions();

  const { kv: milestones } = useMilestones();

  const data = [
    { label: t('Indicative Price'), value: eurosMWp(offer?.price) },
    { label: t('Price for'), value: mapSaleOption(offer?.option) },
    {
      label: t('Willing to acquire'),
      value: `${percentage(offer?.percentage.minimum)} to ${percentage(offer?.percentage.maximum)}`,
    },
    {
      label: t('Payment milestones'),
      value: `${offer?.milestones
        .filter((milestone) => milestone.percentage !== 0)
        .map((milestone) => milestones[milestone.type] + ': ' + percentage(milestone.percentage))
        .join('\n ')}`,
    },
  ];

  const otherMilestone = offer.milestones.find(
    (milestone) => milestone.type === ('other' as OfferMilestoneType) && milestone.comments
  );

  if (otherMilestone) {
    data.push({
      label: t('Other Details'),
      value: otherMilestone.comments || null,
    });
  }

  const header = (
    <CollapsableHeader
      title={t('General Details')}
      description={t(
        'This section outlines the main indicative terms of this Expression of Interest. Please note that all information is non-binding and subject to document review and further negotiations.'
      )}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );

  return (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody data={data} />
    </Collapsable>
  );
}
