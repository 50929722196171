import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Field, Form, Input, Link, Radio, Select, TextArea, Typography } from '@/components';
import { Route as RoutePath } from '@/constants';
import { DocumentUpload, FileSchema } from '@/features/files';
import { useUploadOffer } from '@/features/offers/hooks';
import { DueDiligenceType, OfferMilestone, OfferMilestoneType } from '@/features/offers/types';
import { Opportunity, SaleOption } from '@/features/opportunities/types';
import { useRemoveAttachment } from '@/features/projects';
import { formatStringAsInteger, nullableStringSetter, parseStringToInteger } from '@/helpers';
import { useMilestones, useSaleOptions } from '@/hooks';
import { UseFormRegister } from 'react-hook-form';
import { AdvisoryServiceForm, AdvisoryServiceFormFields } from '@/features/services';

export type CreateOfferFormFields = {
  // Surface
  price: number;
  option: SaleOption;
  minimum_percentage: number;
  maximum_percentage: number;

  // Payment milestones
  milestones: OfferMilestone[];

  // Due diligence procedure details
  due_diligence_type: DueDiligenceType;
  due_diligence_weeks: number;
  due_diligence_requirements: string;
  due_diligence_comments: string;

  // Buyer
  buyer_type: string;
  buyer_comments: string;

  // Others
  file: string | null;
  comments: string;

  // Advisory service
  advisory_service_information: {
    legal_advisors: string;
    financial_advisors: string;
    technical_advisors: string;
    other: string;
  };

  // Terms and conditions
  organization_approval: boolean;
  terms_and_conditions_accepted: boolean;
};

export type MilestoneFieldValues = {
  type: OfferMilestoneType;
  percentage: number;
  comments: string | null;
};

export type MilestonesFieldValues = MilestoneFieldValues[];

export type CreateOfferFormProps = {
  readonly id: string;
  readonly opportunity: Opportunity;
  readonly onSubmit: (data: CreateOfferFormFields) => void;
};

export function CreateOfferForm({ id, opportunity, onSubmit }: CreateOfferFormProps) {
  const { t } = useTranslation(['offers']);
  const [file, setFile] = useState<FileSchema[]>([]);

  const { kv: statusOptions } = useSaleOptions();
  const { options: optionsSelector } = useMilestones();

  const uploadOffer = useUploadOffer();
  const { deleteAttachment } = useRemoveAttachment();

  const textareaMaxLength = 4000;

  const options = {
    defaultValues: {
      // Surface
      price: undefined,
      option: undefined,
      minimum_percentage: opportunity.minimum_percentage,
      maximum_percentage: opportunity.maximum_percentage,

      // Payment milestones
      milestones: [] as MilestonesFieldValues,

      // Due diligence procedure details
      due_diligence_type: undefined,
      due_diligence_weeks: undefined,
      due_diligence_requirements: undefined,
      due_diligence_comments: '',

      // Buyer
      buyer_type: undefined,
      buyer_comments: undefined,

      // Others
      file: null,
      comments: undefined,

      // Advisory service
      advisory_service_information: {
        legal_advisors: '',
        financial_advisors: '',
        technical_advisors: '',
        other: '',
      },

      // Terms and conditions
      organization_approval: false,
      terms_and_conditions_accepted: false,
    },
  };

  return (
    <Form<CreateOfferFormFields> id={id} onSubmit={onSubmit} options={options}>
      {({ formState, register, setValue, watch }) => (
        <div className="space-y-6">
          <Typography as="p" size="body-3" color="primary" weight="normal" className="mt-1 italic">
            {t('We invite you to submit an ')}
            <b>{t('Expression of Interest (EOI)')}</b>
            {t(
              ' to explore this investment opportunity. The terms you provide, including price, are indicative and non-binding, serving as a basis for initial discussions. Final terms will be subject to further review and negotiation with the project owner.'
            )}
          </Typography>
          <div>
            <Typography as="h5" color="primary" size="title" weight="normal">
              {t('General Details')}
            </Typography>
            <Typography as="p" size="body-3" color="tertiary" weight="normal" className="mt-1">
              {t(
                'Please provide the main indicative terms of your interest. All information in this section is non-binding.'
              )}
            </Typography>
          </div>

          <Field
            id="price"
            label={t('Indicative Price')}
            error={formState.errors['price']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="price"
              type="text"
              mask={formatStringAsInteger}
              placeholder={t('e.g. 150.000 €/MWp')}
              suffix="€/MWp"
              color={formState.errors['price'] ? 'error' : 'primary'}
              registration={register('price', {
                required: t('This field is required'),
                setValueAs: parseStringToInteger,
              })}
            />
          </Field>

          <Field
            id="option"
            label={t('Price for')}
            error={formState.errors['option']}
            delay={0.15}
            classes={{ field: 'space-y-2', input: 'flex space-x-3' }}
          >
            {opportunity.options.map((option: SaleOption) => (
              <Radio
                color={formState.errors['option'] ? 'error' : 'primary'}
                key={option}
                value={option}
                registration={register('option', { required: t('This field is required') })}
              >
                {statusOptions[option]}
              </Radio>
            ))}
          </Field>

          <Field label={t('Willing to acquire')} delay={0.3}>
            <div className="flex w-full items-center">
              <div>
                <Input
                  id="minimum_percentage"
                  placeholder={opportunity.minimum_percentage.toString()}
                  color={formState.errors['minimum_percentage'] ? 'error' : 'primary'}
                  registration={register('minimum_percentage', {
                    required: t('This field is required'),
                    min: 0,
                    max: 100,
                    valueAsNumber: true,
                  })}
                  type="number"
                  suffix="%"
                />
              </div>
              <span className="px-3">-</span>
              <div>
                <Input
                  id="maximum_percentage"
                  placeholder={opportunity.maximum_percentage.toString()}
                  color={formState.errors['maximum_percentage'] ? 'error' : 'primary'}
                  registration={register('maximum_percentage', {
                    required: t('This field is required'),
                    min: 0,
                    max: 100,
                    valueAsNumber: true,
                  })}
                  type="number"
                  suffix="%"
                />
              </div>
            </div>
          </Field>

          <Field label={t('Indicative payment milestones')} id="milestones" delay={0.9}>
            {optionsSelector.map((option, index) => (
              <>
                <Input
                  color="primary"
                  type="hidden"
                  className="pointer-events-none mr-4 w-full bg-neutral-25"
                  registration={register(`milestones.${index}.type`, {
                    value: option.value as OfferMilestoneType,
                  })}
                />
                <div className="mt-2 flex gap-4" key={`milestone-${index}`}>
                  <Typography
                    as="p"
                    size="body-3"
                    color="primary"
                    weight="normal"
                    className="border-xl flex w-1/2 items-center justify-start rounded-sm border bg-neutral-25 px-2"
                  >
                    {option.label}
                  </Typography>
                  <Input
                    type="number"
                    placeholder="0"
                    defaultValue="0"
                    suffix="%"
                    id="percentage"
                    registration={register(`milestones.${index}.percentage`)}
                  />
                </div>
              </>
            ))}
            <TextArea
              id="milestones_others"
              color="primary"
              className="mt-2"
              placeholder="Type here any additional details of the indicative payment milestones."
              registration={register('milestones.3.comments', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <hr />
          <div>
            <Typography as="h5" color="primary" size="title" weight="normal">
              {t('Due Diligence Requirements')}
            </Typography>
            <Typography as="p" size="body-3" color="tertiary" weight="normal" className="mt-1">
              {t(
                'Provide details about your due diligence process. This information will guide the seller in understanding your requirements and expectations.'
              )}
            </Typography>
          </div>

          <Field
            id="due_diligence_type"
            error={formState.errors['due_diligence_type']}
            label={t('Due Diligence Type')}
            delay={0.45}
            classes={{ field: 'space-y-2', input: 'space-y-2' }}
          >
            <Radio
              color={formState.errors['due_diligence_type'] ? 'error' : 'primary'}
              registration={register('due_diligence_type', { required: t('This field is required') })}
              value="internal"
            >
              {' '}
              <Typography as="p" size="body-3" color="primary" weight="normal">
                {t('In-house: Conducted by your internal team.')}
              </Typography>
            </Radio>
            <Radio
              color={formState.errors['due_diligence_type'] ? 'error' : 'primary'}
              registration={register('due_diligence_type', { required: t('This field is required') })}
              value="external"
            >
              <Typography as="p" size="body-3" color="primary" weight="normal">
                {t('External: Outsourced to a third-party provider.')}
              </Typography>
            </Radio>
          </Field>

          <Field
            id="due_diligence_weeks"
            label={t('Due Diligence required time')}
            error={formState.errors['due_diligence_weeks']}
            delay={0.6}
          >
            <Input
              id="due_diligence_weeks"
              type="number"
              suffix=" Weeks"
              step="1"
              placeholder={t('e.g. 5 weeks')}
              color={formState.errors['due_diligence_weeks'] ? 'error' : 'primary'}
              registration={register('due_diligence_weeks', { required: t('This field is required') })}
            />
          </Field>

          <Field id="due_diligence_comments" label={t('Due Diligence other comments')} delay={0.9}>
            <TextArea
              id="due_diligence_comments"
              color={formState.errors['due_diligence_comments'] ? 'error' : 'primary'}
              registration={register('due_diligence_comments', {
                setValueAs: nullableStringSetter,
                maxLength: textareaMaxLength,
              })}
              placeholder="Additional details or requirements related to your due diligence process..."
              maxLength={textareaMaxLength}
            />
          </Field>

          <hr />
          <div>
            <Typography as="h5" color="primary" size="title" weight="normal">
              {t('Prospective Buyer Information')}
            </Typography>
            <Typography as="p" size="body-3" color="tertiary" weight="normal" className="mt-1">
              {t(
                'Provide information about the prospective buyer, indicating whether you are the final purchaser or a representative acting for a third party. If you are representing a third party, please include their name and relevant details.'
              )}
            </Typography>
          </div>

          <Field
            id="buyer_type"
            label={t('Your Role in Transaction')}
            delay={0.9}
            error={formState.errors['buyer_type']}
            classes={{ field: 'space-y-2', input: 'space-y-3' }}
          >
            <Radio
              color={formState.errors['buyer_type'] ? 'error' : 'primary'}
              registration={register('buyer_type', { required: t('This field is required') })}
              value="final-buyer"
            >
              {' '}
              <Typography as="p" size="body-3" color="primary" weight="normal">
                {t('Final Buyer: You are the direct purchaser of the project and will hold ownership upon completion.')}
              </Typography>
            </Radio>
            <Radio
              color={formState.errors['buyer_type'] ? 'error' : 'primary'}
              registration={register('buyer_type', { required: t('This field is required') })}
              value="intermediary"
            >
              <Typography as="p" size="body-3" color="primary" weight="normal">
                {t('Third-party representative: You are acting on behalf of another buyer or entity. ')}
              </Typography>
            </Radio>
          </Field>

          <Field id="buyer_comments" label={t('Final Buyer Information & Description ')} delay={1.05}>
            <TextArea
              id="comments"
              color="primary"
              registration={register('buyer_comments', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <hr />
          <div>
            <Typography as="h5" color="primary" size="title" weight="normal">
              {t('Additional Information & Documents')}
            </Typography>
            <Typography as="p" size="body-3" color="tertiary" weight="normal" className="mt-1">
              {t(
                'Use this section to provide any additional information you consider relevant of your expression of interest or the project. You can also upload any supporting documents that may help clarify your position, terms, or requirements.'
              )}
            </Typography>
          </div>

          <Field id="comments" label={t('Additional Information')} delay={1.2}>
            <TextArea
              id="comments"
              color="primary"
              registration={register('comments', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <Field id="document" label={t('Supporting Document')} delay={1.35}>
            <DocumentUpload
              multiple={false}
              files={file}
              onSelectFiles={(newFiles) => {
                uploadOffer(newFiles[0], (data) => {
                  setValue('file', data.id);
                  setFile([data]);
                });
              }}
              onUnSelectFiles={(oldFile) => {
                deleteAttachment(oldFile.id, () => {
                  setValue('file', null);
                  setFile([]);
                });
              }}
            />
          </Field>

          <br />
          <div>
            <Typography as="h5" color="primary" size="title" weight="normal">
              {t('Advisory Services')}
            </Typography>
            <Typography as="p" size="body-3" color="tertiary" weight="normal" className="mt-1">
              {t(
                'Do you need professional advice for this Expression of Interest? At nTeaser, we have selected the best advisors to guide you. Selecting "Yes" is non-binding; we will only conect you with the right expert! Want to know more? Visit our'
              )}{' '}
              <Link to={RoutePath.Services} target="_blank">
                {t('Advisors section')}
              </Link>{' '}
              {t('and discover them all!.')}
            </Typography>
          </div>

          <AdvisoryServiceForm
            register={register as UseFormRegister<AdvisoryServiceFormFields>}
            formState={formState}
          />

          <div className="rounded-md bg-gray-100 p-4">
            <Field
              id="organization_approval"
              error={formState.errors['organization_approval']}
              delay={0.9}
              classes={{ field: 'space-y-1' }}
            >
              <Checkbox
                id="organization_approval"
                color="primary"
                registration={register('organization_approval', {
                  required: t('This consent is required'),
                })}
              >
                <Typography as="p" color="secondary" size="body-3" weight="normal">
                  {t('I confirm I have received the neccessary approvals within my organisation to submit this offer.')}
                </Typography>
              </Checkbox>
            </Field>
            <Field
              id="terms_and_conditions_accepted"
              error={formState.errors['terms_and_conditions_accepted']}
              delay={1.05}
              classes={{ field: 'space-y-1' }}
            >
              <Checkbox
                id="terms_and_conditions_accepted"
                color="primary"
                registration={register('terms_and_conditions_accepted', {
                  required: t('You must accept the terms and conditions to submit this offer.'),
                })}
              >
                <Typography as="p" color="secondary" size="body-3" weight="normal">
                  {t('I accept nTeaser’s ')}
                  <Link to={RoutePath.TermsAndConditions} target="_blank">
                    {t('Terms and Conditions')}
                  </Link>
                  {t(' and accept to pay a success fee of 1% of this NBO at SPA signing.')}
                </Typography>
              </Checkbox>
            </Field>
          </div>
        </div>
      )}
    </Form>
  );
}
