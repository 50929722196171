import { DAY, HOUR, MINUTE, MONTHS, SECOND } from '@/constants';
import { DateDelta, DateFormatedParts, DateParts, DateType, Nullish, isDate, isNumber, isString } from '@/types';

import { padStart } from './string';

export function getDate(value: DateType | Nullish): Date | undefined {
  if (isDate(value)) return value;
  if (isString(value) || isNumber(value)) return new Date(value);
}

export function getDateParts(value: DateType): DateParts | undefined {
  const date = getDate(value);

  if (!date) return;

  return {
    weekDay: date.getDay(),
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    hours: date.getHours(),
    hours12: date.getHours() % 12 || 12,
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
    time: date.getTime(),
  };
}

export function getDateFormatedParts(value: DateType): DateFormatedParts | undefined {
  const date = getDateParts(value);

  if (!date) return;

  const { day, month, year, hours, hours12, minutes, seconds } = date;

  const pad2 = padStart(2, '0');
  const pad4 = padStart(4, '0');

  return {
    day: pad2(day),
    month: pad2(month + 1),
    monthName: MONTHS[month],
    shortYear: year.toString().slice(-2),
    year: pad4(year),
    ampm: hours >= 12 ? 'PM' : 'AM',
    hours: pad2(hours),
    hours12: pad2(hours12),
    minutes: pad2(minutes),
    seconds: pad2(seconds),
  };
}

export function dateFormat(format: string, placeholder = '') {
  return function (date: DateType) {
    const parts = getDateFormatedParts(date);

    if (parts) {
      return format
        .replace('YYYY', parts.year)
        .replace('YY', parts.shortYear)
        .replace('MMM', parts.monthName)
        .replace('MM', parts.month)
        .replace('DD', parts.day)
        .replace('hh', parts.hours)
        .replace('h', parts.hours12)
        .replace('mm', parts.minutes)
        .replace('ss', parts.seconds)
        .replace('ampm', parts.ampm);
    }

    return placeholder;
  };
}

export function isDateOlderThan({ days = 0, hours = 0, minutes = 0, seconds = 0 }: DateDelta) {
  const threshold = days * DAY + hours * HOUR + minutes * MINUTE + seconds * SECOND;
  return function (value: DateType): boolean {
    const date = getDate(value);

    if (!date) return false;

    const now = new Date();
    const difference = now.getTime() - date.getTime();
    return difference > threshold;
  };
}

export function isDateNewerThan({ days = 0, hours = 0, minutes = 0, seconds = 0 }: DateDelta) {
  const threshold = days * DAY + hours * HOUR + minutes * MINUTE + seconds * SECOND;
  return function (value: DateType): boolean {
    const date = getDate(value);

    if (!date) return false;

    const now = new Date();
    const difference = now.getTime() - date.getTime();
    return difference < threshold;
  };
}
