import { ClassValue } from 'clsx';
import { ReactNode } from 'react';

import { cn } from '@/utils/styles';

import { sizes } from './constants';
import { ContainerSize } from './types';

export type ContainerProps = {
  readonly size?: ContainerSize;
  readonly className?: ClassValue;
  readonly children?: ReactNode;
  readonly overflow?: boolean;
};

export function Container({ size = 'lg', className, children, overflow = false }: ContainerProps) {
  return <div className={cn('mx-auto w-full px-8', !overflow ? sizes[size] : undefined, className)}>{children}</div>;
}
