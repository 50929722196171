import { useTranslation } from 'react-i18next';

import { Button, Drawer } from '@/components';
import { ProjectTechnology } from '@/features/projects/types';

import { ConnectionInfrastructureForm } from './connection-infrastructure-form';

export type ConnectionInfrastructureDrawerProps = {
  readonly technology: ProjectTechnology;
  readonly isOpen: boolean;
  readonly onSubmit: (data: Partial<ProjectTechnology>, description?: string) => void;
  readonly onClose: () => void;
};

export function ConnectionInfrastructureDrawer({
  technology,
  isOpen,
  onSubmit,
  onClose,
}: ConnectionInfrastructureDrawerProps) {
  const { t } = useTranslation(['projects']);

  const footer = (
    <>
      <Button size="lg" color="neutral" onClick={onClose}>
        {t('Cancel')}
      </Button>
      <Button type="submit" form="connection-infrastructure-form" size="lg" color="primary">
        {t('Save')}
      </Button>
    </>
  );

  return (
    <Drawer title={t('Connection Infrastructure')} open={isOpen} onClose={onClose} footer={footer}>
      <ConnectionInfrastructureForm
        id="connection-infrastructure-form"
        technology={technology}
        onSubmit={(values: Partial<ProjectTechnology>) =>
          onSubmit(values, t('Connection Infrastructure section has been updated'))
        }
      />
    </Drawer>
  );
}
