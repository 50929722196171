import { ASSETS_BASE_URL } from '@/config';

import { ServiceDetails } from '../../types';

export type ServiceLogoProps = {
  readonly service: ServiceDetails;
};

export function ServiceLogo({ service }: ServiceLogoProps) {
  return (
    <div className="flex">
      <img src={service.logo ? `${ASSETS_BASE_URL}/${service.logo}` : ''} alt={service.name} className="h-32" />
    </div>
  );
}
