import { Dispatch, MiddlewareAPI, UnknownAction } from '@reduxjs/toolkit';

import { logout } from '../../store';
import { api } from '../api';

const apiCacheMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: UnknownAction) => {
  if (logout.match(action)) {
    window.sessionStorage.clear();
    store.dispatch(api.util.resetApiState());
  }
  return next(action);
};

export default apiCacheMiddleware;
