import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ConnectionTo, ConnectionType } from '@/features/projects/types';

export function useConnectionTo() {
  const { t } = useTranslation(['connection']);

  const options = useMemo(
    () => [
      { label: t(`Power Line`), value: 'powerline' as ConnectionTo },
      { label: t(`Substation`), value: 'substation' as ConnectionTo },
    ],
    [t]
  );

  const kv = useMemo(
    () =>
      options.reduce((map, option) => ({ ...map, [option.value]: option.label }), {}) as Record<ConnectionTo, string>,
    [options]
  );

  const map = useCallback((value: ConnectionTo) => kv[value], [kv]);

  return { options, kv, map };
}

export function useConnectionType() {
  const { t } = useTranslation(['connection']);

  const options = useMemo(
    () => [
      { label: t(`Underground`), value: 'underground' as ConnectionType },
      { label: t(`Aerial`), value: 'aerial' as ConnectionType },
    ],
    [t]
  );

  const kv = useMemo(
    () =>
      options.reduce((map, option) => ({ ...map, [option.value]: option.label }), {}) as Record<ConnectionType, string>,
    [options]
  );

  const map = useCallback((value: ConnectionType) => kv[value], [kv]);

  return { options, kv, map };
}
