import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Container } from '@/components';
import { useBreadcrumbs } from '@/features/header';
import { DealDetailsView, DealHeaderView } from '@/features/deals';
import { useState } from 'react';
import { SendInterestDrawer } from '@/features/interest';

export function Deal() {
  const { t } = useTranslation(['deals']);
  const [isOpen, setIsOpen] = useState(false);

  useBreadcrumbs(() => [{ title: t(`Other Deals`) }]);
  const { dealId: id } = useParams();

  if (!id) return null;

  return (
    <div className="flex flex-col">
      <div className="w-full border-b border-neutral-50 bg-neutral-10">
        <Container className="space-y-6 pt-6">
          <DealHeaderView id={id} setIsOpen={setIsOpen} />
        </Container>
      </div>

      <div className="w-full py-10">
        <Container className="space-y-12">
          <DealDetailsView id={id} />
        </Container>
      </div>

      <SendInterestDrawer isOpen={isOpen} setIsOpen={setIsOpen} id={id} />
    </div>
  );
}
