import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Form, TextArea, Toggle, Typography } from '@/components';
import { ProjectTechnology } from '@/features/projects/types';
import { nullableStringSetter } from '@/helpers';

export type OthersFormFields = {
  has_ppa: boolean | null;
  ppa_comments: string | null;
  has_debt: boolean | null;
  debt_comments: string | null;
};

export type OthersFormProps = {
  readonly id: string;
  readonly technology: ProjectTechnology;
  readonly onSubmit: (data: Partial<ProjectTechnology>) => void;
};

export function OthersForm({ id, technology, onSubmit }: OthersFormProps) {
  const { t } = useTranslation(['projects']);

  const options = useMemo(
    () => ({
      defaultValues: {
        has_ppa: technology.has_ppa,
        ppa_comments: technology.ppa_comments,
        has_debt: technology.has_debt,
        debt_comments: technology.debt_comments,
      },
    }),
    [technology]
  );

  return (
    <Form<OthersFormFields> id={id} onSubmit={(data) => onSubmit(data)} options={options}>
      {({ formState, register, watch }) => (
        <div className="space-y-6">
          <Typography as="p" color="secondary" size="body-3" weight="normal" className="mt-1 italic">
            {t(
              'Please provide all additional information regarding the project in this section, such as whether a PPA or debt is included, and provide the relevant details.'
            )}
          </Typography>
          <Field
            id="has_ppa"
            label={t('PPA included')}
            error={formState.errors['has_ppa']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Toggle id="has_ppa" registration={register('has_ppa')} />
          </Field>

          {watch('has_ppa') && (
            <Field
              id="ppa_comments"
              label={t('PPA details')}
              error={formState.errors['ppa_comments']}
              delay={0.15}
              classes={{ field: 'space-y-1' }}
            >
              <TextArea
                id="ppa_comments"
                color="primary"
                registration={register('ppa_comments', {
                  setValueAs: nullableStringSetter,
                })}
              />
            </Field>
          )}

          <Field
            id="has_debt"
            label={t('Debt included')}
            error={formState.errors['has_debt']}
            delay={0.25}
            classes={{ field: 'space-y-1' }}
          >
            <Toggle id="has_debt" registration={register('has_debt')} />
          </Field>

          {watch('has_debt') && (
            <Field
              id="debt_comments"
              label={t('Debt details')}
              error={formState.errors['debt_comments']}
              delay={0.35}
              classes={{ field: 'space-y-1' }}
            >
              <TextArea
                id="debt_comments"
                color="primary"
                registration={register('debt_comments', {
                  setValueAs: nullableStringSetter,
                })}
              />
            </Field>
          )}
        </div>
      )}
    </Form>
  );
}
