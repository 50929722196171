import { ReactNode } from 'react';

import { AnimationShell, Typography } from '@/components';

export type ValueProps = {
  readonly label?: string;
  readonly index?: number;
  readonly children?: ReactNode;
};

export function Value({ label, index = 0, children }: ValueProps) {
  return (
    <AnimationShell type="opacity" delay={0.15 * index} className="gap-1">
      <Typography as="span" size="caption" color="primary" weight="normal">
        {label}
      </Typography>{' '}
      <Typography as="span" size="caption" color={children ? 'primary' : 'disabled'} weight="semibold">
        {children ?? '---'}
      </Typography>
    </AnimationShell>
  );
}
