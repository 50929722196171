import { useCallback, useMemo } from 'react';

import { useGetServicesTagsQuery } from '../api';
import { tagsTransformer } from '../helpers';

export function useServicesTags() {
  const { data: servicesTags } = useGetServicesTagsQuery();

  const normalizedServicesTags = tagsTransformer(servicesTags);

  const options = useMemo(() => {
    if (!normalizedServicesTags) return [];

    return normalizedServicesTags?.map((tag) => ({ label: tag.name, value: tag.id }));
  }, [normalizedServicesTags]);

  const kv = useMemo(
    () => options.reduce((map, option) => ({ ...map, [option.value]: option.label }), {}) as Record<string, string>,
    [options]
  );

  const map = useCallback((value: string) => kv[value], [kv]);

  return { options, kv, map };
}
