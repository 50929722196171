import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Field, Form, Input, TextArea, Toggle, Typography } from '@/components';
import { getTechnologyTypeFromCollection } from '@/features/projects/helpers';
import { ConnectionTo, ConnectionType, ProjectTechnology } from '@/features/projects/types';
import { formatStringAsInteger, nullableStringSetter, parseStringToInteger } from '@/helpers';

export type ConnectionInfrastructureFormFields = {
  evacuation_line_total_distance: number | null;
  evacuation_line_voltage: number | null;
  connection_type: ConnectionType[] | null;
  connection_to: ConnectionTo[] | null;
  shared_infrastructure: boolean | null;
  shared_infrastructure_comments: string | null;
  connection_costs: number | null;
  connection_costs_comments: string | null;
  connection_infrastructure_comments: string | null;
};

export type ConnectionInfrastructureFormProps = {
  readonly id: string;
  readonly technology: ProjectTechnology;
  readonly onSubmit: (data: Partial<ProjectTechnology>) => void;
};

export function ConnectionInfrastructureForm({ id, technology, onSubmit }: ConnectionInfrastructureFormProps) {
  const { t } = useTranslation(['projects']);
  const isSolar = getTechnologyTypeFromCollection(technology.collection) === 'solar';

  const options = useMemo(
    () => ({
      defaultValues: {
        evacuation_line_total_distance: technology.evacuation_line_total_distance,
        evacuation_line_voltage: technology.evacuation_line_voltage,
        connection_type: technology.connection_type ?? [],
        connection_to: technology.connection_to ?? [],
        shared_infrastructure: technology.shared_infrastructure,
        shared_infrastructure_comments: technology.shared_infrastructure_comments,
        connection_costs: technology.connection_costs,
        connection_costs_comments: technology.connection_costs_comments,
        connection_infrastructure_comments: technology.connection_infrastructure_comments,
      },
    }),
    [technology]
  );

  return (
    <Form<ConnectionInfrastructureFormFields> id={id} onSubmit={(data) => onSubmit(data)} options={options}>
      {({ formState, register, watch }) => (
        <div className="space-y-6">
          <Typography as="p" color="secondary" size="body-3" weight="normal" className="mt-1 italic">
            {t(
              "Please complete this section with detailed information about the project's connection infrastructure, including the line distance, voltage, type (underground/overhead), connection to (substation/power line), expected costs, and any additional comments."
            )}
          </Typography>
          <Field
            id="evacuation_line_total_distance"
            label={t('Evacuation line total distance')}
            error={formState.errors['evacuation_line_total_distance']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="evacuation_line_total_distance"
              type="number"
              placeholder={t('e.g. 5')}
              color="primary"
              suffix="km"
              registration={register('evacuation_line_total_distance', { valueAsNumber: true })}
            />
          </Field>

          <Field
            id="evacuation_line_voltage"
            label={t('Evacuation line voltage')}
            error={formState.errors['evacuation_line_voltage']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="evacuation_line_voltage"
              type="number"
              placeholder={t('e.g. 5')}
              color="primary"
              suffix="kV"
              registration={register('evacuation_line_voltage', { valueAsNumber: true })}
            />
          </Field>

          <Field label={t('Connection type')} delay={0} classes={{ field: 'space-y-2', input: 'flex space-x-3' }}>
            <Checkbox color="primary" registration={register('connection_type')} value="underground">
              {t('Underground')}
            </Checkbox>
            <Checkbox color="primary" registration={register('connection_type')} value="aerial">
              {t('Aerial')}
            </Checkbox>
          </Field>

          <Field label={t('Connection to')} delay={0} classes={{ field: 'space-y-2', input: 'flex space-x-3' }}>
            <Checkbox color="primary" registration={register('connection_to')} value="powerline">
              {t('Power line')}
            </Checkbox>
            <Checkbox color="primary" registration={register('connection_to')} value="substation">
              {t('Substation')}
            </Checkbox>
          </Field>

          <Field
            id="connection_costs"
            label={t('Connection costs')}
            error={formState.errors['connection_costs']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="connection_costs"
              type="text"
              mask={formatStringAsInteger}
              placeholder={t('e.g. 150.000')}
              color="primary"
              suffix={isSolar ? '€/MWp' : '€/MW'}
              registration={register('connection_costs', { setValueAs: parseStringToInteger })}
            />
          </Field>

          <Field
            id="connection_costs_comments"
            label={t('Connection costs comments')}
            error={formState.errors['connection_costs_comments']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="connection_costs_comments"
              color="primary"
              registration={register('connection_costs_comments', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <Field
            id="shared_infrastructure"
            label={t('Shared infrastructure')}
            error={formState.errors['shared_infrastructure']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Toggle id="shared_infrastructure" registration={register('shared_infrastructure')} />
          </Field>

          {watch('shared_infrastructure') && (
            <Field
              id="shared_infrastructure_comments"
              label={t('Shared infrastructure comments')}
              error={formState.errors['shared_infrastructure_comments']}
              delay={0}
              classes={{ field: 'space-y-1' }}
            >
              <TextArea
                id="shared_infrastructure_comments"
                color="primary"
                registration={register('shared_infrastructure_comments', { setValueAs: nullableStringSetter })}
              />
            </Field>
          )}

          <Field
            id="connection_infrastructure_comments"
            label={t('Comments')}
            error={formState.errors['connection_infrastructure_comments']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <TextArea
              id="connection_infrastructure_comments"
              color="primary"
              registration={register('connection_infrastructure_comments', { setValueAs: nullableStringSetter })}
            />
          </Field>
        </div>
      )}
    </Form>
  );
}
