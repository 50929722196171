import { ClassValue } from 'clsx';
import { ReactNode } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { cn } from '@/utils/styles';

import { colors, sizes } from './constants';
import { CheckboxColor, CheckboxSize } from './types';

export type CheckboxProps = {
  readonly id?: string;
  readonly value?: string;
  readonly size?: CheckboxSize;
  readonly color?: CheckboxColor;
  readonly autoComplete?: string;
  readonly registration?: Partial<UseFormRegisterReturn>;
  readonly children?: ReactNode;
  readonly classes?: {
    main?: ClassValue;
    input?: ClassValue;
    label?: ClassValue;
  };
};

export function Checkbox({
  id,
  value,
  size = 'md',
  color = 'primary',
  autoComplete = 'off',
  registration,
  children,
  classes,
}: CheckboxProps) {
  return (
    <div className={cn('flex items-center justify-start gap-x-2', classes?.main)}>
      <input
        type="checkbox"
        id={id ?? value}
        autoComplete={autoComplete}
        className={cn(
          'cursor-pointer transition disabled:cursor-not-allowed',
          sizes[size],
          'appearance-none rounded border shadow-none ring-0 ring-transparent',
          'focus:ring-transparent focus-visible:ring-transparent',
          colors[color],
          classes?.input
        )}
        value={value}
        {...registration}
      />

      {children ? (
        <label
          htmlFor={id ?? value}
          className={cn(
            'block text-base font-normal leading-4',
            registration?.disabled ? 'cursor-not-allowed text-neutral-disabled' : 'cursor-pointer text-base-primary',
            classes?.label
          )}
        >
          {children}
        </label>
      ) : null}
    </div>
  );
}
