import { useTranslation } from 'react-i18next';

import { Button, Drawer } from '@/components';

import { useOnCreateOpportunity } from '../../hooks';

import { CreateOpportunityForm } from './form';

export type CreateOpportunityDrawerProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
};

export function CreateOpportunityDrawer({ isOpen, onClose }: CreateOpportunityDrawerProps) {
  const { t } = useTranslation(['opportunities']);
  const onSubmit = useOnCreateOpportunity(onClose);

  const footer = (
    <>
      <Button size="lg" color="neutral" onClick={onClose}>
        {t('Cancel')}
      </Button>
      <Button type="submit" form="create-opportunity-form" size="lg" color="primary">
        {t('Create Project')}
      </Button>
    </>
  );

  return (
    <Drawer title={t('Upload Project')} open={isOpen} onClose={onClose} footer={footer}>
      <CreateOpportunityForm id="create-opportunity-form" onSubmit={onSubmit} />
    </Drawer>
  );
}
