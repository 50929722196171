import { Project, ProjectDetails } from '../types';

import { isDraft, isPublished } from './helpers';
import { technologyTransformer } from './technology-transformer';

export function projectDetailsTransformer(project?: Project): ProjectDetails | null {
  if (!project) return null;

  return {
    id: project.id,
    company: project.company,
    location: {
      country: project.country,
      city: [project.city],
    },
    technologies: project.technologies.map(technologyTransformer),
    isPublished: isPublished(project.status),
    isDraft: isDraft(project.status),
  };
}
