import { useTranslation } from 'react-i18next';

import { AnimationShell, Button, Field, Form, Input } from '@/components';

export type CredentialsFormFields = {
  email: string;
  password: string;
};

export type CredentialsFormProps = {
  readonly onSubmit: (data: CredentialsFormFields) => void;
};

export function CredentialsForm({ onSubmit }: CredentialsFormProps) {
  const { t } = useTranslation(['signin']);

  return (
    <Form<CredentialsFormFields> onSubmit={onSubmit}>
      {({ register, formState }) => (
        <div className="flex flex-col space-y-7">
          <Field
            id="email"
            label={t(`Email address`)}
            error={formState.errors['email']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              type="email"
              color={formState.errors['email'] ? 'error' : 'primary'}
              placeholder={t(`john.doe@example.com`)}
              autoComplete="email"
              registration={register('email', { required: t(`This field is required`) })}
            />
          </Field>

          <Field
            id="password"
            label={t(`Password`)}
            error={formState.errors['password']}
            delay={0.15}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              type="password"
              autoComplete="new-password"
              color={formState.errors['password'] ? 'error' : 'primary'}
              registration={register('password', { required: t(`This field is required`) })}
            />
          </Field>

          <AnimationShell type="opacity" delay={0.25}>
            <Button type="submit" size="lg" className="w-full">
              {t(`Sign in`)}
            </Button>
          </AnimationShell>
        </div>
      )}
    </Form>
  );
}
