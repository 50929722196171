import { useTranslation } from 'react-i18next';

import { Button, Icon, Typography, Link } from '@/components';
import { Dialog, DialogContent, DialogFooter, DialogOverlay, DialogPortal } from '@/components/dialog';
import { Route } from '@/constants';

export type GoUpgradeModalProps = {
  readonly open?: boolean;
  readonly setOpen?: (state: boolean) => void;
};

export function GoUpgradeModal({ open = false, setOpen }: GoUpgradeModalProps) {
  const { t } = useTranslation(['settings']);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent onPointerDownOutside={(event) => event.preventDefault()}>
          <div className="mt-6 items-center justify-center">
            <Typography as="h3" size="heading-2" color="primary" weight="normal" className="mt-1">
              {t('Go up to Bussiness plan')}
            </Typography>
            <Typography as="p" size="body-2" color="primary" weight="normal" className="mt-1">
              {t('Upgrade to bussines plan so you can access all the features of the platform.')}
            </Typography>
            <div className="mt-6 flex shrink-0">
              <Link
                to={Route.PlanSettings}
                target="_blank"
                className="flex items-center justify-center gap-x-2 rounded-md border border-solid border-secondary-accent bg-secondary-accent px-2 py-1.5 text-center text-sm font-semibold text-white no-underline transition hover:bg-white hover:text-secondary-accent active:bg-secondary-accent active:text-white"
              >
                <span className="flex items-center justify-center gap-x-1">
                  <Icon name="lightning" className="h-5 w-5 fill-inherit text-inherit" />

                  {t('Go to plan settings')}
                </span>
              </Link>
            </div>
          </div>

          <DialogFooter>
            <div className="flex space-x-4">
              <Button type="button" color="neutral" onClick={() => setOpen?.(false)}>
                {t('Close')}
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
