import { api } from '@/features/auth';
import { MarketResponse, TransactionResponse } from './type';

export const insightsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMarkets: builder.query<MarketResponse['data'], void>({
      query: () => ({
        url: `items/markets`,
        method: 'GET',
        params: {
          filter: `{"status": {"_eq": "published"}}`,
        },
      }),
      transformResponse: ({ data }: MarketResponse) => data,
    }),
    getTransactions: builder.query<TransactionResponse['data'], void>({
      query: () => ({
        url: `items/transactions`,
        method: 'GET',
        params: {
          filter: `{"status": {"_eq": "published"}}`,
          limit: 500,
        },
      }),
      transformResponse: ({ data }: TransactionResponse) => data,
    }),
  }),
});

export const { useGetMarketsQuery, useGetTransactionsQuery } = insightsApi;
