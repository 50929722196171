import { useCallback, useState } from 'react';
import ReactGa from 'react-ga4';
import { useTranslation } from 'react-i18next';

import { Container } from '@/components';
import { useBreadcrumbs } from '@/features/header';
import { CreateOpportunityDrawer, MyOpportunitiesContent, MyOpportunitiesHeader } from '@/features/opportunities';

export function MyOpportunities() {
  const { t } = useTranslation(['opportunities']);

  useBreadcrumbs(() => [{ title: t(`As Seller`) }]);

  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsOpen(false);

    ReactGa.event({
      category: 'form_closed',
      action: 'Opportunity creation proccess cancelled',
      label: 'User has abandoned the opportunity creation process',
    });
  }, []);

  return (
    <div className="flex h-full w-full flex-col">
      <Container size="xl">
        <div className="mx-auto flex w-10/12 flex-col space-y-3 px-4 py-6">
          <MyOpportunitiesHeader onCreateOpportunity={() => setIsOpen(true)} />
          <MyOpportunitiesContent onCreateOpportunity={() => setIsOpen(true)} />
        </div>
      </Container>

      <CreateOpportunityDrawer isOpen={isOpen} onClose={onClose} />
    </div>
  );
}
