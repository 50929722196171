import { AnimationShell, Link, Typography } from '@/components';
import { Message } from './type';

export type BannerProps = {
  readonly banner: Message;
};

export function Banner({ banner }: BannerProps) {
  return (
    <div className="pb-4">
      <AnimationShell
        key={banner.id}
        type="slide-up"
        className="w-full rounded border border-neutral-10 border-opacity-20 bg-white bg-opacity-10 p-4"
      >
        {banner.title ? (
          <Typography as="p" size="body-2" color="white" weight="semibold" className="mb-2">
            {banner.title}
          </Typography>
        ) : null}

        {banner.label ? (
          <Typography as="p" size="body-3" color="white" weight="normal" className="mb-2">
            {banner.label}
          </Typography>
        ) : null}

        {banner.link ? (
          <Typography as="p" size="body-3" color="primary" weight="normal">
            <Link to={banner.link.href} target={banner.link.target}>
              {banner.link.caption}
            </Link>
          </Typography>
        ) : null}
      </AnimationShell>
    </div>
  );
}
