import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Drawer, Field, Typography } from '@/components';
import { DocumentUpload } from '@/features/files';
import { useUploadAttachment } from '@/features/offers/hooks';
import { useRemoveAttachment } from '@/features/projects/hooks';
import { ProjectTechnology } from '@/features/projects/types';

export type AttachmentsDrawerProps = {
  readonly technology: ProjectTechnology;
  readonly isOpen: boolean;
  readonly onSubmit: (data: Partial<ProjectTechnology>) => void;
  readonly onClose: () => void;
};

export function AttachmentsDrawer({ technology, isOpen, onSubmit, onClose }: AttachmentsDrawerProps) {
  const { t } = useTranslation(['projects']);

  const [files, setFiles] = useState(() => technology.files.map(({ file }) => file) ?? []);
  const [deletedFiles, setDeletedFiles] = useState<string[]>([]);

  const uploadAttachment = useUploadAttachment();
  const { deleteAllAttachments } = useRemoveAttachment();

  const onSubmitFiles = useCallback(() => {
    onSubmit({
      files: files.map((file) => ({ file: { id: file.id } })),
    });
    deleteAllAttachments(deletedFiles);
    setDeletedFiles([]);
  }, [deletedFiles, deleteAllAttachments, onSubmit, files]);

  const onCancel = useCallback(() => {
    const toDeleteFiles = files.filter((f) => !technology.files.map(({ file }) => file).includes(f));
    deleteAllAttachments(toDeleteFiles.map((f) => f.id));
    setFiles(technology.files.map(({ file }) => file));
    setDeletedFiles([]);
    onClose();
  }, [technology, onClose, setFiles, setDeletedFiles, deleteAllAttachments, files]);

  const footer = (
    <>
      <Button size="lg" color="neutral" onClick={onCancel}>
        {t('Cancel')}
      </Button>
      <Button size="lg" color="primary" onClick={onSubmitFiles}>
        {t('Save')}
      </Button>
    </>
  );

  return (
    <Drawer title={t('Attachments')} open={isOpen} onClose={onClose} footer={footer}>
      <div className="space-y-6">
        <Typography as="p" color="secondary" size="body-3" weight="normal" className="mt-1 italic">
          {t(
            'Please include any documents you believe may be interesting to complete the project information (ie. teaser,  plant layout, connection infrastructure scheme, historic production, etc).'
          )}
        </Typography>
        <Field label={t('Document')} delay={0.1}>
          <DocumentUpload
            multiple={true}
            files={files}
            onSelectFiles={(newFiles) => {
              for (const file of newFiles) {
                uploadAttachment(file, (data) => {
                  setFiles((files) => [...files, data]);
                });
              }
            }}
            onUnSelectFiles={(oldFile) => {
              setDeletedFiles((files) => [...files, oldFile.id]);
              setFiles((files) => files.filter(({ id }) => id !== oldFile.id));
            }}
          />
        </Field>
      </div>
    </Drawer>
  );
}
