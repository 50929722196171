import { motion } from 'framer-motion';

export const motionElements = {
  div: motion.div,
  span: motion.span,
  a: motion.a,
  button: motion.button,
  nav: motion.nav,
  article: motion.article,
  section: motion.section,
  header: motion.header,
  footer: motion.footer,
  main: motion.main,
  aside: motion.aside,
  figure: motion.figure,
  img: motion.img,
  video: motion.video,
  canvas: motion.canvas,
  ul: motion.ul,
  li: motion.li,
  form: motion.form,
  input: motion.input,
  select: motion.select,
  label: motion.label,
  table: motion.table,
  thead: motion.thead,
  tbody: motion.tbody,
  tr: motion.tr,
  th: motion.th,
  td: motion.td,
};

export const defaultAnimationOpacity = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
