import { ChipColor } from '@/components/chips/types';
import { Status, DealItemList, DealDetails } from '../types';

export function getUrgencyFromDeadline(deadline: Date) {
  const now = new Date();

  const diff = Math.ceil(deadline.getTime() - now.getTime());
  const days = Math.ceil(diff / (1000 * 3600 * 24));

  if (days < 0) {
    return 'overdue';
  } else if (days < 10) {
    return 'urgent';
  } else if (days < 21) {
    return 'important';
  } else return 'normal';
}

export function getDeadlineStyles(urgency: string) {
  let urgencyClassNames =
    "text-right before:content-[' '] before:mr-2 before:inline-block before:h-[10px] before:w-[10px] before:rounded-full";

  if (urgency === 'urgent') {
    urgencyClassNames += ' before:bg-error-700';
  } else if (urgency === 'important') {
    urgencyClassNames += ' before:bg-warning-400';
  } else if (urgency === 'normal') {
    urgencyClassNames += ' before:bg-primary-700';
  } else {
    urgencyClassNames += ' before:bg-neutral-50';
  }

  return urgencyClassNames;
}

export function getColorFromDealStatus(status: Status) {
  switch (status) {
    case 'draft':
      return 'disabled';
    case 'archived':
      return 'warning';
    case 'published':
      return 'info';
  }
}

export function getChipFromDeal(deal: DealItemList | DealDetails) {
  //TODO: check when category is overdue
  if (deal.status.name === 'published') {
    if (deal.isPaid) {
      return {
        color: 'gold' as ChipColor,
        name: 'featured',
      };
    }
  } else {
    return deal.status;
  }
}

export function getDealsOrderedByDeadline(deals: DealItemList[]) {
  const dealsPaid = deals.filter((deal) => deal.deadline.category !== 'overdue' && deal.isPaid);
  const dealsActive = deals.filter((deal) => deal.deadline.category !== 'overdue' && !deal.isPaid);
  const dealsOverdue = deals.filter((deal) => deal.deadline.category === 'overdue');

  return [
    ...dealsPaid.sort((a, b) => a.deadline.date.getTime() - b.deadline.date.getTime()),
    ...dealsActive.sort((a, b) => a.deadline.date.getTime() - b.deadline.date.getTime()),
    ...dealsOverdue.sort((a, b) => b.deadline.date.getTime() - a.deadline.date.getTime()),
  ];
}
