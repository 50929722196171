import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

import { isNullish } from '@/types';

export function useRegisterGAPageView(title: string | null) {
  const location = useLocation();

  useEffect(() => {
    if (isNullish(title)) return;

    const url = location.pathname + location.search;
    ReactGA.send({ hitType: 'pageview', page: url, title: title });
  }, [location, title]);
}
