import { IconState } from '@/components';
import { ChipColor } from '@/components/chips/types';
import { IconName } from '@/components/icons/types';
import { FileSchema } from '@/features/files';
import { isObject } from '@/types';

export type TechnologyType = 'solar' | 'wind' | 'bess';

export type TechnologyCollection =
  | 'projects_bess_technologies'
  | 'projects_solar_technologies'
  | 'projects_wind_technologies';

export type ProjectStatus = 'draft' | 'pending' | 'published' | 'archived';

export type LandType = 'rental' | 'purchase' | 'other';

export type DUPStatus = 'pending' | 'requested' | 'obtained' | 'not-required';

export type ConnectionType = 'underground' | 'aerial';

export type ConnectionTo = 'powerline' | 'substation';

export type PermitType = 'connection' | 'environmental' | 'previous' | 'construction' | 'other';

export type PermitStatus = 'pending' | 'requested' | 'obtained' | 'not-required';

export type Permit = {
  id?: string;
  type: PermitType;
  title: string | null;
  status: PermitStatus;
  date: string | null;
};

export type SolarProjectTechnology = {
  id: string;
  development_status: string;
  installed_capacity_peak: number | null;
  installed_capacity_nominal: number | null;
  specific_production: number | null;
  ready_to_build: string | null;
  commercial_operation_date: string | null;
  structure_type: string | null;
  module_type: string | null;
  module_power: number | null;
  panel_configuration: string | null;
  pitch_distance: number | null;
  comments: string | null;
  basic_design_comments: string | null;
};

export type WindProjectTechnology = {
  id: string;
  development_status: string;
  installed_capacity: number | null;
  specific_production: number | null;
  ready_to_build: string | null;
  commercial_operation_date: string | null;
  turbine_model: string | null;
  turbine_power: number | null;
  turbiness: number | null;
  comments: string | null;
  basic_design_comments: string | null;
};

export type BESSProjectTechnology = {
  id: string;
  development_status: string;
  installed_capacity: number | null;
  ready_to_build: string | null;
  commercial_operation_date: string | null;
  storage_technology: string | null;
  storage_capacity: number | null;
  hours_cycles: number | null;
  comments: string | null;
  basic_design_comments: string | null;
};

export type ProjectTechnology = {
  id: number;
  project: Project;
  collection: TechnologyCollection;
  files: { file: FileSchema }[];
  item: SolarProjectTechnology | WindProjectTechnology | BESSProjectTechnology;
  land_surface: number | null;
  land_types: LandType[] | null;
  land_price: number | null;
  land_contract_duration: string | null;
  land_contracted_percentage: number | null;
  land_comments: string | null;
  dup_status: DUPStatus | null;
  dup_date: string | null;
  dup_comments: string | null;
  evacuation_line_total_distance: number | null;
  evacuation_line_voltage: number | null;
  connection_type: ConnectionType[] | null;
  connection_to: ConnectionTo[] | null;
  shared_infrastructure: boolean | null;
  shared_infrastructure_comments: string | null;
  connection_costs: number | null;
  connection_costs_comments: string | null;
  connection_infrastructure_comments: string | null;
  estimated_epc: number | null;
  epc_comments: string | null;
  om_price: number | null;
  om_term: number | null;
  om_comments: string | null;
  insurance_price: number | null;
  insurance_comments: string | null;
  has_ppa: boolean | null;
  ppa_comments: string | null;
  has_debt: boolean | null;
  debt_comments: string | null;
  permits: Permit[] | null;
  permiting_comments: string | null;
};

export type Project = {
  id: string;
  created_at: string;
  created_by: string | null;
  updated_at: string | null;
  updated_by: string | null;
  status: ProjectStatus;
  company: string;
  title: string;
  country: string;
  city: string;
  technologies: ProjectTechnology[];
};

export function isSolarProjectTechnology(project: unknown): project is SolarProjectTechnology {
  const keys = [
    'id',
    'development_status',
    'installed_capacity_peak',
    'installed_capacity_nominal',
    'specific_production',
    'ready_to_build',
    'commercial_operation_date',
    'structure_type',
    'module_type',
    'module_power',
    'panel_configuration',
    'pitch_distance',
    'comments',
    'basic_design_comments',
  ];
  return isObject(project) && keys.every((key) => key in project);
}

export function isWindProjectTechnology(project: any): project is WindProjectTechnology {
  const keys = [
    'id',
    'development_status',
    'installed_capacity',
    'specific_production',
    'ready_to_build',
    'commercial_operation_date',
    'turbine_model',
    'turbine_power',
    'turbiness',
    'comments',
    'basic_design_comments',
  ];
  return project && typeof project === 'object' && keys.every((key) => key in project);
}

export function isBESSProjectTechnology(project: any): project is BESSProjectTechnology {
  const keys = [
    'id',
    'development_status',
    'installed_capacity',
    'ready_to_build',
    'commercial_operation_date',
    'storage_technology',
    'storage_capacity',
    'hours_cycles',
    'comments',
    'basic_design_comments',
  ];
  return project && typeof project === 'object' && keys.every((key) => key in project);
}

export type Location = {
  country: string;
  city: string[];
};

export type ProjectItemList = {
  id: string;
  title: string;
  location: Location;
  technologies: {
    types: TechnologyType[];
    icons: {
      name: IconName[];
      states: IconState[];
    };
    state: {
      list: string[];
    };
    capacity: number | null;
  };
  status: {
    name: string;
    color: ChipColor;
  };
  isDraft: boolean;
  isPending: boolean;
  isPublished: boolean;
  isArchived: boolean;
};

export type ProjectHeader = {
  id: string;
  title: string;
  company: string;
  location: Location;
  technologies: {
    icons: IconState[];
  };
  status: {
    name: string;
    color: ChipColor;
  };
};

export type Technology = {
  id: number;
  project: string;
  collection: TechnologyCollection;
  type: TechnologyType;
  details: TechnologyDetails;
};

export type ProjectDetails = {
  id: string;
  company: string;
  location: Location;
  technologies: Technology[];
  isDraft: boolean;
  isPublished: boolean;
};

export type SolarItem = {
  id: string;
  installedCapacityPeak: number | null;
  installedCapacityNominal: number | null;
  specificProduction: number | null;
  readyToBuild: string | null;
  commercialOperationDate: string | null;
  structureType: string | null;
  moduleType: string | null;
  modulePower: number | null;
  panelConfiguration: string | null;
  pitchDistance: number | null;
  generalComments: string | null;
  basicDesignComments: string | null;
};

export type WindItem = {
  id: string;
  installedCapacity: number | null;
  specificProduction: number | null;
  readyToBuild: string | null;
  commercialOperationDate: string | null;
  turbineModel: string | null;
  turbinePower: number | null;
  turbiness: number | null;
  generalComments: string | null;
  basicDesignComments: string | null;
};

export type BessItem = {
  id: string;
  installedCapacity: number | null;
  readyToBuild: string | null;
  commercialOperationDate: string | null;
  storageTechnology: string | null;
  storageCapacity: number | null;
  hoursCycles: number | null;
  generalComments: string | null;
  basicDesignComments: string | null;
};

export type TechnologyDetails = {
  id: number;
  developmentStatus: {
    status: string;
  };
  item: SolarItem | WindItem | BessItem;
  files: FileSchema[];
  permittingPLA: {
    permits: Permit[] | null;
    comments: string | null;
  };
  landRights: {
    surface: number | null;
    type: LandType[] | null;
    price: number | null;
    contractDuration: string | null;
    contractedPercentage: number | null;
    comments: string | null;
    dupStatus: DUPStatus | null;
    dupDate: string | null;
    dupComments: string | null;
  };
  connectionInfrastructure: {
    evacuationLineTotalDistance: number | null;
    evacuationLineVoltage: number | null;
    connectionType: ConnectionType[] | null;
    connectionTo: ConnectionTo[] | null;
    connectionCosts: number | null;
    connectionCostsComments: string | null;
    sharedInfrastructure: boolean | null;
    sharedInfrastructureComments: string | null;
    connectionInfrastructureComments: string | null;
  };
  capexOpex: {
    estimatedEpc: number | null;
    epcComments: string | null;
    omPrice: number | null;
    omTerm: number | null;
    omComments: string | null;
    insurancePrice: number | null;
    insuranceComments: string | null;
  };
  others: {
    hasPpa: boolean | null;
    ppaComments: string | null;
    hasDebt: boolean | null;
    debtComments: string | null;
  };
};
