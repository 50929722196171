import { useMemo } from 'react';

import { useGetCompanyQuery } from '../api';
import { useGetCurrentUserQuery } from '@/features/users';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSuscriptions } from './use-suscriptions';

export function useCompanyRole(id?: string | null) {
  const { data: user } = useGetCurrentUserQuery();
  const { data: company } = useGetCompanyQuery(id ? { id } : user ? { id: user.company.id } : skipToken);
  const { hasTrialActive, hasPremiumActive } = useSuscriptions();

  const suscriptions = useMemo(() => {
    if (!company) return [];
    return company.suscriptions;
  }, [company]);

  const isPremium = useMemo(() => hasPremiumActive(suscriptions) || hasTrialActive(suscriptions), [suscriptions]);

  const isFree = useMemo(() => !isPremium, [isPremium]);

  return {
    suscriptions,
    isFree,
    isPremium,
  };
}
