import clsx, { ClassValue } from 'clsx';
import { ReactNode } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { IconName } from '../icons/types';

const sizes = {
  sm: 'p-1.5',
  lg: 'p-2.5',
};

type SelectableButtonSizes = keyof typeof sizes;

type SelectableButtonType = 'radio' | 'checkbox';

export type SelectableButtonProps = {
  readonly children: ReactNode;
  readonly value: string;
  readonly defaultChecked?: boolean;
  readonly id?: string;
  readonly type?: SelectableButtonType;
  readonly size?: SelectableButtonSizes;
  readonly icon?: IconName;
  readonly label?: string;
  readonly registration?: Partial<UseFormRegisterReturn>;
  readonly disabled?: boolean;
  readonly className?: ClassValue;
};

export function SelectableButton({
  children,
  value,
  defaultChecked,
  id,
  type = 'checkbox',
  size = 'lg',
  registration,
  disabled = false,
  className,
}: SelectableButtonProps) {
  return (
    <div className="inline-flex">
      <input
        id={id}
        value={value}
        type={type}
        className="peer sr-only"
        defaultChecked={defaultChecked}
        disabled={disabled}
        {...registration}
      />
      <label
        htmlFor={id}
        className={clsx(
          'parent-peer flex bg-base-white',
          sizes[size],
          'peer-disabled:bg-base-disabled peer-disabled:text-neutral-disabled',
          {
            'visited:bg-primary-50 hover:border-primary-700 hover:bg-base-white active:bg-primary-50 peer-checked:border-primary-700 peer-checked:bg-primary-50':
              !disabled,
          },
          'cursor-pointer transition hover:z-[1] peer-checked:z-[1] peer-disabled:cursor-not-allowed',
          'rounded border border-neutral-50 focus:outline-none',
          'peer-disabled:fill-neutral-disabled',
          className
        )}
      >
        {children}
      </label>
    </div>
  );
}
