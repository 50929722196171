import { cn } from '@/utils/styles';

import type { ArtworkProps } from '../types';

export function SearchOpportunities({ className }: ArtworkProps) {
  return (
    <svg className={cn(className)} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M154.351 114.539C168.858 75.628 140.137 33.649 98.1607 33.649C90.6446 33.6403 83.1941 35.0486 76.1997 37.8"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.858 141.767C137.391 139.144 140.626 136.143 143.506 132.816"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.4265 81.532C37.776 89.5922 37.8012 97.9058 39.5005 105.956C41.1999 114.006 44.5372 121.62 49.3048 128.326C54.0723 135.031 60.1684 140.684 67.2138 144.933C74.2592 149.182 82.1033 151.936 90.2585 153.024C101.162 154.478 112.256 152.906 122.327 148.48"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.542 84.122C167.61 69.522 185.606 56.56 183.418 51.473C181.613 47.273 166.9 49.326 144.848 55.99"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126.832 90.51C127.57 90.151 128.303 89.793 129.032 89.435"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.6998 101.306C17.8078 113.1 4.29977 123.4 6.14577 127.7C9.23577 134.885 51.4248 123.646 100.377 102.6C103.163 101.4 105.909 100.197 108.615 98.991"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M18.9375 90.3V95.403" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.4897 92.851H16.3857" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M147.014 29.158V34.262" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M149.566 31.71H144.462" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M40.9337 148.725C41.8137 150.507 41.7597 150.484 41.9337 150.509L43.7567 150.774C43.801 150.781 43.8426 150.799 43.8769 150.828C43.9111 150.857 43.9366 150.895 43.9505 150.938C43.9644 150.98 43.9662 151.026 43.9556 151.069C43.9451 151.113 43.9226 151.153 43.8907 151.184C42.4677 152.571 42.4727 152.512 42.5017 152.684L42.8127 154.5C42.8182 154.533 42.8168 154.567 42.8084 154.599C42.8001 154.632 42.7852 154.662 42.7645 154.689C42.7438 154.715 42.7178 154.737 42.6882 154.752C42.6586 154.768 42.6261 154.778 42.5927 154.78C42.5483 154.783 42.504 154.774 42.4647 154.753C40.7057 153.828 40.7647 153.815 40.6097 153.896L38.9787 154.753C38.939 154.774 38.8944 154.783 38.8498 154.78C38.8051 154.777 38.7623 154.761 38.7261 154.735C38.6899 154.708 38.6618 154.672 38.6449 154.631C38.6281 154.589 38.6231 154.544 38.6307 154.5C38.9597 152.58 38.9977 152.593 38.8727 152.471L37.5527 151.185C37.5205 151.154 37.4979 151.114 37.4872 151.071C37.4766 151.027 37.4785 150.981 37.4927 150.939C37.5066 150.896 37.5322 150.858 37.5664 150.83C37.6007 150.801 37.6423 150.782 37.6867 150.775C39.6527 150.489 39.6147 150.533 39.6867 150.375L40.5017 148.722C40.5216 148.682 40.5525 148.648 40.5909 148.624C40.6293 148.6 40.6735 148.588 40.7186 148.588C40.7637 148.588 40.8078 148.601 40.8458 148.626C40.8839 148.65 40.9143 148.684 40.9337 148.725Z"
        fill="#B7F6D1"
      />
      <path
        d="M170.6 89.01C171.991 91.829 171.883 91.738 172.145 91.776L174.971 92.186C175.039 92.1961 175.104 92.2252 175.157 92.2701C175.21 92.315 175.249 92.3739 175.271 92.44C175.292 92.5061 175.295 92.5769 175.278 92.6443C175.261 92.7118 175.226 92.7733 175.177 92.822C172.927 95.015 172.977 94.884 173.025 95.145L173.508 97.96C173.519 98.0285 173.511 98.0988 173.485 98.163C173.459 98.2273 173.415 98.2829 173.359 98.3236C173.302 98.3644 173.236 98.3886 173.167 98.3936C173.097 98.3986 173.028 98.3842 172.967 98.352C170.186 96.89 170.327 96.9 170.093 97.022L167.565 98.352C167.503 98.3847 167.434 98.3996 167.364 98.3948C167.295 98.39 167.228 98.3658 167.172 98.3249C167.116 98.284 167.072 98.2281 167.046 98.1636C167.02 98.0991 167.013 98.0285 167.025 97.96C167.556 94.86 167.59 95 167.4 94.815L165.355 92.822C165.305 92.7733 165.27 92.7117 165.254 92.6443C165.237 92.5768 165.239 92.5061 165.261 92.44C165.282 92.3736 165.321 92.3145 165.375 92.2696C165.428 92.2247 165.493 92.1957 165.562 92.186C168.671 91.734 168.551 91.809 168.668 91.572L169.932 89.011C169.963 88.9488 170.01 88.8964 170.069 88.8596C170.128 88.8229 170.196 88.8033 170.266 88.803C170.335 88.803 170.403 88.8224 170.462 88.8592C170.521 88.896 170.569 88.9476 170.6 89.01Z"
        fill="#B7F6D1"
      />
      <path
        d="M104 13.85C105.122 16.122 105.073 16.144 105.3 16.176L107.679 16.522C107.737 16.5303 107.791 16.5547 107.836 16.5925C107.881 16.6302 107.914 16.6798 107.932 16.7355C107.95 16.7913 107.952 16.8509 107.938 16.9078C107.924 16.9646 107.894 17.0163 107.852 17.057C106.037 18.826 106.002 18.786 106.041 19.012L106.447 21.381C106.457 21.4387 106.45 21.498 106.428 21.5522C106.406 21.6065 106.369 21.6535 106.322 21.6878C106.275 21.7222 106.219 21.7426 106.16 21.7467C106.102 21.7508 106.043 21.7384 105.992 21.711C103.749 20.531 103.776 20.486 103.573 20.593L101.446 21.711C101.407 21.7317 101.364 21.7441 101.321 21.7472C101.277 21.7503 101.233 21.7442 101.192 21.7292C101.15 21.7142 101.113 21.6906 101.081 21.6601C101.05 21.6296 101.025 21.5927 101.009 21.552C100.987 21.4978 100.981 21.4386 100.991 21.381C101.419 18.881 101.471 18.895 101.307 18.735L99.5857 17.057C99.5435 17.0162 99.5137 16.9645 99.4995 16.9076C99.4853 16.8506 99.4874 16.7909 99.5056 16.7351C99.5237 16.6794 99.5572 16.6298 99.6021 16.5921C99.647 16.5545 99.7016 16.5301 99.7597 16.522C102.268 16.158 102.273 16.211 102.374 16.005L103.438 13.85C103.464 13.7978 103.504 13.7538 103.553 13.7231C103.603 13.6924 103.66 13.6761 103.719 13.6761C103.777 13.6761 103.834 13.6924 103.884 13.7231C103.934 13.7538 103.974 13.7978 104 13.85Z"
        fill="#B7F6D1"
      />
      <path
        d="M58.9097 171.54L83.0917 78.922L101.247 171.54"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M83.0919 78.922L81.4019 184.837" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M58.6789 173.524C59.0736 173.524 59.452 173.367 59.7311 173.088C60.0101 172.809 60.1669 172.431 60.1669 172.036C60.1669 171.641 60.0101 171.263 59.7311 170.984C59.452 170.705 59.0736 170.548 58.6789 170.548C58.2843 170.548 57.9058 170.705 57.6267 170.984C57.3477 171.263 57.1909 171.641 57.1909 172.036C57.1909 172.431 57.3477 172.809 57.6267 173.088C57.9058 173.367 58.2843 173.524 58.6789 173.524Z"
        fill="#279D59"
      />
      <path
        d="M81.4016 186.325C81.7962 186.325 82.1747 186.168 82.4538 185.889C82.7328 185.61 82.8896 185.232 82.8896 184.837C82.8896 184.442 82.7328 184.064 82.4538 183.785C82.1747 183.506 81.7962 183.349 81.4016 183.349C81.0069 183.349 80.6284 183.506 80.3494 183.785C80.0703 184.064 79.9136 184.442 79.9136 184.837C79.9136 185.232 80.0703 185.61 80.3494 185.889C80.6284 186.168 81.0069 186.325 81.4016 186.325Z"
        fill="#279D59"
      />
      <path
        d="M101.247 173.028C101.445 173.033 101.643 172.998 101.828 172.926C102.013 172.854 102.182 172.745 102.324 172.606C102.466 172.468 102.579 172.302 102.656 172.119C102.734 171.936 102.773 171.739 102.773 171.54C102.773 171.342 102.734 171.145 102.656 170.962C102.579 170.779 102.466 170.613 102.324 170.475C102.182 170.336 102.013 170.227 101.828 170.155C101.643 170.083 101.445 170.048 101.247 170.053C100.859 170.063 100.49 170.224 100.22 170.502C99.9489 170.78 99.7974 171.153 99.7974 171.54C99.7974 171.928 99.9489 172.301 100.22 172.579C100.49 172.857 100.859 173.018 101.247 173.028Z"
        fill="#279D59"
      />
      <path
        d="M126.726 71.246L125.077 70.76C124.856 69.937 124.428 69.1846 123.832 68.5751C123.237 67.9656 122.495 67.5196 121.677 67.28L119.017 66.494C118.906 70.3728 118.111 74.2015 116.669 77.804L119.157 78.16C119.981 78.3036 120.829 78.2356 121.62 77.9624C122.411 77.6892 123.12 77.2197 123.68 76.598L125.431 76.848C126.13 76.9145 126.83 76.7252 127.4 76.3151C127.97 75.905 128.372 75.302 128.532 74.618C128.691 73.9335 128.596 73.2147 128.265 72.595C127.933 71.9754 127.388 71.4971 126.731 71.249L126.726 71.246Z"
        fill="#279D59"
      />
      <path
        d="M126.726 71.246L125.077 70.76C124.856 69.937 124.428 69.1846 123.832 68.5751C123.237 67.9656 122.495 67.5196 121.677 67.28L119.017 66.494C118.906 70.3728 118.111 74.2015 116.669 77.804L119.157 78.16C119.981 78.3036 120.829 78.2356 121.62 77.9624C122.411 77.6892 123.12 77.2197 123.68 76.598L125.431 76.848C126.13 76.9145 126.83 76.7252 127.4 76.3151C127.97 75.905 128.372 75.302 128.532 74.618C128.691 73.9335 128.596 73.2147 128.265 72.595C127.933 71.9754 127.388 71.4971 126.731 71.249L126.726 71.246Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.214 80.848L114.892 80.89C114.997 80.8946 115.101 80.8688 115.191 80.8155C115.282 80.7623 115.355 80.6839 115.402 80.59C118.052 75.4186 119.285 69.6376 118.976 63.835C118.971 63.7426 118.943 63.653 118.894 63.5743C118.846 63.4956 118.778 63.4305 118.698 63.385L84.6556 43.652C88.2076 57.072 83.8706 70.822 74.0156 78.359L98.9496 79.9"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.6559 43.653L60.9089 27.21C72.6289 44.374 65.7569 69.863 47.0239 78.992L74.0159 78.36C83.8709 70.823 88.2079 57.072 84.6559 43.653Z"
        fill="#279D59"
      />
      <path
        d="M84.6559 43.653L60.9089 27.21C72.6289 44.374 65.7569 69.863 47.0239 78.992L74.0159 78.36C83.8709 70.823 88.2079 57.072 84.6559 43.653Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.3438 15.756C29.1458 11.03 11.7598 21.79 6.50979 39.784C1.25979 57.78 10.1358 76.2 26.3338 80.924C42.5318 85.648 59.9188 74.891 65.1678 56.9C70.4168 38.91 61.5418 20.481 45.3438 15.756Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.8766 19.028C29.8926 15.24 15.6436 24.933 11.0506 40.678C6.45762 56.423 13.2596 72.256 26.2436 76.044C39.2276 79.832 53.4766 70.138 58.0696 54.394C62.6626 38.65 55.8606 22.815 42.8766 19.028ZM30.9116 60.97C24.0696 59.384 19.6116 52.244 19.4316 43.741C23.3366 36.185 30.4806 31.741 37.3226 33.323C44.1646 34.905 48.6226 42.049 48.8026 50.553C44.8996 58.107 37.7536 62.556 30.9116 60.97Z"
        fill="#B7F6D1"
      />
      <path
        d="M58.07 54.395C62.663 38.65 55.86 22.816 42.877 19.029C29.892 15.241 15.643 24.934 11.05 40.678C6.45702 56.422 13.26 72.256 26.244 76.044C39.228 79.832 53.477 70.14 58.07 54.395Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0506 40.678C7.05064 54.396 11.6986 68.183 21.5506 74.009C14.5436 67.186 11.6786 55.459 15.0856 43.777C20.2566 26.057 37.7686 16 51.6146 24.17C38.5466 11.43 17.0566 20.09 11.0506 40.68V40.678Z"
        fill="#279D59"
      />
      <path
        d="M11.0506 40.678C7.05064 54.396 11.6986 68.183 21.5506 74.009C14.5436 67.186 11.6786 55.459 15.0856 43.777C20.2566 26.057 37.7686 16 51.6146 24.17C38.5466 11.43 17.0566 20.09 11.0506 40.68V40.678Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.8038 50.551C50.5738 42.917 45.4338 35.203 37.3238 33.322C29.2128 31.442 21.2018 36.106 19.4318 43.74C17.6618 51.375 22.8018 59.088 30.9118 60.969C39.0238 62.849 47.0338 58.185 48.8038 50.551Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.203 48.79C42.386 43.686 40.173 38.813 36.26 37.906C32.348 36.998 28.216 40.4 27.033 45.504C25.849 50.607 28.062 55.48 31.975 56.387C35.888 57.295 40.019 53.893 41.203 48.79Z"
        fill="#279D59"
      />
      <path
        d="M41.203 48.79C42.386 43.686 40.173 38.813 36.26 37.906C32.348 36.998 28.216 40.4 27.033 45.504C25.849 50.607 28.062 55.48 31.975 56.387C35.888 57.295 40.019 53.893 41.203 48.79Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.209 79.424C91.838 79.84 96.753 85.604 97.735 86.442C99.0356 84.7014 100.497 83.0869 102.1 81.62"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.752 108.607C111.127 104.617 97.7349 86.442 97.7349 86.442"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126.914 117.866V114.011C126.408 111.448 126.074 108.854 125.914 106.246C124.714 105.246 123.538 104.176 122.396 103.08C122.197 104.529 122.025 105.981 121.881 107.436C120.781 118.476 118.334 119.56 116.967 131.879L121.626 131.464C123.676 125.46 126.117 119.708 126.914 117.866Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.427 94.97C133.472 93.518 133.727 88.432 135.142 86.31C134.652 86.334 132.327 86.068 132.114 85.597C130.318 87.1785 128.927 89.1673 128.058 91.397C130.1 92.847 132.024 94.155 133.427 94.97Z"
        fill="#B7F6D1"
      />
      <path
        d="M125.911 106.246C126.071 108.854 126.405 111.448 126.911 114.011L132.647 113.559C132.44 112.562 132.25 111.562 132.076 110.559C129.884 109.328 127.819 107.883 125.911 106.246Z"
        fill="#B7F6D1"
      />
      <path
        d="M133.427 94.97C133.472 93.518 133.727 88.432 135.142 86.31C134.652 86.334 132.327 86.068 132.114 85.597C130.318 87.1785 128.927 89.1673 128.058 91.397"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.911 106.246C126.071 108.854 126.405 111.448 126.911 114.011L132.647 113.559C132.44 112.562 132.25 111.562 132.076 110.559"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126.914 114.011V117.866C124.125 124.245 121.736 130.791 119.759 137.466C119.374 138.895 119.275 140.386 119.47 141.853C119.664 143.32 120.148 144.734 120.892 146.013L133.092 167.013L143.753 161.358L133.673 141.4L138.291 130.229"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.567 131.571C142.935 132.069 143.302 132.554 143.667 133.027C144.576 134.195 145.708 135.171 146.997 135.898C148.286 136.625 149.707 137.089 151.177 137.263L175.288 140.151L177.188 128.233L155.181 124.277L154.015 122.303"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.512 172.265C143.158 177.256 136.316 181.593 129.091 185.21C135.045 172.651 134.845 173.244 134.991 172.351L146.551 166.664L149.512 172.265Z"
        fill="#279D59"
      />
      <path
        d="M149.512 172.265C143.158 177.256 136.316 181.593 129.091 185.21C135.045 172.651 134.845 173.244 134.991 172.351L146.551 166.664L149.512 172.265Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.512 172.265L149.881 172.965C150.075 173.333 150.129 173.758 150.033 174.163C149.937 174.568 149.698 174.924 149.359 175.165C146.187 177.42 137.42 183.42 130.071 186.265C129.927 186.322 129.769 186.333 129.617 186.298C129.466 186.264 129.329 186.184 129.224 186.07C129.119 185.956 129.051 185.814 129.027 185.661C129.004 185.508 129.026 185.351 129.091 185.211C136.316 181.594 143.158 177.256 149.512 172.265Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.553 166.664L134.993 172.351C135.165 171.318 134.972 170.257 134.446 169.351L133.089 167.015L143.75 161.36L146.553 166.664Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M133.673 141.399L132.825 143.449L136.471 146.941L133.673 141.399Z" fill="#279D59" />
      <path
        d="M133.673 141.399L132.825 143.449L136.471 146.941L133.673 141.399Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.109 129.185L180.894 140.816C179.731 140.816 175.294 140.151 175.294 140.151L177.194 128.233L183.109 129.185Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M155.179 124.277L156.379 126.309L161.289 125.375L155.179 124.277Z" fill="#279D59" />
      <path
        d="M155.179 124.277L156.379 126.309L161.289 125.375L155.179 124.277Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.422 104.133C145.838 105.851 145.043 107.49 144.054 109.012C141.217 113.445 136.795 113.096 132.079 110.554C133.131 116.676 135.342 125.501 139.937 133.239C139.937 133.239 151.125 126.624 156.284 119.612C156.284 119.612 153.512 109.35 146.422 104.133Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.781 71.669C144.096 71.3854 144.339 71.0305 144.49 70.6339C144.64 70.2373 144.694 69.8105 144.646 69.389C144.597 68.9679 144.449 68.5643 144.214 68.2117C143.979 67.8591 143.663 67.5675 143.293 67.361C142.733 67.0508 142.081 66.9521 141.454 67.0828C140.828 67.2135 140.27 67.5649 139.881 68.073C139.624 68.4101 139.451 68.8037 139.376 69.221C138.258 69.1409 137.142 69.3939 136.169 69.948C135.52 69.5491 134.766 69.3534 134.005 69.3861C133.244 69.4188 132.51 69.6784 131.898 70.1314C131.285 70.5845 130.822 71.2104 130.568 71.9286C130.315 72.6469 130.281 73.4247 130.473 74.162C130.6 74.6512 130.823 75.1104 131.129 75.513C131.434 75.9157 131.817 76.2538 132.254 76.508C132.616 75.526 133.086 74.587 133.654 73.708C134.95 73.8516 136.261 73.7564 137.522 73.427C137.931 73.263 138.022 73.18 138.422 73.693C139.067 74.693 139.5 75.813 139.695 76.987C140.795 76.216 141.553 77.46 140.934 78.902C140.543 79.814 139.769 80.357 139.206 80.115C139.121 80.0678 139.041 80.0111 138.969 79.946C138.57 80.466 138.339 81.096 138.305 81.751C142.192 82.344 147.237 76.226 143.781 71.669Z"
        fill="#279D59"
      />
      <path
        d="M143.781 71.669C144.096 71.3854 144.339 71.0305 144.49 70.6339C144.64 70.2373 144.694 69.8105 144.646 69.389C144.597 68.9679 144.449 68.5643 144.214 68.2117C143.979 67.8591 143.663 67.5675 143.293 67.361C142.733 67.0508 142.081 66.9521 141.454 67.0828C140.828 67.2135 140.27 67.5649 139.881 68.073C139.624 68.4101 139.451 68.8037 139.376 69.221C138.258 69.1409 137.142 69.3939 136.169 69.948C135.52 69.5491 134.766 69.3534 134.005 69.3861C133.244 69.4188 132.51 69.6784 131.898 70.1314C131.285 70.5845 130.822 71.2104 130.568 71.9286C130.315 72.6469 130.281 73.4247 130.473 74.162C130.6 74.6512 130.823 75.1104 131.129 75.513C131.434 75.9157 131.817 76.2538 132.254 76.508C132.616 75.526 133.086 74.587 133.654 73.708C134.95 73.8516 136.261 73.7564 137.522 73.427C137.931 73.263 138.022 73.18 138.422 73.693C139.067 74.693 139.5 75.813 139.695 76.987C140.795 76.216 141.553 77.46 140.934 78.902C140.543 79.814 139.769 80.357 139.206 80.115C139.121 80.0678 139.041 80.0111 138.969 79.946C138.57 80.466 138.339 81.096 138.305 81.751C142.192 82.344 147.237 76.226 143.781 71.669Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M132.225 120.741L136.189 125.529" stroke="#186A3B" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M145.561 112.8L140.435 115.575C140.254 115.673 140.11 115.827 140.023 116.014C139.937 116.201 139.912 116.41 139.954 116.612C140.327 118.355 141.554 123.192 144.406 122.938C149.566 122.478 150.806 118.438 146.778 113.06C146.64 112.878 146.441 112.752 146.218 112.705C145.994 112.657 145.761 112.691 145.561 112.8Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M187.9 129.932L183.113 129.185L180.898 140.816L193.311 149.716C193.116 148.489 192.828 147.278 192.449 146.095C187.641 130.011 189.82 136.84 187.9 129.932Z"
        fill="#279D59"
      />
      <path
        d="M187.9 129.932L183.113 129.185L180.898 140.816L193.311 149.716C193.116 148.489 192.828 147.278 192.449 146.095C187.641 130.011 189.82 136.84 187.9 129.932Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M193.307 149.711C193.468 149.824 193.66 149.886 193.857 149.887C194.053 149.889 194.246 149.831 194.409 149.72C194.572 149.61 194.697 149.452 194.768 149.269C194.839 149.085 194.853 148.884 194.807 148.693C193.67 144.079 191.257 134.446 190.007 130.649C189.967 130.525 189.893 130.415 189.793 130.332C189.694 130.248 189.574 130.193 189.445 130.174L187.891 129.932C189.813 136.832 187.636 130.008 192.44 146.09C192.821 147.273 193.111 148.484 193.307 149.711Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.142 86.311C135.142 86.311 138.221 85.111 138.453 83.692C138.24 82.363 138.095 81.076 138.967 79.943C139.039 80.009 139.118 80.066 139.204 80.113C139.767 80.354 140.541 79.813 140.932 78.899C141.559 77.437 140.778 76.225 139.693 76.984C139.498 75.8106 139.065 74.6895 138.42 73.69C138.02 73.177 137.933 73.261 137.52 73.424C136.259 73.754 134.948 73.849 133.652 73.705C133.652 73.705 131.161 77.382 131.843 80.185C131.979 80.7706 132.308 81.2933 132.778 81.6693C133.247 82.0452 133.829 82.2526 134.43 82.258C134.371 84.305 132.048 85.14 132.095 85.545C132.158 86.049 134.635 86.335 135.142 86.311Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.434 82.258C135.084 82.3065 135.736 82.2238 136.353 82.015"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.44 89.485C110.44 89.485 113.709 84.999 116.961 83.04C115.96 82.3267 115.006 81.5494 114.105 80.713C113.905 80.349 114.616 77.329 115.091 75.791C115.566 74.253 115.118 73.846 114.556 74.085C114.088 74.285 112.233 77.203 111.623 78.185C111.601 78.2203 111.57 78.2498 111.534 78.2708C111.498 78.2918 111.458 78.3038 111.416 78.3056C111.374 78.3075 111.333 78.2992 111.295 78.2815C111.257 78.2638 111.224 78.2372 111.199 78.204L109.31 75.736C109.31 75.736 106.047 71.471 105.148 71.496C103.248 71.547 107.563 77.675 107.563 77.675C106.763 76.775 105.753 76.032 104.92 75.162C104.329 74.5367 103.719 73.9297 103.09 73.342C102.805 73.08 102.325 72.511 101.897 72.53C101.62 72.542 101.353 72.655 101.328 72.967C101.281 73.567 101.828 74.218 102.164 74.667C103.372 76.217 104.658 77.7043 106.018 79.123C105.218 78.432 101.545 75.267 100.506 75.069C100.088 74.989 99.7579 75.275 99.9219 75.712C100.255 76.602 103.422 79.45 104.287 80.239C104.404 80.345 104.818 80.609 104.853 80.755C104.853 80.755 99.739 78.819 99.175 79.255C97.588 80.468 103.225 81.707 104.159 82.667C105.868 84.42 109.576 88.747 110.44 89.485Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.317 86.024C135.126 88.835 134.708 95.636 134.708 95.636C130.9 94 116.961 83.04 116.961 83.04C113.709 84.999 110.44 89.485 110.44 89.485C110.44 89.485 135.118 122.977 144.054 109.012C151.522 97.339 144.275 88.309 140.317 86.024Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.7 86.689C121.7 86.689 116.43 91.706 114.242 94.262"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.573 90.916C113.749 90.916 113.918 90.846 114.043 90.7215C114.167 90.597 114.237 90.4281 114.237 90.252C114.237 90.0759 114.167 89.907 114.043 89.7825C113.918 89.6579 113.749 89.588 113.573 89.588C113.397 89.588 113.228 89.6579 113.104 89.7825C112.979 89.907 112.909 90.0759 112.909 90.252C112.909 90.4281 112.979 90.597 113.104 90.7215C113.228 90.846 113.397 90.916 113.573 90.916Z"
        fill="#279D59"
      />
      <path
        d="M138.453 83.692C138.221 85.114 135.142 86.311 135.142 86.311C133.728 88.431 133.472 93.518 133.427 94.97C133.841 95.217 134.268 95.439 134.708 95.636C134.708 95.636 135.142 88.586 140.608 85.875C142.383 84.991 138.453 83.692 138.453 83.692Z"
        stroke="#186A3B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
