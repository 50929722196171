import { ArrowLeft } from '../arrow-left';
import { ArrowRight } from '../arrow-right';
import { Bess } from '../bess';
import { Biogas } from '../biogas';
import { BookEdit } from '../book-edit';
import { BookSearch } from '../book-search';
import { Buildings } from '../buildings';
import { Calendar } from '../calendar';
import { CameraNoPicture } from '../camera-no-picture';
import { Check } from '../check';
import { ChevronLeft } from '../chevron-left';
import { Clock } from '../clock';
import { CreditCard } from '../credit-card';
import { Currencies } from '../currencies';
import { CurrencyEuro } from '../currency-euro';
import { Download } from '../download';
import { Edit } from '../edit';
import { Ellipsis } from '../ellipsis';
import { EyeOpen } from '../eye-open';
import { Flag } from '../flag';
import { GraphBar } from '../graph-bar';
import { HandShake } from '../handshake';
import { Hydrogen } from '../hydrogen';
import { Lightning } from '../lightning';
import { Linkedin } from '../linkedin';
import { LogOut } from '../log-out';
import { Mail } from '../mail';
import { Minus } from '../minus';
import { MyOffers } from '../my-offers';
import { MyProjects } from '../my-projects';
import { Opportunities } from '../opportunities';
import { Others } from '../others';
import { People } from '../people';
import { Person } from '../person';
import { Phone } from '../phone';
import { Plus } from '../plus';
import { Price } from '../price';
import { QuestionMarkCircle } from '../question-mark-circle';
import { Search } from '../search';
import { Services } from '../services';
import { Solar } from '../solar';
import { SolarWind } from '../solar-wind';
import { StartRating } from '../start-rating';
import { Status } from '../status';
import { Support } from '../support';
import { Technology } from '../technology';
import { ThumbDown } from '../thumb-down';
import { ThumbUp } from '../thumb-up';
import { Trash } from '../trash';
import { Upload } from '../upload';
import { Web } from '../web';
import { Wind } from '../wind';

export const icons = {
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'bess': Bess,
  'biogas': Biogas,
  'book-edit': BookEdit,
  'book-search': BookSearch,
  'buildings': Buildings,
  'calendar': Calendar,
  'camera-no-picture': CameraNoPicture,
  'check': Check,
  'chevron-left': ChevronLeft,
  'clock': Clock,
  'credit-card': CreditCard,
  'currencies': Currencies,
  'currency-euro': CurrencyEuro,
  'download': Download,
  'edit': Edit,
  'ellipsis': Ellipsis,
  'eye-open': EyeOpen,
  'flag': Flag,
  'graph-bar': GraphBar,
  'handshake': HandShake,
  'hydrogen': Hydrogen,
  'lightning': Lightning,
  'linkedin': Linkedin,
  'log-out': LogOut,
  'mail': Mail,
  'minus': Minus,
  'my-offers': MyOffers,
  'my-projects': MyProjects,
  'opportunities': Opportunities,
  'others': Others,
  'people': People,
  'person': Person,
  'phone': Phone,
  'plus': Plus,
  'price': Price,
  'question-mark-circle': QuestionMarkCircle,
  'search': Search,
  'services': Services,
  'solar-wind': SolarWind,
  'solar': Solar,
  'start-rating': StartRating,
  'status': Status,
  'support': Support,
  'technology': Technology,
  'thumb-down': ThumbDown,
  'thumb-up': ThumbUp,
  'trash': Trash,
  'upload': Upload,
  'web': Web,
  'wind': Wind,
};
