import { ClassValue } from 'clsx';
import { useCallback } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { Icon } from '@/components/icons';
import { IconName } from '@/components/icons/types';
import { cn } from '@/utils/styles';

import { Typography } from '../typography';

import { colors, sizes } from './constants';
import { InputColor, InputSize, InputType } from './types';

export type InputProps = {
  readonly id?: string;
  readonly type?: InputType;
  readonly size?: InputSize;
  readonly color?: InputColor;
  readonly step?: string;
  readonly prefix?: string;
  readonly suffix?: string;
  readonly leftIcon?: IconName;
  readonly rightIcon?: IconName;
  readonly placeholder?: string;
  readonly autoComplete?: string;
  readonly defaultValue?: string;
  readonly registration?: Partial<UseFormRegisterReturn>;
  readonly className?: ClassValue;
  readonly accept?: string;
  readonly readOnly?: boolean;
  readonly mask?: (value: string | null) => string;
};

export function Input({
  id,
  type = 'text',
  size = 'md',
  color = 'primary',
  step = '0.01',
  prefix,
  suffix,
  leftIcon,
  rightIcon,
  placeholder,
  autoComplete = 'off',
  defaultValue,
  registration,
  className,
  readOnly = false,
  mask,
}: InputProps) {
  const isDisabled = registration?.disabled ?? false;
  const isLarge = size === 'lg';

  const handleOnKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (mask) {
        event.preventDefault();
        event.currentTarget.value = mask(event.currentTarget.value);
      }
    },
    [mask]
  );

  return (
    <div className="group flex items-stretch justify-start">
      {prefix ? (
        <span
          className={cn(
            'rounded-l border-y border-l border-neutral-50 bg-neutral-25',
            isDisabled ? 'cursor-not-allowed' : 'cursor-default',
            'flex shrink-0 items-center justify-center',
            sizes[size]
          )}
        >
          <Typography size={isLarge ? 'body-1' : 'body-2'} color="secondary" weight="normal">
            {prefix}
          </Typography>
        </span>
      ) : null}

      <div className="relative w-full">
        {leftIcon && (
          <span
            className={cn(
              'absolute left-px top-px z-[2]',
              'flex w-10 items-center justify-center',
              isDisabled ? 'cursor-not-allowed' : 'cursor-default',
              isLarge ? 'h-11' : 'h-10',
              'pointer-events-none'
            )}
          >
            <Icon name={leftIcon} className={cn('text-neutral-500', isLarge ? 'h-6 w-6' : 'h-5 w-5')} />
          </span>
        )}

        <input
          id={id}
          type={type}
          {...(type === 'number' ? { step } : {})}
          placeholder={placeholder}
          autoComplete={autoComplete}
          defaultValue={defaultValue}
          className={cn(
            'w-full',
            'placeholder:text-base-secondary font-normal text-base-primary sm:text-sm sm:leading-6',
            sizes[size],
            'appearance-none  border focus:ring-0 focus:ring-offset-0',
            colors[color],
            'disabled:border-neutral-50 disabled:bg-neutral-25',
            'disabled:text-neutral-disabled disabled:placeholder:text-neutral-disabled',
            'disabled:cursor-not-allowed',
            { 'pl-10': leftIcon, 'pr-10': rightIcon },
            { 'rounded-l': !prefix, 'rounded-r': !suffix },
            className
          )}
          readOnly={readOnly}
          onKeyUp={handleOnKeyUp}
          {...registration}
        />

        {rightIcon && (
          <span
            className={cn(
              'absolute right-px top-px z-[2]',
              'flex w-10 items-center justify-center',
              isDisabled ? 'cursor-not-allowed' : 'cursor-default ',
              isLarge ? 'h-11' : 'h-10',
              'pointer-events-none'
            )}
          >
            <Icon name={rightIcon} className={cn('text-neutral-500', isLarge ? 'h-6 w-6' : 'h-5 w-5')} />
          </span>
        )}
      </div>

      {suffix ? (
        <span
          className={cn(
            'rounded-r border-y border-r border-neutral-50 bg-neutral-25',
            isDisabled ? 'cursor-not-allowed' : 'cursor-default',
            'flex shrink-0 items-center justify-center',
            sizes[size]
          )}
        >
          <Typography size={isLarge ? 'body-1' : 'body-2'} color="secondary" weight="normal">
            {suffix}
          </Typography>
        </span>
      ) : null}
    </div>
  );
}
