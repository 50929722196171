import { ChipColor } from '@/components/chips/types';
import { Deal, DealDetails } from '../types';
import { getColorFromDealStatus } from './helpers';

export function dealDetailsTransformer(deal?: Deal): DealDetails | null {
  if (!deal) return null;

  const isPaid = deal.type.toLowerCase() === 'paid';
  const statusColor = getColorFromDealStatus(deal.status) as ChipColor;
  return {
    id: deal.id,
    title: deal.title,
    country: deal.country,
    city: deal.city,
    technologies: deal.technologies,
    category: deal.category,
    deadline: new Date(deal.deadline),
    description: deal.description,
    companyDescription: deal.company_description,
    attachments: deal.attachments.map((attachment) => attachment.directus_files_id),
    teaserId: deal.attachments.find((attachment) => attachment.type === 'teaser')?.directus_files_id.id || null,
    isPaid,
    status: {
      name: deal.status,
      color: statusColor,
    },
  };
}
