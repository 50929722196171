import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AnimationShell, Button, Chip, Icons, Typography } from '@/components';
import { Route } from '@/constants';
import { OpportunityCards } from '@/features/opportunities';
import { useLocation } from '@/features/projects/hooks';
import { useRegisterGAPageView } from '@/hooks';
import { isNullish } from '@/types';
import { cn } from '@/utils/styles';

import { useOfferHeaderDetails } from '../../hooks';
import { useCanViewOffer } from '../../hooks/use-can-view-offer';
import { ActionOfferModals } from '../modals/action-offer-modals';

import { OfferHeaderTabs } from './offer-header-tabs';
import { useBelongsToMyCompany } from '@/features/company/hooks';

export type OfferHeaderViewProps = {
  readonly id: string;
  readonly tabActive: number;
  readonly setTabActive: (index: number) => void;
};

export function OfferHeaderView({ id, tabActive, setTabActive }: OfferHeaderViewProps) {
  const { t } = useTranslation(['offers']);
  const navigate = useNavigate();

  const offer = useOfferHeaderDetails(id);
  const offerBelongsToMyCompany = useBelongsToMyCompany(offer?.company);
  const opportunityBelongsToMyCompany = useBelongsToMyCompany(offer?.opportunity?.company);

  useRegisterGAPageView(offer ? `Offer: ${offer.opportunity.name}` : null);

  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);

  const location = useLocation({ location: offer ? offer.opportunity.location : [] });
  const canViewOffer = useCanViewOffer(id);

  useEffect(() => {
    if (!isNullish(canViewOffer) && !canViewOffer) {
      navigate(Route.Opportunities);
    }
  }, [canViewOffer, navigate]);

  if (!offer) return null;

  return (
    <AnimationShell type="opacity" className={cn('shrink-0 space-y-4')}>
      <div className="inline-flex w-full items-center justify-between gap-4">
        <Icons size="xl" icons={offer.opportunity.technologies.icons.states} />
        <div className="inline-flex items-center justify-start gap-2">
          {!offerBelongsToMyCompany && opportunityBelongsToMyCompany && offer.status.name === 'pending' ? (
            <>
              <Button size="sm" color="primary" icon="check" onClick={() => setIsAcceptModalOpen(true)}>
                {t('Accept offer')}{' '}
              </Button>
              <Button size="sm" color="secondary" icon="trash" onClick={() => setIsDeclineModalOpen(true)}>
                {t('Decline offer')}{' '}
              </Button>
            </>
          ) : (
            <Chip color={offer.status.color}>{offer.status.name}</Chip>
          )}
        </div>
      </div>
      <AnimationShell type="slide-left">
        <Typography as="h1" size="heading-1" color="primary" weight="semibold">
          {offer.opportunity.name}
        </Typography>
        <Typography as="p" size="body-2" color="secondary" weight="normal">
          {location}
        </Typography>
      </AnimationShell>

      <OpportunityCards opportunity={offer.opportunity} />

      <OfferHeaderTabs activeTabIndex={tabActive} onTabChange={setTabActive} />

      <ActionOfferModals
        offer={offer.id}
        opportunity={offer.opportunity}
        isAcceptModalOpen={isAcceptModalOpen}
        isDeclineModalOpen={isDeclineModalOpen}
        setIsAcceptModalOpen={setIsAcceptModalOpen}
        setIsDeclineModalOpen={setIsDeclineModalOpen}
      ></ActionOfferModals>
    </AnimationShell>
  );
}
