import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function SolarWind({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="figure"
      aria-labelledby="solar-wind"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0773 11.2747C23.3702 11.5676 23.3702 12.0425 23.0773 12.3354C21.789 13.6237 20.5164 14.3706 19.2066 14.7151C17.9018 15.0583 16.624 14.985 15.3557 14.7361C14.9493 14.6563 14.6844 14.2622 14.7642 13.8557C14.8439 13.4492 15.2381 13.1844 15.6446 13.2642C16.7827 13.4875 17.8148 13.5301 18.8251 13.2644C19.8304 13 20.878 12.4133 22.0166 11.2747C22.3095 10.9818 22.7844 10.9818 23.0773 11.2747Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99526 12.7501C4.35384 12.7397 2.75806 13.3742 1.51428 14.5459C1.21278 14.8299 0.738118 14.8158 0.454091 14.5143C0.170063 14.2128 0.184227 13.7381 0.485725 13.4541C2.00093 12.0267 3.96518 11.2373 6.00475 11.2502C6.41895 11.2528 6.75261 11.5907 6.74999 12.0049C6.74737 12.4191 6.40946 12.7527 5.99526 12.7501Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.07369 16.2273C5.18753 16.2161 3.36335 16.9002 1.94963 18.1488C1.63917 18.423 1.16521 18.3936 0.891002 18.0832C0.616795 17.7727 0.646182 17.2988 0.956639 17.0246C2.64624 15.5322 4.82625 14.7145 7.08044 14.7273C8.12572 14.728 9.31152 15.1605 10.465 15.6205C10.7019 15.7149 10.9402 15.8118 11.1801 15.9093C12.1618 16.3084 13.1715 16.7189 14.2282 17.0218C15.5321 17.3957 16.841 17.5836 18.1307 17.3735C19.4056 17.1659 20.7149 16.5609 22.0166 15.2592C22.3094 14.9663 22.7843 14.9663 23.0772 15.2592C23.3701 15.5521 23.3701 16.0269 23.0772 16.3198C21.5664 17.8306 19.9753 18.5929 18.3718 18.854C16.7831 19.1128 15.2355 18.8711 13.8148 18.4637C12.6729 18.1364 11.5698 17.6876 10.5834 17.2862C10.352 17.1921 10.127 17.1005 9.90948 17.0138C8.71278 16.5366 7.7901 16.2273 7.07813 16.2273H7.07369V16.2273Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.07369 20.2117C5.18753 20.2005 3.36335 20.8846 1.94963 22.1332C1.63917 22.4074 1.16521 22.378 0.891002 22.0676C0.616795 21.7571 0.646182 21.2831 0.956639 21.0089C2.64624 19.5166 4.82625 18.6989 7.08044 18.7117C8.12572 18.7123 9.31152 19.1449 10.465 19.6048C10.7019 19.6993 10.9402 19.7961 11.1801 19.8937C12.1618 20.2928 13.1715 20.7033 14.2282 21.0062C15.5321 21.3801 16.841 21.568 18.1307 21.3579C19.4056 21.1503 20.7149 20.5453 22.0166 19.2436C22.3094 18.9507 22.7843 18.9507 23.0772 19.2436C23.3701 19.5364 23.3701 20.0113 23.0772 20.3042C21.5664 21.815 19.9753 22.5772 18.3718 22.8384C16.7831 23.0972 15.2355 22.8555 13.8148 22.4481C12.6729 22.1207 11.5698 21.6719 10.5834 21.2706C10.352 21.1764 10.127 21.0849 9.90948 20.9982C8.71278 20.521 7.7901 20.2117 7.07813 20.2117H7.07369V20.2117Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6031 5.60312C11.1394 5.06688 11.8666 4.76562 12.625 4.76562C13.3834 4.76562 14.1106 5.06688 14.6469 5.60312C15.1831 6.13935 15.4844 6.86665 15.4844 7.625C15.4844 8.38335 15.1831 9.11065 14.6469 9.64688C14.1106 10.1831 13.3834 10.4844 12.625 10.4844C11.8666 10.4844 11.1394 10.1831 10.6031 9.64688C10.0669 9.11065 9.76562 8.38335 9.76562 7.625C9.76562 6.86665 10.0669 6.13935 10.6031 5.60312ZM12.625 6.26562C12.2645 6.26562 11.9187 6.40884 11.6638 6.66378C11.4088 6.91871 11.2656 7.26447 11.2656 7.625C11.2656 7.98553 11.4088 8.33129 11.6638 8.58622C11.9187 8.84116 12.2645 8.98438 12.625 8.98438C12.9855 8.98438 13.3313 8.84116 13.5862 8.58622C13.8412 8.33129 13.9844 7.98553 13.9844 7.625C13.9844 7.26447 13.8412 6.91871 13.5862 6.66378C13.3313 6.40884 12.9855 6.26562 12.625 6.26562Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.625 1.25C13.0392 1.25 13.375 1.58579 13.375 2V3.40625C13.375 3.82046 13.0392 4.15625 12.625 4.15625C12.2108 4.15625 11.875 3.82046 11.875 3.40625V2C11.875 1.58579 12.2108 1.25 12.625 1.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0938 7.625C16.0938 7.21079 16.4295 6.875 16.8438 6.875H18.25C18.6642 6.875 19 7.21079 19 7.625C19 8.03921 18.6642 8.375 18.25 8.375H16.8438C16.4295 8.375 16.0938 8.03921 16.0938 7.625Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.625 11.0938C13.0392 11.0938 13.375 11.4295 13.375 11.8438V13.25C13.375 13.6642 13.0392 14 12.625 14C12.2108 14 11.875 13.6642 11.875 13.25V11.8438C11.875 11.4295 12.2108 11.0938 12.625 11.0938Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 7.625C6.25 7.21079 6.58579 6.875 7 6.875H8.40625C8.82046 6.875 9.15625 7.21079 9.15625 7.625C9.15625 8.03921 8.82046 8.375 8.40625 8.375H7C6.58579 8.375 6.25 8.03921 6.25 7.625Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11957 3.11957C8.41247 2.82668 8.88734 2.82668 9.18023 3.11957L10.174 4.11332C10.4669 4.40622 10.4669 4.88109 10.174 5.17398C9.88109 5.46688 9.40622 5.46688 9.11332 5.17398L8.11957 4.18023C7.82668 3.88734 7.82668 3.41247 8.11957 3.11957Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1305 3.11957C17.4234 3.41247 17.4234 3.88734 17.1305 4.18023L16.1368 5.17398C15.8439 5.46688 15.369 5.46688 15.0761 5.17398C14.7832 4.88109 14.7832 4.40621 15.0761 4.11332L16.0699 3.11957C16.3628 2.82668 16.8376 2.82668 17.1305 3.11957Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0761 10.0759C15.369 9.78298 15.8439 9.78298 16.1368 10.0759L17.1305 11.0696C17.4234 11.3625 17.4234 11.8374 17.1305 12.1303C16.8376 12.4232 16.3628 12.4232 16.0699 12.1303L15.0761 11.1365C14.7832 10.8436 14.7832 10.3688 15.0761 10.0759Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.174 10.0759C10.4669 10.3688 10.4669 10.8436 10.174 11.1365L9.18023 12.1303C8.88734 12.4232 8.41247 12.4232 8.11957 12.1303C7.82668 11.8374 7.82668 11.3625 8.11957 11.0696L9.11332 10.0759C9.40621 9.78298 9.88109 9.78298 10.174 10.0759Z"
        fill="currentColor"
      />
    </svg>
  );
}
