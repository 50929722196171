import { Transaction, TransactionDetails } from '../types';

export function transactionTransformer(transaction: Transaction): TransactionDetails {
  const isSolar = transaction.technology.includes('solar');

  return {
    id: transaction.id,
    status: transaction.status,
    createdAt: new Date(transaction.date_created).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }),
    date: new Date(transaction.date),
    dateLabel: new Date(transaction.date).toLocaleDateString('en-US', { month: 'short', year: 'numeric' }),
    buyer: transaction.buyer,
    seller: transaction.seller,
    technologies: transaction.technology,
    capacity: transaction.capacity,
    capacityLabel: `${transaction.capacity} MW` + (isSolar ? 'p' : ''),
    city: transaction.city,
    country: transaction.country,
  };
}
