import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { AnimationShell, Chip, Icons, Link, Typography, Value } from '@/components';
import { Route } from '@/constants';
import { useLocation } from '@/features/projects/hooks';
import { ProjectItemList } from '@/features/projects/types';
import { mwp, mw } from '@/helpers';
import { useDevelopmentStatus } from '@/hooks';

export type MyProjectsItemProps = {
  readonly project: ProjectItemList;
  readonly index?: number;
  readonly name?: string;
};

export function MyProjectsItem({ project, name, index = 0 }: Readonly<MyProjectsItemProps>) {
  const { t } = useTranslation(['projects']);
  const location = useLocation({ location: project ? [project.location] : [] });
  const { map: mapDevelopmentStatus } = useDevelopmentStatus();

  const suffix = project.technologies.types.includes('solar') ? mwp : mw;

  return (
    <motion.div
      initial={{ y: 0 }}
      whileHover={{ y: -6 }}
      transition={{ type: 'spring', stiffness: 100 }}
      className="w-full"
    >
      <Link to={Route.Project.replace(':projectId', project.id)} className="hover:no-underline">
        <AnimationShell
          type="scale"
          delay={0.1 * index}
          className="h-full w-full cursor-pointer space-y-4 rounded-md border border-neutral-50 bg-base-white p-4 shadow-sm transition hover:shadow-xl"
        >
          <div className="inline-flex w-full items-center justify-between gap-4">
            <Icons icons={project.technologies.icons.states} />

            <div className="inline-flex items-center justify-start gap-2">
              <Chip color={project.status.color}>{project.status.name}</Chip>
            </div>
          </div>

          <div className="space-y-0.5">
            <div className="inline-flex items-center justify-start gap-2">
              <Typography as="span" size="body-2" color="primary" weight="semibold">
                {name ?? project.title}
              </Typography>
            </div>

            <Typography as="p" size="body-3" color="tertiary" weight="normal">
              {location}
            </Typography>
          </div>

          <div className="inline-flex w-full items-center justify-start gap-4">
            <Value label={t('Capacity')}>{suffix(project.technologies.capacity)}</Value>
            <Value label={t('State')}>{project.technologies.state.list.map(mapDevelopmentStatus)}</Value>
          </div>
        </AnimationShell>
      </Link>
    </motion.div>
  );
}
