const DOMAIN_SANDBOX = 'app.sandbox.nteaser.com';
const DOMAIN_LIVE = 'app.nteaser.com';

const defaults = {
  BASE_URL: 'http://localhost:3000',
  API_BASE_URL: 'http://localhost:8055',
  ASSETS_BASE_URL: 'http://localhost:8055/assets',
  TEASER_FOLDER_ID: '30c5579a-0211-4ff5-a0c0-7082b1af40b0',
  ATTACHMENTS_FOLDER_ID: '59e633a5-a4f5-4d5e-b501-905291109c74',
  OFFERS_FOLDER_ID: '311bb47b-1450-4c80-8157-053103a07c99',
  AVATAR_FOLDER_ID: '69b774b4-3b79-49bd-a834-af19de761c22',
  LOGO_FOLDER_ID: '5f04742b-23af-482c-a5fc-597fb51bc8a2',
  GA_MEASUREMENT_ID: 'G-7XPBXWCMGG',
  STRIPE_PRICING_TABLE_ID: 'prctbl_1P2G6UEuA4eYLSbCp8WW754C',
  STRIPE_PUBLISHABLE_KEY:
    'pk_live_51OOgSZEuA4eYLSbCBY0z8Vg34E3zepFSdQxlCgyrECvmcIwQ5kFMUWzter3Dzgx4lNOvjGk9Gohdwd1z3qKJHkoV0004IJwWjM',
  SENTRY_DSN: 'https://3874f4b24c8293fe86e05d55f627157b@o4508001278033920.ingest.de.sentry.io/4508001452097616',
  SENTRY_ENABLED: true,
};

const variables = {
  development: {
    ...defaults,
    API_BASE_URL: 'https://directus.sandbox.nteaser.com',
    ASSETS_BASE_URL: 'https://directus.sandbox.nteaser.com/assets',
    SENTRY_ENABLED: false,
    SENTRY_ENVIRONMENT: 'local',
    SENTRY_PROPAGATION_TARGETS: [/^https:\/\/directus\.sandbox\.nteaser\.com/],
  },
  sandbox: {
    ...defaults,
    BASE_URL: 'https://app.sandbox.nteaser.com',
    API_BASE_URL: 'https://directus.sandbox.nteaser.com',
    ASSETS_BASE_URL: 'https://directus.sandbox.nteaser.com/assets',
    GA_MEASUREMENT_ID: 'G-7XPBXWCMGG',
    SENTRY_ENVIRONMENT: 'sandbox',
    SENTRY_PROPAGATION_TARGETS: [/^https:\/\/directus\.sandbox\.nteaser\.com/],
  },
  live: {
    ...defaults,
    BASE_URL: 'https://app.nteaser.com',
    API_BASE_URL: 'https://directus.nteaser.com',
    ASSETS_BASE_URL: 'https://directus.nteaser.com/assets',
    GA_MEASUREMENT_ID: 'G-89M5XV4BHG',
    SENTRY_ENVIRONMENT: 'live',
    SENTRY_PROPAGATION_TARGETS: [/^https:\/\/directus\.nteaser\.com/],
  },
};

const getVariables = () => {
  if (location.hostname === DOMAIN_SANDBOX) {
    return variables.sandbox;
  }

  if (location.hostname === DOMAIN_LIVE) {
    return variables.live;
  }

  return variables.development;
};

export const config = getVariables();

export const {
  BASE_URL,
  API_BASE_URL,
  ASSETS_BASE_URL,
  TEASER_FOLDER_ID,
  ATTACHMENTS_FOLDER_ID,
  OFFERS_FOLDER_ID,
  AVATAR_FOLDER_ID,
  LOGO_FOLDER_ID,
  STRIPE_PRICING_TABLE_ID,
  STRIPE_PUBLISHABLE_KEY,
  GA_MEASUREMENT_ID,
  SENTRY_DSN,
  SENTRY_ENABLED,
  SENTRY_ENVIRONMENT,
  SENTRY_PROPAGATION_TARGETS,
} = config;
