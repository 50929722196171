import { AnimationShell } from '@/components';
import { DealItemList } from '@/features/deals/types';
import { PaginatedDealsList } from './paginatedDealsList';
import { getDealsOrderedByDeadline } from '@/features/deals/helpers';

export type DealsItemsProps = {
  readonly deals: DealItemList[];
};

export function DealsItems({ deals }: DealsItemsProps) {

  const orderedDeals = getDealsOrderedByDeadline(deals);
  return (
    <AnimationShell type="opacity" className="flex w-fit flex-col">
      <PaginatedDealsList items={orderedDeals} />
    </AnimationShell>
  );
}
