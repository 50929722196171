import { useMyOpportunityList } from '@/features/opportunities/hooks';
import { useRegisterGAPageView } from '@/hooks';

import { MyOpportunitiesEmptyState } from '../empty-state';
import { MyOpportunitiesItems } from '../items';

export type MyOpportunitiesContentProps = {
  readonly onCreateOpportunity?: () => void;
};

export function MyOpportunitiesContent({ onCreateOpportunity }: MyOpportunitiesContentProps) {
  const opportunities = useMyOpportunityList();
  useRegisterGAPageView('My opportunities list');

  if (!opportunities) return null;

  if (opportunities?.length === 0) {
    return <MyOpportunitiesEmptyState onCreateOpportunity={onCreateOpportunity} />;
  }

  return <MyOpportunitiesItems opportunities={opportunities} />;
}
