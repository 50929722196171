import { useCallback } from 'react';
import ReactGa from 'react-ga4';
import { useTranslation } from 'react-i18next';

import { Button, Drawer } from '@/components';
import { useOpportunityDetails } from '@/features/opportunities/hooks/use-opportunity-details';

import { useOnCreateOffer } from '../../hooks';

import { CreateOfferForm } from './form';

export type CreateOfferDrawerProps = {
  readonly id: string;
  readonly isOpen: boolean;
  readonly setIsOpen: (state: boolean) => void;
};

export function CreateOfferDrawer({ id, isOpen, setIsOpen }: CreateOfferDrawerProps) {
  const { opportunity } = useOpportunityDetails(id);
  const { t } = useTranslation(['offers']);

  const onCloseSuccessSubmit = useCallback(() => {
    setIsOpen(false);
    if (!opportunity) return;

    ReactGa.event({
      category: 'form_submitted',
      action: `New Offer`,
      label: `Offer Created on "${opportunity.name}" opportunity`,
    });
  }, [setIsOpen, opportunity]);

  const onClose = useCallback(() => {
    setIsOpen(false);
    if (!opportunity) return;

    ReactGa.event({
      category: 'form_closed',
      action: `Offer creation proccess cancelled`,
      label: `User has abandoned the offer creation process on "${opportunity.name}" opportunity`,
    });
  }, [setIsOpen, opportunity]);

  const onSubmit = useOnCreateOffer({ id, onClose: onCloseSuccessSubmit });

  const footer = (
    <>
      <Button size="lg" color="neutral" onClick={onClose}>
        {t('Cancel')}
      </Button>
      <Button type="submit" form="create-offer-form" size="lg" color="primary">
        {t('Submit')}
      </Button>
    </>
  );

  if (!opportunity) return null;

  return (
    <Drawer title={t('Submit Your Expression of Interest')} open={isOpen} onClose={onClose} footer={footer}>
      <CreateOfferForm id="create-offer-form" opportunity={opportunity} onSubmit={onSubmit} />
    </Drawer>
  );
}
