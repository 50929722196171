import { useTranslation } from 'react-i18next';

import { Toggle } from '@/components';

export type MyOpportunitiesFilterProps = {
  readonly defaultValue?: string;
  readonly onChange: (value: string) => void;
};

export function MyOpportunitiesFilter({ defaultValue, onChange }: MyOpportunitiesFilterProps) {
  const { t } = useTranslation(['opportunities']);

  return (
    <Toggle
      defaultChecked={defaultValue === 'me'}
      registration={{
        name: 'my_opportunities',
        onChange: (event) => {
          onChange(event.target.checked);
          return Promise.resolve();
        },
      }}
    >
      {t(`Offered by me`)}
    </Toggle>
  );
}
