import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@/components';
import { Route } from '@/constants';
import { PasswordForm, PasswordFormFields, usePasswordResetMutation } from '@/features/auth';
import { useRegisterGAPageView, useTokenParam } from '@/hooks';

export function ResetPasswordPage() {
  const { t } = useTranslation(['reset-password']);
  useRegisterGAPageView('Reset password');

  const navigate = useNavigate();

  const [mutation] = usePasswordResetMutation();

  const token = useTokenParam();

  const onSubmit = useCallback(
    ({ password }: PasswordFormFields) => {
      mutation({ token, password })
        .unwrap()
        .then(() => {
          navigate(Route.SignIn);
        });
    },
    [token, mutation, navigate]
  );

  return (
    <div className="w-full space-y-8">
      <Typography>{t('Reset password')}</Typography>

      <PasswordForm onSubmit={onSubmit} />
    </div>
  );
}
