import { AnimationShell } from '@/components';
import { cn } from '@/utils/styles';

import { Card } from './card';
import { CardsType } from './types';

export function Cards({ info, asGroup = true }: CardsType) {
  const classNameAsGroup = (index: number) => {
    return cn(index ? 'grow justify-start space-y-2 border-l border-neutral-50 pl-8' : 'grow justify-start space-y-2');
  };
  const classNameAsIndividual =
    'grid w-full grid-cols-1 place-items-stretch gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4';
  const classNameCards =
    'my-4 inline-flex w-full items-center justify-between gap-4 rounded-md border border-neutral-50 bg-white p-8';

  return (
    <AnimationShell type="opacity" className={asGroup ? classNameCards : classNameAsIndividual}>
      {info.map(({ icon, title, value, className }, index) => (
        <div key={`${title}-${index}`} className={asGroup ? classNameAsGroup(index) : classNameCards}>
          <Card icon={icon} title={title} value={value} className={className} asGroup={asGroup} />
        </div>
      ))}
    </AnimationShell>
  );
}
