import { skipToken } from '@reduxjs/toolkit/query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AnimationShell, Container } from '@/components';
import { CompanyForm, CompanyFormFields, useGetCompanyQuery, useUpdateCompanyMutation } from '@/features/company';
import { useBreadcrumbs } from '@/features/header';
import { useNotifications } from '@/features/notifications';
import { useGetCurrentUserQuery } from '@/features/users';
import { useRegisterGAPageView } from '@/hooks';
import { cn } from '@/utils/styles';

export function CompanySettingsPage() {
  const { t } = useTranslation(['settings']);

  useBreadcrumbs(() => [{ title: t(`Company settings`) }]);
  useRegisterGAPageView('Company settings');

  const { data: user } = useGetCurrentUserQuery();
  const { data: company } = useGetCompanyQuery(user ? { id: user.company.id } : skipToken);
  const { add } = useNotifications();

  const [updateCompany] = useUpdateCompanyMutation();

  const onCompanySubmit = useCallback(
    ({ location_interests, ...data }: CompanyFormFields) => {
      if (!user) return;

      updateCompany({
        id: user.company.id,
        data: { ...data, location_interests: location_interests.split(',') },
      })
        .unwrap()
        .then(() => {
          add({
            id: new Date().toString(),
            title: t('Company updated'),
            description: t('Your company has been updated'),
          });
        })
        .catch(() => {
          add({
            id: new Date().toString(),
            title: t('ERROR'),
            description: t('There was an error updating your company. Please try again later.'),
          });
        });
    },

    [user, updateCompany, add, t]
  );

  return (
    <div className="flex flex-col">
      <Container className="space-y-12">
        <div className="space-y-4">
          <AnimationShell
            element="article"
            type="opacity"
            duration={0}
            className={cn(
              'mt-8 h-auto max-w-2xl overflow-hidden rounded-lg border border-neutral-50 bg-base-white shadow-sm'
            )}
          >
            <div className="items-center p-6">
              {company ? <CompanyForm company={company} onSubmit={onCompanySubmit} /> : null}
            </div>
          </AnimationShell>
        </div>
      </Container>
    </div>
  );
}
