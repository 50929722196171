import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotifications } from '@/features/notifications';

import { useUpdateOfferStatusMutation, useUpdateOfferFeedbackMutation } from '../api';
import { OfferStatus, Rating } from '../types';

export type useOnUpdateOfferProps = {
  id: string;
  opportunity?: string;
  onClose: () => void;
};

export function useOnUpdateOffer({ id, opportunity, onClose }: useOnUpdateOfferProps) {
  const { t } = useTranslation(['offers']);
  const [updateStatus] = useUpdateOfferStatusMutation();
  const [updateFeedback] = useUpdateOfferFeedbackMutation();
  const { add } = useNotifications();

  const updateOfferStatus = useCallback(
    (data: { status: OfferStatus }) => {
      if (!id) return;
      if (!opportunity) return;

      updateStatus({ id, opportunity, data })
        .unwrap()
        .then(() =>
          add({
            id: new Date().toString(),
            title: t('Offer updated'),
            description: t('An offer has been ' + data.status),
          })
        )
        .catch(() => {
          add({
            id: new Date().toString(),
            title: t('ERROR'),
            description: t('There was an error updating the offer. Please try again later.'),
          });
        })
        .finally(onClose);
    },
    [id, t, add, updateStatus, onClose, opportunity]
  );

  const updateOfferFeedback = useCallback(
    (data: Rating) => {
      if (!id) return;

      updateFeedback({ id, data })
        .unwrap()
        .then(() =>
          add({
            id: new Date().toString(),
            title: t('Feedback sent'),
            description: t('Your feedback has been sent'),
          })
        )
        .catch(() => {
          add({
            id: new Date().toString(),
            title: t('ERROR'),
            description: t('There was an error sending the feedback. Please try again later.'),
          });
        })
        .finally(onClose);
    },
    [id, t, add, updateFeedback, onClose]
  );

  return { updateOfferStatus, updateOfferFeedback };
}
