import { AnimationShell } from '@/components';
import { OpportunityItemList } from '@/features/opportunities/types';

import { MyOpportunitiesItem } from '../item';

export type MyOpportunitiesItemsProps = {
  readonly opportunities: OpportunityItemList[];
};

export function MyOpportunitiesItems({ opportunities }: MyOpportunitiesItemsProps) {
  return (
    <AnimationShell type="opacity" className="grid place-items-stretch gap-6">
      {opportunities.map((opportunity, index) => (
        <MyOpportunitiesItem key={opportunity.id} index={index} opportunity={opportunity} />
      ))}
    </AnimationShell>
  );
}
