import { useTranslation } from 'react-i18next';

import { useBreadcrumbs } from '@/features/header';
import { TransactionsView } from '@/features/insights';

export function Transactions() {
  const { t } = useTranslation(['market']);
  useBreadcrumbs(() => [{ title: t(`Transactions`) }]);

  return <TransactionsView />;
}
