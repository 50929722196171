import { ClassValue } from 'clsx';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import { Icon } from '@/components';
import { cn } from '@/utils/styles';

import { colors, sizes } from './constants';
import type { SidebarColor, SidebarSize } from './types';

export type SidebarProps = {
  readonly children: ReactNode;
  readonly size?: SidebarSize;
  readonly color?: SidebarColor;
  readonly collapsed?: boolean;
  readonly className?: ClassValue;
  readonly onChange?: (collapsed: boolean) => void;
};

export function Sidebar({
  size = 'md',
  color = 'dark',
  collapsed = false,
  onChange,
  children,
  className,
}: SidebarProps) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const [isHover, setIsHover] = useState(false);

  const handleOnClick = useCallback(() => {
    setIsCollapsed((collapsed) => !collapsed);
    onChange?.(!collapsed);
  }, [collapsed, onChange]);

  const handleOnMouseEnter = useCallback(() => setIsHover(true), []);
  const handleOnMouseLeave = useCallback(() => setIsHover(false), []);

  useEffect(() => setIsCollapsed(collapsed), [collapsed]);

  return (
    <aside
      className={cn(
        'relative flex h-full shrink-0 justify-end transition-all',
        colors[color],
        isCollapsed ? 'w-aside-collapsed' : sizes[size]
      )}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      role="menubar"
    >
      <div className={cn('h-full w-full p-4', className)}>{children}</div>

      <div
        className={cn(
          'h-full w-4 shrink-0 border-r-2 border-primary-900 transition hover:border-secondary-accent',
          'absolute bottom-0 right-0 top-0 z-20'
        )}
      >
        <button
          type="button"
          className={cn(
            'relative left-0 top-8 h-8 w-8',
            'flex items-center justify-center',
            'cursor-pointer rounded-full border transition',
            'border-neutral-50 bg-white text-black transition',
            'hover:border-secondary-accent hover:bg-secondary-accent hover:text-white',
            {
              'opacity-0': !isCollapsed,
              'opacity-100': !isCollapsed && isHover,
            }
          )}
          onClick={handleOnClick}
        >
          <Icon name="chevron-left" className={cn('h-4 w-4', isCollapsed ? 'rotate-180' : 'rotate-0')} />
        </button>
      </div>
    </aside>
  );
}
