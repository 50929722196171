import { useMemo } from 'react';

import { AnimationShell, Typography } from '@/components';
import { cn } from '@/utils/styles';

export type AvatarProps = {
  readonly firstName: string;
  readonly lastName: string | null;
  readonly fullName: string;
  readonly avatar: string | null;
  readonly size?: 'sm' | 'md' | 'lg';
};

export function Avatar({ firstName, lastName, fullName, avatar, size = 'sm' }: AvatarProps) {
  const firstLetter = useMemo(() => firstName.slice(0, 1).toUpperCase(), [firstName]);
  const secondLetter = useMemo(() => lastName?.slice(0, 1).toUpperCase(), [lastName]);

  const smallSize = size === 'sm';
  const largeSize = size === 'lg';

  return (
    <AnimationShell
      type="scale"
      className={cn(
        'flex shrink-0 items-center justify-center overflow-hidden rounded-full bg-primary-accent',
        smallSize ? 'h-8 w-8' : largeSize ? 'h-14 w-14' : 'h-10 w-10'
      )}
    >
      {avatar ? (
        <figure title={fullName} role="figure" aria-label={`avatar: ${fullName}`} aria-labelledby="caption">
          <img src={avatar} className="h-full w-full rounded-full" alt={fullName} />
        </figure>
      ) : (
        <Typography
          as="span"
          size={smallSize ? 'body-3' : 'body-1'}
          weight="normal"
          color="white"
          className="uppercase tracking-wider"
        >
          {firstLetter}
          {secondLetter}
        </Typography>
      )}
    </AnimationShell>
  );
}
