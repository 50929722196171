import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { ASSETS_BASE_URL } from '@/config';
import { useSendOpportunityStats } from '@/features/stats/hooks';

import { OpportunityDetails } from '../../types';

export type FilesCollapsableProps = {
  readonly opportunity: OpportunityDetails;
  readonly initialExpandedState?: boolean;
};

export function FilesCollapsable({ opportunity, initialExpandedState = false }: FilesCollapsableProps) {
  const { t } = useTranslation(['opportunities']);
  //   NOTE: This code hide Files section when user is free.
  //   const belongsToMyCompany = useBelongsToMyCompany(opportunity.company);
  //   const { isPremium } = useCompanyRole();
  //   const isVisible = belongsToMyCompany || isPremium || opportunity.isPaid;
  const isVisible = true;

  const sendDownloadStats = useSendOpportunityStats({ type: 'download_teaser' });

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const header = (
    <CollapsableHeader
      title={t('Attachments')}
      description={t('This section includes a teaser and any other relevant documents for the opportunity.')}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );

  const data = [
    {
      label: t('Files'),
      value: (
        <div className="flex flex-col space-y-1.5">
          {opportunity.files.map(({ file }) => (
            <a
              key={file.id}
              href={`${ASSETS_BASE_URL}/${file.id}`}
              target="_blank"
              rel="noreferrer"
              className="text-base text-primary-700 hover:underline"
              onClick={() => sendDownloadStats(opportunity.id)}
            >
              {file.title}
            </a>
          ))}
        </div>
      ),
    },
  ];

  const filteredData = !isVisible ? [] : data;

  return data.length ? (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody data={filteredData} hidden={!isVisible} />
    </Collapsable>
  ) : null;
}
