import { AnimationShell, Button, Container, Typography } from '@/components';
import { cn } from '@/utils/styles';

import { ServiceDetails } from '../../types';
import { ASSETS_BASE_URL } from '@/config';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useSendServiceStats } from '@/features/stats';

export type ServiceHeaderProps = {
  readonly service: ServiceDetails;
};

export function ServiceHeader({ service }: ServiceHeaderProps) {
  const { t } = useTranslation(['services']);
  const sendDownloadStats = useSendServiceStats({ type: 'download' });
  const handleOnClickPresentation = useCallback(() => {
    sendDownloadStats(service.id);
    window.open(`${ASSETS_BASE_URL}/${service.presentationId}`, '_blank');
  }, [service.presentationId, sendDownloadStats]);

  return (
    <div className="bg-neutral-10">
      <Container className="space-y-6 pb-4 pt-24">
        <AnimationShell type="opacity" className={cn('shrink-0 space-y-3')}>
          <div className="flex items-end justify-between">
            <AnimationShell type="slide-left" className="flex flex-col space-y-2">
              <Typography as="p" size="body-2" color="primary" weight="normal">
                {service.category.name}
              </Typography>
              <Typography as="h1" size="heading-1" color="primary" weight="semibold">
                {service.name}
              </Typography>
            </AnimationShell>
            {service.presentationId && (
              <Button
                size="sm"
                color="neutral"
                icon="download"
                onClick={handleOnClickPresentation}
                className="px-6 py-2"
              >
                {t('Download Presentation')}{' '}
              </Button>
            )}
          </div>
        </AnimationShell>
      </Container>
    </div>
  );
}
